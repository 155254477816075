import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import { Row2TaskList } from './Row2TaskList';
import { IcoMenuChevronDown, IconLink } from '@src/resources/icon';
import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { DashboardKeys } from '../keys/DashboardKeys';

// 1
export function Row1CategoryItem({
  filterData,
  item,
  setOpenedJobModifyPage,
  setOpenedJobRegisterPage,
  setOpenedJobDetailPage,
  isSort,
  setIsSort,
  filterExposure,
  isFilter
}: any) {
  const queryClient = useQueryClient();
  const isFutureOrDoneLabel = item.label === 'FUTURE' || item.label === 'DONE';
  const to = useNavigate();
  const [open, setOpen] = useState(
    item?.autoCall && isFutureOrDoneLabel ? true : item.items > 0
  );

  useEffect(() => {
    if (isFutureOrDoneLabel) {
      setOpen(true);
      const k = DashboardKeys.Deep2(item.label, undefined).slice(0, 3);
      queryClient.invalidateQueries(k);
    } else {
      setOpen(item.items > 0);
    }
  }, [item]);

  return (
    <StyledRow1CategoryItem
      key={item.label}
      className={`row-1-category-item ${item.label}`}
    >
      <div
        className={cx({
          'detail-summary': true,
          open
        })}
      >
        <div
          className="btn-collapse"
          onClick={(e) => {
            e.preventDefault();
            // if (!isFutureOrDoneLabel)
            setOpen(!open); // FUTURE, DONE 은 항상 열려있어야 함 (기획정책)
          }}
        >
          <IcoMenuChevronDown
            className="icon-chevron"
            stroke="#000"
            strokeWidth={0.5}
            fill="#000"
          />
          <strong style={{ marginLeft: 10 }}>{item.label}</strong>
          <div className={`${item.label} items`}>{item.items}</div>
        </div>
        {item.url && (
          <div
            {...{
              style: { cursor: 'pointer' },
              tile: item.url,
              onClick(e) {
                e.preventDefault();
                e.stopPropagation();
                const v = item?.url?.trim?.()?.toLowerCase?.();
                if (v) to(v);
              }
            }}
          >
            <IconLink
              style={{
                transform: 'translate(8px, 2px)'
              }}
            />
          </div>
        )}
      </div>
      {open && (
        <Row2TaskList
          {...{
            filterData,
            item,
            setOpenedJobModifyPage,
            setOpenedJobRegisterPage,
            setOpenedJobDetailPage,
            isSort,
            setIsSort,
            filterExposure,
            isFilter
          }}
        />
      )}
    </StyledRow1CategoryItem>
  );
}

const StyledRow1CategoryItem = styled.div`
  margin-top: 16px;
  width: 1840px;
  .detail-summary {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #242424;
    padding-left: 21px;
    padding-right: 21px;
    border-radius: 4px;
    border: 1px solid #ececec;
    height: 46px;
    align-items: center;
    user-select: 'none';
    background-color: #fff;

    & .icon-chevron {
      transform: rotate(-90deg) translateX(2px);
    }

    .btn-collapse {
      user-select: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      .items {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17px;
        margin-left: 4px;
        background-color: #242424;
        border-radius: 12px;
        color: #fff;
        padding: 3px 8px 3px 8px;
        font-size: 11px;
        font-weight: 700;
        line-height: 15 0 0.4px;
      }
    }

    &.open {
      height: auto;
      margin-top: 40px;
      border-color: transparent;
      background-color: transparent;

      .icon-chevron {
        transform: rotate(0deg) translateY(-2px);
      }
    }
  }
`;

import { IUser } from '@src/common/config/ICode';
import React, { useEffect, useRef, useState } from 'react';
import {
  AdditionalListStyle,
  AdditionalUserNumber,
  AvatarContainer,
  Wrap,
  WrapSetPosition
} from './TPAvatar.GroupNewStyle';
import { TooltipTailRight } from '@src/resources/etc';
import { SimpleTooltip } from '../tooltip/SimpleTooltip';
import _ from 'lodash';

interface ITPAvatarGroupNew {
  userList: IUser[] | IUser;
  onClick?: (e: any) => void;
  sortByName?: boolean;
  size?: number;
}
export default function TPAvatarGroupNew(props: ITPAvatarGroupNew) {
  let { sortByName, userList: data, onClick, size } = props;

  const userList = !Array.isArray(data)
    ? [data]
    : sortByName
    ? _.sortBy(data, 'name')
    : data;
  const [showAdditionalList, setShowAdditionalList] = useState(false);

  const additionalUser = userList.length - 3;
  const userListLength = userList.length;

  const showAdditionalListOpenHandler = () => setShowAdditionalList(true);
  const showAdditionalListCloseHandler = () => setShowAdditionalList(false);

  return (
    <Wrap
      className={`TPAvatarGroupNew ${size ? 'TPState' : ''}`}
      onClick={onClick}
    >
      <WrapSetPosition itemLength={userList.length > 4 ? 4 : userList.length}>
        {userList.map((user, index) => {
          if (userListLength <= 4 ? index < 4 : index < 3) {
            let userName = user.name;
            let userGrayScale = '0%';
            let userBrightness = 1;
            if (user.status === 'INACTIVE') {
              userName = userName + ' (비활성화)';
              userGrayScale = '100%';
              userBrightness = 0.7;
            } else if (user.status === 'LEAVE') {
              userName = '삭제된 사용자';
              userGrayScale = '100%';
              userBrightness = 0.7;
            }
            return (
              <SimpleTooltip key={index} message={userName} position="bottom">
                <AvatarContainer index={index} size={size}>
                  <div className="avatar--div--wrap">
                    <img
                      src={user.avatarImgUrl}
                      alt={''}
                      style={{
                        filter: `grayscale(${userGrayScale}) brightness(${userBrightness})`
                      }}
                    />
                  </div>
                </AvatarContainer>
              </SimpleTooltip>
            );
          }
          if (index === 3) {
            return (
              <AdditionalUserMarks
                key={index}
                text={`+${additionalUser}`}
                onMouseEnter={showAdditionalListOpenHandler}
                onMouseLeave={showAdditionalListCloseHandler}
              />
            );
          }
          return null;
        })}
        {userListLength > 4 && showAdditionalList ? (
          <AdditionalList userList={userList} />
        ) : null}
      </WrapSetPosition>
    </Wrap>
  );
}

interface IAdditionalUserMarks {
  text: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const AdditionalUserMarks = (props: IAdditionalUserMarks) => {
  const { text, onMouseEnter, onMouseLeave } = props;

  return (
    <AdditionalUserNumber
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text.replace(/\+/g, '')}
    </AdditionalUserNumber>
  );
};

interface IAdditionalList {
  userList: IUser[];
}
const AdditionalList = (props: IAdditionalList) => {
  const { userList } = props;
  const ref = useRef<HTMLUListElement>(null);

  return (
    <AdditionalListStyle ref={ref}>
      {userList.slice(3).map((user, index) => {
        let userName = user.name;
        let userGrayScale = '0%';
        let userBrightness = 1;
        if (user.status === 'INACTIVE') {
          userName = userName + ' (비활성화)';
          userGrayScale = '100%';
          userBrightness = 0.7;
        } else if (user.status === 'LEAVE') {
          userName = '삭제된 사용자';
          userGrayScale = '100%';
          userBrightness = 0.7;
        }
        return (
          <li key={index}>
            <div className="avatar--div--wrap">
              <img
                src={user.avatarImgUrl}
                alt={''}
                style={{
                  filter: `grayscale(${userGrayScale}) brightness(${userBrightness})`
                }}
              />
            </div>
            {userName}
          </li>
        );
      })}
      <TooltipTailRight className="tooltipTailRight" width={8} height={8} />
    </AdditionalListStyle>
  );
};

import { Box, Tooltip } from '@mui/material';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { Wrap } from '@pages/dashboard/Worker.style';
import { IconInfo } from '@resources/icon';
import { getStatus, getTaskType, getWorkerType } from '@src/common/config/Code';
import usePostSaturationDegree from '@src/hooks/statistics/saturation/postDegree';
import Footer from '@src/layouts/footer/Footer';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import TPSelectFilterCheck from '@src/units/select/TPSelect.filter.check';
import _ from 'lodash';
import GanttChartComponent from '../components/GanttChart';
import { LanguagePairSelectComp } from '../components/LanguagePairSelect.Comp';
import { ManagerSelectComp } from '../components/ManagerSelect.Comp';

const WorkerPage = () => {
  const {
    FilterButton,
    languagePairList,
    setLanguagePairList,
    userId,
    setUserId,
    taskTypeList,
    setTaskTypeList,
    workerTypeList,
    setWorkerTypeList,
    workerStatusList,
    setWorkerStatusList,
    isFilter,
    resetState,
    refetchSaturationDegreeData,
    downloadData,
    saturationDegreeData
  } = usePostSaturationDegree();

  const selectHandler = () => refetchSaturationDegreeData();

  return (
    <section css={Wrap}>
      <div className="worker--title">
        <UnderlineTitle text="작업자 별 포화도" align="notCenter" />{' '}
        <Tooltip
          title={<p dangerouslySetInnerHTML={{ __html: titleDescription }} />}
          placement="right"
        >
          <IconInfo width={24} height={24} />
        </Tooltip>
      </div>

      <Box
        mt={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <LanguagePairSelectComp
          countItemList={[]}
          languagePairList={languagePairList}
          setLanguagePairList={setLanguagePairList}
          description={`조회할 언어페어를 선택해 주세요.`}
          outSideBtn={true}
          isSelectAll
          type="single"
        />
      </Box>
      <Box
        mb={1}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <ManagerSelectComp
          workerUserList={userId}
          setWorkerUserList={setUserId}
          description={`조회할 작업자를 선택해 주세요.`}
          outSideBtn={true}
          disabled={!languagePairList.length}
          isSelectAll
        />
      </Box>
      <Box
        mb={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'8px'}
      >
        <Box display={'inline-flex'} gap={'8px'}>
          <TPSelectFilterCheck
            label={'유형'}
            options={getTaskType([
              'ALL',
              'TRANSLATION',
              'POLISHING',
              'LQA'
            ]).map((e) => ({
              label: e.text,
              value: e.code
            }))}
            value={taskTypeList}
            onChange={(v: any) => {
              setTaskTypeList(v);
            }}
            multiple
            all
          />
          <TPSelectFilterCheck
            label="역할"
            options={getWorkerType([
              'ALL',
              'TRANSLATOR',
              'FIRST_CHECKER',
              'SECOND_CHECKER'
            ]).map((e) => ({
              label: e.text,
              value: e.code
            }))}
            value={workerTypeList}
            onChange={(v: any) => {
              setWorkerTypeList(v);
            }}
            multiple
            all
          />
          <TPSelectFilterCheck
            label="상태"
            options={getStatus([
              'ALL',
              'NEW',
              'PROGRESS',
              'WAIT_QUERY',
              'REJECT',
              'COMPLETE',
              'STOP'
            ]).map((e) => ({
              label: e.text,
              value: e.code
            }))}
            value={workerStatusList}
            onChange={(v: any) => {
              setWorkerStatusList(v);
            }}
            multiple
            all
          />
          {/* <button
            className={'worker--button--search'}
            onClick={selectHandler}
            disabled={!languagePairList.length || !userId.length}
          >
            조회
          </button> */}
          {isFilter && (
            <button className="worker--button--refresh" onClick={resetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          {/* [작업자 별 포화도] 필터링 저장*/}
          <FilterButton
            getFilterData={{
              languagePairList,
              userId,
              degreeFilter: {
                taskTypeList,
                workerTypeList,
                workerStatusList
              }
            }}
          />
        </Box>
        <button
          className={'worker--button--download'}
          onClick={downloadData}
          disabled={!languagePairList.length || !userId.length}
        >
          Excel로 내려받기
        </button>
      </Box>
      {saturationDegreeData.length ? (
        _.map(saturationDegreeData, (el, index) => (
          <Box m={'3rem 0 0 0'} key={index}>
            <GanttChartComponent {...el} />
          </Box>
        ))
      ) : (
        <Box
          m={'3rem 0 5rem 0'}
          style={{
            borderRadius: '16px',
            padding: '30px 20px 50px 20px',
            background: '#ffffff',
            boxShadow: '0 1px 32px 4px #f0edf6',
            textAlign: 'center'
          }}
        >
          <span className="worker--span--empty">
            조회된 작업자가 없습니다.
            <br />
            <br />
            "조회 조건"을 설정한 후, "조회" 버튼을 눌러
            <br />
            작업자 별 포화도를 확인할 수 있습니다.
          </span>
        </Box>
      )}

      <Footer />
    </section>
  );
};

export default WorkerPage;

const titleDescription = `
  “부작업” 기준으로 입력한 조회 조건에 따라<br/>
  작업자 별 포화도를 확인할 수 있습니다.`;

import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { IPMList } from '@src/common/config/ICode';
import { companyPerKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';

/**
 * /statistics/pmList
 * @description 고객사 & 프로젝트 별 통계
 * @returns {{ taskerList }}
 */
export const useGetStatisticsPMList = () => {
  const { defaultUserList } = useHookFunc();
  const getData = async (): Promise<IPMList> => {
    return (await api.getPms(`/statistics/pmList`)).data;
  };

  const { data = { pmList: [...defaultUserList] } } = useQuery(
    [...companyPerKeys.pmList],
    getData,
    {
      select: (data) => ({
        pmList: [...defaultUserList, ...data.projectManagerUserDataList]
      })
    }
  );

  const { pmList } = data;

  return { pmList };
};

import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { apiKeys } from '@src/pages/myTask/MyTaskTab.page';

/**
 * /myWork/status
 * @discription 내 작업 상태 변경
 * @returns {{ putStatus }}
 */
export const usePutMyWorkStatus = () => {
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();

  const putData = async (payload: {
    workerId: string;
    workerStatus: string;
  }) => {
    return await api.put('/myWork/status', { ...payload });
  };

  const { mutateAsync: putStatus } = useMutation({
    mutationFn: putData,
    onSuccess: () => {
      queryClient.cancelQueries(['/myWork']);
      queryClient.invalidateQueries(['/myWork']);
      queryClient.cancelQueries(['myWork']);
      queryClient.invalidateQueries(['myWork']);
      queryClient.cancelQueries(['listByDay']);
      queryClient.invalidateQueries(['listByDay']);
      queryClient.cancelQueries(apiKeys.listByDay());
      queryClient.invalidateQueries(apiKeys.listByDay());
      toastOpen({ text: '작업 상태가 변경되었습니다.', severity: 'success' });
    },
    onError: (error: any) => {
      toastOpen({ text: '작업 상태 변경에 실패했습니다.', severity: 'error' });
    }
  });

  return { putStatus, toastOpen };
};

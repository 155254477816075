import { Box } from '@mui/system';
import { LANGUAGE, TypeCategory } from '@src/common/config/Code';
import { IUser } from '@src/common/config/ICode';
import ModalType from '@src/common/config/ModalType';
import { textSlice } from '@src/common/utils/Func';
import { IconDetail } from '@src/resources/icon';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import TaskNameViewerComp from '@src/pages/components/TaskNameViewer.Comp';
import { DeadlineCheckDateViewer } from '../components/DeadlineCheckDateViewer';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import _ from 'lodash';

export const taskColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'languageTaskStatus',
      value: '상태',
      width: 70,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      renderRowData: (data: any) => <StatusBadge statusCode={data} />
    },
    {
      code: 'languageTaskKey',
      value: '키',
      width: 76,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="key"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 88,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트',
      width: 160,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: (data: any) => {
        if (data) {
          return <TaskNameViewerComp text={data} />;
        }
      }
    },
    {
      code: 'taskTitle',
      value: '작업',
      width: 250,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: (data: any) => {
        if (data) {
          return <TaskNameViewerComp text={data} />;
        }
      }
    },
    {
      code: 'taskType',
      value: '유형',
      width: 72,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="taskType"
          />
        );
      },
      renderRowData: (data: any) => {
        const typeCategory = (TypeCategory[data] as string) || '';
        return <span>{typeCategory}</span>;
      }
    },
    {
      code: 'startLanguage',
      value: '출발어',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'totalGrossAmount',
      value: '총 분량(Gross)',
      width: 110,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
            showTooltip="AmountGross"
          />
        );
      },
      renderRowData: (data: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        return <span>{taskAmountSetForm}</span>;
      }
    },
    {
      code: 'totalNetAmount',
      value: '총 분량(Net)',
      width: 110,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
            showTooltip="AmountNET"
          />
        );
      },
      renderRowData: (data: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        return <span>{taskAmountSetForm}</span>;
      }
    },
    {
      code: 'hopeSupplyDateTime',
      value: '납품일',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return <DeadlineCheckDateViewer data={data} rowData={rowData} />;
      }
    },
    {
      code: 'startDateTime',
      value: '시작일',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any) => {
        return format(new Date(data), 'yy.MM.dd HH:mm').toLowerCase();
      }
    },
    {
      code: 'endDateTime',
      value: '마감일',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return <DeadlineCheckDateViewer data={data} rowData={rowData} />;
      }
    },
    {
      code: 'pmManager',
      value: '담당PM',
      width: 80,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="pm"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        const mainPm = rowData.mainProjectManager;
        const subPm = rowData.subProjectManager;
        const userList = [mainPm, subPm];

        return <TPAvatarGroupNew userList={userList}></TPAvatarGroupNew>;
      }
    },
    {
      code: 'workUserList',
      value: '작업자',
      width: 96,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        const userList = _.uniqBy(data, 'userId');
        const stopWorking = rowData.languageTaskStatus === 'STOP';
        const deduplicationManagers: IUser[] = [
          ...new Set(userList.map((el: any) => JSON.stringify(el)))
        ].map((el: any) => JSON.parse(el));

        deduplicationManagers.sort((a: any, b: any) => {
          if (a.userId - b.userId > 0) return 1;
          return -1;
        });
        if (deduplicationManagers.length === 0) {
          return (
            <TPButtonBasic
              label="배정하기"
              to={`/task/${ModalType.JOBASSIGN_PAGE}/${rowData.languageTaskId}`}
              size="small"
              startIcon={null}
              variant="outlined"
              disabled={stopWorking}
            />
          );
        }
        return <TPAvatarGroupNew userList={deduplicationManagers} />;
      }
    },
    {
      code: 'option',
      value: '',
      width: 22,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        const workerPresence = rowData.workUserList.length;
        const disable = ['COMPLETE', 'STOP', 'DELIVERY_COMPLETE'].includes(
          String(rowData.languageTaskStatus)
        );

        switch (rowData.languageTaskStatus) {
          case 'STOP':
          case 'COMPLETE':
            return;
          default:
            break;
        }

        return !disable && workerPresence > 0 ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TPPopoverMenu
              popoverMenus={[
                {
                  clickMenu: () => {},
                  to: `/task/${ModalType.JOBASSIGN_PAGE}/${rowData.languageTaskId}?history=true`,
                  state: {
                    modalData: {
                      title: '작업자 배정',
                      okLabel: '수정하기',
                      cancelLabel: '취소하기',
                      data: rowData
                    }
                  },
                  label: '작업자 변경하기'
                }
              ]}
              clickPopoverButton={(e: any) => {
                e.stopPropagation();
              }}
            >
              <TPButtonAction icon={<IconDetail />} size="small" />
            </TPPopoverMenu>
          </Box>
        ) : null;
      }
    }
  ] as const;

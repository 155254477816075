import React from 'react';
import { subTaskRole } from '@src/common/config/Code';
import { renderLanguageSubTaskStatus } from '../../pages/dashboard/utils/render';
import { Avatar } from './Avatar';
import { cx } from '@emotion/css';

export type TWorker = {
  userId: number;
  slackId: string;
  workerId: number;
  workerStatus: string;
  workerType: string;
  name: string;
  avatarImgUrl: string;
  status: string;
};

export function Worker({ worker, type = '' }: { worker: any; type: string }) {
  return (
    <div className={cx('worker', worker?.workerStatus)}>
      <div className="status">
        {worker?.workerStatus
          ? renderLanguageSubTaskStatus(worker?.workerStatus as any)
          : '미배정'}
      </div>
      <Avatar worker={worker} />
      <div className="role">
        {/* @ts-ignore */}
        {worker?.workerType
          ? subTaskRole[worker.workerType as any] ?? type
          : type}
      </div>
    </div>
  );
}

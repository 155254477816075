import { ThemeProvider } from '@mui/material/styles';
import { TPInputTextTheme } from './TPInput.theme';
import { Box, FormLabel } from '@mui/material';

interface IInputProps {
  value?: string | React.ReactNode;
  label?: string;
  name: string;
  mt?: number;
}

export const TPInputText = (props: IInputProps) => {
  const { value, label, name, mt } = props;

  return (
    <ThemeProvider theme={TPInputTextTheme}>
      <div>
        {label ? (
          <Box display={'flex'} textAlign={'left'} mt={mt}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
          </Box>
        ) : null}
        <Box display={'flex'} flexDirection={'column'} mt={label ? 1 : mt}>
          {value}
        </Box>
      </div>
    </ThemeProvider>
  );
};

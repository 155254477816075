import api from '@api/Api';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { authKeys } from '@hooks/queryKeyFactory';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Constant from '@common/config/Constant';

type ErrorType<T> = {
  error: T | unknown;
};

export const usePutAuthentication = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { refetchMyInfo: getMyInfo } = useMyInfo();

  const putData = async () => {
    return (
      await api.put(authKeys.authorizationPrivacyAgree[0], {
        privacyPolicyAgreement: Constant.PRIVACY_POLICY_AGREEMENT_YES
      })
    ).data;
  };

  const signOut = async () => {
    localStorage.removeItem('privacyAgree');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessTokenExpiresIn');
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 !== null) {
        try {
          await auth2.signOut();
          await auth2.disconnect();
        } catch (e) {}
      }
    }
    queryClient.clear();
    navigate('/login', { replace: true });
    // window.location.replace('/login');
  };

  const { mutate: putAuth, error } = useMutation(
    authKeys.authorizationPrivacyAgree,
    {
      mutationFn: putData,
      onError(error, variables, context) {
        // alert(JSON.stringify(error, null, 2));
        localStorage.removeItem('privacyAgree');
        signOut();
      },
      onSuccess(data, variables, context) {
        if (/^OK$/i.test(data.result)) {
          localStorage.setItem('privacyAgree', 'DONE');
          getMyInfo();
          navigate('/login', { replace: true });
        } else {
          signOut();
        }
      }
    }
  );

  const errorInfo = Object(error as ErrorType<Object>);

  return {
    putAuth,
    errorInfo,
    signOut
  };
};

import {
  ILanguageSubTask,
  ILanguageTaskList
} from '@src/common/config/ILanguageTask';
import { IMyTaskList } from '@src/common/config/IMyTask';
import { IProjectList } from '@src/common/config/IProject';
import { ITaskList } from '@src/common/config/ITask';
import { IconBulCircleDown, IconBulCircleUp } from '@src/resources/icon';
import { useEffect, useRef, useState } from 'react';
import {
  DataTableColumnHeadStyle,
  DataTableRowDataStyle,
  DataTableStyle,
  NoMessageContainer
} from './DataTable.style';
import {
  LanguageCodeType,
  StatusCodeType,
  WorkerTypeCode
} from '@common/config/Code';
import { AvatarProgressType } from '@units/avatar/TPAvatar.WorkerType';
import { IUser, TASK_TYPE } from '@common/config/ICode';
import _ from 'lodash';
import { ICustomerData } from '@src/hooks/statistics/companyPerProject/getList';
import { StatusBadge } from '@src/units/badge/StatusBadge';

type dataTableCodeType = {
  code: string;
  value: any;
  width: number;
  renderHeader: any;
  renderRowData: any;
};

type DataListType =
  | Partial<IFullLookupDataList>
  | Partial<ICustomerData>
  | Partial<IProjectList>
  | Partial<ITaskList>
  | Partial<IMyTaskList>
  | Partial<ILanguageTaskList>;

interface IDataTable {
  myInfo?: any;
  columns: readonly dataTableCodeType[];
  dataList: DataListType[];
  clickRowData?: (rowData: any) => void;
  noRowsMessage?: string;
  defaultAccordionExposure?: boolean;
  gap?: number;
}

interface IDataTableColumnHead {
  columns: readonly dataTableCodeType[];
  gap?: number;
}

interface IDataTableRowData {
  myInfo?: any;
  rowEnd: boolean;
  rowData: DataListType;
  columns: readonly dataTableCodeType[];
  defaultAccordionExposure: boolean;
  onClick: () => void;
  gap?: number;
}

type pmManagerType =
  | [
      { projectManagerType: 'MAIN'; projectManager: IUser },
      { projectManagerType: 'SUB'; projectManager: IUser }
    ]
  | [];

type StringKeyType = { [key in string]: any };

export interface ISubTask extends ILanguageSubTask, StringKeyType {
  languageSubTaskId: number;
  languageSubTaskKey: string;
  languageSubTaskProcess: WorkerTypeCode;
  languageSubTaskWorker: AvatarProgressType[];
  destinationLanguage: LanguageCodeType;
  endDateTime: Date | string;
  hopeSupplyDate: Date | string;
  netAmount: number;
  pmManager: pmManagerType;
  startDate: Date | string;
  startLanguage: LanguageCodeType;
  taskName: string;
  taskType: TASK_TYPE;
}

export interface IFullLookupDataList extends StringKeyType {
  taskId: number;
  taskStatus: StatusCodeType;
  taskKey: string;
  companyName: string; //
  projectName: string;
  taskName: string;
  taskType: TASK_TYPE;
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType; //
  totalGrossAmount: number;
  totalNetAmount: number;
  hopeSupplyDate: string;
  startDate: string;
  endDateTime: string;
  id: number;
  pmManager: pmManagerType;
  tasker: IUser[];
  languageSubTask: ISubTask[] | [];
}

const DataTableColumnHead = (props: IDataTableColumnHead) => {
  const { columns, gap } = props;
  return (
    <DataTableColumnHeadStyle gap={gap}>
      <div className="head--up--down" />
      {columns.map((el, index) => {
        const width = el.width > 0 ? el.width : null;
        const style = {
          width: `${width}px`
        };

        const renderHeader = el.renderHeader;
        if (renderHeader === 'string')
          return (
            <div className="head" key={index} style={style}>
              <span>{el.value}</span>
            </div>
          );
        return (
          <div className="head" key={index} style={style}>
            {el.renderHeader()}
          </div>
        );
      })}
    </DataTableColumnHeadStyle>
  );
};

const DataTableRowData = (props: IDataTableRowData) => {
  const { rowEnd, rowData, columns, onClick, defaultAccordionExposure, gap } =
    props;
  const [openSubRowData, setOpenSubRowData] = useState(
    defaultAccordionExposure
  );
  const [subLineHeightInfo, setSubLineHeightInfo] = useState({
    mainHeight: 0,
    subEndHeight: 0,
    rowTotalHeight: 0
  });
  const { mainHeight, subEndHeight, rowTotalHeight } = subLineHeightInfo;
  const refRow = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpenSubRowData(defaultAccordionExposure);
  }, [defaultAccordionExposure]);

  useEffect(() => {
    if (refRow.current && openSubRowData && rowData.projectList?.length > 0) {
      const refRowSelector = refRow.current;

      const rowTotalHeight = window.getComputedStyle(refRowSelector).height;
      const mainHeight = window.getComputedStyle(
        refRowSelector.childNodes[0] as any
      ).height;
      const subEndHeight = window.getComputedStyle(
        refRowSelector.childNodes[1]?.lastChild! as HTMLDivElement
      ).height;

      setSubLineHeightInfo({
        // 아코디언 펼쳤을때 전체 높이
        rowTotalHeight: Number(rowTotalHeight.split('px')[0]),
        // 작업 rowData 높이
        mainHeight: Number(mainHeight.split('px')[0]),
        // 언어부작업 마지막 rowData 높이
        subEndHeight: Number(subEndHeight.split('px')[0])
      });
    }
  }, [openSubRowData]);

  const subRowDataOpenHandler = () => {
    setOpenSubRowData(!openSubRowData);
  };
  const showSubRowData = openSubRowData;

  return (
    <DataTableRowDataStyle
      ref={refRow}
      onClick={onClick}
      rowEnd={rowEnd}
      showSubRowData={showSubRowData}
      gap={gap}
    >
      <div className="main" onClick={subRowDataOpenHandler}>
        <div className="rowDataBlock--up--down">
          {rowData.projectList?.length > 0 ? (
            openSubRowData ? (
              <IconBulCircleUp width={16} height={16} />
            ) : (
              <IconBulCircleDown width={16} height={16} />
            )
          ) : null}
        </div>
        {columns.map((el, index) => {
          const data = rowData[el.code];
          const width = el.width > 0 ? el.width : null;
          const style = {
            width: `${width}px`
          };

          if (el.code === 'allPmUserList') {
            const userList = _.uniqBy(data ?? [], 'userId');
            const pmManager: IUser[] = [
              ...new Set(userList.map((el: any) => JSON.stringify(el)))
            ].map((el: any) => JSON.parse(el));

            pmManager.sort((a: any, b: any) => {
              if (a.userId - b.userId > 0) return 1;
              return -1;
            });
            return (
              <div className="rowDataBlock" key={index} style={style}>
                {data ? el.renderRowData(pmManager, rowData) : '-'}
              </div>
            );
          }
          return (
            <div className="rowDataBlock" key={index} style={style}>
              {data ?? '-'}
            </div>
          );
        })}
      </div>
      {showSubRowData ? (
        <div className="sub">
          {rowData.projectList?.map((projectList: ISubTask, index: number) => {
            const isEndSubRow = rowData.projectList.length - 1 === index;
            const subLineHeight =
              rowTotalHeight - mainHeight / 2 - subEndHeight / 2 - 16;
            const endSubLineStyle = {
              height: `${subLineHeight}px`
            };
            const projectKey = projectList.projectKey;
            const projectStatus = projectList.projectStatus;
            const noneBottomLine =
              rowData.projectList.length - 1 === index && rowEnd;

            return (
              <div
                key={index}
                className={`sub--rowData ${
                  noneBottomLine ? 'none--bottom--line' : ''
                }`}
              >
                <div className="customer--status--key">
                  <div
                    className={`line ${isEndSubRow ? 'endSubRow' : ''} `}
                    style={isEndSubRow ? endSubLineStyle : undefined}
                  />
                  <StatusBadge statusCode={projectStatus} />
                </div>
                {columns.slice(1).map((el, index) => {
                  let data = projectList[el.code];

                  switch (el.code) {
                    case 'companyName':
                      data = projectList.companyName ?? '-';
                      break;
                    case 'totalTaskAmount':
                      data = projectKey
                        ? projectList.taskAmount
                        : rowData.totalTaskAmount;
                      break;
                    case 'totalLanguageTaskAmount':
                      data = projectKey
                        ? projectList.languageTaskAmount
                        : rowData.totalLanguageTaskAmount;
                      break;
                    case 'totalGrossAmount':
                      data = projectKey
                        ? projectList.grossAmount
                        : rowData.totalGrossAmount;
                      break;
                    case 'totalNetAmount':
                      data = projectKey
                        ? projectList.netAmount
                        : rowData.totalNetAmount;
                      break;
                    case 'projectTitle':
                      data = projectList.projectTitle ?? '-';
                      break;
                    case 'projectStartDate':
                      data = projectList.projectStartDateString ?? '-';
                      break;
                    case 'projectEndDate':
                      data = projectList.projectEndDateString ?? '-';
                      break;
                    case 'allPmUserList':
                      const userList = _.uniqBy(
                        projectList.pmUserList ?? [],
                        'userId'
                      );
                      const pmManager: IUser[] = [
                        ...new Set(
                          userList.map((el: any) => JSON.stringify(el))
                        )
                      ].map((el: any) => JSON.parse(el));

                      pmManager.sort((a: any, b: any) => {
                        if (a.userId - b.userId > 0) return 1;
                        return -1;
                      });
                      data = pmManager;
                      break;
                  }

                  const renderRowData: any = el.renderRowData;
                  const width = el.width > 0 ? el.width : null;

                  const style = {
                    width: `${width}px`,
                    marginLeft: `
                      ${index === 0 ? '8px' : null}`
                  };

                  if (renderRowData === 'string') {
                    return (
                      <div key={index} className="rowDataBlock" style={style}>
                        {data ?? '-'}
                      </div>
                    );
                  }

                  return (
                    <div key={index} className="rowDataBlock" style={style}>
                      {data ? renderRowData(data, rowData) : '-'}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : null}
    </DataTableRowDataStyle>
  );
};

const DataTableContainer = (props: IDataTable) => {
  const {
    myInfo,
    dataList,
    columns,
    defaultAccordionExposure,
    gap,
    noRowsMessage,
    clickRowData
  } = props;
  return (
    <DataTableStyle>
      <div className="container">
        <DataTableColumnHead gap={gap} columns={columns} />
        {!!dataList.length ? (
          dataList.map((rowData, index) => {
            return (
              <DataTableRowData
                key={index}
                myInfo={myInfo}
                rowEnd={index === dataList.length - 1}
                rowData={rowData}
                columns={columns}
                defaultAccordionExposure={defaultAccordionExposure as boolean}
                gap={gap}
                onClick={() => {
                  if (clickRowData) clickRowData(rowData);
                }}
              />
            );
          })
        ) : (
          <NoMessageContainer>
            <span>{noRowsMessage}</span>
          </NoMessageContainer>
        )}
      </div>
    </DataTableStyle>
  );
};

const DataTableCustomer = (props: IDataTable) => {
  const {
    myInfo,
    columns,
    dataList,
    clickRowData,
    noRowsMessage,
    defaultAccordionExposure = true,
    gap
  } = props;

  return (
    <DataTableContainer
      myInfo={myInfo}
      dataList={dataList}
      columns={columns}
      clickRowData={clickRowData}
      noRowsMessage={noRowsMessage}
      defaultAccordionExposure={defaultAccordionExposure}
      gap={gap}
    />
  );
};

export default DataTableCustomer;

import { Wrap } from '@pages/dashboard/WordWorker.style';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { IconCalendar, IconInfo, IconRefresh } from '@resources/icon';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { IUserWorkerPerWordLastRowTotalAmount } from '@common/config/IStatistics';
import { Box, Tooltip } from '@mui/material';
import { TPButtonActionCalendar } from '@units/button/TPButton.ActionCalendar';
import { format } from 'date-fns';
import DataTable from '@pages/components/DataTable/DataTable';
import { wordWorkerColumns } from '@pages/dashboard/WordWorker.columns';
import { useGetFilter } from '@hooks/searchFilter';
import { LanguagePairSelectComp } from '@pages/components/LanguagePairSelect.Comp';
import { useGetWorkerList } from '@hooks/statistics/wordAmount/postWorker';
import { ManagerSelectComp } from '@pages/components/ManagerSelect.Comp';
import Footer from '@src/layouts/footer/Footer';

const WordWorkerPage = () => {
  const titleDescription = `
    “부작업” 기준으로 작업자 별 단어 수를 확인할 수 있습니다.<br/>
    한 명의 작업자가 2개 이상의 언어페어를 담당하는 경우,<br/>
    동일한 작업자가 여러 번 노출 될 수 있습니다.`;
  const { filterData, Component: FilterButton } = useGetFilter(
    'WORD_AMOUNT_WORKER',
    '조회 조건'
  );
  console.debug(filterData);

  const {
    languagePairList,
    workerUserList,
    wordAmountWorkerList,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState,
    downloadData,
    fatchData
  } = useGetWorkerList(filterData, true);
  const {
    setDateFilter,
    setLanguagePairList,
    setWorkerUserList,
    setResetState,
    setIsSort
  } = setState;
  const { openHandle, Component: Calendar } = useRangeCalendar();
  const { workerPerWordLastRowTotalAmount, workerPerWordAmountList } =
    wordAmountWorkerList;

  const {
    new: totalNew,
    progress,
    stop,
    reject,
    complete,
    totalAmount,
    percentage
  } = workerPerWordLastRowTotalAmount as IUserWorkerPerWordLastRowTotalAmount;

  return (
    <section css={Wrap}>
      <div className="wordworker--title">
        <UnderlineTitle text="작업자 별 단어 수" align="notCenter" />{' '}
        <Tooltip
          title={<p dangerouslySetInnerHTML={{ __html: titleDescription }} />}
          placement="right"
        >
          <IconInfo width={24} height={24} />
        </Tooltip>
      </div>
      <Box
        mt={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <LanguagePairSelectComp
          countItemList={wordAmountWorkerList}
          languagePairList={languagePairList}
          setLanguagePairList={setLanguagePairList}
          description={`조회할 언어페어를 선택해 주세요.`}
          outSideBtn={true}
        />
      </Box>
      <Box
        mb={1}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <ManagerSelectComp
          workerUserList={workerUserList}
          setWorkerUserList={setWorkerUserList}
          description={`조회할 작업자를 선택해 주세요.`}
          outSideBtn={true}
        />
      </Box>
      <Box
        mb={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'inline-flex'} gap={'8px'}>
          <div
            onClick={() => {
              openHandle('dateRange', dateFilter, setDateFilter);
            }}
          >
            <TPButtonActionCalendar
              outline={true}
              size={'medium'}
              content={
                dateFilter.startDateTime && dateFilter.endDateTime
                  ? `${format(
                      new Date(dateFilter.startDateTime),
                      'yyyy.MM.dd'
                    )} - ${format(
                      new Date(dateFilter.endDateTime),
                      'yyyy.MM.dd'
                    )}`
                  : ''
              }
              icon={<IconCalendar />}
            />
          </div>
          <Calendar />
          {isFilter && (
            <button className="main--button--refresh" onClick={setResetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          <button
            className={'main--button--search'}
            onClick={() => {
              fatchData();
            }}
            disabled={!dateFilter.startDateTime || !dateFilter.endDateTime}
          >
            조회
          </button>
          {/* [작업자 별 단어 수] 필터링 저장 */}
          <FilterButton
            getFilterData={{
              dateType,
              dateFilter,
              workerUserList,
              languagePairList
            }}
          />
        </Box>
        <button
          className={'main--button--download'}
          onClick={() => downloadData()}
          disabled={!workerPerWordAmountList.length}
        >
          Excel로 내려받기
        </button>
      </Box>
      <Box>
        <dl className={'wordlanguage--list--total'}>
          <dt className={'colspan-3'}>총합</dt>
          <dd>
            <span>{Number(totalNew).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(progress).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(stop).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(reject).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(complete).toLocaleString('en')}</span>
          </dd>
          <dd className={'colspan-2'}>
            <span>{Number(totalAmount).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(percentage).toLocaleString('en')} %</span>
          </dd>
        </dl>
      </Box>
      <Box m={'1.5rem 0'}>
        <DataTable
          columns={wordWorkerColumns(isSort, setIsSort)}
          dataList={workerPerWordAmountList}
          clickRowData={() => {}}
          noRowsMessage={`조회된 작업자 별 단어 수가 없습니다.<br/><br/>"조회 조건"을 설정한 후, "조회"버튼을 눌러<br/>작업자 별 단어 수를 확인할 수 있습니다.`}
          gap={10.5}
        />
      </Box>
      <Footer />
    </section>
  );
};

export default WordWorkerPage;

import ModalType from '@common/config/ModalType';
import { useAlert } from '@hooks/modal/useAlert';
import { useTaskerSearchBookMark } from '@hooks/modal/useTaskerSearchBookMark';
import { Box } from '@mui/material';
import { JobAssignStyle, alertWrap } from '@pages/modal/JobAssign.style';
import {
  IconCircleClose,
  IconPencel,
  IconPeople,
  IconX
} from '@resources/icon';
import { IUser } from '@src/common/config/ICode';
import { useGetTaskAssignInfo } from '@src/hooks/languageTask/getAssignInfo';
import { usePostLanguageSubTask } from '@src/hooks/languageTask/post';
import { usePageFunc } from '@src/hooks/utils';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { TPTooltipBasic } from '@units/tooltip/TPTooltip.Basic';
import _ from 'lodash';
import {
  HTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  renderLanguageSubTaskStatus,
  renderRowStatus
} from '../dashboard/utils/render';
import { css, cx } from '@emotion/css';
import { TPSelectBasic } from '@src/units/select/TPSelect.basic';

function JobAssignPage({ close, ...props }: any, ref: any) {
  const navigate = useNavigate();
  let { id } = useParams();
  if (id === undefined) id = window.history.state.languageTaskId;

  let [searchParams] = useSearchParams();

  const { assignInfoData } = useGetTaskAssignInfo(id ?? '');
  const { postLanguageSubTask, postModifyLanguageSubTask } =
    usePostLanguageSubTask();
  const { workerType } = usePageFunc();
  const { openHandle: alertHandle } = useAlert();
  const {
    openHandle: taskerSearchHandle,
    Component: TaskerSearchBookMark,
    open
  } = useTaskerSearchBookMark();

  const {
    languageSubTaskList: getTaskList,
    languageTaskId,
    languageTaskKey,
    languageTaskStatus,
    languageTaskTitle,
    taskKey,
    taskType,
    taskTitle,
    totalGrossAmount
  } = assignInfoData;

  const [languageSubTaskList, setLanguageSubTaskList] = useState<
    {
      languageSubTaskKey: string;
      taskAmount: number;
      workerList: IUser[];
    }[]
  >([
    {
      languageSubTaskKey: `${languageTaskKey}-01`,
      taskAmount: 0,
      workerList: []
    }
  ]);
  const [isCount, setIsCount] = useState<number>(0);

  const initTaskListData = useMemo(
    () =>
      getTaskList.length
        ? [
            ..._.map(_.sortBy(getTaskList, 'languageSubTaskKey'), (el) => ({
              languageSubTaskKey: el.languageSubTaskKey,
              taskAmount: el.taskAmount,
              workerList:
                _.map(
                  workerType,
                  (v) =>
                    el.workerList?.find(
                      (worker) => worker.workerType === v
                    ) ?? {
                      userId: 0,
                      name: '',
                      avatarImgUrl: '',
                      workerType: v
                    }
                ) ?? []
            }))
          ]
        : [
            {
              languageSubTaskKey: `${languageTaskKey}-01`,
              taskAmount: totalGrossAmount,
              workerList: []
            }
          ],
    [getTaskList, languageTaskKey, totalGrossAmount]
  );

  useEffect(() => {
    setLanguageSubTaskList(initTaskListData);
  }, [assignInfoData]);

  useEffect(() => {
    setIsCount(
      _.reduce(
        _.map(languageSubTaskList, (el) => el.taskAmount),
        (sum, n) => sum + n,
        0
      )
    );
  }, [languageSubTaskList]);

  const clickAssigning = (rowIdx: number, workerIdx: number) => {
    taskerSearchHandle(
      (select: IUser, rowIdx: number, workerIdx: number) => {
        setLanguageSubTaskList((data) => [
          ..._.set(data, rowIdx, {
            ...data[rowIdx],
            workerList:
              data[rowIdx].workerList.length === 0
                ? workerType.map((v, i) => ({
                    userId: 0,
                    name: '',
                    avatarImgUrl: '',
                    workerType: v,
                    ...(workerIdx === i ? select : {})
                  }))
                : [
                    ..._.set(data[rowIdx].workerList, workerIdx, {
                      ...select,
                      slackId: '',
                      workerStatus: 'NEW',
                      workerType: workerType[workerIdx]
                    })
                  ]
          })
        ]);
      },
      rowIdx,
      workerIdx
    );
  };

  const clickOk = () => {
    alertHandle({
      title: '배정 완료',
      text: `해당 업무로 지정된 작업자에게<br/>작업 안내가 발송됩니다.`,
      cancelLabel: '취소',
      isLQA: true,
      validAmount: !!(totalGrossAmount - isCount),
      ok: _.debounce(() => {
        if (!getTaskList.length) {
          postLanguageSubTask({
            languageTaskId,
            languageSubTaskList: _.map(languageSubTaskList, (el) => ({
              languageSubTaskKey: el.languageSubTaskKey,
              taskAmount: el.taskAmount,
              workerList: _.filter(
                _.map(
                  el.workerList,
                  (item, idx) =>
                    item && {
                      userId: item.userId,
                      workerType: workerType[idx]
                    }
                ),
                (v) => v !== undefined && v.userId && v.userId !== 0
              )
            }))
          });
        } else {
          postModifyLanguageSubTask({
            languageTaskId,
            languageSubTaskList: _.map(languageSubTaskList, (el) => ({
              languageSubTaskKey: el.languageSubTaskKey,
              taskAmount: el.taskAmount,
              workerList: _.filter(
                _.map(
                  el.workerList,
                  (item, idx) =>
                    item && {
                      userId: item.userId,
                      workerType: workerType[idx]
                    }
                ),
                (v) => v !== undefined && v.userId && v.userId !== 0
              )
            }))
          });
        }
        if (typeof window.history.state?.languageTaskId === 'number') {
          if (typeof close === 'function') close();
        } else if (searchParams.get('history')) {
          navigate(`/task/${ModalType.TASKDETAIL_PAGE}/${id}`, {
            replace: true
          });
        } else {
          const s = window.location.pathname.split('/');
          if (/^\d+$/.test(s.pop() as string) && s.pop() === 'assign') {
            navigate(s.join('/'), {
              replace: true
            });
          }
        }
      }, 300)
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        if (typeof window.history.state?.languageTaskId === 'number' || id) {
          if (typeof close === 'function') {
            close();
          } else {
            const s = window.location.pathname.split('/');
            if (/^\d+$/.test(s.pop() as string) && s.pop() === 'assign') {
              navigate(s.join('/'), {
                replace: true
              });
            }
          }
        } else {
          navigate(-1);
        }
      }
    });
  };

  const handleKeyPress = (e: any) => {
    const pattern = /^[0-9\b]+$/;
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  };

  const isValid = [
    ..._.map(languageSubTaskList, (el) => !!el?.workerList[0]?.userId)
  ].includes(false);

  const activeValid = (user: any) => {
    if (user) {
      const { status, name } = user;
      let label = name;
      if (status === 'INACTIVE') {
        label = `${name} (비활성화)`;
      } else if (status === 'LEAVE') {
        label = '삭제된 사용자';
      }
      return label;
    }
  };

  return (
    <div css={[JobAssignStyle, alertWrap]} {...props} ref={ref}>
      {open && <TaskerSearchBookMark />}
      <TPButtonClose onClick={closeModal} />
      <div className="title">작업자 배정</div>

      <div className="info">
        <div className="status">{renderRowStatus(languageTaskStatus)}</div>
        <div className="task-key">
          {languageTaskKey ? ` [${languageTaskKey}] ` : ''}
        </div>
        <div className="task-title">{languageTaskTitle}</div>
      </div>
      <div className="middle-info">
        <div className="amount">
          <span className="name">총 분량</span>
          <span className="totalAmount">
            {taskType === 'LQA'
              ? `${Math.floor(totalGrossAmount / 250)} 시간 ${
                  totalGrossAmount % 250 > 0 ? '30' : '00'
                } 분`
              : totalGrossAmount.toLocaleString('en-US')}
          </span>
          <span> 배분되었습니다.</span>
          {totalGrossAmount - isCount ? (
            <>
              <span className="count">
                총{' '}
                {taskType === 'LQA'
                  ? `${Math.floor(
                      Math.abs(totalGrossAmount - isCount) / 250
                    )} 시간 ${
                      Math.abs(totalGrossAmount - isCount) % 250 > 0
                        ? '30'
                        : '00'
                    }`
                  : Math.abs(totalGrossAmount - isCount)}
              </span>
              <span>
                {totalGrossAmount - isCount > 0
                  ? `${taskType === 'LQA' ? '분이' : '단어(자)가'} 부족합니다.`
                  : `${
                      taskType === 'LQA' ? '분이' : '단어(자)가'
                    } 초과되었습니다.`}
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
        {['NEW', 'COMPLETE_ASSIGN', 'PROGRESS'].includes(
          languageTaskStatus as string
        ) && (
          <div className="reset--divide">
            {/* 비활성화시 disabled 클래스 추가 */}
            <button
              className="reset"
              onClick={() => {
                alertHandle({
                  title: '작성 취소',
                  text: '입력 중인 내용이 모두 삭제됩니다.',
                  cancelLabel: '취소',
                  ok: () => {
                    setLanguageSubTaskList(initTaskListData);
                  }
                });
              }}
            >
              {/* <IconRefresh width={18} height={18} /> */}
              <span>초기화</span>
            </button>
          </div>
        )}
      </div>
      <div className={'jobAssignTable'}>
        <div className="lineWrap columnsData">
          <div className="key">키</div>
          <div className="taskAmount">작업 분량</div>
          <div className="translator column">번역</div>
          <div className="firstChecker column">1차 검수</div>
          <div className="secondChecker column">2차 검수</div>
          <div className="column" style={{ width: '10px' }}></div>
        </div>
        {_.map(languageSubTaskList, (item, row) => (
          <div className="lineWrap rowData" key={row}>
            <div className="key data">{item.languageSubTaskKey}</div>
            {taskType === 'LQA' ? (
              <div className="taskTimeBox">
                <input
                  type="number"
                  value={Math.floor(item.taskAmount / 250)}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      return;
                    }
                    const amount = Number(
                      Number(e.target.value) * 250 +
                        (item.taskAmount % 250 > 0 ? 125 : 0)
                    );
                    setLanguageSubTaskList([
                      ..._.update(
                        languageSubTaskList,
                        `[${row}].taskAmount`,
                        (v) => amount
                      )
                    ]);
                  }}
                />
                시
                <TPSelectBasic
                  value={item.taskAmount % 250 > 0 ? '0.5' : '0'}
                  options={[
                    {
                      label: '0',
                      option: '0'
                    },
                    {
                      label: '30',
                      option: '0.5'
                    }
                  ]}
                  changeSelect={(e) => {
                    const amount = Number(
                      Math.floor(item.taskAmount / 250) * 250 +
                        Number(e.target.value) * 250
                    );
                    setLanguageSubTaskList([
                      ..._.update(
                        languageSubTaskList,
                        `[${row}].taskAmount`,
                        (v) => amount
                      )
                    ]);
                  }}
                  autoWidth={false}
                />
                분
              </div>
            ) : (
              <input
                className="taskAmountInput"
                value={item.taskAmount.toLocaleString()}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/,/g, ''));
                  !isNaN(value) &&
                    setLanguageSubTaskList([
                      ..._.update(
                        languageSubTaskList,
                        `[${row}].taskAmount`,
                        (v) => value
                      )
                    ]);
                }}
                onKeyPress={handleKeyPress}
                readOnly={
                  !['NEW', 'COMPLETE_ASSIGN', 'PROGRESS', 'COMPLETE'].includes(
                    languageTaskStatus as string
                  )
                }
              />
            )}

            {_.map(
              _.map(workerType, (v) =>
                item.workerList.find((el) => el.workerType === v)
              ),
              (workerEl, index) => {
                const checkedDel =
                  index === 2 && row >= getTaskList.length && row !== 0;
                return workerEl?.userId ? (
                  <>
                    <div className={`translator data delBtn`} key={index}>
                      <Box sx={{ ':hover': { cursor: 'default' } }}>
                        <div className={'middleCenterContainer'}>
                          <TPTooltipBasic
                            className="MuiDataGrid-cellContent"
                            placement="top"
                            title={activeValid(workerEl)}
                          >
                            <TPAvatarBasic
                              alt={''}
                              size="xxsmall"
                              src={workerEl?.avatarImgUrl}
                              grayscale={
                                workerEl?.status === 'INACTIVE' ||
                                workerEl?.status === 'LEAVE'
                              }
                            />
                          </TPTooltipBasic>

                          <div className="worker-name">{workerEl?.name}</div>

                          {['NEW', undefined].includes(
                            workerEl?.workerStatus
                          ) && (
                            <>
                              <IconPencel
                                style={{
                                  flexShrink: 0
                                }}
                                width={17}
                                height={17}
                                fill={'#646782'}
                                onClick={() => clickAssigning(row, index)}
                              />
                              {/* <IconX
                          onClick={() => {
                            setLanguageSubTaskList([
                              ..._.set(languageSubTaskList, row, {
                                ...languageSubTaskList[row],
                                workerList: [
                                  ..._.set(
                                    languageSubTaskList[row].workerList,
                                    index,
                                    undefined
                                  )
                                ]
                              })
                            ]);
                          }}
                          width={24}
                          height={24}
                          fill="#646782"
                        /> */}
                            </>
                          )}
                        </div>
                      </Box>
                    </div>
                    <div style={{ width: '20px' }}>
                      {checkedDel && (
                        <button
                          className="removeButton"
                          onClick={() => {
                            setLanguageSubTaskList([
                              ..._.map(
                                [
                                  ..._.slice(languageSubTaskList, 0, row),
                                  ..._.slice(languageSubTaskList, row + 1)
                                ],
                                (el, idx) => ({
                                  languageSubTaskKey: `${languageTaskKey}-${
                                    idx < 9 ? '0' : ''
                                  }${idx + 1}`,
                                  taskAmount: el.taskAmount,
                                  workerList: el.workerList
                                })
                              )
                            ]);
                          }}
                        >
                          <IconCircleClose width={16} height={16} />
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`firstChecker data delBtn`} key={index}>
                      <button
                        className={'assignButton'}
                        onClick={() => clickAssigning(row, index)}
                      >
                        배정하기
                        <svg width="7" height="10" viewBox="0 0 7 10">
                          <path
                            d="M0.8 8.06667L3.90002 5L0.8 2.06667L1.92357 1L6 5L1.92357 9L0.8 8.06667Z"
                            fill="#727272"
                          />
                        </svg>
                      </button>
                    </div>
                    <div style={{ width: '20px' }}>
                      {checkedDel && (
                        <button
                          className="removeButton"
                          onClick={() => {
                            setLanguageSubTaskList([
                              ..._.map(
                                [
                                  ..._.slice(languageSubTaskList, 0, row),
                                  ..._.slice(languageSubTaskList, row + 1)
                                ],
                                (el, idx) => ({
                                  languageSubTaskKey: `${languageTaskKey}-${
                                    idx < 9 ? '0' : ''
                                  }${idx + 1}`,
                                  taskAmount: el.taskAmount,
                                  workerList: el.workerList
                                })
                              )
                            ]);
                          }}
                        >
                          <IconCircleClose width={16} height={16} />
                        </button>
                      )}
                    </div>
                  </>
                );
              }
            )}
          </div>
        ))}
        <SplitButton
          className="mt"
          onClick={() => {
            const rowCt = languageSubTaskList.length + 1;
            setLanguageSubTaskList([
              ...languageSubTaskList,
              {
                languageSubTaskKey: `${languageTaskKey}-${
                  rowCt < 10 ? `0${rowCt}` : rowCt.toString()
                }`,
                taskAmount: 0,
                workerList: []
              }
            ]);
          }}
        >
          분량나누기
        </SplitButton>
      </div>
      <div className="cancel--assign--container">
        <TPButtonBasic
          variant="outlined"
          label={'취소하기'}
          fullWidth
          onClick={closeModal}
        />
        <TPButtonBasic
          variant="contained"
          label={'배정하기'}
          fullWidth
          disabled={isValid}
          onClick={clickOk}
        />
      </div>
    </div>
  );
}

export default forwardRef(JobAssignPage);

export interface SplitButtonProps extends HTMLAttributes<HTMLButtonElement> {}

export function SplitButton({
  children,
  className,
  ...props
}: SplitButtonProps) {
  return (
    <button className={cx(cssSplitButton, className)} {...props}>
      {children}
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <circle cx="8" cy="8" r="8" fill="var(--circle)" />
        <path d="M3.42773 8H12.5706" stroke="var(--cross)" strokeWidth="1.2" />
        <path
          d="M8 12.5703L8 3.42746"
          stroke="var(--cross)"
          strokeWidth="1.2"
        />
      </svg>
    </button>
  );
}
const cssSplitButton = css`
  --circle: #ececec;
  --cross: #727272;
  color: #727272;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    --circle: #242424;
    --cross: #ffffff;
  }
`;

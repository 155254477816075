import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '@hooks/modal/useAlert';
/**
 * /auth/authorization/google
 * @description google 로그인 인증
 * @returns {{ postAuthGoogle, errorGoogleAuth, googleSignOut, error }}
 */
export const useAuthorizationGoogle = () => {
  const { openHandle, closeHandle } = useAlert();
  const queryClient = useQueryClient();

  const errorGoogleAuth = (error: any) => {
    if (error.error !== 'idpiframe_initialization_failed') {
      let erorrMessage = `구글 로그인에 문제가 발생 하였습니다. 아래 고객센터로 문의해 주시기 바랍니다.`;
      erorrMessage += error.details ? `<br/>(${error.details})` : '';
      openHandle({
        text: erorrMessage,
        title: '구글 로그인 문제 발생',
        okLabel: 'help@2bytes.io',
        ok: () => {
          window.location.href = 'mailto:help@2bytes.io';
        }
      });
    }
  };

  const postData = async ({
    email,
    tokenId
  }: {
    email: string;
    tokenId: string | unknown;
  }) => {
    return (
      await api.post(`/auth/authorization/google`, {
        email,
        tokenId,
        loginSite: 'LPM'
      })
    ).data;
  };

  const { mutate: postAuthGoogle, error } = useMutation({
    mutationFn: postData,
    onError: (error, variables, context) => {
      // 에러 핸들링
      openHandle({
        text: `회원정보가 없습니다. 가입을 원하시는 경우,<br/> 아래 고객센터로 문의해 주시기 바랍니다.`,
        title: '회원정보가 없습니다.',
        okLabel: 'help@2bytes.io',
        ok: () => {
          closeHandle();
          window.location.href = 'mailto:help@2bytes.io';
        }
      });
      googleSignOut();
    },
    onSuccess: async (data, variables, context) => {
      const keys = [
        'privacyAgree',
        'accessToken',
        'accessTokenExpiresIn',
        'refreshToken',
        'refreshTokenExpiresIn'
      ];
      for (let k of keys) {
        let v = data[k];
        if (v === undefined) {
          localStorage.removeItem(k);
        } else {
          localStorage.setItem(k, v);
        }
      }
      queryClient.invalidateQueries(['/myInfo']);
    },
    //뭐가 터져도 문제없이
    onSettled: (data, error, variables, context) => {
      console.debug('onSettled', data, error, variables, context);
    }
  });

  const googleSignOut = () => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 !== null) {
        auth2
          .signOut()
          .then(auth2.disconnect())
          .catch((e: any) => console.debug(e));
      }
    }
  };

  return {
    postAuthGoogle,
    errorGoogleAuth,
    googleSignOut,
    error
  };
};

import { useApi4LanguageSubTaskList } from '../hooks/useApi4LanguageSubTaskList';
import Row4LanguageSubTaskItem from './Row4LanguageSubTaskItem';

export default function Row4LanguageSubTaskList({
  filterData,
  item,
  taskType,
  setOpenedJobModifyPage,
  filterExposure,
  isFilter
}: any) {
  const { data } = useApi4LanguageSubTaskList(item.languageTaskId);

  return (
    <>
      {Array.isArray(data) &&
        data.map((item: any, i: any) => {
          return (
            <Row4LanguageSubTaskItem
              key={i}
              {...{
                filterData,
                taskType,
                item,
                setOpenedJobModifyPage,
                filterExposure,
                isFilter
              }}
            />
          );
        })}
    </>
  );
}

import api from '@api/Api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { saturationKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';
import { ILanguageTask } from '@src/common/config/IProjectDetail';
import _, { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { IPostDegree } from '@src/common/config/IStatistics';
import { IUser } from '@src/common/config/ICode';
import download, { downloadByURL } from '@src/hooks/utils/download';
import { useGetFilter } from '@src/hooks/searchFilter';
import { fi } from 'date-fns/locale';

/**
 * /statistics/workerPerSaturationDegree
 * @description 작업자 별 포화도
 * @returns {{
 * FilterButton, languagePairList, userId, degreeFilter, setState, remove,
 * refetch, downloadData, isFilter, saturationDegreeData
 * }}
 */

export default function usePostSaturationDegree() {
  const {
    filterData,
    Component: FilterButton,
    isSuccess: isSuccessWithCloudFilter
  } = useGetFilter('SATURATION_WORKER', '조회 조건');

  //#region state define
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [userId, setUserId] = useState<IUser[]>([]);
  const [taskTypeList, setTaskTypeList] = useState<string[]>(['ALL']);
  const [workerTypeList, setWorkerTypeList] = useState<string[]>(['ALL']);
  const [workerStatusList, setWorkerStatusList] = useState<string[]>(['ALL']);

  const [isFilter, setIsFilter] = useState<boolean>(false);
  //#endregion

  useEffect(() => {
    if (!filterData) return;
    setLanguagePairList(filterData?.languagePairList ?? []);
    setUserId(filterData?.userId ?? []);
    setTaskTypeList(filterData?.degreeFilter?.taskTypeList ?? ['ALL']);
    setWorkerTypeList(filterData?.degreeFilter?.workerTypeList ?? ['ALL']);
    setWorkerStatusList(filterData?.degreeFilter?.workerStatusList ?? ['ALL']);
  }, [filterData]);

  useEffect(() => {
    setIsFilter(
      !!languagePairList.length ||
        !!userId.length ||
        !taskTypeList.includes('ALL') ||
        !workerTypeList.includes('ALL') ||
        !workerStatusList.includes('ALL')
    );
  }, [
    languagePairList,
    userId,
    taskTypeList,
    workerTypeList,
    workerStatusList
  ]);

  const resetState = useCallback(() => {
    setLanguagePairList([]);
    setUserId([]);
    setTaskTypeList(['ALL']);
    setWorkerTypeList(['ALL']);
    setWorkerStatusList(['ALL']);
  }, []);

  const payload = useMemo(() => {
    const computedLanguagePairList =
      !_.some(languagePairList, { startLanguage: 'ALL' }) &&
      languagePairList.length
        ? { languagePairList }
        : undefined;
    const computedUserId = userId.length
      ? { userId: userId.map((item) => item.userId) }
      : undefined;
    const computedTaskTypeList = !taskTypeList.some(
      (e) => e === 'ALL' || e === '0'
    )
      ? { taskTypeList }
      : undefined;
    const computedWorkerTypeList = !workerTypeList.some(
      (e) => e === 'ALL' || e === '0'
    )
      ? { workerTypeList }
      : undefined;

    const computedWorkerStatusList = !workerStatusList.some(
      (e) => e === 'ALL' || e === '0'
    )
      ? { workerStatusList }
      : undefined;

    return {
      ...computedLanguagePairList,
      ...computedUserId,
      ...computedTaskTypeList,
      ...computedWorkerTypeList,
      ...computedWorkerStatusList,
      nowDate: new Date().toISOString().split('T')[0]
    };
  }, [
    languagePairList,
    userId,
    taskTypeList,
    workerTypeList,
    workerStatusList
  ]);

  console.debug(payload);

  const abortControllerRef = useRef<AbortController | null>(null);
  const postData = async (): Promise<IPostDegree[]> => {
    return (
      await api.postPms(`/statistics/workerPerSaturationDegree`, payload, {
        signal: abortControllerRef.current?.signal
      })
    ).data;
  };

  const downloadData = async () => {
    return await api
      .postPms('/statistics/workerPerSaturationDegree/excel', payload, {
        responseType: 'blob'
      })
      .then((res) => {
        download(res, 'workerPerSaturationDegreeStatistic.xls');
      }); // POST
  };
  //
  //   const {
  //     data: saturationDegreeData = [],
  //     refetch,
  //     remove
  //   } = useQuery([...saturationKeys.postDegree], postData, {
  //     // enabled: isSuccessWithCloudFilter,
  //     enabled: false,
  //     select: (data) => [...orderBy(data, ['name'], ['asc'])]
  //   });

  const { mutateAsync, data: saturationDegreeData = [] } = useMutation({
    mutationFn: postData,
    onSuccess: (data) => {
      return [...orderBy(data, ['name'], ['asc'])];
    }
  });

  const refetchSaturationDegreeData = async () => {
    abortControllerRef.current?.abort();
    await mutateAsync();
  };

  console.debug('saturationDegreeData', saturationDegreeData);

  useEffect(() => {
    if (
      isSuccessWithCloudFilter &&
      payload.languagePairList?.length &&
      payload.userId?.length
    ) {
      refetchSaturationDegreeData();
    }
  }, [payload]);

  return {
    FilterButton,
    languagePairList,
    setLanguagePairList,
    userId,
    setUserId,
    taskTypeList,
    setTaskTypeList,
    workerTypeList,
    setWorkerTypeList,
    workerStatusList,
    setWorkerStatusList,
    resetState,
    refetchSaturationDegreeData,
    downloadData,
    isFilter,
    saturationDegreeData
  };
}

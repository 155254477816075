import api from '@api/Api';
import { useMutation } from '@tanstack/react-query';

import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';

/**
 * /auth/password-reset
 * @description 임시 비밀번호 발급
 * @returns {{ postPassReset, error }}
 */
export const usePasswordReset = () => {
  const postData = async ({ email }: { email: string }) => {
    return await api.post(`/auth/password-reset`, { email });
  };
  const { openHandle } = useToast();
  const { openHandle: openAlert, closeHandle } = useAlert();

  const { mutate: postPassReset, error } = useMutation({
    mutationFn: postData,
    onError: (error, variables, context) => {
      // 에러 핸들링
      openAlert({
        text: `네트워크에 문제가 발생 하였습니다.<br/> 아래 고객센터로 문의해 주시기 바랍니다.`,
        title: '네트워크 장애',
        okLabel: 'help@2bytes.io',
        ok: () => {
          closeHandle();
          window.location.href = 'mailto:help@2bytes.io';
        }
      });
    },
    onSuccess: (data, variables, context) => {
      openHandle({
        text: `임시 비밀번호가 발급되었습니다.`
      });
    },
    //뭐가 터져도 문제없이
    onSettled: (data, error, variables, context) => {}
  });

  return {
    postPassReset,
    error
  };
};

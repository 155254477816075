import React from 'react';
import {
  ILanguageSubTask,
  ILanguageTaskList,
  ITaskManager
} from '@src/common/config/ILanguageTask';
import { IMyTaskList } from '@src/common/config/IMyTask';
import { IProjectList } from '@src/common/config/IProject';
import { ITaskList } from '@src/common/config/ITask';
import {
  DataTableColumnHeadStyle,
  DataTableRowDataStyle,
  DataTableStyle,
  NoMessageContainer
} from './DataTable.style';
import { IUser, TASK_TYPE } from '@common/config/ICode';
import {
  LanguageCodeType,
  StatusCodeType,
  WorkerTypeCode
} from '@common/config/Code';
import { AvatarProgressType } from '@units/avatar/TPAvatar.WorkerType';
import { IWorkerPerWordAmountList } from '@common/config/IStatistics';

type dataTableCodeType = {
  code: string;
  value: any;
  width: number;
  renderHeader: any;
  renderRowData: any;
};

type DataListType =
  | Partial<IFullLookupDataList>
  | Partial<IProjectList>
  | Partial<ITaskList>
  | Partial<IMyTaskList>
  | Partial<ILanguageTaskList>
  | Partial<IWorkerPerWordAmountList>
  | Partial<ITaskManager>;

interface IDataTable {
  columns: readonly dataTableCodeType[];
  dataList: DataListType[];
  clickRowData?: (rowData: any) => void;
  clickColData?: { [k: string]: (rowData: any) => void };
  noRowsMessage?: string;
  gap?: number;
  counter?: any;
}

type pmManagerType =
  | [
      { projectManagerType: 'MAIN'; projectManager: IUser },
      { projectManagerType: 'SUB'; projectManager: IUser }
    ]
  | [];

type StringKeyType = { [key in string]: any };

export interface ISubTask extends ILanguageSubTask, StringKeyType {
  languageSubTaskId: number;
  languageSubTaskKey: string;
  languageSubTaskProcess: WorkerTypeCode;
  languageSubTaskWorker: AvatarProgressType[];
  destinationLanguage: LanguageCodeType;
  endDateTime: Date | string;
  hopeSupplyDate: Date | string;
  netAmount: number;
  pmManager: pmManagerType;
  startDate: Date | string;
  startLanguage: LanguageCodeType;
  taskName: string;
  taskType: TASK_TYPE;
}

export interface IFullLookupDataList extends StringKeyType {
  taskId: number;
  taskStatus: StatusCodeType;
  taskKey: string;
  companyName: string;
  projectName: string;
  taskName: string;
  taskType: TASK_TYPE;
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType;
  totalGrossAmount: number;
  totalNetAmount: number;
  hopeSupplyDate: string;
  startDate: string;
  endDateTime: string;
  id: number;
  pmManager: pmManagerType;
  tasker: IUser[];
  languageSubTask: ISubTask[] | [];
}

interface IDataTableRowData {
  rowEnd: boolean;
  rowData: DataListType;
  columns: readonly dataTableCodeType[];
  onClick: () => void;
  clickColData?: { [k: string]: (rowData: any) => void };
  gap?: number;
  dataList?: DataListType[];
  counter?: any;
}

interface IDataTableColumnHead {
  columns: readonly dataTableCodeType[];
  gap?: number;
  counter?: any;
  dataList?: DataListType[];
}

const DataTableContainer = (props: IDataTable) => {
  const {
    dataList,
    columns,
    clickRowData,
    clickColData,
    noRowsMessage,
    gap,
    counter
  } = props;
  return (
    <DataTableStyle>
      <div className="container">
        <DataTableColumnHead
          columns={columns}
          gap={gap}
          counter={counter}
          dataList={dataList}
        />
        {!!dataList.length ? (
          dataList.map((rowData, index) => {
            return (
              <DataTableRowData
                clickColData={clickColData}
                key={index}
                rowEnd={index === dataList.length - 1}
                rowData={rowData}
                columns={columns}
                onClick={() => {
                  if (clickRowData) clickRowData(rowData);
                }}
                gap={gap}
                dataList={dataList}
                counter={counter}
              />
            );
          })
        ) : (
          <NoMessageContainer>
            <span
              dangerouslySetInnerHTML={{
                __html: noRowsMessage || '조회된 정보가 없습니다.'
              }}
            />
          </NoMessageContainer>
        )}
      </div>
    </DataTableStyle>
  );
};

const DataTableColumnHead = (props: IDataTableColumnHead) => {
  const { columns, gap, dataList, counter } = props;
  return (
    <DataTableColumnHeadStyle gap={gap}>
      {columns.map((el, index) => {
        const width = el.width > 0 ? el.width : null;
        const style = {
          width: `${width}px`
        };

        const renderHeader = el.renderHeader;
        if (renderHeader === 'string')
          return (
            <div className="head" key={index} style={style}>
              <span>{el.value}</span>
            </div>
          );
        return (
          <div className="head" key={index} style={style}>
            {el.renderHeader(dataList, counter)}
          </div>
        );
      })}
    </DataTableColumnHeadStyle>
  );
};

const DataTableRowData = (props: IDataTableRowData) => {
  const {
    rowEnd,
    rowData,
    columns,
    onClick,
    gap,
    dataList,
    counter,
    clickColData
  } = props;

  return (
    <DataTableRowDataStyle onClick={onClick} rowEnd={rowEnd} gap={gap}>
      <div className="main">
        {columns.map((el, index) => {
          const handle = clickColData?.[el.code];
          const isHandle = typeof handle === 'function';
          const data = rowData[el.code];
          const renderRowData: any = el.renderRowData;
          const width = el.width > 0 ? el.width : null;
          const style = {
            width: `${width}px`,
            cursor: isHandle ? 'pointer' : 'default'
          };

          return (
            <div
              className="rowDataBlock"
              key={index}
              style={style}
              onClick={isHandle ? () => handle(rowData) : undefined}
            >
              {renderRowData === 'string'
                ? data
                : renderRowData(data, rowData, counter, dataList)}
            </div>
          );
        })}
      </div>
    </DataTableRowDataStyle>
  );
};

const DataTable = (props: IDataTable) => {
  const {
    columns,
    dataList,
    clickRowData,
    clickColData,
    noRowsMessage,
    gap,
    counter
  } = props;

  return (
    <DataTableContainer
      dataList={dataList}
      columns={columns}
      clickRowData={clickRowData}
      clickColData={clickColData}
      noRowsMessage={noRowsMessage}
      gap={gap}
      counter={counter}
    />
  );
};

export default DataTable;

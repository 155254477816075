import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  IconBulCircleDown,
  IconBulCircleUp,
  IconInfo
} from '@src/resources/icon';
import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { GANTTTYPE } from '@src/common/config/ICode';
import {
  GANTT_COLOR,
  getLanguageLabelsByCode,
  getStatus,
  getTaskType
} from '@src/common/config/Code';
import { TPTooltipBasic } from '@src/units/tooltip/TPTooltip.Basic';
import { TPAvatarBasic } from '@src/units/avatar/TPAvatar.Basic';
import { IPostDegree } from '@src/common/config/IStatistics';
import { Box } from '@mui/material';
import { usePageFunc } from '@src/hooks/utils';

function GanttChartComponent({
  workerTypePerDayList: headerData,
  workerPerSaturationList: bodyData,
  avatarImgUrl,
  status,
  name
}: IPostDegree) {
  const { lankColor, holidays } = usePageFunc();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const dataTableRef = useRef<HTMLTableElement>(null);

  const [collapsed, setCollapsed] = useState(false);

  const toggleRows = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (!tableWrapperRef.current || !dataTableRef.current) {
      return;
    }

    const tableWrapper = tableWrapperRef.current;
    const dataTable = dataTableRef.current;

    function toggleScroll() {
      if (dataTable.scrollWidth > dataTable.clientWidth) {
        tableWrapper.classList.add('show-scroll');
      } else {
        tableWrapper.classList.remove('show-scroll');
      }
    }

    toggleScroll();

    window.addEventListener('resize', toggleScroll);

    return () => {
      window.removeEventListener('resize', toggleScroll);
    };
  }, []);

  const customHeaderData: { [key: string]: any } = {};
  headerData.map((task: any) => {
    const day = task.day.split('-');
    customHeaderData[`${day[0]}-${day[1]}`] = [
      ...(customHeaderData[`${day[0]}-${day[1]}`] ?? []),
      {
        day: day[2],
        dayPerTotalAmount: task.dayPerTotalAmount,
        dayPerTranslatorAmount: task.dayPerTranslatorAmount,
        dayPerFirstCheckerAmount: task.dayPerFirstCheckerAmount,
        dayPerSecondCheckerAmount: task.dayPerSecondCheckerAmount,
        isSunday: task.isSunDay
      }
    ];
    return task;
  });
  const graphColSpan = _.reduce(
    _.map(Object.keys(customHeaderData), (key) => customHeaderData[key].length),
    (sum, n) => sum + n,
    0
  );

  //#region data grouping
  const translatorData: any[][] = [];
  const firstCheckerData: any[][] = [];
  const secondCheckerData: any[][] = [];

  const groupData = (data: any[], type: string, targetData: any[][]) => {
    _.forEach(_.filter(data, { workerType: type }), (item: any) => {
      let groupIndex = -1;
      for (let i = 0; i < targetData.length; i++) {
        const group = targetData[i];
        const overlap = group.find(
          (groupItem) =>
            (item.dayStart >= groupItem.dayStart &&
              item.dayStart <= groupItem.dayEnd) ||
            (item.dayEnd >= groupItem.dayStart &&
              item.dayEnd <= groupItem.dayEnd) ||
            (item.dayStart <= groupItem.dayStart &&
              item.dayEnd >= groupItem.dayEnd)
        );
        if (!overlap) {
          group.push(item);
          groupIndex = i;
          break;
        }
      }
      if (groupIndex === -1) {
        targetData.push([item]);
      }
    });
  };

  groupData(bodyData, 'TRANSLATOR', translatorData);
  groupData(bodyData, 'FIRST_CHECKER', firstCheckerData);
  groupData(bodyData, 'SECOND_CHECKER', secondCheckerData);
  //#endregion

  //#region component set
  let theadCom1 = '';
  let theadCom2 = '';
  let tbodyCom1 = '';
  let tbodyCom2 = '';
  let tbodyCom3 = '';
  let tbodyCom4 = '';

  const countArr: any[] = [];
  _.map(Object.keys(customHeaderData), (key, idx) => {
    _.map(customHeaderData[key], (el, idx) => {
      countArr.push({ key, ...el });
    });
  });

  _.map(_.sortBy(Object.keys(customHeaderData)), (key, idx) => {
    const gapIndices = _.chain(customHeaderData[key])
      .map('isSunday')
      .map((used, index) => (used ? index + 1 : null))
      .compact()
      .value();

    const nowMonth = key.split('-')[0] + '년&nbsp;' + key.split('-')[1] + '월';

    _.map(gapIndices, (el, gapIdx) => {
      const gap = gapIdx > 0 ? el - gapIndices[gapIdx - 1] : el;
      theadCom1 += `<td colSpan="${gap}" key=${idx} title=${nowMonth}>
              ${gap < 3 ? nowMonth.slice(0, 3) + '...' : nowMonth}
          </td>`;
      if (gapIdx === gapIndices.length - 1)
        theadCom1 +=
          customHeaderData[key].length - el
            ? `<td colSpan="${customHeaderData[key].length - el}" key=${
                idx + '-1'
              } title=${nowMonth}>
              ${
                customHeaderData[key].length - el < 3
                  ? nowMonth.slice(0, 3) + '...'
                  : nowMonth
              }
          </td>`
            : '';
    });

    _.map(_.sortBy(customHeaderData[key], 'day'), (data: any, idx) => {
      const isHoliday = holidays(key, data.day);
      const [year, month, day] = new Date()
        .toISOString()
        .split('T')[0]
        .split('-');
      const nowMonth = `${year}-${month}`;
      const nowDay = `${day}`;

      const dayColor = isHoliday
        ? `style="background-color:rgb(0 0 0 / 20%);"`
        : '';

      if (key === nowMonth && data.day === nowDay) {
        theadCom2 += `<td key=${idx} style="background-color:yellow;" id="${name}scrollTarget">${Number(
          data.day
        )}일</td>`;
      } else {
        theadCom2 += `<td key=${idx} ${dayColor}>${Number(data.day)}일</td>`;
      }
      tbodyCom1 += `<td key=${idx} ${lankColor(
        data.dayPerTotalAmount,
        isHoliday
      )}>
        <Tooltip title=${data.dayPerTotalAmount}>
          <span>
            ${
              String(data.dayPerTotalAmount).length < 7
                ? data.dayPerTotalAmount
                : String(data.dayPerTotalAmount).slice(0, 7) + '...'
            }
          </span>
        </Tooltip>
      </td>`;
      tbodyCom2 += `<td key=${idx}>
        <Tooltip title=${data.dayPerTranslatorAmount}>
          <span>
            ${
              String(data.dayPerTranslatorAmount).length < 7
                ? data.dayPerTranslatorAmount
                : String(data.dayPerTranslatorAmount).slice(0, 7) + '...'
            }
          </span>
        </Tooltip>
      </td>`;
      tbodyCom3 += `<td key=${idx}>
        <Tooltip title=${data.dayPerFirstCheckerAmount}>
          <span>
            ${
              String(data.dayPerFirstCheckerAmount).length < 7
                ? data.dayPerFirstCheckerAmount
                : String(data.dayPerFirstCheckerAmount).slice(0, 7) + '...'
            }
          </span>
        </Tooltip>
      </td>`;
      tbodyCom4 += `<td key=${idx}>
        <Tooltip title=${data.dayPerSecondCheckerAmount}>
          <span>
            ${
              String(data.dayPerSecondCheckerAmount).length < 7
                ? data.dayPerSecondCheckerAmount
                : String(data.dayPerSecondCheckerAmount).slice(0, 7) + '...'
            }
          </span>
        </Tooltip>
      </td>`;
    });
  });
  //#endregion

  useEffect(() => {
    const targetElement = document.querySelector(
      `#${name}scrollTarget`
    ) as HTMLElement;
    if (targetElement) {
      const containerElement = targetElement.parentElement?.parentElement
        ?.parentElement?.parentElement as HTMLElement;
      const scrollOffset = targetElement.offsetLeft - 420;

      containerElement.scrollTo({
        left: scrollOffset,
        behavior: 'smooth'
      });
    }
  });

  const activeValid = (user: any) => {
    if (user) {
      const { status, name } = user;
      let label = name;
      if (status === 'INACTIVE') {
        label = `${name} (비활성화)`;
      } else if (status === 'LEAVE') {
        label = '삭제된 사용자';
      }
      return label;
    }
  };

  return bodyData.length ? (
    <Box
      style={{
        borderRadius: '16px',
        padding: '30px 20px',
        background: '#ffffff',
        boxShadow: '0 1px 32px 4px #f0edf6'
      }}
    >
      {/* <TPTooltipBasic
        description
        placement="top"
        title={activeValid({ status, name })}
      >
        <div
          style={{
            display: 'flex',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <TPAvatarBasic
            alt={''}
            size="medium"
            src={avatarImgUrl}
            grayscale={status === 'INACTIVE' || status === 'LEAVE'}
          />
          <span style={{ marginLeft: '15px' }}>{name}</span>
        </div>
      </TPTooltipBasic> */}
      <div
        style={{
          display: 'flex',
          width: 'fit-content',
          alignItems: 'center'
        }}
      >
        <img
          alt=""
          src={avatarImgUrl}
          loading="lazy"
          style={{
            width: 48,
            height: 48,
            borderRadius: '50%'
          }}
        />
        <span style={{ marginLeft: '15px' }}>{name}</span>
      </div>
      <GanttChartWrap>
        <div>
          <table className="table--column">
            <thead>
              <tr>
                <td />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td onClick={toggleRows}>
                  <div className="collapsed_button">
                    {collapsed ? (
                      <IconBulCircleUp width={14} height={14} />
                    ) : (
                      <IconBulCircleDown width={14} height={14} />
                    )}
                    <span>배율적용 총합(Net)</span>
                    <Tooltip
                      title={
                        <p>
                          번역(Net), 1차검수(Net), 2차검수(Net)의 분량 총합이
                          배율이 적용되어 표시됩니다.
                          <br />
                          <br />
                          일일 권장 분량은 2,000 입니다.
                          <br />
                          <br />
                          [역할 별 분량 배율]
                          <br />
                          번역(Net) = 1.0
                          <br />
                          1차검수(Net) = 0.5
                          <br />
                          2차검수(Net) = 0.25
                          <br />
                        </p>
                      }
                    >
                      <IconInfo width={14} height={14} />
                    </Tooltip>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={collapsed ? 'collapsed left' : 'expanded left'}>
                  <div className="columnBox">
                    <div className="listLine" />
                    <span>번역(Net)</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={collapsed ? 'collapsed left' : 'expanded left'}>
                  <div className="columnBox">
                    <div className="listLine" />
                    <span>1차검수(Net)</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={collapsed ? 'collapsed left' : 'expanded left'}>
                  <div className="columnBox">
                    <div className="listLine--last" />
                    <span>2차검수(Net)</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: `${
                      (translatorData.length ? translatorData.length : 1) * 30
                    }px`
                  }}
                >
                  번역
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: `${
                      (firstCheckerData.length ? firstCheckerData.length : 1) *
                      30
                    }px`
                  }}
                >
                  1차검수
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: `${
                      (secondCheckerData.length
                        ? secondCheckerData.length
                        : 1) * 30
                    }px`
                  }}
                >
                  2차검수
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="div--container">
          <div className="table--wrapper" ref={tableWrapperRef}>
            <table id="data-table" className="chart-table" ref={dataTableRef}>
              <thead>
                <tr dangerouslySetInnerHTML={{ __html: theadCom1 }} />
                <tr dangerouslySetInnerHTML={{ __html: theadCom2 }} />
              </thead>
              <tbody>
                <tr dangerouslySetInnerHTML={{ __html: tbodyCom1 }} />
                <tr
                  className={collapsed ? 'collapsed' : 'expanded'}
                  dangerouslySetInnerHTML={{ __html: tbodyCom2 }}
                />
                <tr
                  className={collapsed ? 'collapsed' : 'expanded'}
                  dangerouslySetInnerHTML={{ __html: tbodyCom3 }}
                />
                <tr
                  className={collapsed ? 'collapsed' : 'expanded'}
                  dangerouslySetInnerHTML={{ __html: tbodyCom4 }}
                />
                <tr className="tr--graph">
                  <GraphTd data={translatorData} colSpan={graphColSpan} />
                </tr>
                <tr className="tr--graph">
                  <GraphTd data={firstCheckerData} colSpan={graphColSpan} />
                </tr>
                <tr className="tr--graph">
                  <GraphTd data={secondCheckerData} colSpan={graphColSpan} />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </GanttChartWrap>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          marginTop: '20px'
        }}
      >
        {_.map(Object.keys(GANTT_COLOR), (key, idx) => (
          <div
            key={idx}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: '0 10px 0 10px'
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                margin: '0 5px 0 0',
                backgroundColor: `${GANTT_COLOR[key as GANTTTYPE]}`,
                borderRadius: '50%'
              }}
            />
            <span
              style={{
                fontSize: '12px'
              }}
            >
              {getStatus([key as GANTTTYPE])[0].label}
            </span>
          </div>
        ))}
      </div>
    </Box>
  ) : null;
}

const GraphTd = ({ data, colSpan }: { data: any; colSpan: number }) => (
  <td
    style={{
      padding: 0,
      height: `${(data.length ? data.length : 1) * 30}px`
    }}
    colSpan={colSpan}
  >
    {_.map(data, (group, gIdx) => {
      return (
        <div className="div--graphArea" key={gIdx}>
          {group.length
            ? _.map(group, (item, idx: number) => {
                const dayStart = new Date(item.dayStart);
                const dayEnd = new Date(item.dayEnd);
                const dayDefault = new Date(
                  idx === 0 ? '2023-01-01' : group[idx - 1].dayEnd
                );

                // 날짜 간의 차이 계산
                const diffDays = Math.ceil(
                  Math.abs(dayEnd.getTime() - dayStart.getTime()) /
                    (1000 * 3600 * 24)
                );

                // 날짜 간의 차이 계산
                const diffDays2 = Math.ceil(
                  Math.abs(dayStart.getTime() - dayDefault.getTime()) /
                    (1000 * 3600 * 24)
                );

                return (
                  <Tooltip
                    title={
                      <div>
                        {getStatus(item.workerStatus)[0].label}
                        <ul>
                          <li>작업명 : {item.taskTitle}</li>
                          <li>유형 : {getTaskType(item.taskType)[0].label}</li>
                          <li>
                            언어페어 :
                            {
                              getLanguageLabelsByCode([item.startLanguage])[0]
                                .label
                            }{' '}
                            -{' '}
                            {
                              getLanguageLabelsByCode([
                                item.destinationLanguage
                              ])[0].label
                            }
                          </li>
                        </ul>
                      </div>
                    }
                    key={idx}
                    placement="top-start"
                    followCursor
                  >
                    <div
                      className="bar"
                      style={{
                        backgroundColor:
                          GANTT_COLOR[`${item.workerStatus as GANTTTYPE}`],
                        width: `${60 * (diffDays + 1) - 2}px`,
                        margin: `0 1px 0 ${
                          60 * (idx ? diffDays2 - 1 : diffDays2)
                        }px`,
                        color: '#fff'
                      }}
                    >
                      <p>
                        <span className={'title'}>{item.taskTitle}</span>
                        <span>({item.totalAmount})</span>
                      </p>
                    </div>
                  </Tooltip>
                );
              })
            : null}
        </div>
      );
    })}
  </td>
);

export const GanttChartWrap = styled.div`
  display: flex;
  font-size: 12px;
  .table--column {
    width: 140px;
    border: 1px solid #000;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    margin-top: 20px;
    thead {
      width: 100px;
      height: 60px;
      border: 1px solid #000;
    }
    .left {
      text-align: left;
      padding-left: 10px;
      .columnBox {
        display: flex;
        align-items: center;
      }
      .listLine--last,
      .listLine {
        width: 10px;
        margin-right: 3px;
        position: relative;
      }
      .listLine:before {
        content: '';
        position: absolute;
        top: -20px;
        width: 1px;
        height: 30px;
        border-left: 1px solid #999999;
      }
      .listLine--last:before {
        content: '';
        position: absolute;
        top: -20px;
        width: 1px;
        height: 20px;
        border-left: 1px solid #999999;
      }
      .listLine--last:after,
      .listLine:after {
        content: '';
        position: absolute;
        border-top: 1px solid #999999;
        height: 8px;
        width: 10px;
      }
    }
    .collapsed_button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        padding: 1px 4px 0px 1px;
      }
      svg {
        position: relative;
        bottom: 0.2px;
        right: 2px;
      }
    }
    td {
      width: 100px;
      height: 30px;
      border: 1px solid #b6b6b6;
    }
  }
  .div--container {
    width: calc(100% - 140px);
    margin-top: 20px;
    .table--wrapper {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
    /* 왼쪽 표 */

    .table--wrapper table {
      width: max-content;
      border: 1px solid #b6b6b6;
      border-left: none;
      table-layout: fixed;
      border-collapse: collapse;
      text-align: center;
      td:first-of-type {
        border-left: none;
      }
      td {
        width: 60px;
        height: 30px;
        border: 1px solid #b6b6b6;
      }
      .tr--graph {
        td {
          border-top: none;
          border-bottom: none;
          border-bottom: 1px dashed #b6b6b6;
          .div--graphArea {
            height: 29px !important;
            display: flex;
            align-items: center;
            /* height: min-content; */
          }
          .bar {
            height: 26px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              line-height: 8px;
              display: flex;
              justify-content: center;
              width: calc(100% - 4px);
              flex-wrap: wrap;
              span {
                text-align: center;
                padding: 2px;
                &.title {
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
    /* 왼쪽 표 */
    .table--wrapper::-webkit-scrollbar {
      width: 20px; /*스크롤바의 너비*/
    }
    .table--wrapper::-webkit-scrollbar-thumb {
      background-color: rgb(101, 55, 173, 0.8); /*스크롤바의 색상*/
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: 50px;
    }
    .table--wrapper::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.03); /*스크롤바 트랙 색상*/
      border-radius: 50px;
    }
  }

  .collapsed {
    display: none;
    /* opacity: 0; */
    animation: fade-out 1s;
    animation-fill-mode: forwards;
  }
  .expanded {
    animation: fade-in 1s;
    animation-fill-mode: forwards;
  }

  .show-scroll {
    overflow-x: scroll;
  }

  .bar:hover {
    outline: 1px solid #ff0000;
    line-height: 23px;
  }
`;
export default GanttChartComponent;

import { css } from '@emotion/react';
import { cssResetButton } from './utils/css';

export const Wrap = css`
  margin: 71px 40px 90px;

  .wordlanguage--title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4px;
    align-items: flex-start;
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .main--button--download,
  .main--button--search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    width: auto;
    height: 48px;
    font-size: 14px;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #9b51e0;
    color: #9b51e0;
    border-radius: 40px;
    &:disabled {
      border-color: #d5d3e4;
      color: #d5d3e4;
      cursor: auto;
    }
  }
  .wordlanguage--list--total {
    display: flex;
    gap: 10.5px;
    align-items: center;
    text-align: center;
    width: 1768px;
    height: 100%;
    margin-top: 20px;
    border-radius: 16px;
    padding: 16px 13px 16px 23.5px;
    background: #ffffff;
    box-shadow: 0 1px 32px 4px #f0edf6;
    dt,
    dd {
      align-items: center;
      width: 150px;
      border-left: 1px solid #ddd;
      &.colspan-2 {
        width: 300px;
      }
    }
    dt {
      border-left: 0;
    }
  }
`;

import { Link } from 'react-router-dom';
import { footerWrap } from './Footer.style';
import { HTMLAttributes } from 'react';

function Footer(props: HTMLAttributes<HTMLElement>) {
  return (
    <footer css={footerWrap} {...props}>
      <ul className="footer--ul--menu">
        <li>
          <Link to={''}>개인정보처리방침</Link>
        </li>
        <li>
          <Link to={''}>이용약관</Link>
        </li>
        <li>
          <Link to={''}>공식사이트</Link>
        </li>
      </ul>
      <address className="footer--address--copyright">
        © Copyright 2bytes Corp. All Rights Reserved.
      </address>
    </footer>
  );
}
export default Footer;

import { createTheme } from '@mui/material/styles/';

export const TPChipTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          lineHeight: '160%',
          fontWeight: 400,
          borderRadius: '8px 0'
        },
        filled: {
          color: '#fff',
          background: '#2C3558',
          boxShadow: '0px 2px 8px rgba(44, 53, 88, 0.16)',
          '& svg *': { fill: '#fff' },
          '&.state--NEW, &.state--REQUEST_QUOTE': {
            color: '#2C3558',
            background: '#8bdda3',
            boxShadow: '0px 2px 8px rgba(113, 216, 142, 0.16)',
            '& svg *': { fill: '#2C3558' }
          },
          '&.state--COMPLETE_ASSIGN': {
            color: '#2C3558',
            background: 'rgba(24, 195, 242, 0.8)',
            boxShadow: '0px 2px 8px rgba(24, 195, 242, 0.16)',
            '& svg *': { fill: '#2C3558' }
          },
          '&.state--PROGRESS': {
            color: '#2C3558',
            background: '#ffd585',
            boxShadow: '0px 2px 8px rgba(255, 184, 0, 0.16)',
            '& svg *': { fill: '#2C3558' }
          },
          '&.state--WAIT_DELIVERY': {
            background: '#CC4647',
            boxShadow: '0px 2px 8px rgba(204, 70, 71, 0.16);'
          },
          '&.state--COMPLETE': {
            background: '#CC4647',
            boxShadow: '0px 2px 8px rgba(204, 70, 71, 0.16);'
          },
          '&.state--REJECT, &.state--TRANSLATOR_REJECT, &.state--FIRST_CHECKER_REJECT':
            {
              background: '#F46E0C',
              boxShadow: '0px 2px 8px rgba(255, 184, 0, 0.16)',
              '& svg *': { fill: '#2C3558' }
            },
          '&.state--STOP': {
            background: '#9A9CB5',
            boxShadow: '0px 2px 8px rgba(154, 156, 181, 0.16);',
            '& svg *': { fill: '#fff' }
          },
          '&.state--disabled': {
            color: '#fff',
            background: '#71D88E',
            padding: '0 16px',
            borderRadius: 8,
            height: 48,
            fontSize: '1rem',
            fontWeight: 500,
            boxShadow: 'none'
          },
          '&.MuiChip-deletable': {
            color: '#fff',
            background: '#71D88E',
            padding: '0 16px',
            borderRadius: 8,
            height: 48,
            fontSize: '1rem',
            fontWeight: 500,
            boxShadow: 'none',
            '& svg': {
              width: 24,
              height: 24,
              marginRight: 0,
              marginLeft: 8,
              '& *': { fill: '#fff' }
            },
            '& .MuiChip-label': {
              padding: 0
            }
          }
        },
        outlined: {
          border: '1px solid #D5D3E4',
          '&.chip--round': {
            background: '#FAFAFF',
            border: '1px solid #EEEDF4',
            borderRadius: '4px'
          }
        },
        sizeSmall: {
          height: 24,
          fontWeight: 500,
          padding: '0 6px',
          '& .MuiChip-label': { padding: 0 }
        }
      }
    }
  }
});

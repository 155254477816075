import { useEffect, FocusEvent, KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formStepInner } from '@pages/login/Login.style';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { useAuthentication } from '@hooks/auth/postAuth';
import { usePasswordReset } from '@hooks/auth/postPassReset';

function PasswordForm({ variables }: { variables?: string }) {
  const { postAuth, errorInfo } = useAuthentication();
  const { postPassReset } = usePasswordReset();

  const [pw, setPw] = useState('');
  const [activePw] = useState('');
  const [focusPw, setFocusPw] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors }
  } = useForm<{ loginpw: string }>();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setPw(value.loginpw || '');
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const focusInput = (e: FocusEvent<HTMLInputElement>) => {
    if (e.type === 'focus') {
      setFocusPw('focus');
    } else {
      if (!e.target.value) setFocusPw('');
    }
  };

  const keypressInput = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      trigger('loginpw').then((result) => {
        if (result) onSubmit();
      });
    }
  };

  // 이메일로 시작하기
  const onSubmit = () => {
    postAuth({ email: variables as string, password: pw });
  };

  const resetPassword = () => {
    postPassReset({ email: variables as string });
  };

  return (
    <div css={formStepInner}>
      <>
        <label className="formLabel" htmlFor="loginpw">
          <input
            id="loginpw"
            className={`formInput ${activePw} ${
              (errors.loginpw || errorInfo.clientMessage) && 'error'
            }`}
            type="password"
            value={pw}
            onFocus={focusInput}
            onKeyDown={keypressInput}
            {...register('loginpw', {
              required: true,
              minLength: {
                value: 8,
                message: '영문 소문자, 숫자, 특수문자를 조합하여 8자 이상'
              },
              onBlur: focusInput
            })}
          />
          <span
            className={`formPlaceholder ${activePw} ${focusPw} ${
              (errors.loginpw || errorInfo.clientMessage) && 'error'
            }`}
          >
            비밀번호
          </span>
        </label>

        {(errors.loginpw || errorInfo.clientMessage) && (
          <span className="formValidText">
            {errorInfo
              ? errors.loginpw && errors.loginpw.message
                ? errors.loginpw.message
                : errorInfo.clientMessage
              : '영문 소문자, 숫자, 특수문자를 조합하여 8자 이상'}
          </span>
        )}

        <TPButtonBasic
          className="loginEmailBtn"
          label="이메일로 시작하기"
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit(onSubmit)}
          disabled={false}
        />

        <div>
          <p className="loginPwReissuanceWrap">
            <button onClick={resetPassword}>비밀번호 재발급</button>
          </p>
        </div>
      </>
    </div>
  );
}

export default PasswordForm;

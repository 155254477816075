import { LANGUAGE, getLanguageLabelByCode } from '@src/common/config/Code';
import { textSlice } from '@src/common/utils/Func';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import { css } from '@emotion/react';

export const wordLanguageColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'startLanguage',
      value: '출발어',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => getLanguageLabelByCode(data)
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => getLanguageLabelByCode(data)
    },
    {
      code: 'new',
      value: '신규(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'completeAssign',
      value: '배정(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'progress',
      value: '진행(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'stop',
      value: '중지(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'waitDelivery',
      value: '납품대기(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'deliveryComplete',
      value: '납품완료(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'totalAmount',
      value: '총합(Net)',
      width: 300,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
            showTooltip="AmountLanguageTotal"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <dl css={ratio}>
            <dt>{Number(data).toLocaleString('en')}</dt>
            <dd>
              <span style={{ width: `${rowData.percentage}%` }}></span>
            </dd>
          </dl>
        );
      }
    },
    {
      code: 'percentage',
      value: '총합(Net) 비율',
      width: 145,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
            showTooltip="AmountLanguageRatio"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')} %</span>;
      }
    }
  ] as const;

export const ratio = css`
  display: flex;
  align-items: center;
  width: 100%;
  dt {
    width: 50%;
  }
  dd {
    position: relative;
    width: 50%;
    height: 22px;
    border: 1px solid #aaa;
    border-radius: 4px;
    overflow: hidden;
    span {
      position: absolute;
      top: 0;
      left: 0;
      background: #ddd;
      width: 0;
      height: 100%;
    }
  }
`;

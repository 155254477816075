import { ReactElement, useState } from 'react';
import { TPInputCheckBox } from '../input/TPInput.checkBox';
import { FilterWrap, SelectList, FilterInput, Gap } from './TPSelect.Style';
import { IcoMenuChevronDownActive, IcoMenuChevronDown } from '@resources/icon';
import { IUser } from '@src/common/config/ICode';
import { useHookFunc } from '@src/hooks/utils';
import _ from 'lodash';
import { cx } from '@emotion/css';

interface ISelectCheckPMProps {
  label: string | ReactElement;
  PMList: IUser[];
  selectOptions: (string | number)[];
  showAvatar?: boolean;
  changeSelect: (state: any) => void;
}

export const TPSelectCheckManager = (props: ISelectCheckPMProps) => {
  const { label, PMList, selectOptions, showAvatar, changeSelect } = props;

  const getPMList = _.chain(PMList)
    .sortBy('name')
    .sortBy((item) => item.userId !== 0)
    .value();

  let showSelectedValue: any = selectOptions.filter(
    (e) => e !== '0' && e !== '' && e !== null && e !== undefined
  );

  if (showSelectedValue.length === 0) {
    showSelectedValue = '';
  } else {
    showSelectedValue = showSelectedValue.length;
  }

  const setSelect = (option: string) => {
    changeSelect(option);
  };

  return (
    <FilterWrap>
      <FilterInput
        className={cx('filter-input', {
          'filter--active': Boolean(showSelectedValue)
        })}
      >
        <div className="label--checked">
          <span className="label">{label}</span>
          <span className="checked">{showSelectedValue}</span>
        </div>
        <IcoMenuChevronDown className="icon" />
      </FilterInput>
      <div className="dropdown">
        <Gap />
        <SelectList>
          {getPMList?.map((item, index) => {
            return (
              <TPInputCheckBox
                option={item.name}
                avatarUrl={item.avatarImgUrl}
                optionKey={String(item.userId)}
                selectOptions={selectOptions}
                onClick={setSelect}
                showAvatar={showAvatar}
                status={item.status}
                key={index}
              />
            );
          })}
        </SelectList>
      </div>
    </FilterWrap>
  );
};

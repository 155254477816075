import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StatusCodeType, getStatus } from '@src/common/config/Code';
import React from 'react';

interface IStatusBadge {
  [key: string]: any;
  statusCode: StatusCodeType;
  forceText?: string;
  padding?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
}
export const StatusBadge = (props: IStatusBadge) => {
  const {
    statusCode = 'NEW',
    forceText,
    padding,
    width,
    height,
    borderRadius,
    ...otherProps
  } = props;
  const { label } = getStatus([statusCode])[0];

  return (
    <Wrap
      statusCode={statusCode}
      padding={padding}
      width={width}
      height={height}
      borderRadius={borderRadius}
      {...otherProps}
    >
      {forceText ? forceText : label}
    </Wrap>
  );
};

interface IWrap {
  statusCode: StatusCodeType;
  padding?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
}

const Wrap = styled.div<IWrap>`
  background-color: #71d88e;
  box-shadow: 0 2px 8px rgba(113, 216, 142, 0.16);
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '8px 0'};
  text-align: center;
  padding: ${({ padding }) => (padding ? padding : '6px 0')};
  line-height: 1.6;
  color: #2c3558;
  height: ${({ height }) => (height ? height : '32px')};
  min-width: ${({ width }) => (width ? width : '54px')};
  max-width: 68px;
  max-height: 32px;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;

  ${({ statusCode }) => {
    switch (statusCode) {
      case 'COMPLETE_ASSIGN':
        return css`
          background-color: #7eccf1;
        `;
      case 'PROGRESS':
        return css`
          background-color: #ffd585;
          box-shadow: 0 2px 8px rgba(255, 184, 0, 0.16);
        `;
      // case 'WAIT_DELIVERY':
      //   return css`
      //     color: #fff;
      //     background-color: #cc4647;
      //     box-shadow: 0 2px 8px rgba(204, 70, 71, 0.16);
      //   `;
      case 'REJECT':
        return css`
          color: #fff;
          background-color: #f46e0c;
          box-shadow: 0 2px 8px rgba(154, 156, 181, 0.16);
        `;
      case 'STOP':
        return css`
          color: #fff;
          background-color: #9a9cb5;
          box-shadow: 0 2px 8px rgba(154, 156, 181, 0.16);
        `;
      case 'COMPLETE':
      case 'COMPLETE_TASK':
      case 'DELIVERY_COMPLETE':
      case 'END':
        return css`
          color: #fff;
          background-color: #2c3558;
          box-shadow: 0 2px 8px rgba(44, 53, 88, 0.16);
        `;
      default:
        break;
    }
  }}
`;

import styled from '@emotion/styled';
import { WorkerTypeCode } from '@src/common/config/Code';
import { IUser } from '@src/common/config/ICode';
import { SubTaskWorkStatusType } from '@src/common/config/ICode';
import { IconProgressCheck } from '@src/resources/icon';

export type NewAvatarProgressType = {
  userId: number;
  name: string;
  avatarImgUrl: string;
  slackId: string;
  workerType: WorkerTypeCode;
  workerStatus: SubTaskWorkStatusType;
};
export type AvatarProgressType = {
  worker: IUser;
  languageSubTaskWorkStatus: SubTaskWorkStatusType;
  workerType: WorkerTypeCode;
};
interface ITPAvatarWorkerType {
  info: NewAvatarProgressType;
}
export const TPAvatarWorkerType = ({ info }: ITPAvatarWorkerType) => {
  const { avatarImgUrl, workerStatus, workerType } = info;

  //완료된 작업인가?
  const completeWork = workerStatus === 'COMPLETE';

  let workerTypeAbbreviation = '';
  switch (workerType) {
    case 'TRANSLATOR':
      workerTypeAbbreviation = 'T';
      break;
    case 'FIRST_CHECKER':
      workerTypeAbbreviation = 'R1';
      break;
    case 'SECOND_CHECKER':
      workerTypeAbbreviation = 'R2';
      break;
  }
  return (
    <Wrap progress={workerStatus === 'PROGRESS'}>
      <div className={'avatar--div--wrap'}>
        <img src={avatarImgUrl} alt={''} />
      </div>
      <div className="badge">{workerTypeAbbreviation}</div>
      {completeWork ? (
        <div className="completed">
          <IconProgressCheck width={12} height={9} />
        </div>
      ) : null}
    </Wrap>
  );
};

interface IWrap {
  progress: boolean;
}
const Wrap = styled.div<IWrap>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar--div--wrap {
    overflow: hidden;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    outline: ${({ progress }) => (progress ? '2px solid #ffb800' : null)};
  }
  .badge {
    position: absolute;
    top: -5px;
    right: -4px;
    font-size: 10px;
    line-height: 1;
    padding: 1.5px 3.5px 1px;
    background: #ffb800;
    border-radius: 4px 0;
    z-index: 2;
  }
  .completed {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

import { cx } from '@emotion/css';
import {
  IconFileUseFalse,
  IconFileUseTrue,
  IconFileFalse,
  IconFileTrue
} from '@resources/icon';
import _ from 'lodash';
import {
  FilterFileInput,
  FilterFileWrap,
  Gap,
  SelectFileList
} from './TPSelect.Style';
import { useAlert } from '@src/hooks/modal/useAlert';
import { StatusCodeType } from '@src/common/config/Code';

interface ISelectCheckedProps {
  data: {
    isBoracatUseOrNot: boolean;
    languageTaskStatus: StatusCodeType;
  };
  selectOptions: boolean;
  changeSelect: (state: boolean) => void;
}

export const TPSelectFile = (props: ISelectCheckedProps) => {
  const {
    selectOptions,
    changeSelect,
    data: { isBoracatUseOrNot, languageTaskStatus }
  } = props;
  const { openHandle: alertHandle } = useAlert();
  const changeStatus = (state: boolean) => {
    if (state !== selectOptions) {
      changeSelect(state);
    }
  };

  const imgStatus = ['NEW', 'COMPLETE_ASSIGN'].includes(languageTaskStatus);
  const FileImage = (selectOptions: boolean) => {
    if (imgStatus) {
      return selectOptions ? (
        <IconFileUseTrue className="icon up" />
      ) : (
        <IconFileUseFalse className="icon up" />
      );
    } else {
      return selectOptions ? (
        <IconFileTrue className="icon up" />
      ) : (
        <IconFileFalse className="icon up" />
      );
    }
  };
  const alertObj = {
    isTrue: {
      title: '작업 파일 등록 상태 변경',
      text: '작업 파일 등록 상태로 변경하겠습니까?',
      ok: () => {
        changeStatus(true);
      },
      cancelLabel: '취소'
    },
    isFalse: {
      title: '작업 파일 등록 상태 변경',
      text: '작업 파일 미등록 상태로 변경하겠습니까?',
      ok: () => {
        changeStatus(false);
      },
      cancelLabel: '취소'
    },
    isBoracat: {
      title: '작업 파일 등록 상태 변경 불가',
      text: (
        <span>
          보라캣에 등록된 작업 파일은
          <br /> 작업 파일 등록 상태 변경을 할 수 없습니다.
        </span>
      ),
      ok: () => {}
    }
  };

  const eventHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: 'isTrue' | 'isFalse' | 'isBoracat'
  ) => {
    e.stopPropagation();
    alertHandle(alertObj[isBoracatUseOrNot ? 'isBoracat' : type]);
  };

  return (
    <FilterFileWrap>
      <FilterFileInput className={cx('filter-input')}>
        {FileImage(selectOptions)}
      </FilterFileInput>
      <div className={`${imgStatus ? 'dropdown' : 'default'}`}>
        <Gap />
        <SelectFileList>
          <div
            className={`file-box ${selectOptions ? '' : 'checked'}`}
            onClick={(e) => {
              eventHandler(e, 'isFalse');
            }}
          >
            <IconFileFalse />
          </div>
          <div
            className={`file-box ${selectOptions ? 'checked' : ''}`}
            onClick={(e) => {
              eventHandler(e, 'isTrue');
            }}
          >
            <IconFileTrue />
          </div>
        </SelectFileList>
      </div>
    </FilterFileWrap>
  );
};

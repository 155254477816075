import React, { Fragment, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { alertWrap } from '@hooks/modal/Modal.style';
import { JobDetailStyle, AssignTheme, SubTaskBox } from './JobDetail.style';
import { IconBulDown, IconModalClose } from '@resources/icon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@mui/material';
import {
  getStatus as getTaskStatus,
  LANGUAGE,
  subTaskRole
} from '@src/common/config/Code';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import TPAvatarGroupNew from '@units/avatar/TPAvatar.GroupNew';
import { TPTooltipBasic } from '@units/tooltip/TPTooltip.Basic';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { TPBigRightArrow } from '@units/contour/TPBigRightArrow';
import { ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';
import { usePageFunc } from '@src/hooks/utils';
import { useGetMyWorkDetail } from '@src/hooks/myWork/getDetail';
import {
  DetailModalHeader,
  DetailModalFooter,
  TaskAmountReadOnly
} from './common.Comp';
import { ILanguageSubTaskList } from '@src/common/config/ILanguageTask';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import TPButtonClose from '@src/units/button/TPButton.Close';

/**
 * 내 작업 상세정보 모달 페이지
 * route : /myWorkDetail/:id
 * @returns
 */
function JobDetailMyWork(props: any, ref: any) {
  const navigate = useNavigate();
  let { id } = useParams();
  if (id === undefined) {
    id = window.history.state?.languageSubTaskId;
  }
  const { detailData } = useGetMyWorkDetail(id ?? '');
  const { workerType } = usePageFunc();

  const closeModal = () => {
    if (typeof props.close === 'function') {
      props.close();
    } else {
      navigate(-1);
    }
  };

  const activeValid = (worker: any) => {
    const { status, name } = worker;
    let label = name;
    if (status === 'INACTIVE') {
      label = `${name} (비활성화)`;
    } else if (status === 'LEAVE') {
      label = '삭제된 사용자';
    }
    return label;
  };

  return (
    <div css={[JobDetailStyle, alertWrap]} ref={ref}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <DetailModalHeader
        {...{
          taskStatus: detailData.languageTaskStatus,
          taskKey: detailData.languageTaskKey,
          ...detailData
        }}
      />
      <div className="modal--div--contents">
        {/* 총 분량 */}
        <TaskAmountReadOnly {...detailData} />
        {/* 상세 작업 목록 */}
        <ThemeProvider theme={AssignTheme}>
          <Accordion defaultExpanded={true}>
            {/* 아코디언 summary */}
            <Box
              component={AccordionSummary}
              expandIcon={<IconBulDown width={24} height={24} />}
              display={'flex'}
              className={''}
            >
              <Box
                component={'dl'}
                display={'inline-flex'}
                alignItems={'center'}
                gap={1.5}
              >
                <Box component={'dt'}>
                  <StatusBadge
                    statusCode={detailData?.languageTaskStatus ?? 'NEW'}
                  />
                </Box>
                <Box
                  component={'dd'}
                  textOverflow={'ellipsis'}
                  flexBasis={100}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  paddingRight={1}
                >
                  {detailData.languageTaskKey}
                </Box>
              </Box>

              <Box
                component={'dl'}
                display={'inline-flex'}
                alignItems={'center'}
                gap={1.5}
              >
                <Box component={'dt'}>출발어</Box>
                <Box
                  component={'dd'}
                  textOverflow={'ellipsis'}
                  flexBasis={100}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  paddingRight={1}
                >
                  {
                    _.find(
                      LANGUAGE,
                      (el) => el.code === detailData.startLanguage
                    )?.label
                  }
                </Box>
              </Box>
              <Box
                component={'dl'}
                display={'inline-flex'}
                alignItems={'center'}
                gap={1.5}
              >
                <Box component={'dt'}>도착어</Box>
                <Box
                  component={'dd'}
                  textOverflow={'ellipsis'}
                  flexBasis={100}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  paddingRight={1}
                >
                  {
                    _.find(
                      LANGUAGE,
                      (el) => el.code === detailData.destinationLanguage
                    )?.label
                  }
                </Box>
              </Box>
              <Box
                component={'dl'}
                display={'inline-flex'}
                alignItems={'center'}
                gap={1.5}
              >
                <Box component={'dt'} flexBasis={60} width={60}>
                  언어리더
                </Box>
                <Box
                  component={'dd'}
                  display={'inline-flex'}
                  alignItems={'center'}
                >
                  <Box>
                    <TPAvatarGroupNew
                      userList={detailData?.mainLanguageLeader ?? []}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* 아코디언 detail */}
            <Box component={AccordionDetails}>
              {_.map(
                detailData.languageSubTaskList,
                (subTask: ILanguageSubTaskList, idx) => (
                  <SubTaskBox selectSubList={false} key={idx}>
                    {idx === 0 &&
                      detailData.totalNetAmount !==
                        _.reduce(
                          _.map(
                            detailData.languageSubTaskList,
                            (v: any) => v.taskAmount
                          ),
                          (sum, n) => sum + n,
                          0
                        ) && (
                        <div className="amountMismatch">
                          * 총 분량과 배분된 부작업의 총합이 불일치합니다.
                        </div>
                      )}
                    <div className="key--amount">
                      <div className="key">
                        <span className="item">키</span>
                        <span className="value key">
                          {subTask.languageSubTaskKey}
                        </span>
                      </div>
                      <div className="amount">
                        <span className="item">분량</span>
                        <span className="value">{subTask.taskAmount}</span>
                      </div>
                    </div>
                    <div className="work--box--container">
                      {_.map(
                        [
                          ..._.sortBy(subTask.workerList, (o) =>
                            workerType.indexOf(o.workerType ?? '')
                          )
                        ],
                        (worker, idx) => {
                          const { label } = getTaskStatus([
                            worker?.workerStatus || 'NEW'
                          ])[0];
                          return (
                            <Fragment key={idx}>
                              {idx > 0 && <TPBigRightArrow disable={false} />}
                              <div className={'workBox'}>
                                <StatusBadge
                                  statusCode={worker?.workerStatus ?? 'NEW'}
                                  forceText={label}
                                  padding={`2px 8px`}
                                  width={'48px'}
                                  borderRadius={'0 8px'}
                                  height={'24px'}
                                />

                                <div className="step--avatar">
                                  <div className="step">
                                    {subTaskRole[
                                      `${worker?.workerType}`
                                    ].toString()}
                                  </div>
                                  <TPTooltipBasic title={activeValid(worker)}>
                                    <TPAvatarBasic
                                      src={worker?.avatarImgUrl ?? ''}
                                      size="small"
                                      grayscale={
                                        worker.status === 'INACTIVE' ||
                                        worker.status === 'LEAVE'
                                      }
                                    />
                                  </TPTooltipBasic>
                                </div>
                              </div>
                            </Fragment>
                          );
                        }
                      )}
                    </div>
                  </SubTaskBox>
                )
              )}
            </Box>
          </Accordion>
        </ThemeProvider>
        {/* 납품일 */}
        <DetailModalFooter {...detailData} />
      </div>
    </div>
  );
}

export default forwardRef(JobDetailMyWork);

import { useIsFetching } from '@tanstack/react-query';
import { Backdrop, Fade, Grid, Modal, ThemeProvider } from '@mui/material';
import { ProgressTheme, ProgressStyle } from '@hooks/modal/Progress.style';

/**
 * progress hook
 * @type {() => {Component: () => JSX.Element | null}}
 */
export const useProgress = () => {
  const isFetching = useIsFetching();

  //#region component
  const Component = (): JSX.Element | null => {
    return isFetching ? (
      <ThemeProvider theme={ProgressTheme}>
        <Modal
          styledmodal-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isFetching > 0}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500
            }
          }}
        >
          <Fade in={isFetching > 0}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <ProgressStyle>
                {new Array(8)
                  .fill(undefined)
                  .map((v, i) => <span key={i} />)
                  .map((item, index) => {
                    return item;
                  })}
              </ProgressStyle>
            </Grid>
          </Fade>
        </Modal>
      </ThemeProvider>
    ) : null;
  };

  return {
    Component
  };
  //#endregion
};

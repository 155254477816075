import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { myWorkKeys } from '@hooks/queryKeyFactory';
import { IMyWorkDetailData } from '@src/common/config/ITask';

/**
 * /languageTask/{languageTaskId}
 * @param languageTaskId 언어 작업 아이디
 * @returns {{ detailData }}
 */
export const useGetMyWorkDetail = (languageSubTaskId: string) => {
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof myWorkKeys)['get']>
  >): Promise<IMyWorkDetailData> => {
    const [, languageSubTaskId] = queryKey;
    return (
      await api.get(`/myWork/detail?languageSubTaskId=${languageSubTaskId}`)
    ).data;
  };

  const {
    data: detailData = {
      projectId: 0,
      languageTaskId: 0,
      languageTaskKey: '',
      taskTitle: '',
      totalGrossAmount: 0,
      totalNetAmount: 0,
      description: '',
      taskAmountList: [],
      linkList: [],
      languageSubTaskList: []
    }
  } = useQuery([...myWorkKeys.get(languageSubTaskId)], getData, {
    enabled: !!languageSubTaskId,
    refetchOnMount: true
  });
  return { detailData };
};

import styled from '@emotion/styled';

interface IDataTableRowDataStyle {
  rowEnd: boolean;
  showSubRowData?: boolean;
  gap?: number;
}

export const DataTableColumnHeadStyle = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
  border-bottom: 1px solid #eeedf4;
  height: 38px;
  .head {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #2c3558;
  }
  .head--up--down {
    width: 18px;
    height: 100%;
  }
`;

export const DataTableRowDataStyle = styled.div<IDataTableRowDataStyle>`
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  .main {
    position: relative;
    display: flex;
    align-items: center;
    gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
    border-bottom: ${({ rowEnd, showSubRowData }) =>
      rowEnd && !showSubRowData ? null : '1px solid #eeedf4'};

    /* ::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 120px;
      left: 20px;
      bottom: 0;
      z-index: 10;
    } */
    /* 왜 첫째 td영역을 막았지? */
    .rowDataBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: break-spaces;
      word-break: break-all;
    }

    .rowDataBlock--up--down {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      left: 10px;
    }
    :hover {
      background: #faf6fe;
    }
  }
  .sub {
    > .sub--rowData {
      position: relative;
      background-color: #fafaff;
      display: flex;
      align-items: center;
      gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
      padding: 16px 0;
      border-bottom: 1px solid #eeedf4;
      > .status--key {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: calc(18px + 17.9px + 32px + 58px);
        width: 323.4px;

        > .line {
          position: absolute;
          left: calc(18px + 17.9px + 32px);
          bottom: 50%;
          width: 58px;
          border-bottom: 1px solid #d5d3e4;
        }
        > .line.diphthong {
          border-left: 1px solid #d5d3e4;
          height: 400%;
        }
        > .line.endSubRow {
          border-left: 1px solid #d5d3e4;
          border-radius: 0 0 0 8px;
        }
        > .badge {
          background-color: blue;
        }
        > .key {
          flex-grow: 0.9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid #d5d3e4;
          border-radius: 6px;
          height: 30px;
          &.not--exist {
            color: #e6311a;
          }
        }
      }
      &.sub--rowData:first-of-type {
        > .status--key {
          > .line.diphthong {
            height: 300%;
          }
        }
      }
      > .customer--status--key {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: calc(18px + 17.9px + 2px + 8px);
        width: 102.4px;
        margin-right: 10px;
        > .line {
          position: absolute;
          left: 18px;
          bottom: 50%;
          width: 28px;
          border-bottom: 1px solid #d5d3e4;
        }
        > .line.endSubRow {
          border-left: 1px solid #d5d3e4;
          border-radius: 0 0 0 8px;
        }
        > .badge {
          background-color: blue;
        }
        > .status {
          flex-grow: 0.9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid #d5d3e4;
          border-radius: 6px;
          height: 30px;
          &.not--exist {
            color: #e6311a;
          }
        }
      }
      .rowDataBlock {
      }
      &.none--bottom--line {
        border-bottom: none;
      }
    }
  }
`;

export const DataTableStyle = styled.div`
  width: 1840px;
  height: 100%;
  margin-top: 20px;
  background: #ffffff;
  .container {
    width: 100%;
    height: 100%;
  }
  border: 1px solid #eeedf4;
  border-radius: 4px;
  background-color: #fff;
`;

export const NoMessageContainer = styled.div`
  width: 100%;
  min-height: 72px;
  display: flex;
  padding-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  span {
    color: #9a9cb5;
    font-size: 14px;
  }
`;

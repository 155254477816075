import { css } from '@emotion/css';
import ModalType from '@src/common/config/ModalType';

import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

interface IMainPMAssignButton {
  id: number;
  data: any;
  rowData: any;
  userInfo: any;
}
export const MainPMAssignButton = (props: IMainPMAssignButton) => {
  // id:number - projectId
  const { id, data, userInfo } = props;
  const navigate = useNavigate();

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (userInfo?.roles.includes('PML')) {
      navigate(`/project/${ModalType.PMASSIGN_PAGE}/${Number(id)}`, {
        state: { data }
      });
    }
  };

  let cell: ReactElement = (
    <TPAvatarGroupNew userList={data} onClick={clickHandler} />
  );

  if (userInfo?.roles.includes('PML')) {
    return data[0]?.userId ? (
      cell
    ) : (
      <button className={cssButton} onClick={clickHandler}>
        배정하기
      </button>
    );
  } else {
    return data[0]?.userId ? (
      cell
    ) : (
      <TPButtonBasic
        label="배정중"
        size="small"
        startIcon={null}
        variant="outlined"
        disabled={true}
      />
    );
  }
};

const cssButton = css`
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: transparent;
  color: #9b51e0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.25px;
  text-align: center;
  width: 100%;
  height: 100%;
`;

import { useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { usePageFunc } from '@src/hooks/utils';

const barColor = ['#76aff0', '#ff2222', '#ffd058', '#f28525', '#54ba3d'];

function BarChartComponent({
  graphData: getData = [],
  type
}: {
  graphData: any[];
  type: string;
}) {
  const graphData = _.sortBy(getData, 'z');
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const dataTableRef = useRef<HTMLTableElement>(null);

  const pageHook = usePageFunc();
  const { maxCount, formatNumber } = {
    maxCount: pageHook.maxCount(false, graphData),
    formatNumber: pageHook.formatNumber
  };

  useEffect(() => {
    if (!tableWrapperRef.current || !dataTableRef.current) {
      return;
    }

    const tableWrapper = tableWrapperRef.current;
    const dataTable = dataTableRef.current;

    const toggleScroll = () => {
      if (dataTable.scrollWidth > dataTable.clientWidth) {
        tableWrapper.classList.add('show--scroll');
      } else {
        tableWrapper.classList.remove('show--scroll');
      }
    };

    toggleScroll();

    window.addEventListener('resize', toggleScroll);

    return () => {
      window.removeEventListener('resize', toggleScroll);
    };
  }, []);

  return (
    <BarChartWrap>
      <div>
        <table className="table--column">
          <tbody>
            {_.times(5, (i) => (
              <tr key={i}>
                <td>{formatNumber((maxCount / 5) * (5 - i))}</td>
              </tr>
            ))}
            <tr>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="div--container">
        <div className="table--wrapper" ref={tableWrapperRef}>
          <table id="data-table" ref={dataTableRef}>
            <tbody>
              <tr className="bar--row">
                {_.map(graphData, (item, idx) => {
                  return (
                    <td key={idx}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column-reverse',
                          alignItems: 'center',
                          height: '100%',
                          marginTop: '2px'
                        }}
                      >
                        {_.map(
                          _.sortBy(item.y, 'projectTitle'),
                          (el, elIdx: number) => (
                            <Tooltip
                              key={elIdx}
                              title={
                                <div>
                                  <ul>
                                    <li>{`${el.projectTitle}`}</li>
                                    {type === 'word' ? (
                                      <>
                                        <li>단어 수 : {`${el.count}`}</li>
                                        <li>
                                          <br />* 단어 수는 “해당 프로젝트에
                                          속한 각 하위 작업의 총 분량(Net)” x
                                          “각 하위 작업에 속한 언어작업
                                          수”입니다.
                                        </li>
                                      </>
                                    ) : (
                                      <li>작업 수 : {`${el.count}`}</li>
                                    )}
                                  </ul>
                                </div>
                              }
                              followCursor
                            >
                              <div
                                className="bar"
                                style={{
                                  backgroundColor: barColor[elIdx % 5],
                                  height: `${(360 / maxCount) * el.count}px`,
                                  width: '27px'
                                }}
                              />
                            </Tooltip>
                          )
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tbody>
            <tfoot>
              <tr>
                {_.map(graphData, (data: any, idx) => (
                  <td key={idx}>
                    <div>{data.x}</div>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </BarChartWrap>
  );
}

// textarea 감싸는 div 엘리먼트 스타일
export const BarChartWrap = styled.div`
  display: flex;
  font-size: 12px;
  .table--column {
    width: 60px;
    table-layout: fixed;
    text-align: center;
    border-spacing: 0;
    margin-top: 23px;
    tbody {
      width: 100px;
      height: 420px;
      tr {
        height: 60px;
        td {
          display: flex;
          position: relative;
          flex-direction: row-reverse;
          bottom: 10px;
          right: 10px;
          width: 100%;
        }
      }
    }
    tfoot {
      border: 1px solid;
      td:nth-of-type(1) {
        height: 30px;
        border-left: 1px solid #000;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        text-align: left;
      }
      tr:nth-of-type(2) > td {
        height: 60px;
        border-bottom: 1px solid #000;
      }
    }
  }
  .div--container {
    width: calc(100% - 60px);
    min-width: calc(100% - 60px);
    margin-top: 20px;

    .table--wrapper {
      width: 100%;
      overflow-x: auto;
      table {
        width: max-content;
        min-width: calc(100% - 20px);
        /* border-right: 1px dotted #000; */
        border-top: 1px dotted #b6b6b6;
        border-spacing: 0;
        border-left: none;
        table-layout: fixed;
        text-align: center;
        tbody > tr > td {
          height: 360px;
        }
        td:first-of-type {
          border-spacing: 0;
          border-left: none;
        }
        td {
          font-size: 11px;
          width: 80px;
          height: 30px;
        }
        tfoot > tr > td {
          border-top: 1px solid #b6b6b6;
        }
        tfoot > tr {
          height: 60px;
        }
        tfoot > tr > td {
          overflow: hidden;
          position: relative;
          padding: 10px;
          div {
            width: fit-content;
            transform-origin: top right;
            transform: rotate(-45deg);
            line-height: 1.2;
            word-break: break-all;
            margin-top: -20px;
            display: flex;
            position: absolute;
            right: 50%;
          }
        }
        tfoot > tr:nth-of-type(3) > td {
          border-bottom: 1px solid #b6b6b6;
        }
      }
    }
    /* 왼쪽 표 */
    .table--wrapper::-webkit-scrollbar {
      width: 20px; /*스크롤바의 너비*/
    }

    .table--wrapper::-webkit-scrollbar-thumb {
      background-color: rgb(101, 55, 173, 0.8); /*스크롤바의 색상*/
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: 50px;
    }

    .table--wrapper::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.03); /*스크롤바 트랙 색상*/
      border-radius: 50px;
    }
  }

  .show--scroll {
    overflow-x: scroll;
  }

  .bar {
    z-index: 1;
  }

  .bar:hover {
    border: 2px solid #ff0000;
    border-radius: 2px;
  }

  .bar--row {
    position: relative;
  }

  .bar--row > td:nth-of-type(1):before,
  .bar--row > td:nth-of-type(1):after,
  .bar--row > td:nth-of-type(2):before,
  .bar--row > td:nth-of-type(2):after,
  .bar--row .fifth-line:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 0;
    border-bottom: 1px dotted black;
  }

  .bar--row > td:nth-of-type(1):before {
    top: calc(20% - 1px);
    display: block;
  }

  .bar--row > td:nth-of-type(1):after {
    top: calc(40% - 1px);
  }

  .bar--row > td:nth-of-type(2):before {
    top: calc(60% - 1px);
  }

  .bar--row > td:nth-of-type(2):after {
    top: calc(80% - 1px);
  }

  .bar--row .fifth-line:before {
    top: calc(100% - 1px);
  }
`;

export default BarChartComponent;

import api from '@api/Api';
import { css, cx } from '@emotion/css';
import { ContextProps, context } from '@src/Context';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { useNotice } from '@src/hooks/notice/useNotice';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import AntSwitch from './components/AntSwitch';
import { renderAlarmDateTime } from './dashboard/utils/render';
import { has } from 'lodash';

interface AlarmProps extends React.HTMLAttributes<HTMLDivElement> {}

export interface AlarmItem {
  id: number;
  contents: string;
  readStatus: boolean;
  sendByUserName: string;
  sendByDateTime: string;
  links: { label: string; url: string }[];
}

export const useAlarmsApi = () => {
  const useGetAlarms = (
    unReadMessage: boolean,
    options?: UseQueryOptions<AlarmItem[]>
  ) =>
    useQuery<AlarmItem[]>(
      ['alarms'],
      async () =>
        (await api.get('/message', { unReadMessage })).data.messageList ?? [],
      options
    );

  const { mutateAsync: deleteAlarm } = useMutation((id: number) =>
    api.delete(`message/${id}`)
  );

  const { mutateAsync: readAlarm } = useMutation((id: number) =>
    api.put(`message`, { id })
  );

  return {
    useGetAlarms,
    deleteAlarm,
    readAlarm
  };
};

export default function Alarm({ className, ...props }: AlarmProps) {
  const { hasNewAlarm, setHasNewAlarm, unReadMessage, setUnReadMessage } =
    useContext<ContextProps>(context);
  const { useGetAlarms, deleteAlarm, readAlarm } = useAlarmsApi();
  const { data: alarms = [], refetch: refetchAlarms } =
    useGetAlarms(unReadMessage);

  useEffect(() => {
    refetchAlarms();
  }, [unReadMessage, hasNewAlarm]);

  useEffect(() => {
    const is = alarms.some((e) => e.readStatus === false);
    setHasNewAlarm(is);
  }, [alarms, setHasNewAlarm]);

  return (
    <div className={cx('alarm-ui', cssAlarm, className)} {...props}>
      <div>
        <header>
          <h3>알림</h3>
          <div className="control">
            <span className="title">읽지 않은 알림만 표시</span>
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              checked={unReadMessage}
              onChange={(e) => {
                setUnReadMessage(e.target.checked);
              }}
            />
          </div>
        </header>
        <section className="alarm-list">
          {Array.isArray(alarms) &&
            alarms.map((e) => {
              return (
                <section
                  key={e.id}
                  className="alarm-item"
                  onClick={async () => {
                    if (e.readStatus === true) return;
                    // 링크가 없을 수도 있어 해당 블록을 클릭할 시 읽음처리한다.
                    await readAlarm(e.id);
                    await refetchAlarms();
                  }}
                >
                  {e.readStatus === false && (
                    <svg
                      className="circle new"
                      viewBox="0 0 6 6"
                      width={6}
                      height={6}
                    >
                      <circle cx="3" cy="3" r="3" fill="#E6311A" />
                    </svg>
                  )}
                  <TPButtonClose
                    style={{ '--black': '#727272' } as any}
                    onClick={async () => {
                      await deleteAlarm(e.id);
                      await refetchAlarms();
                    }}
                  />
                  <div
                    className="alarm-item--title"
                    dangerouslySetInnerHTML={{ __html: e.contents }}
                  />
                  <div className="alarm-item--user">
                    <i>by</i> {e.sendByUserName} ·{' '}
                    {renderAlarmDateTime(new Date(e.sendByDateTime))}
                  </div>
                  <div className="alarm-item--links">
                    {e.links.map((link, linkIndex) => (
                      <a
                        key={linkIndex}
                        href={link.url}
                        className="alarm-item--link"
                      >
                        {link.label}
                      </a>
                    ))}
                  </div>
                  <div className="alarm-item--id">{e.id}</div>
                </section>
              );
            })}
          <footer>지난 7일 동안 받은 모든 알림은 여기까지 입니다.</footer>
        </section>
      </div>
    </div>
  );
}

const cssAlarm = css`
  font-family: 'IBM Plex Sans', sans-serif;

  border: 2px solid #ccc;
  /* position: fixed;
  top: 100px;
  right: 100px;
  z-index: 999999; */

  border: 1px solid #ececec;
  background-color: #fff;
  font-family: 'IBM Plex Sans', sans-serif;
  width: 540px;
  box-shadow: 2px 2px 4px 0px #00000014;
  padding: 40px 20px 24px 20px;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -1px;
      text-align: left;
      color: #242424;
    }

    .control {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-align: right;
        color: #727272;
      }
    }
  }
  .alarm-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 568px;
    overflow: overlay;
    padding-right: 13px;
    margin-right: -13px;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 3px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .alarm-item {
      position: relative;
      padding: 12px 16px;
      background: #fafafa;
      color: #727272;
      strong {
        color: #242424;
      }
      .new.circle {
        position: absolute;
        top: 17px;
        left: 14px;
      }
      .button.close.circle {
        position: absolute;
        top: 20px;
        right: 16px;
        transform: translateY(-50%);
      }
      .alarm-item--title {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-align: left;
        padding-left: 14px;
        padding-right: 26px;
        white-space: break-spaces;
        word-break: break-all;
      }
      .alarm-item--user {
        margin-top: 8px;
        margin-bottom: 8px;
        padding-left: 14px;
        padding-right: 14px;
        i {
          font-style: normal;
          color: #aaaaaa;
        }
      }
      .alarm-item--links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        padding-left: 14px;
        padding-right: 14px;

        .alarm-item--link {
          font-size: 12px;
          font-weight: 400;
          line-height: 4px;
          letter-spacing: -0.25px;
          text-align: left;
          color: #727272;
        }
      }
      .alarm-item--id {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        color: #fdfdfd;
        padding: 2px 14px;
        ::selection {
          background: black;
        }
      }
    }

    footer {
      margin-top: 8px;
      margin-bottom: 6px;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: center;
      color: #aaaaaa;
    }
  }
`;

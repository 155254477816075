import { css } from '@emotion/react';

export const PrivacyCss = css`
  max-width: 1840px;
  min-width: 1200px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 40px 0;
  gap: 40px;
  position: relative;
  /* width: 570px; */
  background: #ffffff;
  border-radius: 24px;
  .closeButton {
    top: 24px;
    right: 24px;
    position: absolute;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
    :active {
      opacity: 0.8;
    }
  }
  .title {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
    span {
      position: relative;
      font-size: 32px;
      font-weight: 700;
      color: #2c3558;
      z-index: 1;
      ::after {
        z-index: -1;
        content: '';
        bottom: -7px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 12px;
        background-color: #71d88e99;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1840px;
    height: 392px;
    overflow: auto;
    color: #646782;
    white-space: pre-line;
    flex-shrink: 1;
    flex-grow: 1;

    p {
      text-align: center;
      /* margin-bottom: 16px; */
    }
    table {
      border: 1px solid #2c3558;
      border-collapse: collapse;
      thead th {
        background-color: #2c3558;
        color: #ffffff;
        font-weight: 500;
        line-height: 22.4px;
        font-size: 14px;
      }
      th,
      td {
        border: 1px solid #2c3558;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  .buttonContainer {
    /* position: fixed; */
    /* bottom: 0; */
    /* margin-top: 44px; */
    display: flex;
    gap: 16px;
    width: 100%;
    height: 104px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #eeedf4;
    button {
      width: max-content;
      height: 56px;
    }
  }
`;

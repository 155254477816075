import { css } from '@emotion/react';
import { cssResetButton } from '@src/pages/dashboard/utils/css';
import { MainWrap } from '@src/pages/main/Main.style';

export const Wrap = css`
  margin: 71px 40px 90px;
  width: max-content;

  .fullLookup--title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
  }

  .fullLookup--lookup--period {
    margin-bottom: 40px;
  }
  .fullLookup--lookup--period,
  .fullLookup--list {
    > .name {
      height: 34px;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 20px;
      font-weight: 700;
    }
    > .input--list {
      height: 64px;
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }
    > .filter--list {
      margin-top: 16px;
      .bt8 {
        margin-bottom: 8px;
      }
      .group {
        display: flex;
        gap: 8px;
      }
    }
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .language--span--refresh {
    height: 2.8rem;
    top: 1rem;

    display: flex;
    align-items: flex-end;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  .project--dl--info {
    display: flex;
    flex-basis: calc(100% - 200px);
    justify-content: center;
    align-items: center;
    font-weight: 500;
    dd {
      font-size: 0.875rem;
      > button {
        margin-right: 8px;
      }
    }
  }
`;

import { useEffect, FocusEvent, KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formStepInner } from '@pages/login/Login.style';
import GoogleLoginBtn from '@pages/login/components/GoogleLoginBtn.comp';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';

function EmailForm({
  mutate,
  type
}: {
  mutate: (email: string) => void;
  type?: string;
}) {
  const [email, setEmail] = useState('');
  const [activeEmail] = useState('');
  const [focusEmail, setFocusEmail] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid }
  } = useForm<{ loginemail: string }>();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setEmail(value.loginemail || '');
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const focusInput = (e: FocusEvent<HTMLInputElement>) => {
    if (e.type === 'focus') {
      setFocusEmail('focus');
    } else {
      if (!e.target.value) setFocusEmail('');
    }
  };

  const keypressInput = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      trigger('loginemail').then((result) => {
        if (result) onSubmit();
      });
    }
  };

  const onSubmit = () => {
    mutate(email);
  };

  return (
    <div css={formStepInner}>
      {type === 'email' ? (
        <>
          <label className="formLabel" htmlFor="loginemail">
            <input
              id="loginemail"
              className={`formInput ${activeEmail} ${
                errors.loginemail && 'error'
              } ${isValid && 'valid'}`}
              type="text"
              value={email}
              onFocus={focusInput}
              onKeyDown={keypressInput}
              {...register('loginemail', {
                required: true,
                pattern:
                  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,9}$/i,
                onBlur: focusInput
              })}
            />

            <span
              className={`formPlaceholder ${activeEmail} ${focusEmail} ${
                errors.loginemail && 'error'
              }`}
            >
              이메일
            </span>
          </label>
          {errors.loginemail && (
            <span className="formValidText">
              올바른 형식의 이메일 주소를 입력해 주세요.
            </span>
          )}

          <TPButtonBasic
            className="loginEmailBtn"
            label="이메일로 시작하기"
            fullWidth
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
            disabled={false}
          />
        </>
      ) : (
        <div>
          <GoogleLoginBtn />
        </div>
      )}
    </div>
  );
}

export default EmailForm;

import styled from '@emotion/styled';
import { HTMLAttributes } from 'highcharts';
import { CSSProperties, TdHTMLAttributes } from 'react';

export const Td = ({
  title,
  content,
  width,
  style,
  ...props
}: {
  [key: string]: any;
  title?: any;
  content?: any;
  width?: string | number;
  style?: CSSProperties;
}) => {
  return (
    <TdWrap
      {...props}
      style={{
        ...style,
        width
      }}
    >
      <div>
        <div
          className="title"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5,
            fontSize: '12px',
            lineHeight: '19px',
            color: '#aaa'
          }}
        >
          {title}
        </div>
        <div
          className="content"
          style={{
            width: '100%',
            fontSize: '14px',
            lineHeight: '22.4px',
            color: '#727272'
          }}
        >
          {content}
        </div>
      </div>
    </TdWrap>
  );
};

const TdWrap = styled.td`
  padding: 5px 8px;
  .task-title,
  .project-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 2px;

    .title-fileuse {
      width: 16px;
      height: 16px;
    }
  }
`;

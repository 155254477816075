import api from '@api/Api';
import { taskKeys } from '@hooks/queryKeyFactory';
import { ILanguageSpecListRes } from '@src/common/config/IProjectDetail';
import { useQuery } from '@tanstack/react-query';

const getData = async (): Promise<ILanguageSpecListRes> => {
  return await api.get(`/task/languageSpecList`).then((res) => res.data);
};

export const useGetLanguageSpecList = () => {
  const {
    isLoading,
    isSuccess,
    data: languageSpecList = {
      startLanguageList: [],
      destinationLanguageList: []
    },
    refetch: refetchLanguageSpecList
  } = useQuery([...taskKeys.languageSpecList], getData, {
    refetchOnMount: false
  });

  return { isLoading, isSuccess, languageSpecList, refetchLanguageSpecList };
};

import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { taskManagerKeys } from '../queryKeyFactory';

export const usePostFile = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();

  const uploadHandle = ({
    fileId,
    rowOption,
    columnOption,
    formData
  }: {
    fileId: string | undefined;
    rowOption: string;
    columnOption: string;
    formData: FormData;
  }) => {
    alertHandle({
      title: '등록 확인',
      text: '입력한 내용으로 등록하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        uploadFile({ fileId, rowOption, columnOption, formData });
        navigate('/taskManager', { replace: true });
      }
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/taskManager', { replace: true });
      }
    });
  };
  //#region api call
  const postData = async ({
    fileId,
    rowOption,
    columnOption,
    formData
  }: {
    fileId: string | undefined;
    rowOption: string;
    columnOption: string;
    formData: FormData;
  }) => {
    await api.postBora(
      `/stock/upload?languageTaskId=${fileId}&rowOption=${rowOption}&columnOption=${columnOption}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 360000,
        signal: AbortSignal.timeout(360000)
      }
    );
  };
  const putData = async ({
    fileId,
    rowOption,
    columnOption,
    formData
  }: {
    fileId: string | undefined;
    rowOption: string;
    columnOption: string;
    formData: FormData;
  }) => {
    await api.putBora(
      `/stock/reUpload?languageTaskId=${fileId}&rowOption=${rowOption}&columnOption=${columnOption}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000,
        signal: AbortSignal.timeout(60000)
      }
    );
  };
  //#endregion

  //#region useQuery define
  const { mutate: uploadFile } = useMutation({
    mutationFn: postData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/project']);
      queryClient.invalidateQueries(['/languageTask']);
      openHandle({
        text: `파일 업로드가 성공되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `파일이 업로드에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });

  const { mutate: reUploadFile } = useMutation({
    mutationFn: putData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/project']);
      queryClient.invalidateQueries(['/languageTask']);
      openHandle({
        text: `파일 재업로드가 성공되었습니다.`,
        severity: 'success'
      });
      navigate('/taskManager', { replace: true });
    },
    onError: () => {
      openHandle({
        text: `파일이 재업로드에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  return {
    uploadHandle,
    reUploadFile,
    closeModal
  };
};

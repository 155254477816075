import {
  LANGUAGE,
  getDateType,
  getStatus,
  getTaskType
} from '@common/config/Code';
import { useGetDashbordList } from '@src/hooks/task/getTaskList';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { useGetFilter } from '@hooks/searchFilter';
import { Box } from '@mui/material';
import DataTableTaskAll from '@src/pages/components/DataTable/DataTableTaskAll';
import { IconCalendar, IconInfo, IconRefresh } from '@resources/icon';
import { useGetTaskManagerList } from '@src/hooks/task/getManagerList';
import { Wrap } from '@src/pages/task/all/TaskAll.style';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { TPInputSearchNew } from '@units/input/TPInput.searchNew';
import { TPSelectCheck } from '@units/select/TPSelect.check';
import { format } from 'date-fns';
import _, { omit } from 'lodash';
import UnderlineTitle from '../../components/UnderlineTitle';
import { taskAllColumns } from './TaskAll.columns';
import Footer from '@src/layouts/footer/Footer';

function TaskAllPage() {
  const { myInfo } = useMyInfo();
  const { filterData, Component: FilterButton } = useGetFilter('ALL_TASK_LIST');
  const { pmList, taskerList } = useGetTaskManagerList();
  const {
    value,
    dataList,
    dashboardFilter,
    setState,
    searchDateFilter,
    setSearchDateFilter,
    dateFilter,
    isSort,
    setDateFilter
  } = useGetDashbordList(filterData);
  const {
    searchDateType,
    filterState,
    filterTaskType,
    filterStartLanguage,
    filterDestinationLanguage,
    filterPm,
    filterTasker,
    filterExposure,
    filterDateType,
    isFilter
  } = dashboardFilter;
  const {
    setSearchDateType,
    setChangeValue,
    setSearchValue,
    setFilterState,
    setFilterTaskType,
    setFilterStartLanguage,
    setFilterDestinationLanguage,
    setFilterPm,
    setFilterTasker,
    setFilterExposure,
    setFilterDateType,
    setClearFilter,
    setIsSort
  } = setState;

  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'searchDateRange'
  });
  const { openHandle: workPeriodOpenHandle, Component: WorkPeriodCalendar } =
    useRangeCalendar({ dateType: 'workPeriodDateRange' });

  const lookupPeriodTooltipMessage =
    '작업을 조회할 기간을 설정할 수 있습니다.<br/> 조회할 수 있는 최대 기간은 1년이며<br/>조회해야할 데이터가 많을 경우,<br/>불러오는 시간이 오래 걸릴 수 있습니다.';
  const listTooltipMessage =
    '조회 기간에 해당하는 작업에 대해서 검색 또는<br/> 필터를 적용할 수 있습니다.';

  const defaultAccordionExposure = filterExposure === 'open';

  return (
    <section css={Wrap}>
      <div className="fullLookup--title">
        <UnderlineTitle text="전체 작업 목록" align="notCenter" />
      </div>
      <div className="fullLookup--lookup--period">
        <div className="name">
          <span>조회 기간</span>
          <SimpleTooltip message={lookupPeriodTooltipMessage}>
            <IconInfo width={24} height={24} />
          </SimpleTooltip>
        </div>
        <div className="input--list">
          <TPSelectOne
            //todo default '마감일'
            label="기간 대상"
            optionList={getDateType([
              'HOPE_SUPPLY_DATE',
              'START_DATE',
              'END_DATE'
            ])}
            selectOption={searchDateType}
            changeSelect={setSearchDateType}
          />

          <div
            onClick={() => {
              openHandle('search', searchDateFilter, setSearchDateFilter);
            }}
          >
            <TPButtonActionCalendar
              outline={true}
              size={'medium'}
              content={
                searchDateFilter.startDateTime && searchDateFilter.endDateTime
                  ? `${format(
                      new Date(searchDateFilter.startDateTime),
                      'yyyy.MM.dd'
                    )} - ${format(
                      new Date(searchDateFilter.endDateTime),
                      'yyyy.MM.dd'
                    )}`
                  : ''
              }
              icon={<IconCalendar />}
            />
          </div>
        </div>
      </div>
      <div className="fullLookup--list">
        <div className="name">
          <span>{`작업 & 부작업 리스트`}</span>
          <SimpleTooltip message={listTooltipMessage}>
            <IconInfo width={24} height={24} />
          </SimpleTooltip>
        </div>
        <div className="filter--list">
          <div className="group bt8">
            <TPInputSearchNew
              value={value}
              placeholder="검색"
              onChange={setChangeValue}
              clickSearch={setSearchValue}
              width={208}
            />
            <TPSelectCheck
              label="상태"
              useOptionLabel
              optionList={getStatus([
                'ALL',
                'NEW',
                'COMPLETE_ASSIGN',
                'PROGRESS',
                'STOP',
                'COMPLETE',
                'DELIVERY_COMPLETE'
              ])}
              selectOptions={filterState}
              changeSelect={setFilterState}
            />
            <TPSelectCheck
              label="유형"
              optionList={getTaskType([
                'ALL',
                'TRANSLATION',
                'POLISHING',
                'LQA'
              ])}
              selectOptions={filterTaskType}
              changeSelect={setFilterTaskType}
            />
            <TPSelectCheck
              label="출발어"
              optionList={_.filter(LANGUAGE, (item) => item.code !== '')}
              useOptionLabel
              selectOptions={filterStartLanguage}
              changeSelect={setFilterStartLanguage}
            />
            <TPSelectCheck
              label="도착어"
              optionList={_.filter(LANGUAGE, (item) => item.code !== '')}
              useOptionLabel
              selectOptions={filterDestinationLanguage}
              changeSelect={setFilterDestinationLanguage}
            />

            <TPSelectCheckManager
              label="담당 PM"
              PMList={pmList}
              showAvatar={true}
              selectOptions={filterPm}
              changeSelect={setFilterPm}
            />

            <TPSelectCheckManager
              label="작업자"
              PMList={taskerList}
              showAvatar={true}
              selectOptions={filterTasker}
              changeSelect={setFilterTasker}
            />
            <TPSelectOne
              label="노출"
              optionList={[
                { code: 'open', label: '펼침' },
                { code: 'close', label: '닫힘' }
              ]}
              selectOption={filterExposure}
              changeSelect={setFilterExposure}
            />
          </div>
          <div className="group">
            <TPSelectOne
              label="날짜"
              optionList={getDateType([
                'ALL',
                'HOPE_SUPPLY_DATE',
                'START_DATE',
                'END_DATE'
              ])}
              selectOption={filterDateType}
              changeSelect={setFilterDateType}
            />
            {filterDateType !== 'ALL' && (
              <>
                <div
                  onClick={() => {
                    workPeriodOpenHandle(
                      'workPeriod',
                      dateFilter,
                      setDateFilter
                    );
                  }}
                >
                  <TPButtonActionCalendar
                    outline={true}
                    size={'medium'}
                    content={
                      dateFilter.startDateTime && dateFilter.endDateTime
                        ? `${format(
                            new Date(dateFilter.startDateTime),
                            'yyyy.MM.dd'
                          )} - ${format(
                            new Date(dateFilter.endDateTime),
                            'yyyy.MM.dd'
                          )}`
                        : ''
                    }
                    icon={<IconCalendar />}
                  />
                </div>
              </>
            )}
            {isFilter && (
              <button
                className="main--button--refresh"
                onClick={() => setClearFilter()}
              >
                {/* <IconRefresh /> */}
                <span>초기화</span>
              </button>
            )}
            {/* [전체 작업 조회] 필터링 저장 */}
            <FilterButton
              getFilterData={{
                dashboardFilter: omit(dashboardFilter, 'searchValue'), // 작업키 또는 작업명 검색 제외
                searchDateFilter,
                dateFilter
              }}
            />
          </div>
        </div>
      </div>

      <Box m={'1.5rem 0'}>
        <DataTableTaskAll
          myInfo={myInfo}
          columns={taskAllColumns(isSort, setIsSort)}
          dataList={[...dataList()]}
          accordionType="fullLookup"
          defaultAccordionExposure={defaultAccordionExposure}
          noRowsMessage={`${isFilter ? '검색된' : '배정된'} 작업이 없습니다.`}
          gap={17.8}
        />
      </Box>

      <Footer />

      <Calendar />
      <WorkPeriodCalendar />
    </section>
  );
}

export default TaskAllPage;

import { ThemeProvider } from '@mui/material/styles';
import { TPInputRadioTheme } from './TPInput.theme';
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { IconChecked, IconUnChecked } from '@resources/icon';
import { ChangeEvent, ReactElement } from 'react';

interface IInputProps {
  value?: string;
  label?: string;
  required?: boolean;
  name: string;
  options: { label: string; value: string }[];
  icon?: ReactElement;
  unCheckIcon?: ReactElement;
  mt?: number;
  changeRadio?: (e: ChangeEvent) => void;
  disabled?: boolean;
}

export const TPInputRadio = (props: IInputProps) => {
  const {
    value,
    label,
    required = false,
    name,
    options = [],
    icon = <IconChecked width={16} height={16} />,
    unCheckIcon = <IconUnChecked width={16} height={16} />,
    mt = 0,
    changeRadio = () => {},
    disabled
  } = props;

  return (
    <ThemeProvider theme={TPInputRadioTheme}>
      {label ? (
        <Box display={'flex'} textAlign={'left'} mt={mt}>
          <FormLabel htmlFor={name}>
            {label}
            {required ? (
              <Box component={'sup'} color="#E6311A">
                *
              </Box>
            ) : null}
          </FormLabel>
        </Box>
      ) : null}
      <Box mt={label ? 0.875 : mt} ml={'1px'}>
        <RadioGroup
          row
          aria-labelledby={label}
          value={value}
          name={name}
          onChange={changeRadio}
        >
          {options.map((item, index) => {
            let { value, label } = item;
            return (
              <FormControlLabel
                disabled={disabled}
                sx={{ mr: '30px' }}
                key={index}
                value={value}
                control={<Radio checkedIcon={icon} icon={unCheckIcon} />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </Box>
    </ThemeProvider>
  );
};

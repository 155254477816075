import { TPInputCheckBoxWrap } from './TPInput.theme';
import { Box, Checkbox } from '@mui/material';
import { IconChecked, IconUnChecked } from '@resources/icon';
import { TPAvatarBasic } from '../avatar/TPAvatar.Basic';

interface IInputCheckBoxProps {
  option: string;
  checked?: boolean;
  optionKey: string;
  selectOptions: (string | number)[];
  avatarUrl?: string;
  showAvatar?: boolean;
  onClick: (state: string) => void;
  status?: string;
}

export const TPInputCheckBox = (props: IInputCheckBoxProps) => {
  const {
    option,
    optionKey,
    selectOptions,
    avatarUrl,
    onClick,
    showAvatar,
    status
  } = props;

  const icon = <IconChecked width={16} height={16} />;
  const unCheckIcon = <IconUnChecked width={16} height={16} />;
  const checked = selectOptions.includes(optionKey);

  const activeValid = () => {
    let name = option;

    if (status === 'INACTIVE') {
      name = `${option} (비활성화)`;
    } else if (status === 'LEAVE') {
      name = '삭제된 사용자';
    }

    return name;
  };

  return (
    <TPInputCheckBoxWrap
      onClick={(e) => {
        e.stopPropagation();
        onClick(optionKey);
      }}
    >
      <Checkbox
        checked={checked}
        value={option}
        icon={unCheckIcon}
        checkedIcon={icon}
      />

      {showAvatar && option !== '전체' ? (
        <>
          <TPAvatarBasic
            alt={option}
            size="xsmall"
            src={avatarUrl}
            grayscale={status === 'INACTIVE' || status === 'LEAVE'}
          />
          <div style={{ width: '8px' }}></div>
        </>
      ) : null}

      <Box>{activeValid()}</Box>
    </TPInputCheckBoxWrap>
  );
};

/**
 * @description Input CheckBox 컴포넌트
 * @param props
 */
export const TPInputCheckSingleBox = (props: {
  option: string;
  checked: boolean;
  avatarUrl?: string;
  showAvatar?: boolean;
  disabled?: boolean;
  onClick: (state: any) => void;
}) => {
  const { option, checked, avatarUrl, onClick, disabled, showAvatar } = props;

  const icon = <IconChecked width={24} height={24} />;
  const unCheckIcon = <IconUnChecked width={24} height={24} />;

  return (
    <TPInputCheckBoxWrap
      className={disabled ? 'disabled' : ''}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) onClick(!checked);
      }}
    >
      <Checkbox
        checked={checked}
        value={option}
        icon={unCheckIcon}
        checkedIcon={icon}
        disabled={disabled}
      />

      {showAvatar && option !== '전체' ? (
        <>
          <TPAvatarBasic
            sx={{ marginRight: '12px' }}
            alt={option}
            size="xsmall"
            src={avatarUrl}
          />
          <Box sx={{ width: '8px' }}></Box>
        </>
      ) : null}

      <Box>{option}</Box>
    </TPInputCheckBoxWrap>
  );
};

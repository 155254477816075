import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { roleType } from '@common/config/Code';
import { useAuthorizationGoogle } from '@hooks/auth/postAuthGoogle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { authKeys, myInfoKeys } from '@hooks/queryKeyFactory';
import { ErrorCustom } from '@pages/errors/ErrorCustom';

interface IAuthRole {
  [key: string]: string[];
}

const authRole: IAuthRole = {
  project: ['PML', 'PM'],
  detail: ['PML', 'PM'],
  tasktab: ['LL'],
  task: ['LL'],
  mytasktab: ['TL'],
  mytask: ['TL'],
  dashboard: ['PML', 'PM', 'LL', 'TL']
};

export const usePagePermission = (myInfo: any) => {
  const ruleRoles = authRole[window.location.pathname.split('/')[1]];
  const myRoles = myInfo?.roles;
  if (Array.isArray(myRoles) && Array.isArray(ruleRoles)) {
    return ruleRoles.some((v: any) => myRoles.includes(v));
  }
  return false;
};

export const useAuth = () => {
  const queryClient = useQueryClient();
  const { googleSignOut } = useAuthorizationGoogle();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const { myInfo } = useMyInfo();

  const toAgreeOrDisagreePage = () => {
    const isDone =
      localStorage.getItem('accessToken') !== null &&
      localStorage.getItem('privacyAgree') !== 'DONE';
    if (isDone) {
      navigate('/login/privacy', { replace: true });
    }
    //  else {
    //   queryClient.clear();
    //   navigate('/login', { replace: true });
    // }
    return isDone;
  };

  // 라우터로 권한체크하는 로직. 실제 기획에 맞지 않아서 폐기처분한다.
  const permissionCheck = () => {
    const roles = authRole[pathname.split('/')[1]];
    if (myInfo && roles) {
      const shouldRedirect = !roles.filter((v: any) => myInfo.roles.includes(v))
        .length;
      if (shouldRedirect) {
        throw new ErrorCustom(
          'Error: No roles matches URL',
          { message: 'No roles matches URL' },
          403,
          false,
          'Not Permitted'
        );
      }
    }
  };

  const signIn = () => {
    const rolesOrder = {
      ADMIN: 1,
      PML: 2,
      PM: 3,
      LL: 4,
      TL: 5
    };
    const copyMyInfo = JSON.parse(JSON.stringify(myInfo));
    const sortUserData = {
      ...copyMyInfo,
      roles: copyMyInfo.roles.sort(
        (a: roleType, b: roleType) => rolesOrder[a] - rolesOrder[b]
      )
    };

    if (!pathname.includes('login') && pathname !== '/') {
      navigate(`${pathname}${search ? search : ''}`, { replace: true });
      return;
    }

    if (sessionStorage.getItem('directPath') !== null) {
      navigate(sessionStorage.getItem('directPath') as string, {
        replace: true
      });
      sessionStorage.removeItem('directPath');
      return;
    }

    const permissionIndex = sortUserData.roles[0] === 'ADMIN' ? 1 : 0;
    switch (sortUserData.roles[permissionIndex]) {
      case 'ADMIN':
      case 'PML':
      case 'PM':
        navigate('/dashboard', { replace: true });
        break;
      case 'LL':
        navigate('/tasktab', { replace: true });
        break;
      case 'TL':
        navigate('/mytasktab', { replace: true });
        break;
      default:
        // navigate('/errors', { replace: true });
        navigate('/login', { replace: true });
        break;
    }
  };

  const signOut = () => {
    if (!pathname.includes('/login')) {
      if (
        myInfo === undefined &&
        localStorage.getItem('accessToken') === null
      ) {
        if (pathname !== '/') sessionStorage.setItem('directPath', pathname);
      }
      localStorage.removeItem('privacyAgree');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessTokenExpiresIn');
      queryClient.invalidateQueries([
        ...myInfoKeys.get,
        ...authKeys.emailValidation,
        ...authKeys.authentication
      ]);

      navigate('/login', { replace: true });
      googleSignOut();
      queryClient.clear();
    }
  };

  return {
    signIn,
    signOut,
    permissionCheck,
    toAgreeOrDisagreePage
  };
};

import { ThemeProvider } from '@mui/material/styles';
import { TPInputDateTheme } from './TPInput.theme';
import { Box, FormLabel, TextField } from '@mui/material';
import { IconCalendar } from '@src/resources/icon';
import { format } from 'date-fns';

interface IInputProps {
  date?: string | Date;
  label?: string;
  required?: boolean;
  name?: string;
  placeholder?: string;
  helperText?: string;
  mt?: number;
  openHandle?: () => void;
  disabled?: boolean;
}

export const TPInputDate = (props: IInputProps) => {
  const {
    date,
    label,
    required = false,
    name,
    placeholder,
    helperText,
    mt,
    openHandle,
    disabled
  } = props;

  const _date = new Date(date as any);

  const dateFormat = 'yyyy.MM.dd HH:mm';
  const showDate = !isNaN(_date?.getTime())
    ? format(_date, dateFormat).toLowerCase()
    : '';

  return (
    <ThemeProvider theme={TPInputDateTheme}>
      {label ? (
        <Box display={'flex'} textAlign={'left'} mt={mt}>
          <FormLabel htmlFor={name}>
            {label}
            {required ? (
              <Box component={'sup'} color="#E6311A">
                *
              </Box>
            ) : null}
          </FormLabel>
        </Box>
      ) : null}
      <Box mt={label ? 1 : mt}>
        <TextField
          disabled={disabled}
          value={showDate}
          placeholder={placeholder}
          helperText={helperText}
          InputProps={{
            readOnly: true,
            endAdornment: disabled ? (
              <></>
            ) : (
              <IconCalendar
                width={24}
                height={24}
                onClick={openHandle}
                style={{ cursor: 'pointer' }}
              />
            )
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

import api from '@api/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fileManagementKeys } from '@hooks/queryKeyFactory';
import _ from 'lodash';
import { useAlert } from '../modal/useAlert';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../modal/useToast';

export const useGetFileList = (fileId: any) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();

  //#region api call
  const getData = async (): Promise<any[]> => {
    return await api
      .getBora(`/stock/contents?languageTaskId=${fileId}`)
      .then((res) => res.data);
  };
  const postData = async (segmentObj: any) => {
    await api.postBora(`/segment`, segmentObj, {
      timeout: 360000,
      signal: AbortSignal.timeout(360000)
    });
  };
  //#endregion

  //#region useQuery define
  // const { data = [] } = useQuery([...fileManagementKeys.list], getData);
  const { data = [] } = useQuery([...fileManagementKeys.list, fileId], getData);

  const { mutate: postSegment } = useMutation({
    mutationFn: postData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/project']);
      queryClient.invalidateQueries(['/languageTask']);
      openHandle({
        text: `Segment 성공되었습니다.`,
        severity: 'success'
      });
      navigate('/taskManager', { replace: true });
    },
    onError: () => {
      openHandle({
        text: `Segment 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/taskManager', { replace: true });
      }
    });
  };

  return { data, postSegment, closeModal };
};

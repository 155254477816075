import { css } from '@emotion/react';
import { cssResetButton } from '../dashboard/utils/css';

export const MainWrap = css`
  margin: 71px 40px 90px;
  width: max-content;
  min-width: calc(100vw - 40px);

  font-family: 'IBM Plex Sans', sans-serif;
  letter-spacing: -1px;
  text-align: left;

  .info-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    .info {
      .sub-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #727272;
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: #242424;
      }
    }
  }

  h2 {
    max-width: 40%;
    word-break: break-all;
    word-wrap: break-word;
  }

  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }

  .filter-toolbar {
    width: 100vw;
    position: fixed;
    top: 56px;
    left: 0;
    background-color: #fff;
    z-index: 1052;

    & > div {
      padding-left: 42px;
      padding-right: 42px;
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > div {
        display: flex;
        flex-direction: row;
      }
    }
  }
  .content {
    margin-top: -40px;
    margin-left: -40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
    background-color: #f9f9f9;
  }

  .calendar-range {
    margin-bottom: 20px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 14px;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -0.25px;
    color: #727272;
    gap: 8px;
  }
`;

import { ThemeProvider } from '@mui/material/styles';
import { TPInputSearchTheme, TPInputWrap } from './TPInput.theme';
import { Button, TextField } from '@mui/material';
import { IconSearch, IconCircleClose } from '@resources/icon';
import { KeyboardEvent } from 'react';

interface IInputProps {
  value?: string;
  size?: 'medium' | 'small';
  autoFocus?: boolean;
  changeText: (e?: any) => void;
  clickSearch?: () => void;
  resetButton?: boolean;
  placeholder?: string;
}

export const TPInputSearch = (props: IInputProps) => {
  const {
    value,
    size = 'small',
    autoFocus,
    changeText,
    placeholder,
    resetButton,
    clickSearch = () => {}
  } = props;
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      clickSearch();
    }
  };

  const activeResetButton = value && resetButton;

  return (
    <TPInputWrap>
      <ThemeProvider theme={TPInputSearchTheme}>
        <TextField
          autoFocus={autoFocus}
          onChange={changeText}
          value={value}
          size={size}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => {
                  activeResetButton ? changeText() : clickSearch();
                }}
              >
                {activeResetButton ? (
                  <IconCircleClose fill={'#9A9CB5'} />
                ) : (
                  <IconSearch />
                )}
              </Button>
            )
          }}
        />
      </ThemeProvider>
    </TPInputWrap>
  );
};

import { useQuery } from '@tanstack/react-query';
import api from '@api/Api';
import { IUser } from '@src/common/config/ICode';
import { projectKeys } from '@hooks/queryKeyFactory';

/**
 * /project/allProjectManagerList
 * @discription 프로젝트 매니저 전체 목록 조회
 * @returns {{allPmList}}
 */
export const useGetAllPmList = () => {
  const getData = async (): Promise<IUser[]> => {
    return (await api.get('/project/allProjectManagerList')).data
      .projectManagerUserDataList;
  };

  const { data = [] } = useQuery([...projectKeys.allPmList], getData);

  const allPmList = [...data.filter((e) => e.userId !== null)];

  return { allPmList };
};

import { css } from '@emotion/react';

export const footerWrap = css`
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  min-width: 1180px;
  padding: 40px 80px 40px 0;
  font-size: 12px;
  font-weight: 400;
  .footer--ul--menu {
    display: inline-flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    li {
      margin-left: 24px;
    }
  }
  .footer--address--copyright {
    font-style: normal;
    color: #9a9cb5;
    text-align: right;
  }
`;

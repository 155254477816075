import _ from 'lodash';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { useEffect, useState } from 'react';
import { ILanguageByCount } from '@src/common/config/ILanguageTask';

/**
 *  /myWork/languageByCount
 * @discription 언어별 작업 수 조회
 * @returns {{ languagePairList, count }}
 */
export const useLanguageByCount = ({
  countItemList,
  pageType
}: {
  countItemList?: any;
  pageType?: string;
}) => {
  const { myInfo } = useMyInfo();

  const [languagePairOptions, setLanguagePairOptions] = useState([
    {
      startLanguage: '',
      destinationLanguage: '',
      count: 0
    }
  ]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!myInfo) return;
    let languagePairList = _.map(
      JSON.parse(JSON.stringify(myInfo?.languagePairList)),
      (o: any) => {
        let filterData: any = {
          startLanguage: o.startLanguage,
          destinationLanguage: o.destinationLanguage
        };
        if (pageType === 'work') {
          filterData = {
            ...filterData,
            languageTaskStatus: 'NEW'
          };
        } else {
          filterData = {
            ...filterData,
            workerStatus: 'NEW'
          };
        }
        const count = _.filter(countItemList, filterData).length;
        return { ...o, count };
      }
    );
    setLanguagePairOptions(languagePairList);
    setCount(
      _.sumBy(languagePairList, (o: ILanguageByCount) => {
        return o.count;
      })
    );
    return () => {};
  }, [countItemList, myInfo]);

  return { languagePairOptions, count };
};

import { Box, Tooltip } from '@mui/material';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { Wrap } from '@pages/dashboard/Languages.style';
import { IconInfo } from '@resources/icon';
import {
  getLanguageLabelsByCode,
  getStatus,
  getTaskType
} from '@src/common/config/Code';
import { useGetFilter } from '@src/hooks/searchFilter';
import { useGetSaturationDegree } from '@src/hooks/statistics/saturation/getDegree';
import { useGetLanguageSpecList } from '@src/hooks/task/getLanguageSpec';
import Footer from '@src/layouts/footer/Footer';
import TPInputNumberNew from '@src/units/input/TPInput.numberNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import BarChartFooterComponent from '../components/BarChart.Footer';

const LanguagesPage = () => {
  const titleDescription = `
    “언어작업” 기준으로 입력한 조회 조건에 따라 언어페어 별 포화도를 확인할 수 있습니다.`;

  const { filterData, Component: FilterButton } = useGetFilter(
    'SATURATION_WORD',
    '조회 조건'
  );

  //#region set state
  const {
    degreeFilter,
    startLanguage,
    destinationLanguage,
    getDegreeData,
    setState,
    remove,
    refetch,
    downloadData,
    isFilter
  } = useGetSaturationDegree(filterData);
  const { languageSpecList } = useGetLanguageSpecList();

  //#endregion
  const {
    setTaskTypeList,
    setTaskStatusList,
    setStartLanguage,
    setDestinationLanguage,
    setResetState
  } = setState;

  useEffect(() => {
    return () => remove();
  }, []);

  const selectHandler = () => {
    refetch();
  };

  const [workerCount, setWorkerCount] = useState('');

  const resetState = () => {
    setResetState();
    setWorkerCount('');
  };

  return (
    <section css={Wrap}>
      <div className="languages--title">
        <UnderlineTitle text="언어 별 포화도" align="notCenter" />{' '}
        <Tooltip title={titleDescription} placement="right">
          <IconInfo width={24} height={24} />
        </Tooltip>
      </div>
      <Box
        mb={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'8px'}
      >
        <Box display={'inline-flex'} gap={'8px'}>
          <TPInputNumberNew
            autoFocus
            value={workerCount}
            onChange={(e) => {
              if (e.target.value === '') return setWorkerCount('');
              const value = Number(e.target.value.replace(/,/g, ''));
              !isNaN(value) && value < 1000 && setWorkerCount(String(value));
            }}
            placeholder="작업자 수 입력"
          />
          <TPSelectCheck
            label="유형"
            optionList={getTaskType(['ALL', 'TRANSLATION', 'POLISHING', 'LQA'])}
            selectOptions={degreeFilter.taskTypeList}
            changeSelect={setTaskTypeList}
          />
          <TPSelectCheck
            label="상태"
            useOptionLabel
            optionList={getStatus([
              'ALL',
              'NEW',
              'COMPLETE_ASSIGN',
              'PROGRESS',
              'COMPLETE',
              'DELIVERY_COMPLETE',
              'STOP'
            ])}
            selectOptions={degreeFilter.taskStatusList}
            changeSelect={setTaskStatusList}
          />
          <Box width={'fit-content'}>
            <TPSelectOne
              label="출발어"
              optionList={[
                ..._.map(languageSpecList?.startLanguageList ?? [], (el) => ({
                  code: el.value,
                  label: el.key,
                  text: el.key
                }))
              ]}
              selectOption={startLanguage}
              changeSelect={setStartLanguage}
            />
          </Box>
          <Box width={'fit-content'}>
            <TPSelectOne
              label="도착어"
              optionList={[
                ..._.map(
                  _.filter(
                    languageSpecList?.destinationLanguageList ?? [],
                    (v) => v.value !== startLanguage
                  ),
                  (el) => ({
                    code: el.value,
                    label: el.key,
                    text: el.key
                  })
                )
              ]}
              selectOption={destinationLanguage}
              changeSelect={setDestinationLanguage}
              unable={!startLanguage.length}
            />
          </Box>
          <button
            className={'languages--button--search'}
            onClick={() => {
              selectHandler();
            }}
            disabled={
              startLanguage === '' ||
              destinationLanguage === '' ||
              workerCount === '' ||
              workerCount === '0'
            }
          >
            조회
          </button>
          {isFilter && (
            <button className="languages--button--refresh" onClick={resetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          {/* [언어 별 포화도] 필터링 저장 */}
          <FilterButton
            getFilterData={{
              degreeFilter,
              startLanguage,
              destinationLanguage
            }}
          />
        </Box>
        <button
          className={'languages--button--download'}
          onClick={downloadData}
          disabled={
            startLanguage === '' ||
            destinationLanguage === '' ||
            !getDegreeData.length
          }
        >
          Excel로 내려받기
        </button>
      </Box>
      <Box
        m={'3rem 0 5rem 0'}
        style={{
          borderRadius: '16px',
          padding: '40px 20px 50px 20px',
          background: '#ffffff',
          boxShadow: '0 1px 32px 4px #f0edf6',
          textAlign: 'center'
        }}
      >
        {getDegreeData.length ? (
          <BarChartFooterComponent
            data={getDegreeData}
            workerCount={Number(workerCount)}
            startLanguage={getLanguageLabelsByCode([startLanguage])[0].label}
            destinationLanguage={
              getLanguageLabelsByCode([destinationLanguage])[0].label
            }
          />
        ) : (
          <span className="languages--span--empty">
            조회된 언어가 없습니다.
            <br />
            <br />
            "조회 조건"을 설정한 후, "조회" 버튼을 눌러
            <br />
            언어 별 포화도를 확인할 수 있습니다.
          </span>
        )}
      </Box>

      <Footer />
    </section>
  );
};

export default LanguagesPage;

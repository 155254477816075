import api from '@api/Api';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { IUser } from '@src/common/config/ICode';
import { useEffect, useState } from 'react';
import { useHookFunc, useHookLLAssignFunc } from '../utils';
import { getLanguageLabelByCode } from '@src/common/config/Code';

export const useGetAllProjectAssignerList = () => {
  const [keyword, setKeyword] = useState<string>('');
  const { defaultUserList } = useHookLLAssignFunc();
  const getData = async (): Promise<IUser[]> => {
    return (await api.get(`/project/allProjectAssignerList`)).data
      .projectAssignerList;
  };

  const projectAssignerListQuery = useQuery(
    ['project/allProjectAssignerList'],
    getData,
    {
      select: (data) =>
        keyword.length
          ? [
              ..._.filter(
                data,
                (el) =>
                  el.name.toUpperCase().includes(keyword.toUpperCase()) ||
                  (Array.isArray(el.languagePairList) &&
                    el.languagePairList.some(
                      ({ startLanguage, destinationLanguage }) =>
                        getLanguageLabelByCode(startLanguage).includes(
                          keyword
                        ) ||
                        getLanguageLabelByCode(destinationLanguage).includes(
                          keyword
                        )
                    ))
              )
            ]
          : data
    }
  );

  const {
    data: allProjectAssignerList = [...defaultUserList],
    refetch: refetchAllProjectAssignerList
  } = projectAssignerListQuery;

  return {
    keyword,
    setKeyword,
    allProjectAssignerList,
    refetchAllProjectAssignerList,
    projectAssignerListQuery
  };
};

export const useGetProjectAssignerList = () => {
  const { defaultUserList } = useHookFunc();
  const getData = async (): Promise<IUser[]> => {
    return (await api.get(`/project/projectAssignerList`)).data
      .projectAssignerList;
  };

  const projectAssignerListQuery = useQuery(
    ['/project', '/projectAssignerList'],
    getData,
    {
      select: (data) => [...defaultUserList, ...data]
    }
  );

  const {
    data: projectAssignerList = [...defaultUserList],
    refetch: refetchAllProjectAssignerList
  } = projectAssignerListQuery;

  return {
    projectAssignerList,
    refetchAllProjectAssignerList,
    projectAssignerListQuery
  };
};

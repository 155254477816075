import { css } from '@emotion/react';
import { Color } from '@src/common/styles/color';

export const JobAssignStyle = css`
  width: 756px;
  /* height: 795px; */
  text-align: center;
  color: #242424;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 50px 20px 30px;

  .button.close.circle {
    top: 6px;
    right: 12px;
    position: absolute;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.25px;
    text-align: left;
    text-align: center;
    margin-bottom: 64px;
  }

  .info {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #727272;
    white-space: break-spaces;
    word-break: break-all;
    background-color: #fafafa;
    padding: 10px 20px;
    margin-bottom: 20px;

    .status {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
    .task-key {
      display: inline;
      color: #242424;
      padding-left: 6px;
      padding-right: 2px;
    }
    .task-title {
      display: inline;
      font-size: 14px;
    }
  }

  .middle-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #9a9cb5;
    border-bottom: 1px solid #ececec;
    padding-bottom: 8px;

    .amount {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #727272;

      .name {
        color: #242424;
      }

      .totalAmount {
        font-weight: 700;
        color: #2c3558;
        margin: 0 8px;
      }

      .count {
        color: #da2e1a;
        margin: 0 8px;
        max-width: 230px;
        overflow: auto;
      }
    }
    .reset--divide {
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid #cbcbcb;
      padding: 5px 14px;

      .reset {
        display: flex;
        align-items: center;
        gap: 6.25px;

        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-align: left;
      }
      .divide {
      }
    }
  }
  .disable {
    color: #e6e5ef;
    border-color: #e6e5ef;
    :hover {
      cursor: default;
    }
  }
  .resetButton {
    text-decoration-line: underline;
  }

  .cancel--assign--container {
    display: flex;
    gap: 12px;
    margin-top: 28px;
    height: 42px;
  }
  .infoBox {
    width: 720px;
    padding: 9px 40px;
    background: #f9f8fc;
    margin: 40px 0;
    .itemWrap {
      display: flex;
      align-items: flex-start;
      margin: 24px 0;
      .item {
        font-size: 12px;
        font-weight: 500;
        line-height: 190%;
        text-align: center;
        width: 63.93px;
        height: 24px;
        border: 1px solid #d5d3e4;
        border-radius: 8px 0;
        background-color: #ffffff;
        margin-right: 10px;
      }
      .value {
        text-align: justify;
        width: 566px;
        word-break: break-all;
      }
    }
  }
  .underlineTitle {
    display: flex;
    width: 100%;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
    span {
      position: relative;
      font-size: 32px;
      font-weight: 700;
      color: #2c3558;
      z-index: 1;
      ::after {
        z-index: -1;
        content: '';
        bottom: -7px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 12px;
        background-color: #71d88e99;
      }
    }
  }

  .jobAssignTable {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 400px;
    gap: 12px;
    width: 720px;
    text-align: center;
    padding: 8px 8px 8px 18px;
    /* margin-right: 40px; */

    ::-webkit-scrollbar {
      width: 7px;
      height: 10px;
      margin: 50px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
      margin-left: 10px;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    .lineWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
    }
    .removeButton {
      /* padding-left: 18px; */
      /* right: 20px; */
      fill: #9a9cb5;
      height: 20px;
      :hover {
        svg {
          circle {
            fill: #242424;
          }
          path {
            fill: #727272;
          }
        }
      }
    }
    .key {
      width: 90px;
      white-space: nowrap;
      &.data {
        width: 100px;
      }
    }
    .taskAmount {
      width: 150px;
    }
    .translator {
      width: 125px;
      &.data {
        width: 110px !important;
      }
    }
    .firstChecker {
      width: 130px;
      &.data {
        width: 110px !important;
      }
    }
    .secondChecker {
      width: 130px;
    }
    .column {
      text-align: center;
    }
    .delBtn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .columnsData {
      font-weight: 500;
      font-size: 14px;
      color: #727272;
    }
    .rowData {
      font-weight: 400;
      font-size: 12px;
      padding-left: 12px !important;
      height: 35px;
    }
    .taskAmountInput {
      width: 100px;
      height: 35px;
      margin-right: 30px;
      margin-left: 30px;
      border: 1px solid #ececec;
      text-align: center;
      &:read-only {
        color: #9a9cb5;
      }
    }
    .taskTimeBox {
      width: 140px;
      margin-right: 10px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      input {
        width: 45px;
        height: 35px;
        border: 1px solid #ececec;
        text-align: center;
        &:read-only {
          color: #9a9cb5;
        }
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .MuiFormControl-root .MuiInputBase-root {
        width: 45px !important;
        height: 35px !important;
        svg {
          right: 5px !important;
        }
      }
    }

    .middleCenterContainer {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
    }

    .assignButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      gap: 4px;
      padding: 9px;
      color: #727272;
      width: 100%;
      height: 48px;
      font-size: 12px;
      transition: 0.1s;
      cursor: default;
      &:hover {
        background-color: #9b51e00f;
        cursor: pointer;
      }
    }
  }
  .mt {
    margin-top: 6px;
  }
`;

const modalStyle = css`
  background: #fff;
  overflow-y: scroll;
  margin-right: -20px;
  padding-right: 20px;
  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    margin: 50px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d5d3e4;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    margin-left: 10px;
  }
`;

export const alertWrap = css`
  box-sizing: border-box;
  position: absolute;
  background: #fff;
  min-width: 420px;
  //max-width: 420px;
  max-height: calc(100% - 30px);
  border-radius: 2px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  padding: 50px 20px 30px;
  text-align: center;
  .hidden {
    display: none;
  }
  .alertClose {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 0;
  }
  .alertTitle {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 140%;
    margin-bottom: 16px;
  }
  .alertBtn {
    display: block;
    width: 100%;
    margin-top: 40px;
    background: linear-gradient(270deg, #aa51e0 0%, #9b51e0 100%);
    border-radius: 2px;
    color: #fff;
    line-height: 56px;
  }
  .confirmBtnWrap {
    width: 340px;
    .confirmBtn {
      display: inline-block;
      width: 162px;
      margin-top: 40px;
      border-radius: 2px;
      line-height: 48px;
      &.ok {
        color: #fff;
        background: linear-gradient(270deg, #aa51e0 0%, #9b51e0 100%);
      }
      &.cancel {
        margin-right: 16px;
        border: 1px solid #b5b3c6;
      }
    }
  }
  .modal--div--search {
    max-height: 300px;
    ${modalStyle}
  }
  .modal--div--contents {
    max-height: 600px;
    ${modalStyle}
  }
  .modal--div--modify {
    max-height: 700px;
    ${modalStyle}
  }
  .modal--div--register {
    max-height: 800px;
    ${modalStyle}
  }
`;

import interceptor from '@api/Interceptor';
// import Config from '@src/common/config/Config';
import { format } from 'date-fns';

interface IErrorDoc {
  version: string;
  message: string;
  token: string;
  created: string;
  userAgent: string;
}

const Slack = {
  // 2p_ channel에 메시지 전송
  sendMessageToErrorChannel: (message: string) => {
    interceptor.post(
      '',
      { text: message },
      {
        baseURL: '/slack',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }
};

const Logger = {
  debug: (message: string) => {
    console.debug(message);
  },
  info: (message: string) => {
    console.info(message);
  },
  warn: (message: string) => {
    console.warn(message);
  },
  error: (message: string, type?: string) => {
    // let token = localStorage.getItem('accessToken');
    let errorDoc = {} as IErrorDoc;
    errorDoc.created = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    // errorDoc.version = Config.version;
    // errorDoc.token = token || 'Not token';
    if (navigator.userAgent) errorDoc.userAgent = navigator.userAgent;
    message = JSON.stringify(message).substring(0, 2500);
    if (message) errorDoc.message = message;
    try {
      Slack.sendMessageToErrorChannel(
        `${type ? type : 'SERVER'} : ${JSON.stringify(errorDoc)}`
      );
    } catch (e) {
      Slack.sendMessageToErrorChannel(
        `${type ? type : 'SERVER'} : ${JSON.stringify(errorDoc)}`
      );
    }
  },
  log: function (message: string) {
    console.debug(message);
  }
};

export default Logger;

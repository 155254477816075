import { LANGUAGE } from '@src/common/config/Code';
import { textSlice } from '@src/common/utils/Func';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import { css } from '@emotion/react';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';

export const wordWorkerColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'name',
      value: '작업자',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="pm"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        let userName = rowData.name;
        let userGrayScale = '0%';
        let userBrightness = 1;
        if (rowData.status === 'INACTIVE') {
          userName = userName + ' (비활성화)';
          userGrayScale = '100%';
          userBrightness = 0.7;
        } else if (rowData.status === 'LEAVE') {
          userName = '삭제된 사용자';
          userGrayScale = '100%';
          userBrightness = 0.7;
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              textAlign: 'left',
              width: '100%',
              gap: '8px',
              paddingLeft: '18px',
              filter: `grayscale(${userGrayScale}) brightness(${userBrightness})`,
              wordBreak: 'keep-all',
              lineHeight: '1.2'
            }}
          >
            <TPAvatarBasic size={'small'} src={rowData.avatarImgUrl} />
            {userName}
          </div>
        );
      }
    },
    {
      code: 'startLanguage',
      value: '출발어',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'new',
      value: '신규(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'progress',
      value: '진행(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'stop',
      value: '중지(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'reject',
      value: '반려(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'complete',
      value: '작업완료(Net)',
      width: 150,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')}</span>;
      }
    },
    {
      code: 'totalAmount',
      value: '총합(Net)',
      width: 300,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
            showTooltip="AmountLanguageTotal"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <dl css={ratio}>
            <dt>{Number(data).toLocaleString('en')}</dt>
            <dd>
              <span style={{ width: `${rowData.percentage}%` }}></span>
            </dd>
          </dl>
        );
      }
    },
    {
      code: 'percentage',
      value: '총합(Net) 비율',
      width: 145,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
            showTooltip="AmountLanguageRatio"
          />
        );
      },
      renderRowData: (data: any) => {
        return <span>{Number(data).toLocaleString('en')} %</span>;
      }
    }
  ] as const;

export const ratio = css`
  display: flex;
  align-items: center;
  width: 100%;
  dt {
    width: 50%;
  }
  dd {
    position: relative;
    width: 50%;
    height: 22px;
    border: 1px solid #aaa;
    border-radius: 4px;
    overflow: hidden;
    span {
      position: absolute;
      top: 0;
      left: 0;
      background: #ddd;
      width: 0;
      height: 100%;
    }
  }
`;

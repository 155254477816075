import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { myWorkKeys } from '@hooks/queryKeyFactory';
import { IMyWorkDetailData } from '@src/common/config/ITask';
import { apiKeys } from '@src/pages/myTask/MyTaskTab.page';
import { compareWorkerType } from '@src/common/config/Code';
import { useEffect, useState } from 'react';

interface ITEM {
  companyName: any;
  destinationLanguage: any;
  endDateTime: any;
  languageSubTaskId: number;
  mainProjectManager: any;
  projectTitle: any;
  startDateTime: any;
  startLanguage: any;
  subProjectManager: any;
  taskAmount: any;
  taskTitle: any;
  languageTaskTitle: any;
  taskType: any;
  workerList: any;
  workerStatus: any;
  workerType: any;
}

/**
 * /languageTask/{languageTaskId}
 * @param languageTaskId 언어 작업 아이디
 * @returns {{ detailData }}
 */
export const useGetMyTaskListByDay = () => {
  const [filter, setFilter] = useState<any>({});
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof apiKeys)['listByDay']>
  >): Promise<any> => {
    return (await api.post('/myWork/listByDay', filter)).data;
  };

  const { data, isLoading, refetch, isSuccess } = useQuery(
    [...apiKeys.listByDay(filter)],
    getData,
    {
      initialData: { label: '', myWorkList: [] },
      enabled: false
    }
  );

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      refetch();
    }
  }, [filter]);

  return { data, isLoading, isSuccess, setFilter, refetch };
};

import { usePagePermission, useAuth } from '@hooks/auth/useAuth';
import { useAlert } from '@hooks/modal/useAlert';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { myInfoKeys } from '@hooks/queryKeyFactory';
import updateAccessToken from '@src/api/updateAccessToken';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const AuthPage = () => {
  const countRef = useRef<number>(0);
  const { myInfo, isSuccess, refetchMyInfo: getMyInfo } = useMyInfo();
  const { signIn, signOut, toAgreeOrDisagreePage } = useAuth();
  const { pathname } = useLocation();
  const { openHandle } = useAlert();
  const queryClient = useQueryClient();
  const closehandle = () => {
    localStorage.removeItem('privacyAgree');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessTokenExpiresIn');
    localStorage.removeItem('refreshTokenExpiresIn');
    queryClient.invalidateQueries([...myInfoKeys.get]);
    signOut();
  };

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: (err: any) => {
          console.debug('[ERROR] ERROR 발생.', err?.status);
          queryClient.cancelQueries();
          if (err?.status === 401 || err?.status === 'UNAUTHORIZED') {
            // console.debug('[UNAUTHORIZED] ERROR 발생. 토튼 다시 받아오기.');
            if (countRef.current > 1) return;
            updateAccessToken()
              .then((data) => {
                countRef.current = 0;
              })
              .catch((err) => {
                countRef.current += 1;
                localStorage.clear();
                sessionStorage.clear();
                openHandle({
                  title: '로그인 만료',
                  text: '로그인이 만료되었습니다.',
                  ok: () => closehandle(),
                  cancel: () => closehandle()
                });
              });
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (toAgreeOrDisagreePage()) return () => {};
    if (isSuccess && myInfo) {
      // permissionCheck(); // TODO: 프론트 권한 체크 일치 해지
      signIn();
    } else {
      if (localStorage.getItem('accessToken')) {
        getMyInfo().then();
      } else {
        signOut();
      }
    }
    return () => {};
  }, [isSuccess, myInfo, pathname]);

  return <Outlet />;
};

export default AuthPage;

import React from 'react';
import {
  IconBigRightArrow,
  IconBigRightArrowDisable
} from '@src/resources/icon';

interface ITPBigRightArrow {
  disable?: boolean;
}
export const TPBigRightArrow = (props: ITPBigRightArrow) => {
  const { disable = 'true' } = props;
  return disable ? (
    <IconBigRightArrow width={48} height={48} />
  ) : (
    <IconBigRightArrowDisable width={48} height={48} />
  );
};

import ModalType from '@common/config/ModalType';
import ColumnHeadComp from '@pages/components/ColumnHead.Comp';
import { MainPMAssignButton } from '@pages/components/Main.PMAssignButton';
import { IconDetail } from '@resources/icon';
import { TPButtonAction } from '@units/button/TPButton.Action';
import { TPPopoverMenu } from '@units/popover/TPPopover.Menu';
import { useNavigate } from 'react-router-dom';
import { MainLLAssignButton } from '../components/MainLLAssignButton';
import TaskStatus from '../dashboard/units/TaskStatus';
import { css } from '@emotion/css';

export const mainColumns = (myInfo: any, isSort: any, setIsSort: any) => {
  return [
    {
      code: 'projectStatus',
      value: '계약 상태',
      renderHeader: 'string',
      renderRowData: (_: any, rowData: any) => (
        <TaskStatus
          item={{
            languageTaskStatus: rowData.projectStatus
          }}
        />
      )
    },
    {
      code: 'projectKey',
      value: '키',
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트',
      cssHeader: css`
        min-width: 134px !important;
        max-width: 475px !important;
      `,
      cssBody: css`
        min-width: 134px !important;
        max-width: 475px !important;
        text-align: left !important;
      `,
      renderHeader(_: any, counter: any, props: any) {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
            css={_.cssHeader}
          />
        );
      },
      renderRowData: 'string'
    },

    {
      code: 'companyName',
      value: '고객사',
      cssHeader: css`
        min-width: 122px !important;
        max-width: 235px !important;
        text-align: left !important;
      `,
      cssBody: css`
        min-width: 122px !important;
        max-width: 235px !important;
        text-align: left !important;
      `,
      renderHeader(_: any, counter: any) {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
            style={{
              justifyContent: 'flex-start'
            }}
          />
        );
      },
      renderRowData: 'string'
    },

    {
      code: 'newCount',
      value: '신규',
      renderHeader(_: any, counter: any) {
        return (
          <TaskStatus
            item={{ languageTaskStatus: 'NEW' }}
            counter={counter?.newCount}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'completeAssignCount',
      value: '배정완료',
      renderHeader(_: any, counter: any) {
        return (
          <TaskStatus
            item={{ languageTaskStatus: 'COMPLETE_ASSIGN' }}
            counter={counter?.completeAssignCount}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'progressCount',
      value: '진행',
      renderHeader(_: any, counter: any) {
        return (
          <TaskStatus
            item={{ languageTaskStatus: 'PROGRESS' }}
            counter={counter?.progressCount}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'completeCount',
      value: '작업완료',
      renderHeader(_: any, counter: any) {
        return (
          <TaskStatus
            item={{ languageTaskStatus: 'COMPLETE' }}
            counter={counter?.completeCount}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'deliveryCompleteCount',
      value: '납품완료',
      renderHeader(_: any, counter: any) {
        return (
          <TaskStatus
            item={{ languageTaskStatus: 'DELIVERY_COMPLETE' }}
            counter={counter?.deliveryCompleteCount}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'stopCount',
      value: '중지',
      renderHeader(_: any, counter: any) {
        return (
          <TaskStatus
            item={{ languageTaskStatus: 'STOP' }}
            counter={counter?.stopCount}
          />
        );
      },
      renderRowData: 'string'
    },
    // {
    //   code: 'startDateTime',
    //   value: '계약일',
    //   width: 140,
    //   renderHeader(_: any, counter: any) {
    //     return (
    //       <ColumnHeadThComp
    //         title={this.value}
    //         field={this.code}
    //         isSort={isSort}
    //         setIsSort={setIsSort}
    //         sortType="string"
    //       />
    //     );
    //   },
    //   renderRowData: (v: string) => renderDateTime(v, false)
    // },
    // {
    //   code: 'endDateTime',
    //   value: '만료일',
    //   width: 140,
    //   renderHeader(_: any, counter: any) {
    //     return (
    //       <ColumnHeadThComp
    //         title={this.value}
    //         field={this.code}
    //         isSort={isSort}
    //         setIsSort={setIsSort}
    //         sortType="string"
    //       />
    //     );
    //   },
    //   renderRowData: (v: string) => renderDateTime(v, true)
    // },
    {
      code: 'mainProjectManager',
      value: '담당PM',
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        return (
          <MainPMAssignButton
            id={rowData.projectId}
            data={[
              {
                ...rowData.mainProjectManager,
                projectManagerType: 'main'
              },
              {
                ...rowData.subProjectManager,
                projectManagerType: 'sub'
              }
            ]}
            rowData={rowData}
            userInfo={myInfo}
          />
        );
      }
    },
    {
      code: 'projectAssignerList',
      value: '담당자', // 언어리더
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        return (
          <MainLLAssignButton
            id={rowData.projectId}
            data={[...rowData.projectAssignerList]}
            rowData={rowData}
            userInfo={myInfo}
            // allProjectAssignerList={rowData.allProjectAssignerList}
          />
        );
      }
    }
    // {
    //   code: 'option',
    //   value: '',
    //   width: 24,
    //   renderHeader() {
    //     return (
    //       <ColumnHeadThComp
    //         title={this.value}
    //         field={this.code}
    //         isSort={isSort}
    //         setIsSort={setIsSort}
    //         sortType={'unable'}
    //       />
    //     );
    //   },
    //   renderRowData: (data: any, rowData: any) => {
    //     return <OptionsRow rowData={rowData} myInfo={myInfo} />;
    //   }
    // }
  ] as const;
};

const OptionsRow = ({ rowData, myInfo }: any) => {
  const navigate = useNavigate();
  const { projectId, mainProjectManager } = rowData;

  if (myInfo?.roles.includes('PML') && mainProjectManager.userId) {
    return (
      <TPPopoverMenu
        popoverMenus={[
          {
            clickMenu: () => {
              navigate(`/project/${ModalType.PMASSIGN_PAGE}/${projectId}`);
            },
            label: '담당 변경하기'
          }
        ]}
        clickPopoverButton={(e: any) => {
          e.stopPropagation();
        }}
      >
        <TPButtonAction icon={<IconDetail />} size="small" />
      </TPPopoverMenu>
    );
  }
  return <></>;
};

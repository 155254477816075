import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { projectDetailKeys, taskKeys } from '../queryKeyFactory';

/**
 * /task
 * @description 작업 상세 모달 등록/수정/삭제
 * @returns {{ postTask, putTask, deleteTask }}
 */
export const useUpsertTask = () => {
  const { openHandle } = useToast();
  const queryClient = useQueryClient();

  const postData = async (payload: any) => await api.post(`/task`, payload);
  const putData = async (payload: any) => await api.put(`/task`, payload);
  const putDataStatus = async (payload: any) =>
    await api.put(`/task/status`, payload);
  const deleteData = async (id: string) => await api.delete(`/task/${id}`);

  const { mutateAsync: postTask } = useMutation({
    mutationFn: postData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/task']);
      queryClient.invalidateQueries(['/projectDetail']);
      queryClient.invalidateQueries(['DashboardKeys']);
      openHandle({
        text: `작업이 등록되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `작업 등록에 실패했습니다.`,
        severity: 'error'
      });
    }
  });

  const { mutateAsync: putTask } = useMutation({
    mutationFn: putData,
    onSuccess: (res, payload) => {
      queryClient.invalidateQueries(['/task']);
      queryClient.invalidateQueries(['DashboardKeys']);
      queryClient.invalidateQueries([...projectDetailKeys.default]);
      openHandle({
        text: `작업이 수정되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `작업 수정에 실패했습니다.`,
        severity: 'error'
      });
    }
  });

  const { mutate: deleteTask } = useMutation({
    mutationFn: deleteData,
    onSuccess: () => {
      openHandle({ text: `작업이 삭제되었습니다.`, severity: 'success' });
      queryClient.invalidateQueries(['/task']);
      queryClient.invalidateQueries(['/projectDetail']);
      queryClient.invalidateQueries(['DashboardKeys']);
    },
    onError: () => {
      openHandle({ text: `작업이 삭제에 실패했습니다.`, severity: 'error' });
    },
    onSettled: () => {}
  });

  const { mutate: putTaskStatus } = useMutation({
    mutationFn: putDataStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(['/task']);
      queryClient.invalidateQueries(['/projectDetail']);
      queryClient.invalidateQueries(['DashboardKeys']);
      openHandle({
        text: `작업 상태가 변경되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `작업 상태 변경에 실패했습니다.`,
        severity: 'error'
      });
    }
  });

  return { postTask, putTask, deleteTask, putTaskStatus };
};

import React, { useEffect, useRef, useState } from 'react';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box, Checkbox } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useGetFileList } from '@src/hooks/file/getList';
import { TPSelectBasic } from '@src/units/select/TPSelect.basic';
import { useAlert } from '@src/hooks/modal/useAlert';
import { TaskCreateStyle } from './TaskCreate.style';
import { TPInputCheckSingleBox } from '@src/units/input/TPInput.checkBox';
import TPButtonClose from '@src/units/button/TPButton.Close';

const TaskCreatePage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { fileId } = useParams();
  const { data, postSegment, closeModal } = useGetFileList(fileId);
  const { openHandle: alertHandle } = useAlert();

  const [optionList, setOptionList] = useState<any[]>([]);
  const [useHeader, setUseHeader] = useState(false);

  const handleUpload = async () => {
    try {
      postSegment({
        languageTaskId: fileId,
        optionData: { useHeader },
        columnDataList: _.compact([
          ..._.map(optionList, (v, i) => {
            if (v !== '') {
              return { index: i + 1, segmentType: v };
            }
          })
        ])
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const setNthValue = (arr: any[], n: number, value: any) => {
    if (n > arr.length) {
      const emptyValues = new Array(n - arr.length).fill('');
      const newArr = arr.concat(emptyValues);
      _.set(newArr, n, value);
      return newArr;
    }

    _.set(arr, n, value);
    return arr;
  };

  const selectNumber = _.findIndex(optionList, (v) => v === 'SOURCE');

  return (
    <div css={[TaskCreateStyle, alertWrap]}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <div>
        <TPHeadlineBasic decoration type="h2">
          Segmentation
        </TPHeadlineBasic>
      </div>
      <div className="modal--div--checkbox">
        <TPInputCheckSingleBox
          option={'첫 번째 열(Row)은 행(Column) 이름'}
          checked={useHeader}
          onClick={setUseHeader}
        />
      </div>
      <div ref={scrollRef} className="modal--div--box">
        <table className="modal--table--table">
          <thead>
            <tr>
              {_.map(
                _.maxBy(data, (arr) => arr.length),
                (v: any, i: number) => (
                  <td key={i}>{String.fromCharCode(65 + i)}</td>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(data, (item: any, trIdx: number) => (
              <tr key={trIdx}>
                {_.map(item, (v: any, tdIdx: number) => (
                  <td key={`${trIdx}-${tdIdx}`}>
                    {v.length > 20 ? v.slice(0, 20) : v}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="modal--div--bottom">
        <div ref={scrollRef} className="modal--div--box bottom">
          <table className="modal--table--table">
            <tbody>
              {_.map(
                _.maxBy(data, (arr) => arr.length),
                (v: any, i: number) => (
                  <tr key={i}>
                    <td className="table--td--title">
                      {String.fromCharCode(65 + i)}
                    </td>
                    <td className="table--td--selectbox">
                      <TPSelectBasic
                        large={true}
                        value={optionList[i] ?? ''}
                        options={[
                          { label: 'Source', option: 'SOURCE' },
                          { label: 'Context', option: 'CONTEXT' },
                          {
                            label: `Translation ${
                              selectNumber >= 0
                                ? 'for ' +
                                  String.fromCharCode(65 + selectNumber)
                                : ''
                            }`,
                            option: 'TRANSLATION'
                          }
                        ]}
                        changeSelect={(e) => {
                          const isSource =
                            e.target.value === 'SOURCE' &&
                            _.includes(optionList, 'SOURCE');
                          const isTranslation =
                            e.target.value === 'TRANSLATION' &&
                            _.includes(optionList, 'TRANSLATION');

                          const alertTitle = isSource
                            ? 'Source를 변경하시겠습니까?'
                            : isTranslation
                            ? 'Translation를 변경하시겠습니까?'
                            : '';

                          if (alertTitle) {
                            alertHandle({
                              title: undefined,
                              text: alertTitle,
                              cancelLabel: '취소',
                              ok: () => {
                                const modifiedList = _.map(
                                  optionList,
                                  (value) =>
                                    (isSource && value === 'SOURCE') ||
                                    (isTranslation && value === 'TRANSLATION')
                                      ? ''
                                      : value
                                );
                                setOptionList([
                                  ...setNthValue(
                                    modifiedList,
                                    i,
                                    e.target.value
                                  )
                                ]);
                              }
                            });
                          } else {
                            setOptionList([
                              ...setNthValue(optionList, i, e.target.value)
                            ]);
                          }
                        }}
                        autoWidth={false}
                        isDisabled={false}
                        placeholder={'옵션을 선택해 주세요.'}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={3}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="등록하기"
            fullWidth
            onClick={handleUpload}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TaskCreatePage;

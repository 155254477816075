import styled from '@emotion/styled';
import {
  IcoMenuChevronDownActiveUrl,
  IcoMenuChevronDownUrl
} from '@src/resources/icon';

export const TopMenuStyle = styled.aside`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #fff;
  align-items: center;
  z-index: 1100;
  border-bottom: 1px solid #fafafa;
  padding: 0 21px;

  /* &:hover {
    height: 156px;
    padding-top: 0;
    padding-bottom: 100px;
  } */

  .top-menu--left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 21px;
    height: 100%;

    .top-menu--logo {
      display: block;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 11px;
      height: 20px;
    }

    .top-menu--menu--wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 21px;

      & > .top-menu--menu {
        .top-menu--ul--main {
          --ico-width: 12px;
          --ico-height: 14px;

          > a {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 14px;

            & > div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              &.has-sub-menu::after {
                content: '';
                background-image: url(${IcoMenuChevronDownUrl});
                background-repeat: no-repeat;
                background-position: right;
                width: var(--ico-width);
                height: var(--ico-height);
              }
            }
          }

          &:has(.active) {
            a {
              color: #9b51e0;
              font-weight: 600;
              &::after {
                content: '';
                width: 100%;
                height: 2px;
                background: #9b51e0;
                position: absolute;
                bottom: -15px;
              }
              .has-sub-menu::after {
                background: url(${IcoMenuChevronDownActiveUrl});
                background-repeat: no-repeat;
                background-position: right;
                width: var(--ico-width);
                height: var(--ico-height);
              }
            }
          }

          &:hover .top-menu--ul--sub {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            z-index: 1;
            position: fixed;
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 8%);

            font-size: 14px;
            border-radius: 2px;
            border: 1px solid #ececec;
            background-color: #fff;
            padding: 8px 14px 8px 14px;
            gap: 8px;

            a {
              font-weight: 400;
              color: #000;
              &.active {
                font-weight: 600;
                color: #9b51e0;
              }
              &::after {
                display: none;
              }
            }
          }
        }
        .top-menu--ul--sub {
          display: none;
        }
      }
    }
  }
`;

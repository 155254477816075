import { createTheme } from '@mui/material/styles/';

export const TPPopoverTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          background: '#fff',
          paddingTop: 16,
          paddingBottom: 16
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          '&:hover': {
            background: 'rgba(155, 81, 224, 0.06)'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 16px 4px #F0EDF6',
          borderRadius: '12px',
          border: '1px solid #D5D3E4',
          background: 'transparent'
        }
      }
    }
  }
});

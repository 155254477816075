import { css } from '@emotion/react';

export const LanguageSelectModalStyle = css`
  width: 600px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  padding: 40px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  .closeButton {
    position: absolute;
    top: 30px;
    right: 30px;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  .notice {
    margin: 40px auto 20px;
    text-align: center;
  }
  .dropDownContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  .selectLanguagePairContainer {
    position: relative;
    display: flex;
    width: 580px;
    padding-left: 30px;
    margin: 16px 0 20px 0;
    max-height: 272px;
    /* min-height: 164px; */
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .virtualArea {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      max-width: 520px;
    }
  }

  .showListContainer {
    position: relative;
    width: 580px;
    padding-left: 30px;
    max-height: 288px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .virtualArea {
      max-width: 520px;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .buttonContainer {
    display: flex;
    gap: 16px;
    width: 100%;
    min-height: 120px;
    padding-top: 64px;
  }
`;

export const SelectLanguagePairStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 16px;
  gap: 8px;
  height: 48px;
  background: #71d88e;
  border-radius: 8px;
  span.selectLanguagePair {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 17px;
    font-size: 14px;
    line-height: 1.6;
    margin-right: 4px;
    color: #fff;
  }
  .removeButton {
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

export const CheckButtonWrap = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 20px;
  color: #646782;
  :hover {
    background-color: rgba(155, 81, 224, 0.06);
    cursor: pointer;
  }
  .showItem {
    height: 48px;
    display: flex;
    align-items: center;
    gap: 9.5px;
  }
`;

export const CheckButtonStyle = css`
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #9a9cb5;
  border-radius: 50%;
  ::after {
    position: absolute;
    content: '';
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
  }
  &.active {
    border-color: #9b51e0;
    ::after {
      position: absolute;
      content: '';
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #9b51e0;
    }
  }
`;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

//todo hook에서 사용하는 css는 어떻게 할 것인가?
//todo 인터페이스도 정리 요망
interface IWrap {
  isThereSavedInfo: boolean;
}
export const Wrap = styled.button<IWrap>`
  /* Auto layout */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 24px;
  height: 32px;
  white-space: nowrap;
  color: #9b51e0;
  border-radius: 2px;

  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.25px;
  text-align: center;

  ${({ isThereSavedInfo }) => {
    if (isThereSavedInfo)
      return css`
        color: #242424;
        font-weight: 400;
        border: 1px solid #cbcbcb;
      `;
  }}
`;

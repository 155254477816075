import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryFunctionContext } from '@tanstack/query-core';
import { format, add } from 'date-fns';
import api from '@api/Api';
import _ from 'lodash';
import {
  DashboardFilterStatusTypes,
  DateTypeCode,
  LanguageCodeType,
  StatusCodeType,
  WorkerTypeCode
} from '@common/config/Code';
import { dashboardKeys } from '@hooks/queryKeyFactory';
import { ILanguageSubTask } from '@common/config/ILanguageTask';
import { makeSortedList } from '@common/utils/makeSortedList';
import { IUser, TASK_TYPE } from '@common/config/ICode';
import { AvatarProgressType } from '@units/avatar/TPAvatar.WorkerType';
import { useHookFunc, usePageFunc } from '@hooks/utils';

type ExposureType = 'open' | 'close';
type StringKeyType = { [key in string]: any };
type pmManagerType =
  | [
      { projectManagerType: 'MAIN'; projectManager: IUser },
      { projectManagerType: 'SUB'; projectManager: IUser }
    ]
  | [];

interface IDashboardFilterData {
  searchDateType: DateTypeCode;
  searchValue: string;
  filterState: string[];
  filterTaskType: string[];
  filterStartLanguage: LanguageCodeType[];
  filterDestinationLanguage: LanguageCodeType[];
  filterPm: string[];
  filterTasker: string[];
  filterExposure: ExposureType;
  filterDateType: DateTypeCode;
  searchStartDateTime: Date | string;
  searchEndDateTime: Date | string;
  isFilter: boolean;
}

export interface ISubTask extends ILanguageSubTask, StringKeyType {
  languageSubTaskId: number;
  languageSubTaskKey: string;
  languageSubTaskProcess: WorkerTypeCode;
  languageSubTaskWorker: AvatarProgressType[];
  destinationLanguage: LanguageCodeType;
  endDateTime: Date | string;
  hopeSupplyDate: Date | string;
  netAmount: number;
  pmManager: pmManagerType;
  startDate: Date | string;
  startLanguage: LanguageCodeType;
  taskName: string;
  taskType: TASK_TYPE;
}

export interface IFullLookupDataList extends StringKeyType {
  taskId: number;
  taskStatus: StatusCodeType;
  taskKey: string;
  companyName: string; //
  projectName: string;
  taskName: string;
  taskType: TASK_TYPE;
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType; //
  totalGrossAmount: number;
  totalNetAmount: number;
  hopeSupplyDate: string;
  startDate: Date;
  endDateTime: Date;
  id: number;
  pmManager: pmManagerType;
  tasker: IUser[];
  languageSubTask: ISubTask[] | [];
}

export const useGetDashbordList = (filterData: any) => {
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();

  //#region state define
  const clearFilterData = {
    keyword: '',
    taskStatus: [
      DashboardFilterStatusTypes.NEW,
      DashboardFilterStatusTypes.COMPLETE_ASSIGN,
      DashboardFilterStatusTypes.PROGRESS,
      DashboardFilterStatusTypes.STOP,
      DashboardFilterStatusTypes.COMPLETE
    ],
    taskType: ['ALL'],
    startLanguageList: ['ALL'],
    destinationLanguageList: ['ALL'],
    dateType: 'ALL',
    startDateTime: '',
    endDateTime: '',
    searchDateType: 'END_DATE',
    searchStartDateTime: format(add(new Date(), { weeks: -1 }), 'yyyy-MM-dd'),
    searchEndDateTime: format(add(new Date(), { years: 1 }), 'yyyy-MM-dd'),
    projectManagerUserIdList: ['0'],
    workUserIdList: ['0']
  };
  const [dashboardFilter, setDashboardFilter] = useState<IDashboardFilterData>({
    searchDateType: 'END_DATE',
    searchStartDateTime: add(new Date(), { weeks: -1 }),
    searchEndDateTime: add(new Date(), { years: 1 }),
    searchValue: '',
    filterState: ['NEW', 'COMPLETE_ASSIGN', 'PROGRESS', 'COMPLETE'],
    filterTaskType: ['ALL'],
    filterStartLanguage: ['ALL'],
    filterDestinationLanguage: ['ALL'],
    filterPm: ['0'],
    filterTasker: ['0'],
    filterExposure: 'open',
    filterDateType: 'ALL',
    isFilter: false
  });
  const [value, setValue] = useState<string>('');

  const [searchDateFilter, setSearchDateFilter] = useState<{
    [key: string]: Date;
  }>({
    startDateTime: add(new Date(), { weeks: -1 }),
    endDateTime: add(new Date(), { years: 1 })
  });
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    startDateTime: undefined,
    endDateTime: undefined
  });
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  useEffect(() => {
    if (filterData) {
      if (filterData?.dashboardFilter)
        setDashboardFilter(filterData?.dashboardFilter);
      if (filterData?.searchDateFilter)
        setSearchDateFilter(filterData?.searchDateFilter);
      if (filterData?.dateFilter) setDateFilter(filterData?.dateFilter);
    }
  }, [filterData]);

  //#region setState
  const setState = {
    setSearchDateType: (value: DateTypeCode) => {
      setDashboardFilter((state) => ({
        ...state,
        searchDateType: value
      }));
    },
    setChangeValue: (e: any) => {
      const { value } = e.target;
      setValue(value);
    },
    setSearchValue: () => {
      setDashboardFilter((state) => ({
        ...state,
        searchValue: value
      }));
    },
    setFilterState: (value: string) => {
      const filterState =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.filterState, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        filterState: filterState.length ? filterState : ['ALL']
      }));
    },
    setFilterTaskType: (value: string) => {
      const filterTaskType =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.filterTaskType, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        filterTaskType: filterTaskType.length ? filterTaskType : ['ALL']
      }));
    },
    setFilterStartLanguage: (value: string) => {
      const filterStartLanguage =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.filterStartLanguage, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        filterStartLanguage: filterStartLanguage.length
          ? (filterStartLanguage as LanguageCodeType[])
          : ['ALL']
      }));
    },
    setFilterDestinationLanguage: (value: string) => {
      const filterDestinationLanguage =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.filterDestinationLanguage, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        filterDestinationLanguage: filterDestinationLanguage.length
          ? (filterDestinationLanguage as LanguageCodeType[])
          : ['ALL']
      }));
    },
    setFilterPm: (value: string) => {
      const filterPm =
        value === '0'
          ? ['0']
          : _.filter(
              arraySet(dashboardFilter.filterPm, value),
              (item) => item !== '0'
            );
      setDashboardFilter((state) => ({
        ...state,
        filterPm: filterPm.length ? filterPm : ['0']
      }));
    },
    setFilterTasker: (value: string) => {
      const filterTasker =
        value === '0'
          ? ['0']
          : _.filter(
              arraySet(dashboardFilter.filterTasker, value),
              (item) => item !== '0'
            );
      setDashboardFilter((state) => ({
        ...state,
        filterTasker: filterTasker.length ? filterTasker : ['0']
      }));
    },
    setFilterExposure: (value: ExposureType) => {
      setDashboardFilter((state) => ({
        ...state,
        filterExposure: value
      }));
    },
    setFilterDateType: (value: DateTypeCode) => {
      setDashboardFilter((state) => ({
        ...state,
        filterDateType: value
      }));
    },
    setIsFilter: (value: boolean) => {
      setDashboardFilter((state) => ({
        ...state,
        isFilter: value
      }));
    },
    setClearFilter: () => {
      setDashboardFilter((state) => ({
        ...state,
        searchDateType: 'END_DATE',
        searchValue: '',
        filterState: ['ALL'],
        filterTaskType: ['ALL'],
        filterStartLanguage: ['ALL'],
        filterDestinationLanguage: ['ALL'],
        filterPm: ['0'],
        filterTasker: ['0'],
        filterExposure: 'open',
        filterDateType: 'ALL',
        isFilter: false
      }));
      setSearchDateFilter((state) => ({
        ...state,
        startDateTime: add(new Date(), { weeks: -1 }),
        endDateTime: add(new Date(), { years: 1 })
      }));
      setDateFilter((state) => ({
        ...state,
        startDateTime: undefined,
        endDateTime: undefined
      }));
      setValue('');
    },
    setIsSort
  };
  //#endregion

  //#region api call
  const postData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof dashboardKeys)['allList']>
  >): Promise<IFullLookupDataList> => {
    const [, , filters] = queryKey;

    const copyFilters = JSON.parse(JSON.stringify(filters));
    copyFilters.searchStartDateTime = format(
      new Date(copyFilters.searchStartDateTime),
      'yyyy-MM-dd'
    );
    copyFilters.searchEndDateTime = format(
      new Date(copyFilters.searchEndDateTime),
      'yyyy-MM-dd'
    );

    let base: any = {};
    _.forEach(clearFilterData, (value, key) => {
      if (value) {
        base[key] = value;
      } else {
        if (key === 'keyword') base[key] = '';
      }
    });

    if (JSON.stringify(copyFilters) === JSON.stringify(base)) {
      setState.setIsFilter(false);
    } else {
      setState.setIsFilter(true);
    }
    let payload: any = {};

    _.forEach(filters, (value: any, key) => {
      if (Array.isArray(value)) {
        if (_.includes(value, 'ALL') || _.includes(value, '0')) {
          payload[key] = [];
        } else {
          payload[key] = value;
        }
      } else {
        if (value && ['startDateTime', 'searchStartDateTime'].includes(key)) {
          payload[key] = getDateTime({
            date: new Date(value),
            filter: 'start'
          });
        } else if (
          value &&
          ['endDateTime', 'searchEndDateTime'].includes(key)
        ) {
          payload[key] = getDateTime({ date: new Date(value), filter: 'end' });
        } else {
          payload[key] = value;
        }
      }
    });

    return await api.post('/task/allList', payload).then((res) => res.data);
  };
  //#endregion

  const { data } = useQuery(
    [
      ...dashboardKeys.allList({
        keyword: dashboardFilter.searchValue,
        taskStatus: dashboardFilter.filterState,
        taskType: dashboardFilter.filterTaskType,
        startLanguageList: dashboardFilter.filterStartLanguage,
        destinationLanguageList: dashboardFilter.filterDestinationLanguage,
        dateType: dashboardFilter.filterDateType,
        startDateTime: dateFilter?.startDateTime,
        endDateTime: dateFilter?.endDateTime,
        searchDateType: dashboardFilter.searchDateType,
        searchStartDateTime: new Date(searchDateFilter.startDateTime),
        searchEndDateTime: new Date(searchDateFilter.endDateTime),
        projectManagerUserIdList: dashboardFilter.filterPm,
        workUserIdList: dashboardFilter.filterTasker
      })
    ],
    postData,
    {
      select: (data) => ({
        taskList: isSort.code.length
          ? [
              ..._.orderBy(
                data.taskList,
                [isSort.code],
                [isSort.isAsc ? 'asc' : 'desc']
              )
            ]
          : data.taskList
      })
    }
  );

  const dataList = () => {
    if (data && data?.taskList) {
      return data.taskList.map((task: any) => {
        const allSubTaskWorker: any = [];
        const languageSubTask = task.languageTaskList
          .map((el: any) => {
            if (el.languageSubTaskList.length) {
              allSubTaskWorker.push(
                el.languageSubTaskList.map((el: any) => el.workerList)
              );
              return el.languageSubTaskList;
            }
            const defaultLanguageSubTask = {
              languageSubTaskKey: '',
              taskAmount: task.totalNetAmount,
              languageSubTaskWorker: [],
              taskName: task.taskName,
              destinationLanguage: el.destinationLanguage,
              startLanguage: el.startLanguage,
              endDateTime: el.endDateTime,
              hopeSupplyDate: el.hopeSupplyDate
            };

            return [defaultLanguageSubTask];
          })
          .flat();
        const addLanguageSubTask = {
          ...task,
          languageSubTask: makeSortedList(
            languageSubTask,
            'languageSubTaskKey',
            true,
            'key'
          )
        };
        const allTasker = [
          ...new Set(
            allSubTaskWorker
              .flat()
              .flat()
              .flat()
              .map((el: any) => {
                return JSON.stringify(el);
              })
          )
        ].map((el: any) => {
          return JSON.parse(el);
        });

        const newTask = {
          ...addLanguageSubTask
        };

        if (allTasker.length) {
          newTask.tasker = allTasker;
        }

        return newTask;
      });
    } else {
      return [];
    }
  };

  return {
    value,
    setValue,
    dataList,
    dashboardFilter,
    setState,
    searchDateFilter,
    setSearchDateFilter,
    dateFilter,
    isSort,
    setDateFilter
  };
};

import styled from '@emotion/styled';
import { getDateType } from '@src/common/config/Code';
import { IconCalendar, IconRefresh } from '@src/resources/icon';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import { add, addDays, format } from 'date-fns';
import { useMemo, useState } from 'react';

export function createOptionList(date: Date) {
  let baseStart = new Date(format(date, 'yyyy/MM/dd 00:00:00'));
  let baseEnd = new Date(format(date, 'yyyy/MM/dd 23:59:59'));
  baseEnd.setMilliseconds(999);

  let next7dayStart = baseStart;
  let next7dayEnd = add(baseEnd, { days: 7 });

  let next30dayStart = baseStart;
  let next30dayEnd = add(baseEnd, { days: 30 });

  let next60dayStart = baseStart;
  let next60dayEnd = add(baseEnd, { days: 60 });

  let next90dayStart = baseStart;
  let next90dayEnd = add(baseEnd, { days: 90 });

  let next1yearStart = baseStart;
  let next1yearEnd = add(baseEnd, { years: 1 });

  return [
    {
      code: 'next7day',
      label: '7일',
      text: `${format(next7dayStart, 'yyyy.MM.dd')} - ${format(
        next7dayEnd,
        'yyyy.MM.dd'
      )}`,
      value: [next7dayStart, next7dayEnd]
    },
    {
      code: 'next30day',
      label: '30일',
      text: `${format(next30dayStart, 'yyyy.MM.dd')} - ${format(
        next30dayEnd,
        'yyyy.MM.dd'
      )}`,
      value: [next30dayStart, next30dayEnd]
    },
    {
      code: 'next60day',
      label: '60일',
      text: `${format(next60dayStart, 'yyyy.MM.dd')} - ${format(
        next60dayEnd,
        'yyyy.MM.dd'
      )}`,
      value: [next60dayStart, next60dayEnd]
    },
    {
      code: 'next90day',
      label: '90일',
      text: `${format(next90dayStart, 'yyyy.MM.dd')} - ${format(
        next90dayEnd,
        'yyyy.MM.dd'
      )}`,
      value: [next90dayStart, next90dayEnd]
    },
    {
      code: 'next1year',
      label: '1년',
      text: `${format(next1yearStart, 'yyyy.MM.dd')} - ${format(
        next1yearEnd,
        'yyyy.MM.dd'
      )}`,
      value: [next1yearStart, next1yearEnd]
    }
  ];
}

export function useFutureDateFilterProps() {
  const optionList = createOptionList(addDays(new Date(), 7)) as any[];
  const defaultOption = optionList.find((e) => e.isDefault) ?? optionList[0];

  const [selectedItem, setSelectedItem] = useState(() => defaultOption);

  const [dateType, setDateType] = useState('END_DATE');
  const [startDateTime, setStartDateTime] = useState(
    () => defaultOption.value[0]
  );
  const [endDateTime, setEndDateTime] = useState(() => defaultOption.value[1]);

  const isFilter = useMemo(() => {
    return (
      dateType !== 'END_DATE' ||
      startDateTime?.getTime?.() !== defaultOption.value[0]?.getTime?.() ||
      endDateTime?.getTime?.() !== defaultOption.value[1]?.getTime?.()
    );
  }, [dateType, startDateTime, endDateTime]);

  const reset = () => {
    setSelectedItem(defaultOption);
    setDateType('END_DATE');
    setStartDateTime(defaultOption.value[0]);
    setEndDateTime(defaultOption.value[1]);
  };

  return {
    optionList,
    selectedItem,
    dateType,
    startDateTime,
    endDateTime,
    setSelectedItem,
    setDateType,
    setStartDateTime,
    setEndDateTime,
    isFilter,
    reset
  };
}

export default function FutureDateFilter(props: any) {
  const {
    optionList,
    selectedItem,
    dateType,
    startDateTime,
    endDateTime,
    setSelectedItem,
    setDateType,
    setStartDateTime,
    setEndDateTime,
    isFilter,
    reset,
    disabledHopeSupplyDate
  } = props;

  return (
    <StyledDateFilter>
      <TPSelectOne
        label=""
        optionList={getDateType(
          disabledHopeSupplyDate
            ? ['START_DATE', 'END_DATE']
            : ['START_DATE', 'END_DATE', 'HOPE_SUPPLY_DATE']
        )}
        selectOption={dateType}
        changeSelect={setDateType}
      />
      <TPSelectOne
        className="date-filter"
        label={`${selectedItem.text} (${selectedItem.label})`}
        // icon={<IconCalendar />}
        optionList={optionList}
        selectOption={selectedItem.code}
        changeSelect={(d) => {
          const e = optionList.find((e: any) => e.code === d);
          setSelectedItem(e);
          setStartDateTime(e.value[0]);
          setEndDateTime(e.value[1]);
        }}
      />
      {isFilter && (
        <button
          className="main--button--refresh"
          onClick={() => {
            reset();
          }}
        >
          {/* <IconRefresh /> */}
          <span>초기화</span>
        </button>
      )}
    </StyledDateFilter>
  );
}

const StyledDateFilter = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 8px;

  .date-filter {
    --width: 246px;
    width: var(--width);
    .label--selectOption {
      .selectOption {
        display: none;
      }
    }
    .option {
      width: var(--width);
    }
  }
`;

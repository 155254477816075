//lib
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
//etc
import { getDateType, getStatus, getTaskType } from '@common/config/Code';
import { IconCalendar } from '@resources/icon';
import ModalType from '@src/common/config/ModalType';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import { DashBoard } from '../components/DashBoard';
import DataTable from '../components/DataTable/DataTable';
import { LanguagePairSelectComp } from '../components/LanguagePairSelect.Comp';
import UnderlineTitle from '../components/UnderlineTitle';
import { taskColumns } from './Task.columns';
import { LanguageWrap } from './Task.style';
//hooks
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { useGetWorkerList } from '@src/hooks/languageTask/getManagerList';
import { usePostLanguageTaskList } from '@src/hooks/languageTask/postList';
import { useGetFilter } from '@src/hooks/searchFilter';
import Footer from '@src/layouts/footer/Footer';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';

function TaskPage() {
  const navigate = useNavigate();

  //#region hooks call
  //todo 필터 타입 지정 필요
  const { myInfo } = useMyInfo();
  const { filterData, Component: FilterButton } = useGetFilter('TASK');
  const { pmList, taskerList } = useGetWorkerList(myInfo);
  const {
    languagePairList,
    languageTaskList,
    taskFilter,
    dashBoardItemList,
    dateType,
    dateFilter,
    isFilter,
    sortInfo: isSort,
    stateSetters: setState
  } = usePostLanguageTaskList(filterData);
  const { openHandle, Component: Calendar } = useRangeCalendar();
  //#endregion

  //#region set state
  const [searchValue, setSearchValue] = useState('');
  const {
    setWorkerUser,
    setPmManager,
    setTaskType,
    setTaskStatus,
    setTaskTitle,
    setDateType,
    setDateFilter,
    setLanguagePairList,
    setResetState,
    setIsSort
  } = setState;
  //#endregion

  const resetState = () => {
    setResetState();
    setSearchValue('');
  };

  const clickRowData = (rowData: any) => {
    const { languageTaskId } = rowData;
    navigate(`/task/${ModalType.TASKDETAIL_PAGE}/${languageTaskId}`);
  };

  return (
    <section
      css={LanguageWrap}
      style={{
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 71
      }}
    >
      <div className="title--languagePair">
        <h2>작업자 배정</h2>
        <LanguagePairSelectComp
          countItemList={languageTaskList}
          languagePairList={languagePairList}
          setLanguagePairList={setLanguagePairList}
        />
      </div>
      <Box
        mb={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'inline-flex'} gap={'8px'}>
          <TPInputSearchNew
            value={searchValue}
            placeholder="검색"
            onChange={(e) => setSearchValue(e.target.value)}
            clickSearch={() => setTaskTitle(searchValue)}
          />
          <TPSelectCheck
            label="상태"
            useOptionLabel
            optionList={getStatus([
              'ALL',
              'NEW',
              'COMPLETE_ASSIGN',
              'PROGRESS',
              'STOP',
              'COMPLETE',
              'DELIVERY_COMPLETE'
            ])}
            selectOptions={taskFilter.languageTaskStatus}
            changeSelect={setTaskStatus}
          />
          <TPSelectCheck
            label="유형"
            optionList={getTaskType(['ALL', 'TRANSLATION', 'POLISHING', 'LQA'])}
            selectOptions={taskFilter.taskType}
            changeSelect={setTaskType}
          />

          <TPSelectCheckManager
            label="담당 PM"
            PMList={pmList}
            showAvatar={true}
            selectOptions={taskFilter.projectManagerUserIdList}
            changeSelect={setPmManager}
          />

          <TPSelectCheckManager
            label="작업자"
            PMList={taskerList}
            showAvatar={true}
            selectOptions={taskFilter.workUserIdList}
            changeSelect={setWorkerUser}
          />

          <TPSelectOne
            label="날짜"
            optionList={getDateType([
              'ALL',
              'HOPE_SUPPLY_DATE',
              'START_DATE',
              'END_DATE'
            ])}
            selectOption={dateType}
            changeSelect={setDateType}
          />

          {dateType !== 'ALL' && (
            <>
              <div
                onClick={() => {
                  openHandle('task', dateFilter, setDateFilter);
                }}
              >
                <TPButtonActionCalendar
                  outline={true}
                  size={'medium'}
                  content={
                    dateFilter.startDateTime && dateFilter.endDateTime
                      ? `${format(
                          new Date(dateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )} - ${format(
                          new Date(dateFilter.endDateTime),
                          'yyyy.MM.dd'
                        )}`
                      : ''
                  }
                  icon={<IconCalendar />}
                />
              </div>
              <Calendar />
            </>
          )}
          {isFilter && (
            <button className="main--button--refresh" onClick={resetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          {/* [담당자 배정] 필터링 저장 */}
          <FilterButton
            getFilterData={{
              taskFilter,
              dateType,
              dateFilter,
              // taskTitle, // 작업명 제거
              languagePairList
            }}
          />
        </Box>
      </Box>
      <DashBoard list={dashBoardItemList} />

      <Box m={'1.5rem 0'}>
        <DataTable
          columns={taskColumns(isSort, setIsSort)}
          dataList={languageTaskList}
          clickRowData={clickRowData}
          noRowsMessage={`${isFilter ? '검색된' : '배정된'} 작업이 없습니다.`}
          gap={10.5}
        />
      </Box>
      <Outlet />

      <Footer />
    </section>
  );
}

export default TaskPage;

import { Box } from '@mui/material';
import { TPTooltipBasic } from '@src/units/tooltip/TPTooltip.Basic';
import { LanguageCodeType, LanguagePairType } from '../config/Code';
import { IUser } from '../config/ICode';

/**
 * 페이지 별 데이터 그리드 공통 필터링
 * @param option        변경할 필터값
 * @param defaultArr    기존 필터링 데이터
 * @param defaultOption 예외 처리할 필터값
 * @returns
 */
export const filterArr = (
  option: any,
  defaultArr: any[],
  defaultOption: any
) => {
  let filterArr: Array<string> = new Array<string>();

  if (option !== defaultOption) {
    const filterOption = defaultArr.filter((data) => data === option);

    filterArr = filterOption.length
      ? defaultArr.filter((v) => v !== option)
      : defaultArr.filter((v) => v !== defaultOption).concat(option);
  }

  return filterArr;
};

/**
 * api payload dateFormat yyyy-MM-dd HH:mm:ss
 * @param dateTime 포맷할 문자열
 * @returns
 */
export const dateFormat = (dateTime: string): string => {
  var date = new Date(dateTime);
  date.setHours(date.getHours() + 9);
  date.setDate(date.getDate() + 1);
  return date.toISOString().replace('T', ' ').substring(0, 19);
};

/**
 * state dateFormat ISOString : yyyy-MM-ddTHH:mm:ss+timezone
 * @param dateTime
 * @returns
 */
export const dateISOFormat = (dateTime: string): string => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const date = new Date(dateTime) as any;
  const timezoneDate = new Date(date - timezoneOffset);
  return timezoneDate.toISOString();
};

/**
 * 데이터 그리드 말줄임표
 * @param text    텍스트
 * @param length  최대 길이
 * @returns
 */
export const textSlice = (
  text: string | Array<string>,
  length?: number,
  unit?: string
): JSX.Element => {
  if (Array.isArray(text)) text = text.filter((v) => v);

  length = length ?? 0;
  const textType: boolean = Array.isArray(text);
  const setText = textType
    ? text.length > 1
      ? text[0] + '  외 ' + (text.length - 1) + (unit ?? '개')
      : text[0]
    : text.slice(0, length) + '...';

  if ((typeof text === 'string' && text.length > length) || textType) {
    return (
      <TPTooltipBasic
        className="MuiDataGrid-cellContent"
        placement="top"
        title={text.toString()}
      >
        <Box display={'inline'}>{setText}</Box>
      </TPTooltipBasic>
    );
  }
  return <Box display={'inline'}>{text}</Box>;
};

export const makeSimplifyManagerList = (
  managers: IUser[],
  lengthWhenOnePerson: number,
  lengthWhenMultiple: number
): string => {
  const showWorker =
    managers.length === 1
      ? managers[0]?.name.length > lengthWhenOnePerson
        ? `${managers[0].name.slice(0, lengthWhenOnePerson)}...`
        : managers[0]?.name
      : managers[0]?.name.length > lengthWhenMultiple
      ? `${managers[0].name.slice(0, lengthWhenMultiple)}...`
      : managers[0]?.name;

  return `${showWorker}${
    managers.length > 1 ? ' 외 ' + (managers.length - 1) + '인' : ''
  }`;
};

export const googleSignOut = (callback: any) => {
  if (window.gapi) {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2 !== null) {
      return auth2
        .signOut()
        .then(auth2.disconnect())
        .catch((e: any) => console.debug(e))
        .then(() => typeof callback === 'function' && callback());
    }
  }
};

export const getList = (
  allList: any[],
  currentPage: number,
  pageSize: number
): any[] => {
  const start = (currentPage - 1) * pageSize;
  const end = currentPage * pageSize;
  if (allList) {
    const listOnCurrentPage: any[] = allList.slice(start, end);
    return listOnCurrentPage;
  }
  return [];
};

export const getLanguageCodeString = (languagePair: LanguagePairType) => {
  const { startLanguage, destinationLanguage } = languagePair;
  return `${startLanguage}${destinationLanguage}`;
};

export const getLanguagePair = (languagePair: string) => {
  return {
    startLanguage: `${languagePair[0]}${languagePair[1]}` as LanguageCodeType,
    destinationLanguage:
      `${languagePair[2]}${languagePair[3]}` as LanguageCodeType
  };
};

import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { companyPerKeys } from '@hooks/queryKeyFactory';
import _ from 'lodash';
import { IWordAmountGraph } from '@src/common/config/IStatistics';

/**
 * /statistics/companyPerProjectWordAmountGraph
 * @description 고객사&프로젝트 별 통계(단어수 막대 그래프)
 * @returns {{ wordGraphData }}
 */
export const useGetWordGraph = () => {
  const getData = async (): Promise<IWordAmountGraph[]> => {
    return (await api.getPms(`/statistics/companyPerProjectWordAmountGraph`))
      .data;
  };

  const { data = [] } = useQuery([...companyPerKeys.wordGraph], getData);

  const wordGraphData = data
    ? _.map(data, (task: any, idx: number) => ({
        x: task.x,
        y: task.y,
        z: task.z,
        sum: _.reduce(
          _.map(task.y, (el) => el.count),
          (sum, n) => sum + n,
          0
        )
      }))
    : [];

  return { wordGraphData };
};

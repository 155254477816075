import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { Box, styled as muiStyled } from '@mui/material';
import {
  IcoMenuChevronDown,
  IcoStateLoading,
  IconInfoBlack,
  IconStateProgressUrl,
  IconStateComplateUrl
} from '@resources/icon';
import Api from '@src/api/Api';
import {
  LANGUAGE_SUB_TASK_STATUS_TYPE,
  STATUS_COLOR,
  compareWorkerType,
  getCategoryLabel,
  getLanguageLabelByCode,
  getLanguageSubTaskStatusTypeLabel,
  getStatus,
  getTaskStatusLabelColor,
  getTaskType,
  getWorkerType,
  getWorkerTypeLabel
} from '@src/common/config/Code';
import { useGetWorkerList } from '@src/hooks/languageTask/getManagerList';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { usePostMyWorkTabList } from '@src/hooks/myWork/postList.tab';
import { useGetFilter } from '@src/hooks/searchFilter';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import TPSelectLangPair from '@src/units/select/TPSelect.langPair';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import CategoryTable from '../components/CategoryTable/CategoryTable';
import { Td } from '../components/CategoryTable/Td';
import { DashBoard } from '../components/DashBoard';
import { LanguagePairSelectComp } from '../components/LanguagePairSelect.Comp';
import {
  JobDetailMyWorkModalButton,
  MyWorkRowStatusButton,
  renderDateTime,
  renderDateTimeNoWrap,
  renderNetInfo
} from '../dashboard/utils/render';
import { MyTaskWrap } from './MyTask.style';
import Footer from '@src/layouts/footer/Footer';
import _ from 'lodash';
import { useGetMyTaskCount } from '@src/hooks/myWork/getCount';
import { IconFileTitleTrue, IconFileTitleFalse } from '@src/resources/icon';
import CategoryTableMyTask from '../components/CategoryTable/CategoryTableMyTask';

// API Keys
export const apiKeys = {
  count(filter?: any) {
    const keys = ['myWork', 'count'];
    if (filter !== undefined) keys.push(filter);
    return keys;
  },
  listByDay(filter?: any) {
    const keys = ['myWork', 'listByDay'];
    if (filter !== undefined) keys.push(filter);
    return keys;
  }
} as const;

// API Hook
function useApi__myWork_count(filter?: any) {
  return useQuery(
    apiKeys.count(filter),
    () =>
      Api.post('/myWork/count', filter).then(
        (res: any) => res?.data?.section ?? []
      ),
    {}
  );
}

// API Hook
// function useApi__myWork_listByDay(filter?: any, setOpen?: any) {
//   type ITEM = {
//     companyName: any;
//     destinationLanguage: any;
//     endDateTime: any;
//     languageSubTaskId: number;
//     mainProjectManager: any;
//     projectTitle: any;
//     startDateTime: any;
//     startLanguage: any;
//     subProjectManager: any;
//     taskAmount: any;
//     taskTitle: any;
//     languageTaskTitle: any;
//     taskType: any;
//     workerList: any;
//     workerStatus: any;
//     workerType: any;
//   };

//   return useQuery(
//     apiKeys.listByDay(filter),
//     () =>
//       Api.post('/myWork/listByDay', filter).then((res: any) => {
//         let data = res?.data?.myWorkList ?? [];

//         // id 기준으로 그룹핑
//         let idMap: {
//           [key: string]: ITEM[];
//           ids: any[];
//         } = { ids: [] };

//         data.forEach((e: ITEM) => {
//           if (Array.isArray(idMap[e.languageSubTaskId])) {
//             idMap[e.languageSubTaskId].push(e);
//           } else {
//             idMap[e.languageSubTaskId] = [e];
//           }
//           idMap.ids.push(Number(e.languageSubTaskId));
//           if (Array.isArray(e?.workerList)) {
//             e.workerList.sort(compareWorkerType);
//           }
//         });

//         // 중복 제거 및 정렬
//         idMap.ids = [...new Set(idMap.ids)].sort((a, b) => a - b);

//         for (let e of Object.values(idMap)) {
//           if (Array.isArray(e)) {
//             e.sort((a, b) => compareWorkerType(a.workerType, b.workerType));
//           }
//         }

//         return idMap;
//       }),
//     {
//       initialData: { ids: [] },
//       select: (data) => {
//         if (data?.ids?.length === 0) setOpen?.(false);
//         if (Array.isArray(data?.ids)) {
//           data.ids.sort((a, b) => {
//             const aDt = data[a][0].endDateTime;
//             const bDt = data[b][0].endDateTime;
//             return aDt > bDt ? 1 : aDt < bDt ? -1 : 0;
//           });
//         }
//         return data;
//       }
//     }
//   );
// }

// Main Component
function MyTaskTabPage() {
  const navigate = useNavigate();
  const { filterData, Component: FilterButton } = useGetFilter('MY_WORK_TAB');
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const { myInfo, isLoading } = useMyInfo();
  const permissions = useMemo(() => {
    return {
      isTL: Boolean(myInfo?.roles?.includes('TL')),
      isLL: myInfo?.roles.find((el: any) => ['TL', 'LL'].includes(el))
    };
  }, [myInfo?.roles]);

  const {
    languagePairList,
    myWorkList,
    dashBoardItemList,
    myWorkFilter,
    dateType,
    dateFilter,
    isFilter,
    taskTitle,
    isSort,
    setState
  } = usePostMyWorkTabList(filterData);
  const { pmList, taskerList } = useGetWorkerList(myInfo);
  //#endregion

  //#region set state
  const [searchValue, setSearchValue] = useState('');
  const {
    setTaskType,
    setWorkerType,
    setWorkStatus,
    setTaskTitle,
    setPmManager,
    setLanguagePairList,
    setResetState
  } = setState;
  //#endregion

  const resetState = () => {
    setResetState();
    setSearchValue('');
  };

  const filter = useMemo(() => {
    const filter = {
      keyword: taskTitle,
      languagePairList,
      workerType: myWorkFilter.workerType.filter(
        (e) => e !== '0' && e !== 'ALL'
      ),
      workerStatus: myWorkFilter.workerStatus.filter(
        (e) => e !== '0' && e !== 'ALL'
      ),
      taskType: myWorkFilter.taskType.filter((e) => e !== '0' && e !== 'ALL'),
      projectManagerUserIdList: myWorkFilter.projectManagerUserIdList.filter(
        (e) => e !== '0' && e !== 'ALL'
      )
    };
    return filter;
  }, [taskTitle, myWorkFilter, languagePairList]);

  const { data: myWorkCountList, setFilter } = useGetMyTaskCount();

  useEffect(() => {
    setFilter(filter);
  }, [filter]);

  useLayoutEffect(() => {
    const toolbar = ref.current;
    const content = ref2.current;
    if (toolbar && content) {
      const toolbarHeight = toolbar.getBoundingClientRect().height;
      content.style.paddingTop = toolbarHeight - 30 + 'px';
    }
  });

  if (!isLoading && permissions.isTL === false)
    throw new Error('접근 권한이 없습니다.');

  return (
    <section css={MyTaskWrap}>
      <div className="filter-toolbar" ref={ref}>
        <Box className="title">
          <Box mb={2}>
            <Box
              sx={{
                color: '#9B51E0',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px'
              }}
            >
              작업
            </Box>
            <Box
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '34px'
              }}
            >
              작업관리
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '22px',
              letterSpacing: '-0.25px',
              mb: 2
            }}
          >
            <Box
              sx={{
                display: permissions.isLL ? 'flex' : 'none',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
                pb: 1,
                borderBottom: '2px solid transparent',
                cursor: 'pointer',
                color: '#727272'
              }}
              onClick={() => {
                navigate('/tasktab');
              }}
            >
              <span>언어 작업</span>
              <SimpleTooltip message="화면에 노출되는 언어 작업에 대해서 검색 또는 필터를 적용할 수 있습니다.">
                <IconInfoBlack width={12} height={12} fill="#727272" />
              </SimpleTooltip>
            </Box>
            <Box
              sx={{
                display: permissions.isTL ? 'flex' : 'none',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
                pb: 1,
                cursor: 'pointer',
                borderBottom: '2px solid #242424'
              }}
            >
              <span>내 작업</span>
              <SimpleTooltip message="화면에 노출되는 내 작업에 대해서 검색 또는 필터를 적용할 수 있습니다.">
                <IconInfoBlack width={12} height={12} />
              </SimpleTooltip>
            </Box>
          </Box>
        </Box>

        <Box
          className="toolbar"
          mx={-5}
          px={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: 'inline-flex',
              gap: '8px',
              '*': {
                whiteSpace: 'nowrap'
              }
            }}
          >
            <TPSelectCheck
              label="상태"
              useOptionLabel
              optionList={[
                'ALL',
                'NEW',
                'PROGRESS',
                'WAIT_QUERY',
                'REJECT',
                'COMPLETE',
                'STOP'
              ].map((code) => {
                const label = getLanguageSubTaskStatusTypeLabel(code);
                return { code, label, text: label };
              })}
              selectOptions={myWorkFilter.workerStatus}
              changeSelect={setWorkStatus}
            />
            <TPSelectCheck
              label="역할"
              optionList={getWorkerType([
                'ALL',
                'TRANSLATOR',
                'FIRST_CHECKER',
                'SECOND_CHECKER'
              ])}
              selectOptions={myWorkFilter.workerType}
              changeSelect={setWorkerType}
            />
            <TPSelectCheck
              label="유형"
              optionList={getTaskType([
                'ALL',
                'TRANSLATION',
                'POLISHING',
                'LQA'
              ])}
              selectOptions={myWorkFilter.taskType}
              changeSelect={setTaskType}
            />
            <TPSelectLangPair
              badgeContent={languagePairList.length}
              languagePairList={languagePairList}
              onChange={(v: any) => {
                setLanguagePairList((o) => {
                  return [...o, v];
                });
              }}
            />
            <TPSelectCheckManager
              label="담당 PM"
              PMList={pmList}
              showAvatar={true}
              selectOptions={myWorkFilter.projectManagerUserIdList}
              changeSelect={setPmManager}
            />
            {isFilter && (
              <button className="main--button--refresh" onClick={resetState}>
                {/* <IconRefresh /> */}
                <span>초기화</span>
              </button>
            )}
            {/* [내 작업] 필터링 저장 */}
            <FilterButton
              getFilterData={{
                // taskTitle, // 작업명 제거
                myWorkFilter,
                dateType,
                dateFilter,
                languagePairList
              }}
            />
          </Box>
          <TPInputSearchNew
            value={searchValue}
            placeholder="검색"
            onChange={(e) => setSearchValue(e.target.value)}
            clickSearch={() => setTaskTitle(searchValue)}
          />
        </Box>

        {!!languagePairList?.length && (
          <LanguagePairSelectComp
            countItemList={myWorkList}
            languagePairList={languagePairList}
            setLanguagePairList={setLanguagePairList}
            pageType={'mytask'}
          />
        )}

        <DashBoard list={dashBoardItemList} />
      </div>

      <Box mt={'1.5rem 0'} pb={'80px'} className="content" ref={ref2}>
        {Array.isArray(myWorkCountList) &&
          myWorkCountList.map((item: any, i) => {
            return (
              <CategoryTableMyTask
                key={i}
                type={'mytask'}
                taskFilter={filter}
                item={item}
              />
            );
          })}
      </Box>
      <Footer
        style={{
          position: 'absolute',
          left: 0
        }}
      />
      <Outlet />
    </section>
  );
}

export default MyTaskTabPage;

interface ITEM {
  companyName: any;
  destinationLanguage: any;
  endDateTime: any;
  languageSubTaskId: number;
  mainProjectManager: any;
  projectTitle: any;
  startDateTime: any;
  startLanguage: any;
  subProjectManager: any;
  taskAmount: any;
  taskTitle: any;
  languageTaskTitle: any;
  taskType: any;
  workerList: any;
  workerStatus: any;
  workerType: any;
}

// Sub Component
export function MyWorkTable({
  label,
  data,
  // myWorkListByDayData,
  count,
  setOpen
}: any) {
  const renderWorkers = (item: any) => {
    const list = item?.workerList ?? [];

    let translator: any = list.find((e: any) => e.workerType === 'TRANSLATOR');
    let firstChecker: any = list.find(
      (e: any) => e.workerType === 'FIRST_CHECKER'
    );
    let secondChecker = list.find(
      (e: any) => e.workerType === 'SECOND_CHECKER'
    );

    const noUserEl = (
      <Box
        className="worker"
        width={34}
        height={34}
        bgcolor="#fafafa"
        borderRadius={24}
      />
    );

    const translatorEl = translator ? (
      <div
        className={cx({
          worker: true,
          [translator.workerStatus]: true
        })}
      >
        <TPAvatarGroupNew userList={[translator]} size={30} />
      </div>
    ) : (
      noUserEl
    );

    const firstCheckerEl = firstChecker ? (
      <div
        className={cx({
          worker: true,
          [firstChecker.workerStatus]: true
        })}
      >
        <TPAvatarGroupNew userList={[firstChecker]} size={30} />
      </div>
    ) : (
      noUserEl
    );

    const secondCheckerEl = secondChecker ? (
      <div
        className={cx({
          worker: true,
          [secondChecker.workerStatus]: true
        })}
      >
        <TPAvatarGroupNew userList={[secondChecker]} size={30} />
      </div>
    ) : (
      noUserEl
    );
    return (
      <div className="workers">
        {translatorEl}
        <IcoStateLoading
          fill="#D9D9D9"
          style={{
            margin: '0 4px'
          }}
        />
        {firstCheckerEl}
        <IcoStateLoading
          fill="#D9D9D9"
          style={{
            margin: '0 4px'
          }}
        />
        {secondCheckerEl}
      </div>
    );
  };

  const getData = () => {
    let myWorkList = data?.myWorkList ?? [];

    // id 기준으로 그룹핑
    let idMap: {
      [key: string]: ITEM[];
      ids: any[];
    } = { ids: [] };

    myWorkList.forEach((e: ITEM) => {
      if (Array.isArray(idMap[e.languageSubTaskId])) {
        idMap[e.languageSubTaskId].push(e);
      } else {
        idMap[e.languageSubTaskId] = [e];
      }
      idMap.ids.push(Number(e.languageSubTaskId));
      if (Array.isArray(e?.workerList)) {
        e.workerList.sort(compareWorkerType);
      }
    });

    idMap.ids = [...new Set(idMap.ids)].sort((a, b) => a - b);
    for (let e of Object.values(idMap)) {
      if (Array.isArray(e)) {
        e.sort((a, b) => compareWorkerType(a.workerType, b.workerType));
      }
    }

    if (Array.isArray(idMap?.ids)) {
      idMap.ids.sort((a: any, b: any) => {
        const aDt = idMap[a][0].endDateTime;
        const bDt = idMap[b][0].endDateTime;
        return aDt > bDt ? 1 : aDt < bDt ? -1 : 0;
      });
    }
    return idMap;
  };

  return (
    <MyWorkTableWrap>
      {getData()?.ids?.length > 0 ? (
        getData().ids.map((id: number, i: number) => {
          const items = getData()[id] as any[];
          return (
            <tbody key={i}>
              {items.map((item: any, i: number) => {
                return (
                  <tr
                    key={i}
                    data-language-sub-task-id={item.languageSubTaskId}
                  >
                    <td
                      style={{
                        borderLeft: '3px solid',
                        borderLeftColor: getTaskStatusLabelColor(
                          item.workerStatus
                        ),
                        width: 125,
                        height: 61,
                        paddingLeft: 14,
                        paddingRight: 14
                      }}
                    >
                      <div
                        className="language-task-status"
                        data-language-task-status={item.workerStatus}
                      >
                        <MyWorkRowStatusButton
                          taskStatus={item.workerStatus}
                          workerType={item.workerType}
                          workerId={item.workerId}
                          fileRegisterStatus={item.fileRegisterStatus}
                          workerList={items}
                        />
                      </div>
                    </td>
                    <Td
                      title="역할"
                      content={getWorkerTypeLabel(item.workerType)}
                    />
                    <Td
                      title={renderNetInfo(undefined, '작업 분량', false)}
                      content={
                        item.taskType === 'LQA'
                          ? `${Math.floor(
                              Number(item.taskAmount ?? 0) / 250
                            )} 시간 ${
                              Number(item.taskAmount ?? 0) % 250 > 0
                                ? '30'
                                : '00'
                            }분`
                          : item.taskAmount ?? 0
                      }
                    />
                    <Td title="진행단계" content={renderWorkers(item)} />
                    <Td
                      width="280px"
                      title={
                        <div className="project-title">
                          {item.companyName}
                          <IcoMenuChevronDown
                            fill="#D9D9D9"
                            style={{ rotate: '-90deg' }}
                          />
                          {item.projectTitle}
                        </div>
                      }
                      content={
                        <JobDetailMyWorkModalButton
                          languageTaskId={item.languageTaskId}
                        >
                          <div className="task-title">
                            {item.fileRegisterStatus ? (
                              <IconFileTitleTrue className="title-fileuse" />
                            ) : (
                              <IconFileTitleFalse className="title-fileuse" />
                            )}
                            {item.languageTaskTitle ??
                              '{languageTaskTitle} 없음'}
                          </div>
                        </JobDetailMyWorkModalButton>
                      }
                    />
                    <Td
                      title="마감일"
                      content={renderDateTime(
                        item.endDateTime,
                        item.workerStatus !== 'COMPLETE'
                      )}
                    />
                    <Td
                      title="출발어"
                      content={getLanguageLabelByCode(item.startLanguage)}
                    />
                    <Td
                      title="도착어"
                      content={getLanguageLabelByCode(item.destinationLanguage)}
                    />
                    <Td
                      title="시작일"
                      content={renderDateTime(item.startDateTime, false)}
                    />
                    <Td
                      title="유형"
                      content={getCategoryLabel(item.taskType)}
                    />
                    <Td
                      title="담당 PM"
                      content={
                        <TPAvatarGroupNew
                          userList={
                            [
                              item.mainProjectManager,
                              item.subProjectManager
                            ] as any[]
                          }
                        />
                      }
                    />
                  </tr>
                );
              })}
              <tr className="gap"></tr>
            </tbody>
          );
        })
      ) : (
        <caption className="nodata">배정받은 작업이 없습니다.</caption>
      )}
    </MyWorkTableWrap>
  );
}

const MyWorkTableWrap = styled.table`
  width: 1820px;
  border-collapse: collapse;

  tbody {
    /* background-color: white; */
  }

  tr:not(.gap) {
    height: 61px;
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;
  }
  tr.gap {
    height: 10px;
  }

  td {
    white-space: nowrap;

    .project-title {
      white-space: break-spaces;
      word-break: break-all;
    }

    .task-title {
      white-space: break-spaces;
      word-break: break-all;
    }
  }

  .language-task-status,
  .workers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .language-task-status {
    gap: 10px;
  }

  .nodata {
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #727272;
    background-color: #fff;
  }

  .worker {
    position: relative;
    border-radius: 100%;
    padding: 1px;
    border: 1px solid #e8d0ff;
    border-radius: 50%;
    &.COMPLETE,
    &.STOP {
      border: 1px solid rgba(114, 114, 114, 0.5);
      ::after {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        width: 12px;
        height: 12px;
        background: url(${IconStateComplateUrl});
        border-radius: 100%;
      }
      .avatar--div--wrap {
        opacity: 0.25;
      }
    }
    &.PROGRESS,
    &.WAIT_QUERY {
      border: 1px solid #9b51e0;
      ::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%);
        width: 19px;
        height: 9px;
        background: url(${IconStateProgressUrl});
        border-radius: 50px;
      }
    }

    &.NEW {
      --color: ${STATUS_COLOR.NEW};
    }
    &.PROGRESS {
      --color: ${STATUS_COLOR.PROGRESS};
    }
    &.WAIT_QUERY {
      --color: ${STATUS_COLOR.WAIT_QUERY};
    }
    &.REJECT {
      --color: ${STATUS_COLOR.REJECT};
    }
    &.COMPLETE {
      --color: ${STATUS_COLOR.COMPLETE};
    }
    &.STOP {
      --color: ${STATUS_COLOR.STOP};
    }
    /* &:hover {
      box-shadow: 0 0 0 4px var(--color);
    } */
  }
`;

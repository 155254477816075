import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { globalKeys } from '../queryKeyFactory';
import { Backdrop, Fade, Modal, ThemeProvider } from '@mui/material';
import styled from '@emotion/styled';
import { ModalTheme } from '@hooks/modal/Modal.style';
import { useEffect, useState } from 'react';
import {
  CheckButtonStyle,
  CheckButtonWrap,
  LanguageSelectModalStyle,
  SelectLanguagePairStyle
} from '@hooks/modal/LanguageSelect.style';
import {
  IconCircleClose,
  IconModalClose,
  IconNextArrow
} from '@resources/icon';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { TPSelectOne } from '@units/select/TPSelect.one';
import { DividingLine } from '@pages/components/DividingLine';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import _ from 'lodash';
import { getLanguageLabelsByCode } from '@common/config/Code';
import { usePageFunc } from '@hooks/utils';

const ModalChildren = styled.div`
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1;
`;

/**
 * dateRangePicker hook
 * @type {(props?: IRangeCalendarState) => {dateRange: {from: Date, to: Date }; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useLanguageSelect = (
  setLanguagePairList: any,
  description?: string,
  isSelectAll?: boolean,
  type?: string
) => {
  const { sortingLanguagePairList } = usePageFunc();

  //#region global state
  const languageSelectData = useGlobalValue([...globalKeys.languageSelect], {
    open: false
  });
  const languageSelectFetcher = useSetGlobalState([
    ...globalKeys.languageSelect
  ]);
  const { open } = languageSelectData;
  //#endregion

  //#region handler
  const openHandle = () => {
    languageSelectFetcher({ ...languageSelectData, open: true });
  };

  const closeHandle = () => {
    languageSelectFetcher({ ...languageSelectData, open: false });
  };

  const confirmHandle = (pairList: any[]) => {
    setLanguagePairList(pairList);
    languageSelectFetcher({ ...languageSelectData, open: false });
  };
  //#endregion

  //#region component
  const Component = ({
    languagePairList,
    languagePairOptions
  }: any): JSX.Element => {
    const [startList, setStartList] = useState<any[]>([]);
    const [destinationList, setDestinationList] = useState<any[]>([]);

    const [languageList, setLanguageList] = useState<any[]>([]);
    const [pairList, setPairList] = useState<any[]>([]);
    const [startPair, setStartPair] = useState<string>('');
    const [destinationPair, setDestinationPair] = useState<string>('');

    const addPair = (code: string) => {
      setDestinationPair(code);
      if (type === 'single') {
        setPairList([{ startLanguage: startPair, destinationLanguage: code }]);
      } else if (
        !_.find(pairList, {
          startLanguage: startPair,
          destinationLanguage: code
        })
      ) {
        setPairList([
          ...pairList,
          { startLanguage: startPair, destinationLanguage: code }
        ]);
      }
    };

    const allAddPair = () => {
      setPairList([]);
    };

    const removePair = (item: any) => {
      const removeList = _.filter(pairList, (i) => {
        return !(
          i.startLanguage === item.startLanguage &&
          i.destinationLanguage === item.destinationLanguage
        );
      });
      setPairList(removeList);
    };

    const singleSelectPair = (item: any) => {
      if (
        _.find(pairList, {
          startLanguage: item.startLanguage,
          destinationLanguage: item.destinationLanguage
        })
      ) {
        removePair(item);
      } else {
        setPairList([item]);
      }
    };
    const selectPair = (item: any) => {
      if (
        _.find(pairList, {
          startLanguage: item.startLanguage,
          destinationLanguage: item.destinationLanguage
        })
      ) {
        removePair(item);
      } else {
        if (
          _.isEqual(
            _.sortBy(
              [...pairList, item],
              ['startLanguage', 'destinationLanguage']
            ),
            _.sortBy(languageList, ['startLanguage', 'destinationLanguage'])
          ) &&
          !isSelectAll
        ) {
          setPairList([]);
        } else {
          setPairList([...pairList, item]);
        }
      }
    };

    useEffect(() => {
      const etcList = getLanguageLabelsByCode(
        _.map(languagePairOptions, 'destinationLanguage') as (
          | 'ALL'
          | 'KO'
          | 'EN'
          | 'ZH_CN'
          | 'ZH_TW'
          | 'JA'
          | 'DE'
          | 'FR'
          | 'ES'
          | 'ES_419'
          | 'TR'
          | 'ID'
          | 'TH'
          | 'RU'
          | 'PT'
          | 'PT_BR'
          | 'VI'
          | 'PL'
          | 'IT'
          | 'NL'
          | 'NO'
          | 'AR'
          | 'HI'
          | 'MS'
          | 'CS'
          | 'TL'
          | 'SV'
          | 'DA'
          | 'FI'
          | 'HU'
          | 'HR'
          | 'SR'
          | 'ETC'
        )[]
      );
      const removeCount = _.map(languagePairOptions, (item) => {
        return item.count
          ? item
          : {
              startLanguage: item.startLanguage,
              destinationLanguage: item.destinationLanguage,
              count: 0
            };
      });

      setLanguageList([
        ...removeCount,
        ..._.map(etcList, (item: any) => {
          return {
            startLanguage: 'ETC',
            destinationLanguage: item.code
          };
        })
      ]);
      return () => {};
    }, [languagePairOptions]);

    useEffect(() => {
      setPairList(languagePairList);
      return () => {};
    }, [languagePairList]);

    useEffect(() => {
      setStartList(
        getLanguageLabelsByCode(_.map(languageList, 'startLanguage'))
      );
      setDestinationList(
        getLanguageLabelsByCode(_.map(languageList, 'destinationLanguage'))
      );
      return () => {};
    }, [languageList]);

    useEffect(() => {
      if (startPair === 'ETC') {
        setDestinationList(
          getLanguageLabelsByCode(_.map(languageList, 'destinationLanguage'))
        );
      } else {
        const removeETC = _.remove(
          getLanguageLabelsByCode(_.map(languageList, 'destinationLanguage')),
          (item) => item.code !== 'ETC'
        );
        setDestinationList(removeETC);
      }
      return () => {};
    }, [startPair]);

    return (
      open && (
        <ThemeProvider theme={ModalTheme}>
          <Modal
            styledmodal-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition={true}
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Fade in={open}>
              <ModalChildren>
                <div css={LanguageSelectModalStyle}>
                  <IconModalClose
                    onClick={closeHandle}
                    className="closeButton"
                    width={24}
                    height={24}
                  />
                  <UnderlineTitle text="언어페어 선택" />
                  <div className="notice">
                    {description ? (
                      <p>{description}</p>
                    ) : (
                      <>
                        <p>리스트에 노출할 언어페어를 선택해 주세요.</p>
                        <p>담당 중인 언어페어만 선택할 수 있습니다.</p>
                      </>
                    )}
                  </div>
                  <div className="dropDownContainer">
                    <TPSelectOne
                      label="출발어"
                      selectOption={startPair}
                      optionList={startList}
                      changeSelect={(code) => setStartPair(code)}
                      fullWidth
                      height="56px"
                      optionLeftMargin="16px"
                      fontSize="16px"
                    />
                    <TPSelectOne
                      label="도착어"
                      selectOption={destinationPair}
                      optionList={destinationList}
                      unable={!startPair}
                      changeSelect={(code) => addPair(code)}
                      fullWidth
                      height="56px"
                      optionLeftMargin="16px"
                      fontSize="16px"
                    />
                  </div>
                  <div className="selectLanguagePairContainer">
                    <div className="virtualArea">
                      {!!pairList.length ? (
                        pairList.map((item, index) => {
                          const { startLanguage, destinationLanguage } = item;
                          return (
                            <div
                              css={SelectLanguagePairStyle}
                              key={`pairItem${index}`}
                            >
                              <span className="selectLanguagePair">
                                {
                                  getLanguageLabelsByCode([startLanguage])[0]
                                    .label
                                }{' '}
                                <IconNextArrow
                                  width={16}
                                  height={16}
                                  fill="#fff"
                                />{' '}
                                {
                                  getLanguageLabelsByCode([
                                    destinationLanguage
                                  ])[0].label
                                }
                              </span>
                              <IconCircleClose
                                onClick={() => removePair(item)}
                                className="removeButton"
                                width={24}
                                height={24}
                                fill={'#fff'}
                              />
                            </div>
                          );
                        })
                      ) : !isSelectAll ? (
                        <div css={SelectLanguagePairStyle}>
                          <span className="selectLanguagePair">전체</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <DividingLine />
                  <div className="showListContainer">
                    <div className="virtualArea">
                      {!isSelectAll && (
                        <div css={CheckButtonWrap} onClick={() => allAddPair()}>
                          <span className="showItem">전체</span>
                          <div
                            css={CheckButtonStyle}
                            className={pairList.length === 0 ? 'active' : ''}
                          ></div>
                        </div>
                      )}
                      {sortingLanguagePairList(languageList).map(
                        (item, index) => {
                          const { startLanguage, destinationLanguage } = item;
                          return (
                            <div
                              key={`languageList${index}`}
                              css={CheckButtonWrap}
                              onClick={() => {
                                type === 'single'
                                  ? singleSelectPair(item)
                                  : selectPair(item);
                              }}
                            >
                              <span className="showItem">
                                {getLanguageLabelsByCode([startLanguage])[0]
                                  ? getLanguageLabelsByCode([startLanguage])[0]
                                      .label
                                  : ''}
                                <IconNextArrow
                                  width={12}
                                  height={12}
                                  fill="#646782"
                                />
                                {getLanguageLabelsByCode([
                                  destinationLanguage
                                ])[0]
                                  ? getLanguageLabelsByCode([
                                      destinationLanguage
                                    ])[0].label
                                  : ''}
                              </span>
                              <div
                                css={CheckButtonStyle}
                                className={
                                  _.find(pairList, {
                                    startLanguage: item.startLanguage,
                                    destinationLanguage:
                                      item.destinationLanguage
                                  })
                                    ? 'active'
                                    : ''
                                }
                              ></div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <DividingLine />
                  <div className="buttonContainer">
                    <TPButtonBasic
                      label="취소하기"
                      variant="outlined"
                      onClick={closeHandle}
                      fullWidth
                    />
                    <TPButtonBasic
                      label="선택하기"
                      variant="contained"
                      disabled={!isSelectAll ? false : !pairList.length}
                      onClick={() => {
                        confirmHandle(pairList);
                      }}
                      fullWidth
                    />
                  </div>
                </div>
              </ModalChildren>
            </Fade>
          </Modal>
        </ThemeProvider>
      )
    );
  };

  return {
    closeHandle,
    openHandle,
    Component
  };
  //#endregion
};

import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { IcoMenuChevronDown, IcoMenuChevronDownActive } from '@resources/icon';
import { IconChecked, IconUnChecked } from '@resources/icon';
import { memo, useState } from 'react';

const ALL = 'ALL';

export interface TPSelectFilterCheckProps {
  label: string;
  options: { label: string; value: any }[];
  multiple?: boolean;
  all?: boolean;
  value?: any;
  onChange?: any;
  disabledCheckedLength?: boolean;
}

export default function TPSelectFilterCheck({
  label,
  options,
  multiple,
  all,
  value,
  onChange,
  disabledCheckedLength,
  ...props
}: any) {
  const checkedLength = value.filter((e: any) => e !== 'ALL').length;

  const handleChange = (v: any) => {
    if (multiple) {
      onChange?.((prev: any) => {
        const isExist = prev.includes(v);
        let rs = value;
        if (all) {
          if (v === ALL) {
            rs = [ALL];
          } else {
            if (isExist) {
              rs = prev.filter((e: any) => e !== v && e !== ALL);
            } else {
              rs = [...prev.filter((e: any) => e !== ALL), v];
            }
          }
        } else {
          if (isExist) {
            rs = prev.filter((e: any) => e !== v);
          } else {
            rs = [...prev, v];
          }
        }
        if (all && (rs.length === 0 || rs.length === options.length - 1)) {
          rs = ['ALL'];
        } else {
          v = rs;
        }
        return rs;
      });
    } else {
      onChange?.(v);
    }
  };

  return (
    <TPSelectFilterCheckWrap
    // className={cx({ active: checkedLength > 0 })}
    >
      <div className="header">
        <div>{label}</div>
        {checkedLength !== 0 && !disabledCheckedLength && (
          <div className="length">{checkedLength}</div>
        )}
        <div>
          <IcoMenuChevronDownActive className="icon active" />
          <IcoMenuChevronDown className="icon normal" />
        </div>
      </div>
      {Array.isArray(options) && (
        <ul>
          {options.map((e, i) => {
            const checked = multiple
              ? value.includes(e.value)
              : value === e.value;
            return (
              <li
                className={cx({ checked })}
                key={i}
                onClick={() => {
                  handleChange(e.value);
                }}
              >
                {checked ? <IconChecked /> : <IconUnChecked />}
                {e.label}
              </li>
            );
          })}
        </ul>
      )}
    </TPSelectFilterCheckWrap>
  );
}

const TPSelectFilterCheckWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  position: relative;
  user-select: none;
  line-height: normal;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
    height: 32px;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer;
    white-space: nowrap;

    .length {
      margin-left: 4px;
      background-color: #000;
      color: #fff;
      width: 15px;
      height: 15px;
      padding: 1px 4px;
      border-radius: 30px;
      font-size: 10px;
      font-weight: 700;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      display: none;
      margin-left: 10px;

      &.normal {
        display: block;
      }
    }
  }

  ul {
    list-style: none;
    position: absolute;
    top: 36px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    min-width: 100%;
    background-color: #fff;
    display: none;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
      flex-direction: row;
      padding: 0.33rem 2rem 0.33rem 0.33rem;
      white-space: nowrap;
      svg {
        height: 16px;
      }
    }
  }

  &:hover {
    .header {
      background-color: #000;
      color: #fff;
      .normal {
        fill: currentColor;
      }
    }
    .length {
      background-color: #fff;
      color: #000;
    }
    ul {
      display: block;

      li {
        &.checked {
          background-color: #f9f5fd;
        }
        &:hover {
          background-color: #f9f5fd;
        }
      }
    }
  }
`;

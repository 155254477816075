import { css } from '@emotion/react';

export const TaskerSearchCss = css`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  max-width: 100%;
  min-height: 400px;
  text-align: center;
  position: relative;
  background: #fff;
  .modal--div--search {
    width: 100%;
    margin-top: 40px;
    .assignCountry {
      width: 100%;
      max-height: 200px;
      padding-right: 44px;
    }
  }

  .button--container {
    margin-top: 44px;
    display: flex;
    gap: 16px;
    width: 100%;
    height: 56px;
  }
  .modalCloseButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .searchContainer {
    margin: 40px 0 6px;
  }
  .bookmark--text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    padding-left: 14px;
    text-align: left;
    color: #9a9cb5;
    margin-top: 20px;
    &.empty {
      font-size: 12px;
      margin: 18px 0 20px;
    }
  }

  .bookmark--list,
  .tasker--list {
    margin-left: 6px;
    > li {
      &.active {
        background-color: #f9f4fe;
      }
    }
  }
  .bookmark--list {
    max-height: 150px;
  }

  .tasker--list {
    max-height: 250px;
  }

  ul {
    overflow-y: auto;
    margin-right: -20px;
    padding-right: 20px;
    ::-webkit-scrollbar {
      width: 7px;
      height: 10px;
      margin: 50px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
      margin-left: 10px;
    }

    margin-right: -26px;
    padding-right: 26px;
    ::-webkit-scrollbar {
      width: 6px;
    }
    li {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      width: 320px;
      padding: 8px 0;
      :hover {
        background-color: #f9f4fe;
        cursor: pointer;
      }
      .img--avatar--name {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        img {
          margin: 0 12px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 12px;
        }
        .name {
          height: 26px;
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;
          text-align: left;
          color: #2c3558;
        }
        .book--mark {
          position: absolute;
          right: 10px;
        }
      }
      .language--pair--list {
        margin-left: 48px;
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .language--pair {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: fit-content;
          padding: 0 16px;
          border: 1px solid #eeedf4;
          border-radius: 8px;
          color: #646782;
          background-color: #fff;
          font-size: 14px;
        }
      }
    }
  }
`;

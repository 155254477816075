import styled from '@emotion/styled';

type alignType = 'notCenter';
interface IUnderlineTitle {
  text: string;
  align?: alignType;
}
/**
 * @props text : string;
 * @props align? : 'notCenter'
 * @default align = 'center'
 */
const UnderlineTitle = (props: IUnderlineTitle) => {
  const { text, align } = props;
  return (
    <Title align={align}>
      <span>{text}</span>
    </Title>
  );
};

interface ITitle {
  align?: alignType;
}
const Title = styled.div<ITitle>`
  display: flex;
  width: ${({ align }) => (align ? null : '100%')};
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45px;
  span {
    position: relative;
    font-size: 32px;
    font-weight: 700;
    color: #2c3558;
    z-index: 1;
    ::after {
      z-index: -1;
      content: '';
      bottom: -7px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 12px;
      background-color: #71d88e99;
    }
  }
`;

export default UnderlineTitle;

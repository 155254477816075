import { css } from '@emotion/react';

export const StateSelectorStyle = css`
  width: 320px;
  min-width: 300px;
  max-width: 100%;
  min-height: 300px;
  text-align: center;
  .state--list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .state--list--text {
      cursor: pointer;
      font-weight: 500;
      margin-left: 8px;
      display: inline;
      &.active {
        color: #9b51e0;
      }
    }
  }
`;

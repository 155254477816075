import { Button, ButtonGroup } from '@mui/material';
import { ITaskManager } from '@src/common/config/ILanguageTask';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import { useNavigate as Navigate } from 'react-router-dom';
import { renderLanguages } from '../dashboard/utils/render';

export const taskManagerColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'languageTaskId',
      value: '작업번호',
      width: 120,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'key',
      value: '관리키',
      width: 200,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="key"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 200,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트명',
      width: 200,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'taskTitle',
      value: '작업명',
      width: 900,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'startLanguage',
      value: '출발어',
      width: 300,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData(code: any) {
        return renderLanguages(Array.isArray(code) ? code : [code]);
      }
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 300,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData(code: any) {
        return renderLanguages(Array.isArray(code) ? code : [code]);
      }
    },
    {
      code: '',
      value: '',
      width: 300,
      renderHeader() {
        return <></>;
      },
      renderRowData(v: never, rowData: ITaskManager) {
        const navigate = Navigate();

        return (
          <ButtonGroup
            sx={{
              '& > .MuiButton-root': {
                minWidth: 68
              }
            }}
          >
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                navigate(
                  `/taskManager/${rowData.isUpload ? 'reUpload' : 'upload'}/${
                    rowData?.languageTaskId
                  }`,
                  {
                    state: rowData
                  }
                );
              }}
            >
              {rowData.isUpload && '다시'} 업로드
            </Button>
            <Button
              disabled={!rowData.isUpload && !rowData.isTaskCreated}
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                navigate(`/taskManager/create/${rowData?.languageTaskId}`);
              }}
            >
              일감생성
            </Button>

            <Button
              disabled={!rowData.isUpload && !rowData.isTaskCreated}
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                navigate(`/taskManager/download/${rowData?.languageTaskId}`);
              }}
            >
              다운로드
            </Button>
          </ButtonGroup>
        );
      }
    }
  ] as const;

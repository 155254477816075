import styled from '@emotion/styled';
import { getDateType } from '@src/common/config/Code';
import { IconCalendar, IconRefresh } from '@src/resources/icon';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import {
  add,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { useMemo, useState } from 'react';

export default function DoneDateFilter(props: any) {
  const {
    optionList,
    selectedItem,
    dateType,
    startDateTime,
    endDateTime,
    setSelectedItem,
    setDateType,
    setStartDateTime,
    setEndDateTime,
    isFilter,
    reset,
    disabledHopeSupplyDate
  } = props;

  return (
    <StyledDateFilter>
      <TPSelectOne
        label=""
        optionList={getDateType(
          disabledHopeSupplyDate
            ? ['START_DATE', 'END_DATE']
            : ['START_DATE', 'END_DATE', 'HOPE_SUPPLY_DATE']
        )}
        selectOption={dateType}
        changeSelect={setDateType}
      />
      <TPSelectOne
        className="date-filter"
        label={`${selectedItem.text} (${selectedItem.label})`}
        icon={<IconCalendar />}
        optionList={optionList}
        selectOption={selectedItem.code}
        changeSelect={(d) => {
          const e = optionList.find((e: any) => e.code === d);
          setSelectedItem(e);
          setStartDateTime(e.value[0]);
          setEndDateTime(e.value[1]);
        }}
      />
      {isFilter && (
        <button
          className="main--button--refresh"
          onClick={() => {
            reset();
          }}
        >
          {/* <IconRefresh /> */}
          <span>초기화</span>
        </button>
      )}
    </StyledDateFilter>
  );
}

export function createOptionList(date: Date) {
  let baseStart = new Date(format(date, 'yyyy/MM/dd 00:00:00'));
  let baseEnd = new Date(format(date, 'yyyy/MM/dd 23:59:59'));
  baseEnd.setMilliseconds(999);

  let options = { weekStartsOn: 0 } as const; // 일요일 시작

  let thisWeekStart = startOfWeek(baseStart, options);
  let thisWeekEnd = endOfWeek(baseEnd, options);
  let lastWeekStart = startOfWeek(add(thisWeekStart, { days: -1 }), options);
  let lastWeekEnd = endOfWeek(add(thisWeekStart, { days: -1 }), options);
  let thisMonthStart = startOfMonth(baseStart);
  let thisMonthEnd = endOfMonth(baseEnd);
  let lastMonthStart = startOfMonth(add(thisMonthStart, { days: -1 }));
  let lastMonthEnd = endOfMonth(add(thisMonthStart, { days: -1 }));
  let thisYearStart = startOfYear(baseStart);
  let thisYearEnd = endOfYear(baseEnd);
  let lastYearStart = startOfYear(add(thisYearStart, { days: -1 }));
  let lastYearEnd = endOfYear(add(thisYearStart, { days: -1 }));

  return [
    {
      code: 'lastWeek',
      label: '지난 주',
      text: `${format(lastWeekStart, 'yyyy.MM.dd')} - ${format(
        lastWeekEnd,
        'yyyy.MM.dd'
      )}`,
      value: [lastWeekStart, lastWeekEnd]
    },
    {
      code: 'thisWeek',
      label: '이번 주',
      text: `${format(thisWeekStart, 'yyyy.MM.dd')} - ${format(
        thisWeekEnd,
        'yyyy.MM.dd'
      )}`,
      value: [thisWeekStart, thisWeekEnd],
      isDefault: true
    },
    {
      code: 'lastMonth',
      label: '지난 달',
      text: `${format(lastMonthStart, 'yyyy.MM.dd')} - ${format(
        lastMonthEnd,
        'yyyy.MM.dd'
      )}`,
      value: [lastMonthStart, lastMonthEnd]
    },
    {
      code: 'thisMonth',
      label: '이번 달',
      text: `${format(thisMonthStart, 'yyyy.MM.dd')} - ${format(
        thisMonthEnd,
        'yyyy.MM.dd'
      )}`,
      value: [thisMonthStart, thisMonthEnd]
    },
    {
      code: 'lastYear',
      label: '지난 해',
      text: `${format(lastYearStart, 'yyyy.MM.dd')} - ${format(
        lastYearEnd,
        'yyyy.MM.dd'
      )}`,
      value: [lastYearStart, lastYearEnd]
    },
    {
      code: 'thisYear',
      label: '올해',
      text: `${format(thisYearStart, 'yyyy.MM.dd')} - ${format(
        thisYearEnd,
        'yyyy.MM.dd'
      )}`,
      value: [thisYearStart, thisYearEnd]
    }
  ];
}

export function useDoneDateFilterProps() {
  const optionList = createOptionList(new Date()) as any[];
  const defaultOption = optionList.find((e) => e.isDefault) ?? optionList[0];

  const [selectedItem, setSelectedItem] = useState(defaultOption);

  const [dateType, setDateType] = useState('END_DATE');
  const [startDateTime, setStartDateTime] = useState(defaultOption.value[0]);
  const [endDateTime, setEndDateTime] = useState(defaultOption.value[1]);

  const isFilter = useMemo(() => {
    return (
      dateType !== 'END_DATE' ||
      startDateTime?.getTime?.() !== defaultOption.value[0]?.getTime?.() ||
      endDateTime?.getTime?.() !== defaultOption.value[1]?.getTime?.()
    );
  }, [dateType, startDateTime, endDateTime]);

  const reset = () => {
    setSelectedItem(defaultOption);
    setDateType('END_DATE');
    setStartDateTime(defaultOption.value[0]);
    setEndDateTime(defaultOption.value[1]);
  };

  return {
    optionList,
    selectedItem,
    dateType,
    startDateTime,
    endDateTime,
    setSelectedItem,
    setDateType,
    setStartDateTime,
    setEndDateTime,
    isFilter,
    reset
  };
}

const StyledDateFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  z-index: 1051;
  position: relative;

  .date-filter {
    --width: 264px;
    width: var(--width);
    .label--selectOption {
      .selectOption {
        display: none;
      }
    }
    .option {
      width: var(--width);
    }
  }
`;

import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { Fade, Grid, Modal } from '@mui/material';
import { context } from '@src/Context';
import {
  getLanguageLabelByCode,
  sortByLanguage
} from '@src/common/config/Code';
import { IUser } from '@src/common/config/ICode';
import { useFavorites } from '@src/hooks/favorites';
import { useGetTaskAssignWorker } from '@src/hooks/languageTask/getAssignWorker';
import { ModalTheme } from '@src/hooks/modal/Modal.style';
import { useToast } from '@src/hooks/modal/useToast';
import {
  useGetAllProjectAssignerList,
  useGetProjectAssignerList
} from '@src/hooks/project/getAssignList';
import { usePageFunc } from '@src/hooks/utils';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ModalTitleWrap,
  renderLanguageByCode
} from '../dashboard/utils/render';
import { TaskerSearchCss } from '@src/hooks/modal/TaskerSearchBookMark.style';
import { alertWrap } from './JobAssign.style';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPGroup } from '@src/units/group/TPGroup';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { projectKeys } from '@src/hooks/queryKeyFactory';
import { IconBulRight } from '@src/resources/icon';
import TPButtonCheck from '@src/units/button/TPButton.check';
import TPButtonStar from '@src/units/button/TPButton.Star';
import Api from '@src/api/Api';

export const LLAssignPage = () => {
  const { id } = useParams(); // projectId
  const { tmp } = useContext(context);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { favoritesList, onChangeFavorites } = useFavorites(); // Good
  const { sortingLanguagePairList } = usePageFunc();
  const { openHandle } = useToast();

  const { keyword, setKeyword, allProjectAssignerList } =
    useGetAllProjectAssignerList();

  const [searchValue, setSearchValue] = useState(keyword); // 입력폼에 있는 검색어(아직 검색보내기 전)

  const [value, setValue] = useState<any>([]);

  useEffect(() => {
    if (Array.isArray(tmp?.data) && tmp.data.length > 0) {
      setValue(
        _.cloneDeep(
          tmp.data.map((u: any) => ({
            userId: u.userId,
            name: u.name,
            languagePairList: sortingLanguagePairList(u?.languagePairList) ?? []
          })) ?? []
        )
      );
    }
  }, []);

  const renderList = allProjectAssignerList ?? [];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <LLAssignPageWrap css={[TaskerSearchCss, alertWrap]}>
        <TPButtonClose
          onClick={() => {
            navigate(-1);
          }}
        />
        <ModalTitleWrap className="modai--title">담당자 선택</ModalTitleWrap>

        <div className="component">
          <TPInputSearchNew
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="검색"
            clickSearch={() => setKeyword(searchValue)}
          />
          <SelectedUsers users={value} setUsers={setValue} />
          <div className="workers">
            <TPGroup
              label="즐겨찾기"
              open={Boolean(
                Array.isArray(favoritesList) && favoritesList.length
              )}
            >
              {Array.isArray(favoritesList) && favoritesList.length ? (
                favoritesList.map((userId: any, i) => {
                  const user: any = allProjectAssignerList.find(
                    (e) => e.userId === userId
                  );
                  return (
                    user && (
                      <User
                        key={i}
                        user={user}
                        favoritesList={favoritesList}
                        onChangeFavorites={onChangeFavorites}
                        value={value}
                        setValue={setValue}
                      />
                    )
                  );
                  //   : (
                  //   <div key={i}>{`존재하지 않는 유저ID (${userId})`}</div>
                  // );
                })
              ) : (
                <div className="nodata">즐겨찾기한 작업자가 없습니다.</div>
              )}
            </TPGroup>

            {/* 가로선 */}
            <div className="divider" />

            {/* 담당자 선택 */}
            <div className="select-workers">
              {Array.isArray(renderList) && renderList.length ? (
                renderList.map((user: any, i) => (
                  <User
                    key={user.userId ?? 1000000 + i}
                    user={user}
                    favoritesList={favoritesList}
                    onChangeFavorites={onChangeFavorites}
                    value={value}
                    setValue={setValue}
                  />
                ))
              ) : (
                <div className="nodata">검색결과가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
        <div className="button--container">
          <TPButtonBasic
            sx={{ width: 1, height: 42 }}
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
            label="취소하기"
          />
          <TPButtonBasic
            sx={{ width: 1, height: 42 }}
            variant="contained"
            disabled={Boolean(value?.length === 0)}
            onClick={() => {
              navigate(-1);
              // api
              const projectAssignerList = value;
              Api.post('/project/appointProjectAssigner', {
                projectId: id,
                projectAssignerList
              })
                .then(() => {
                  queryClient.invalidateQueries(projectKeys.lists);
                  openHandle({
                    severity: 'success',
                    text: '배정 완료했습니다.'
                  });
                })
                .catch(() => {
                  openHandle({
                    severity: 'error',
                    text: '배정 실패했습니다.'
                  });
                });
            }}
            label="배정하기"
          />
        </div>
      </LLAssignPageWrap>
    </Grid>
    // </Fade>
    //   </Modal>
    // </ThemeProvider>
  );
};

const LLAssignPageWrap = styled.div`
  *::-webkit-scrollbar {
    scrollbar-color: #cbcbcb;
    width: 3px;
    height: 3px;
  }
  *::-webkit-scrollbar-track {
    /* background: #f5f5f5; */
    border-radius: 3px;
  }
  *::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 0.5em;
    border: 3px solid #cbcbcb;
  }

  border-radius: 2px !important;
  width: 756px !important;
  justify-content: space-between;
  padding: 50px 0px 30px !important;

  .close.button.circle {
    position: absolute;
    top: 6px;
    right: 12px;
    --white: #fafafa;
  }

  .component {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    overflow: overlay;

    .input-search {
      margin-left: 10px;
      margin-right: 10px;
      input {
        width: 100%;
      }
    }
  }

  .select-workers {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .workers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    overflow: overlay;
    padding: 0 20px;
    margin-right: 4px;
    height: 499px;
  }

  .modai--title {
    margin-bottom: 64px;
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid #ececec;
  }

  .button--container {
    padding: 0 20px;
  }
`;

function User(props: {
  user: {
    userId: string;
    name: string;
    avatarImgUrl: string;
    languagePairList: { destinationLanguage: string; startLanguage: string }[];
  };
  favoritesList: any;
  onChangeFavorites: any;
  value: any[];
  setValue: (v: any) => void;
}) {
  const {
    user,
    favoritesList,
    onChangeFavorites,
    value = [],
    setValue
  } = props;
  const [isImgError, setIsImgError] = useState(false);
  const selected =
    Array.isArray(value) &&
    value?.find?.((u) => u?.userId === user.userId)?.languagePairList
      ?.length === user?.languagePairList?.length;

  // if (!user) return null;
  // if (!value) return null;
  // if (!favoritesList) return null;

  // TODO 여기 에러 터짐
  let langPairs = useMemo(() => {
    let langPairs =
      Array.isArray(value) &&
      Array.isArray(user?.languagePairList) &&
      user?.languagePairList.length > 0 ? (
        <div className="langPairs">
          {user.languagePairList
            .sort((a, b) => {
              const x = sortByLanguage(a.startLanguage, b.startLanguage);
              if (x === 0) {
                return sortByLanguage(
                  a.destinationLanguage,
                  b.destinationLanguage
                );
              }
              return x;
            })
            .map((item, i) => {
              const selected =
                Array.isArray(value) &&
                value?.find?.(
                  (u) =>
                    u?.userId === user.userId &&
                    Array.isArray(u?.languagePairList) &&
                    u.languagePairList.find(
                      (ulp: any) =>
                        ulp.startLanguage === item.startLanguage &&
                        ulp.destinationLanguage === item.destinationLanguage
                    )
                );
              const handleChange = (e: any) => {
                let o: {
                  userId: any;
                  name: string;
                  languagePairList: any;
                } =
                  Array.isArray(value) &&
                  value.find((o) => o.userId === user.userId);
                if (o) {
                  let lpIndex = o.languagePairList.findIndex(
                    (lp: any) =>
                      lp.startLanguage === item.startLanguage &&
                      lp.destinationLanguage === item.destinationLanguage
                  );
                  if (lpIndex > -1) {
                    o.languagePairList.splice(lpIndex, 1);

                    if (o.languagePairList.length === 0) {
                      setValue([
                        ...value.filter((o: any) => o.userId !== user.userId)
                      ]);
                    } else {
                      setValue([...value]);
                    }
                  } else {
                    o.languagePairList.push(item);
                    setValue([...value]);
                  }
                } else {
                  setValue([
                    ...value,
                    {
                      userId: user.userId,
                      name: user.name,
                      languagePairList: [item]
                    }
                  ]);
                }
              };

              return item?.startLanguage && item?.destinationLanguage ? (
                <div className="langPair" key={i} onClick={handleChange}>
                  <TPButtonCheck status={selected ? 'selected' : 'normal'} />
                  {renderLanguageByCode(item?.startLanguage)}
                  <IconBulRight />
                  {renderLanguageByCode(item?.destinationLanguage)}
                </div>
              ) : null;
            })}
        </div>
      ) : null;
    return langPairs;
  }, [value, user]);

  // element
  const avatar = user ? (
    <div className="AvatarWrap">
      {isImgError ? (
        <div className="no-avatar" />
      ) : (
        <img
          className="avatar"
          src={user?.avatarImgUrl}
          alt="avatar"
          onError={(e) => setIsImgError(true)}
        />
      )}
      <div className="name">{user?.name}</div>
    </div>
  ) : null;

  const handleChangeAll = () => {
    let i = value.findIndex((o) => o.userId === user.userId);
    if (i > -1) {
      // has langPair
      setValue([...value.slice(0, i), ...value.slice(i + 1)]);
    } else {
      setValue([
        ...value,
        {
          userId: user.userId,
          name: user.name,
          languagePairList: [...user.languagePairList]
        }
      ]);
    }
  };

  return (
    <UserWrap>
      <div className="row">
        <TPButtonCheck
          status={selected ? 'selected' : 'normal'}
          onClick={handleChangeAll}
        />
        {avatar}
        <TPButtonStar
          status={favoritesList.includes(user.userId) ? 'selected' : 'normal'}
          onClick={() => {
            onChangeFavorites(favoritesList, user.userId);
          }}
        />
      </div>
      {langPairs}
    </UserWrap>
  );
}

const UserWrap = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .AvatarWrap {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    width: 100%;
    flex-grow: 0;
    padding: 0 8px;
    .avatar {
      --v: 30px;
      width: var(--v);
      height: var(--v);
      border-radius: var(--v);
    }
  }
  .langPairs {
    padding-left: 20px;
    user-select: none;
    cursor: pointer;
    .langPair {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }
  }
`;

// UI 상단에 노출되는 선택된 유저들
function SelectedUsers({ users, setUsers }: any) {
  // TODO 여기 에러 터짐
  const handleChange = (user: any, item: any) => {
    let o: {
      userId: any;
      name: string;
      languagePairList: any;
    } = Array.isArray(users) && users.find((o) => o.userId === user.userId);
    if (o) {
      let lpIndex = o.languagePairList.findIndex(
        (lp: any) =>
          lp.startLanguage === item.startLanguage &&
          lp.destinationLanguage === item.destinationLanguage
      );
      if (lpIndex > -1) {
        o.languagePairList.splice(lpIndex, 1);

        if (o.languagePairList.length === 0) {
          setUsers([...users.filter((o: any) => o.userId !== user.userId)]);
        } else {
          setUsers([...users]);
        }
      } else {
        o.languagePairList.push(item);
        setUsers([...users]);
      }
    } else {
      setUsers([
        ...users,
        {
          userId: user.userId,
          name: user.name,
          languagePairList: [item]
        }
      ]);
    }
  };

  return Array.isArray(users) && users.length > 0 ? (
    <SelevtedUsersWrap>
      {users.map((u: any, i: any) => {
        if (!u) return null;
        const langPairElements =
          Array.isArray(u?.languagePairList) && u?.languagePairList?.length > 0
            ? u.languagePairList.map((ulp: any, i: any) =>
                ulp?.startLanguage && ulp?.destinationLanguage ? (
                  <div className="langPair" key={i}>
                    {renderLanguageByCode(ulp.startLanguage)}
                    <IconBulRight />
                    {renderLanguageByCode(ulp.destinationLanguage)}
                    <TPButtonClose
                      onClick={() => {
                        handleChange(u, ulp);
                      }}
                    />
                  </div>
                ) : null
              )
            : null;

        return (
          <div className="selected-user" key={i}>
            <div className="name">{u.name}</div>
            <div className="langpairs">{langPairElements}</div>
          </div>
        );
      })}
    </SelevtedUsersWrap>
  ) : null;
}
const SelevtedUsersWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;

  padding: 0 20px;
  overflow-y: overlay;
  user-select: none;

  max-height: 166px;

  .selected-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 5px 14px;
    white-space: nowrap;

    background-color: #fafafa;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    .langpairs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    .langPair {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #727272;
      padding: 0 4px;
    }

    .close.button.circle {
      position: relative;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
    }
  }
`;

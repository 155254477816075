import styled from '@emotion/styled';
import {
  STATUS_COLOR,
  TaskStatusSortLevel,
  getTaskStatusLabelColor
} from '@src/common/config/Code';
import { IWidgets } from '@src/common/config/ICode';
import { Outlet } from 'react-router-dom';

export const DashBoard = ({ list }: IWidgets) => {
  const sortedList = Array.isArray(list)
    ? [...list].sort((a, b) => {
        const al =
          TaskStatusSortLevel[a.icon as keyof typeof TaskStatusSortLevel];
        const bl =
          TaskStatusSortLevel[b.icon as keyof typeof TaskStatusSortLevel];
        return typeof al === 'number' && typeof bl === 'number' ? al - bl : 0;
      })
    : [];
  return (
    <DashboardWrap className="DashboardWrap">
      {sortedList.map((el, index) => {
        return (
          <div className="item" key={index}>
            <span
              className="color"
              style={{
                backgroundColor: getTaskStatusLabelColor(
                  el.icon as keyof typeof TaskStatusSortLevel
                )
              }}
            />
            <span className="label" data-status={el.icon}>
              {el.label}
            </span>
            <span className="count">{el.count}</span>
          </div>
        );
      })}
    </DashboardWrap>
  );
};

const DashboardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  color: #2c3558;

  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #ececec;
  padding-top: 7px;
  padding-bottom: 9px;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.25px;

    span.color {
      width: 10px;
      height: 10px;
    }
    span.label {
      font-weight: 400;
    }
    span.count {
      font-weight: 700;
    }
  }
`;

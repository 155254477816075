import { css } from '@emotion/css';
import { cssResetButton } from '../dashboard/utils/css';

export const LanguageWrap = css`
  /* margin: 71px 40px 90px; */
  font-size: 'IBM Plex Sans', sans-serif;
  /* width: minmax(max-content, available); */

  .title--languagePair {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 100%;
    .test {
      width: 100%;
      margin: 0 4px;
      overflow: hidden;
    }
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .language--span--refresh {
    height: 2.8rem;
    top: 1rem;

    display: flex;
    align-items: flex-end;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  .project--dl--info {
    display: flex;
    flex-basis: calc(100% - 200px);
    justify-content: center;
    align-items: center;
    font-weight: 500;
    dd {
      font-size: 0.875rem;
      > button {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 1920px) {
    width: max-content;
  }
`;

import { PMAssignCss } from '@src/pages/modal/PMAssign.style';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconBicClose, IconPencel, IconX } from '@src/resources/icon';
import { TPAvatarBasic } from '@src/units/avatar/TPAvatar.Basic';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import TPButtonCustom from '@src/units/button/TPButton.Custom';
import { TPTooltipBasic } from '@src/units/tooltip/TPTooltip.Basic';
import { useGetProjectList } from '@hooks/project/postList';
import { useTaskerSearch } from '@src/hooks/modal/useTaskerSearch';
import { usePostPmAssign } from '@src/hooks/project/postAssign';
import _ from 'lodash';
import { ModalTitleWrap } from '../dashboard/utils/render';
import { useGetAllPmList } from '@src/hooks/project/getAllPmList';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import TPSelectOnePM from '@src/units/select/TPSelect.one.PM';
import TPButtonClose from '@src/units/button/TPButton.Close';
// import UnderlineTitle from '../components/UnderlineTitle';
// import { useAlert } from '@hooks/modal/useAlert';

/**
 * PM 배정 모달
 * route : /pmAssign/:id
 * auth : PML
 * @return {JSX.Element} PM 배정 모달
 * */
export const PMAssignPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  // const { openHandle: alertHandle } = useAlert();
  const { Component: PMSelectModal, openHandle: openSelectPMModal } =
    useTaskerSearch();
  const { postPmList } = usePostPmAssign();
  const [mainPm, setMainPm] = useState<any>(location.state?.data?.[0]);
  const [subPm, setSubPm] = useState<any>(location.state?.data?.[1]);
  const { projectList } = useGetProjectList();
  const { allPmList } = useGetAllPmList();

  const cancelHandler = () => {
    navigate(-1);
    // alertHandle({
    //   title: '작성 취소',
    //   text: '입력 중인 내용이 모두 삭제됩니다.',
    //   cancelLabel: '취소',
    //   ok: () => {
    //     navigate(-1);
    //   }
    // });
  };

  const okHandler = () => {
    if (mainPm && subPm) {
      const project: any = _.find(projectList, { projectId: Number(id) });
      const pageType =
        project.mainProjectManager.userId && project.subProjectManager.userId;
      postPmList({
        projectId: Number(id),
        mainProjectManagerUserId: mainPm.userId,
        subProjectManagerUserId: subPm.userId,
        pageType: pageType ? 'modify' : 'register'
      });
      navigate(-1);
    }
  };

  const activeValid = (pm: any) => {
    const { status, name } = pm;
    let label = name;
    if (status === 'INACTIVE') {
      label = `${name} (비활성화)`;
    } else if (status === 'LEAVE') {
      label = '삭제된 사용자';
    }
    return label;
  };

  useEffect(() => {
    const project = _.find(projectList, { projectId: Number(id) });
    if (project) {
      if (project.mainProjectManager.userId)
        setMainPm(project.mainProjectManager);
      if (project.subProjectManager.userId) setSubPm(project.subProjectManager);
    }
    return () => {};
  }, [projectList]);

  return (
    <>
      <div css={PMAssignCss}>
        <TPButtonClose className="closeButton" onClick={cancelHandler} />
        <ModalTitleWrap>담당 PM 배정</ModalTitleWrap>

        <div className="selectPmButtonContainer">
          <TPSelectOnePM
            label="메인 PM"
            pmList={allPmList}
            value={mainPm}
            onChange={setMainPm}
          />
          <TPSelectOnePM
            label="서브 PM"
            pmList={allPmList}
            value={subPm}
            onChange={setSubPm}
          />
        </div>
        <div className="buttonContainer">
          <TPButtonBasic
            label="취소하기"
            variant="outlined"
            fullWidth
            onClick={cancelHandler}
          />
          <TPButtonBasic
            disabled={!mainPm || !subPm}
            label="배정하기"
            variant="contained"
            fullWidth
            onClick={okHandler}
          />
        </div>
      </div>
      <PMSelectModal />
    </>
  );
};

import {
  ILanguageSubTask,
  ILanguageTaskList
} from '@src/common/config/ILanguageTask';
import { IMyTaskList } from '@src/common/config/IMyTask';
import { IProjectList } from '@src/common/config/IProject';
import { ITaskList } from '@src/common/config/ITask';
import { IconBulCircleDown, IconBulCircleUp } from '@src/resources/icon';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import React, { useEffect, useRef, useState } from 'react';
import { DashboardSubTaskProgress } from '../SubTask.Progress';
import {
  DataTableColumnHeadStyle,
  DataTableRowDataStyle,
  DataTableStyle,
  NoMessageContainer
} from './DataTable.style';
import {
  LanguageCodeType,
  StatusCodeType,
  WorkerTypeCode
} from '@common/config/Code';
import { AvatarProgressType } from '@units/avatar/TPAvatar.WorkerType';
import { IUser, TASK_TYPE } from '@common/config/ICode';
import _ from 'lodash';

type dataTableCodeType = {
  code: string;
  value: any;
  width: number;
  renderHeader: any;
  renderRowData: any;
};

type AccordionType = 'fullLookup' | undefined;

type DataListType =
  | Partial<IFullLookupDataList>
  | Partial<IProjectList>
  | Partial<ITaskList>
  | Partial<IMyTaskList>
  | Partial<ILanguageTaskList>;

interface IDataTable {
  myInfo?: any;
  columns: readonly dataTableCodeType[];
  dataList: DataListType[];
  clickRowData?: (rowData: any) => void;
  noRowsMessage?: string;
  accordionType?: AccordionType;
  defaultAccordionExposure?: boolean;
  gap?: number;
}

interface IDataTableColumnHead {
  columns: readonly dataTableCodeType[];
  accordionType: AccordionType;
  gap?: number;
}

interface IDataTableRowData {
  myInfo?: any;
  rowEnd: boolean;
  rowData: DataListType;
  columns: readonly dataTableCodeType[];
  accordionType: AccordionType;
  defaultAccordionExposure: boolean;
  onClick: () => void;
  gap?: number;
}

type pmManagerType =
  | [
      { projectManagerType: 'MAIN'; projectManager: IUser },
      { projectManagerType: 'SUB'; projectManager: IUser }
    ]
  | [];

type StringKeyType = { [key in string]: any };

export interface ISubTask extends ILanguageSubTask, StringKeyType {
  languageSubTaskId: number;
  languageSubTaskKey: string;
  languageSubTaskProcess: WorkerTypeCode;
  languageSubTaskWorker: AvatarProgressType[];
  destinationLanguage: LanguageCodeType;
  endDateTime: Date | string;
  hopeSupplyDate: Date | string;
  netAmount: number;
  pmManager: pmManagerType;
  startDate: Date | string;
  startLanguage: LanguageCodeType;
  taskName: string;
  taskType: TASK_TYPE;
}

export interface IFullLookupDataList extends StringKeyType {
  taskId: number;
  taskStatus: StatusCodeType;
  taskKey: string;
  companyName: string; //
  projectName: string;
  taskName: string;
  taskType: TASK_TYPE;
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType; //
  totalGrossAmount: number;
  totalNetAmount: number;
  hopeSupplyDate: string;
  startDate: string;
  endDateTime: string;
  id: number;
  pmManager: pmManagerType;
  tasker: IUser[];
  languageSubTask: ISubTask[] | [];
}

const DataTableColumnHead = (props: IDataTableColumnHead) => {
  const { columns, accordionType, gap } = props;
  return (
    <DataTableColumnHeadStyle gap={gap}>
      {accordionType === 'fullLookup' ? (
        <div className="head--up--down" />
      ) : null}
      {columns.map((el, index) => {
        const width = el.width > 0 ? el.width : null;
        const style = {
          width: `${width}px`
        };

        const renderHeader = el.renderHeader;
        if (renderHeader === 'string')
          return (
            <div className="head" key={index} style={style}>
              <span>{el.value}</span>
            </div>
          );
        return (
          <div className="head" key={index} style={style}>
            {el.renderHeader()}
          </div>
        );
      })}
    </DataTableColumnHeadStyle>
  );
};

const DataTableRowData = (props: IDataTableRowData) => {
  const {
    myInfo,
    rowEnd,
    rowData,
    columns,
    onClick,
    accordionType,
    defaultAccordionExposure,
    gap
  } = props;
  const [openSubRowData, setOpenSubRowData] = useState(
    defaultAccordionExposure
  );
  const [subLineHeightInfo, setSubLineHeightInfo] = useState({
    mainHeight: 0,
    subEndHeight: 0,
    rowTotalHeight: 0
  });

  const refRow = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpenSubRowData(defaultAccordionExposure);
  }, [defaultAccordionExposure]);

  useEffect(() => {
    if (
      accordionType === 'fullLookup' &&
      refRow.current &&
      openSubRowData &&
      rowData.languageSubTask?.length > 0
    ) {
      const refRowSelector = refRow.current;

      const rowTotalHeight = window.getComputedStyle(refRowSelector).height;
      const mainHeight = window.getComputedStyle(
        refRowSelector.childNodes[0] as any
      ).height;
      const subEndHeight = window.getComputedStyle(
        refRowSelector.childNodes[1]?.lastChild! as HTMLDivElement
      ).height;

      setSubLineHeightInfo({
        // 아코디언 펼쳤을때 전체 높이
        rowTotalHeight: Number(rowTotalHeight.split('px')[0]),
        // 작업 rowData 높이
        mainHeight: Number(mainHeight.split('px')[0]),
        // 언어부작업 마지막 rowData 높이
        subEndHeight: Number(subEndHeight.split('px')[0])
      });
    }
  }, [openSubRowData]);

  const subRowDataOpenHandler = () => {
    setOpenSubRowData(!openSubRowData);
  };
  const showSubRowData = accordionType === 'fullLookup' && openSubRowData;

  return (
    <DataTableRowDataStyle
      ref={refRow}
      onClick={onClick}
      rowEnd={rowEnd}
      showSubRowData={showSubRowData}
      gap={gap}
    >
      <div className="main" onClick={subRowDataOpenHandler}>
        {accordionType === 'fullLookup' ? (
          <div className="rowDataBlock--up--down">
            {rowData.languageSubTask?.length > 0 ? (
              openSubRowData ? (
                <IconBulCircleUp width={16} height={16} />
              ) : (
                <IconBulCircleDown width={16} height={16} />
              )
            ) : null}
          </div>
        ) : null}
        {columns.map((el, index) => {
          const data = rowData[el.code];
          const renderRowData: any = el.renderRowData;
          const width = el.width > 0 ? el.width : null;
          const style = {
            width: `${width}px`
          };

          if (renderRowData === 'string') {
            return (
              <div className="rowDataBlock" key={index} style={style}>
                {data}
              </div>
            );
          }

          return (
            <div key={index} className="rowDataBlock" style={style}>
              {renderRowData(data, rowData)}
            </div>
          );
        })}
      </div>
      {showSubRowData ? (
        <div className="sub">
          {rowData.languageSubTask?.map(
            (languageSubTask: ISubTask, index: number) => {
              const languageTaskList = _.find(rowData.languageTaskList, {
                languageSubTaskList: [
                  { languageSubTaskId: languageSubTask.languageSubTaskId }
                ]
              });

              // const isEndSubRow = rowData.languageSubTask.length - 1 === index;
              // const subLineHeight =
              //   rowTotalHeight - mainHeight / 2 - subEndHeight / 2 - 16;
              // const endSubLineStyle = {
              //   height: `${subLineHeight}px`
              // };
              const languageSubTaskKey = languageSubTask.languageSubTaskKey;
              const noneBottomLine =
                rowData.languageTaskList.length - 1 === index && rowEnd;
              return (
                <div
                  key={index}
                  className={`sub--rowData ${
                    noneBottomLine ? 'none--bottom--line' : ''
                  }`}
                >
                  <div className="status--key">
                    <div className={'line diphthong'} />
                    {/* <div
                      className={`line ${isEndSubRow ? 'endSubRow' : ''} `}
                      style={isEndSubRow ? endSubLineStyle : undefined}
                    />*/}

                    <div
                      className={`key ${
                        languageSubTaskKey ? '' : 'not--exist'
                      }`}
                      key={index}
                    >
                      {languageSubTaskKey ? languageSubTaskKey : '배정 필요'}
                    </div>
                  </div>
                  {columns.slice(3).map((el, index) => {
                    let data = languageSubTask[el.code];

                    switch (el.code) {
                      case 'totalNetAmount':
                        data = languageSubTaskKey
                          ? languageSubTask.netAmount
                            ? languageSubTask.netAmount
                            : 0
                          : languageSubTask.taskAmount;
                        break;
                      case 'projectTitle':
                        data = rowData.projectTitle;
                        break;
                      case 'hopeSupplyDateTime':
                        data = rowData.hopeSupplyDateTime;
                        break;
                      case 'startLanguageList':
                        data = languageSubTaskKey
                          ? languageTaskList
                            ? languageTaskList.startLanguage
                            : ''
                          : languageSubTask.startLanguage;
                        break;
                      case 'destinationLanguageList':
                        data = languageSubTaskKey
                          ? languageTaskList
                            ? languageTaskList.destinationLanguage
                            : ''
                          : languageSubTask.destinationLanguage;
                        break;
                      case 'startDateTime':
                        data = rowData.startDateTime;
                        break;
                      case 'endDateTime':
                        data = rowData.endDateTime;
                        break;
                      case 'taskTitle':
                        data = rowData.taskTitle;
                        break;
                      case 'taskType':
                        data = rowData.taskType;
                        break;
                      case 'pmManager':
                        const pmManager = [];
                        if (rowData.mainProjectManager)
                          pmManager.push({
                            projectManager: rowData.mainProjectManager,
                            projectManagerType: 'MAIN'
                          });
                        if (rowData.subProjectManager)
                          pmManager.push({
                            projectManager: rowData.subProjectManager,
                            projectManagerType: 'SUB'
                          });
                        data = pmManager;
                        break;
                    }

                    const renderRowData: any = el.renderRowData;
                    const width = el.width > 0 ? el.width : null;
                    const style = {
                      width: `${width}px`,
                      marginLeft: `
                      ${index === 0 && !accordionType ? '8px' : null}`
                    };

                    if (renderRowData === 'string') {
                      return (
                        <div key={index} className="rowDataBlock" style={style}>
                          {data}
                        </div>
                      );
                    }

                    if (el.code === 'tasker') {
                      return (
                        <div key={index} className="rowDataBlock" style={style}>
                          {languageSubTask.workerList &&
                          languageSubTask.workerList.length > 0 ? (
                            <DashboardSubTaskProgress
                              subTaskProgressInfo={
                                languageSubTask.workerList as any
                              }
                              rowData={rowData as any}
                              myInfo={myInfo}
                              readonly
                            />
                          ) : (
                            <TPButtonBasic
                              label="배정중"
                              size="small"
                              startIcon={null}
                              variant="outlined"
                              disabled={true}
                            />
                          )}
                        </div>
                      );
                    }

                    return (
                      <div key={index} className="rowDataBlock" style={style}>
                        {data ? renderRowData(data, rowData) : '-'}
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </DataTableRowDataStyle>
  );
};

export const DataTableContainer = (props: IDataTable) => {
  const {
    myInfo,
    dataList,
    columns,
    accordionType,
    defaultAccordionExposure,
    gap,
    noRowsMessage,
    clickRowData
  } = props;

  return (
    <DataTableStyle>
      <div className="container">
        <DataTableColumnHead
          gap={gap}
          columns={columns}
          accordionType={accordionType}
        />
        {!!dataList.length ? (
          dataList.map((rowData, index) => {
            return (
              <DataTableRowData
                key={index}
                myInfo={myInfo}
                rowEnd={index === dataList.length - 1}
                rowData={rowData}
                columns={columns}
                accordionType={accordionType}
                defaultAccordionExposure={defaultAccordionExposure as boolean}
                gap={gap}
                onClick={() => {
                  if (clickRowData) clickRowData(rowData);
                }}
              />
            );
          })
        ) : (
          <NoMessageContainer>
            <span>{noRowsMessage}</span>
          </NoMessageContainer>
        )}
      </div>
    </DataTableStyle>
  );
};

const DataTableTaskAll = (props: IDataTable) => {
  const {
    myInfo,
    columns,
    dataList,
    clickRowData,
    noRowsMessage,
    accordionType,
    defaultAccordionExposure = true,
    gap
  } = props;

  return (
    <DataTableContainer
      myInfo={myInfo}
      dataList={dataList}
      columns={columns}
      clickRowData={clickRowData}
      noRowsMessage={noRowsMessage}
      accordionType={accordionType}
      defaultAccordionExposure={defaultAccordionExposure}
      gap={gap}
    />
  );
};

export default DataTableTaskAll;

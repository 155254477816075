import { LANGUAGE, TypeCategory } from '@src/common/config/Code';
import { textSlice } from '@src/common/utils/Func';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import TaskNameViewerComp from '@src/pages/components/TaskNameViewer.Comp';
import { DeadlineCheckDateViewer } from '../../components/DeadlineCheckDateViewer';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import _ from 'lodash';

export const taskAllColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'taskStatus',
      value: '상태',
      width: 64,
      renderHeader: 'string',
      renderRowData: (data: any) => <StatusBadge statusCode={data} />
    },
    {
      code: 'taskKey',
      value: '키',
      width: 100,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 88,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트',
      width: 100,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        if (data) {
          return <TaskNameViewerComp text={data} />;
        }
      }
    },
    {
      code: 'taskTitle',
      value: '작업',
      width: 230,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        if (data) {
          return <TaskNameViewerComp text={data} />;
        }
      }
    },
    {
      code: 'taskType',
      value: '유형',
      width: 55,
      renderHeader: 'string',
      renderRowData: (data: string) => {
        const typeCategory = TypeCategory[data] as string;
        return <span>{typeCategory}</span>;
      }
    },
    {
      code: 'startLanguageList',
      value: '출발어',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'destinationLanguageList',
      value: '도착어',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'totalGrossAmount',
      value: '총 분량(Gross)',
      width: 80,
      renderHeader: 'string',
      renderRowData: (data: any, row: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        if (row.taskType === 'LQA') {
          return (
            <span>
              {Math.floor(Number(data) / 250)}
              시간 {Number(data) % 250 > 0 ? '30' : '00'}분
            </span>
          );
        } else {
          return <span>{taskAmountSetForm}</span>;
        }
      }
    },
    {
      code: 'totalNetAmount',
      value: '총 분량(Net)',
      width: 80,
      renderHeader: 'string',
      renderRowData: (data: any, row: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        if (row.taskType === 'LQA') {
          return (
            <span>
              {Math.floor(Number(data) / 250)}
              시간 {Number(data) % 250 > 0 ? '30' : '00'}분
            </span>
          );
        } else {
          return <span>{taskAmountSetForm}</span>;
        }
      }
    },
    {
      code: 'hopeSupplyDateTime',
      value: '납품일',
      width: 80,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return <DeadlineCheckDateViewer data={data} rowData={rowData} />;
      }
    },
    {
      code: 'startDateTime',
      value: '시작일',
      width: 80,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      renderRowData: (data: any) => {
        return format(new Date(data), 'yy.MM.dd, HH:mm').toLowerCase();
      }
    },
    {
      code: 'endDateTime',
      value: '마감일',
      width: 80,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return <DeadlineCheckDateViewer data={data} rowData={rowData} />;
      }
    },
    {
      code: 'pmManager',
      value: '담당PM',
      width: 70,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        const mainPm = rowData.mainProjectManager;
        const subPm = rowData.subProjectManager;
        const userList = [mainPm, subPm];

        return <TPAvatarGroupNew userList={userList}></TPAvatarGroupNew>;
      }
    },
    {
      code: 'tasker',
      value: '담당자',
      width: 110,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        const deduplicationManagers = data ? [...data] : [];
        deduplicationManagers.sort((a: any, b: any) => {
          if (a.id - b.id > 0) return 1;
          return -1;
        });

        if (deduplicationManagers.length === 0) {
          return (
            <TPButtonBasic
              label="배정중"
              size="small"
              startIcon={null}
              variant="outlined"
              disabled={true}
            />
          );
        }

        return (
          <TPAvatarGroupNew
            userList={_.unionBy(deduplicationManagers, 'userId')}
          ></TPAvatarGroupNew>
        );
      }
    }
  ] as const;

import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import { projectDetailKeys } from '@hooks/queryKeyFactory';
import { IUser } from '@src/common/config/ICode';
import { useHookFunc } from '@hooks/utils';

/**
 * /projectDetail/workUserList
 * @description 프로젝트 상세 작업자 리스트 조회
 * @param {string} projectId
 * @returns {{ taskManagerList }}
 */
export const useGetProjectWorkerList = (projectId: string) => {
  const { defaultUserList } = useHookFunc();

  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof projectDetailKeys)['workerList']>
  >): Promise<IUser[]> => {
    const [, , id] = queryKey;
    return (await api.get(`/project/workUserList?projectId=${id}`)).data;
  };

  const { data = [...defaultUserList] } = useQuery(
    [...projectDetailKeys.workerList(projectId)],
    getData
  );

  const taskManagerList = [...defaultUserList, ...data];

  return { taskManagerList };
};

import React from 'react';
import { Wrap } from '@pages/dashboard/WordLanguage.style';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { IconCalendar, IconInfo, IconRefresh } from '@resources/icon';
import { LanguagePairSelectComp } from '@pages/components/LanguagePairSelect.Comp';
import { Box, Tooltip } from '@mui/material';
import { TPButtonActionCalendar } from '@units/button/TPButton.ActionCalendar';
import { format } from 'date-fns';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { useGetFilter } from '@hooks/searchFilter';
import { usePostLanguage } from '@hooks/statistics/wordAmount/postLanguage';
import DataTable from '@pages/components/DataTable/DataTable';
import { wordLanguageColumns } from '@pages/dashboard/WordLanguage.columns';
import { IWorkerPerWordLastRowTotalAmount } from '@common/config/IStatistics';
import Footer from '@src/layouts/footer/Footer';

const WordLanguagePage = () => {
  const titleDescription = `
    “언어작업” 기준으로 언어 별 단어 수를 확인할 수 있습니다.`;
  const { filterData, Component: FilterButton } = useGetFilter(
    'WORD_AMOUNT_WORD',
    '조회 조건'
  );
  const {
    languagePairList,
    wordAmountLanguageList,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState,
    downloadData,
    fatchData
  } = usePostLanguage(filterData);
  const { setDateFilter, setLanguagePairList, setResetState, setIsSort } =
    setState;
  const { openHandle, Component: Calendar } = useRangeCalendar();
  const { workerPerWordLastRowTotalAmount, workerPerWordAmountList } =
    wordAmountLanguageList;
  const {
    new: totalNew,
    completeAssign,
    progress,
    stop,
    waitDelivery,
    deliveryComplete,
    totalAmount,
    percentage
  } = workerPerWordLastRowTotalAmount as IWorkerPerWordLastRowTotalAmount;

  return (
    <section css={Wrap}>
      <div className="wordlanguage--title">
        <UnderlineTitle text="언어 별 단어 수" align="notCenter" />{' '}
        <Tooltip title={titleDescription} placement="right">
          <IconInfo width={24} height={24} />
        </Tooltip>
      </div>
      <Box
        mt={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <LanguagePairSelectComp
          countItemList={wordAmountLanguageList}
          languagePairList={languagePairList}
          setLanguagePairList={setLanguagePairList}
          description={`조회할 언어페어를 선택해 주세요.`}
          outSideBtn={true}
        />
      </Box>
      <Box
        mb={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'inline-flex'} gap={'8px'}>
          <div
            onClick={() => {
              openHandle('dateRange', dateFilter, setDateFilter);
            }}
          >
            <TPButtonActionCalendar
              outline={true}
              size={'medium'}
              content={
                dateFilter.startDateTime && dateFilter.endDateTime
                  ? `${format(
                      new Date(dateFilter.startDateTime),
                      'yyyy.MM.dd'
                    )} - ${format(
                      new Date(dateFilter.endDateTime),
                      'yyyy.MM.dd'
                    )}`
                  : ''
              }
              icon={<IconCalendar />}
            />
          </div>
          <Calendar />
          {isFilter && (
            <button className="main--button--refresh" onClick={setResetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          <button
            className={'main--button--search'}
            onClick={() => {
              fatchData();
            }}
            disabled={!dateFilter.startDateTime || !dateFilter.endDateTime}
          >
            조회
          </button>
          {/* [언어 별 단어 수] 필터링 저장 */}
          <FilterButton
            getFilterData={{
              dateType,
              dateFilter,
              languagePairList
            }}
          />
        </Box>
        <button
          className={'main--button--download'}
          onClick={() => downloadData()}
        >
          Excel로 내려받기
        </button>
      </Box>
      <Box>
        <dl className={'wordlanguage--list--total'}>
          <dt className={'colspan-2'}>총합</dt>
          <dd>{<span>{Number(totalNew).toLocaleString('en')}</span>}</dd>
          <dd>
            <span>{Number(completeAssign).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(progress).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(stop).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(waitDelivery).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(deliveryComplete).toLocaleString('en')}</span>
          </dd>
          <dd className={'colspan-2'}>
            <span>{Number(totalAmount).toLocaleString('en')}</span>
          </dd>
          <dd>
            <span>{Number(percentage).toLocaleString('en')} %</span>
          </dd>
        </dl>
      </Box>
      <Box m={'1.5rem 0'}>
        <DataTable
          columns={wordLanguageColumns(isSort, setIsSort)}
          dataList={workerPerWordAmountList}
          clickRowData={() => {}}
          noRowsMessage={`조회된 언어 별 단어 수가 없습니다.<br/><br/>"조회 조건"을 설정한 후, "조회"버튼을 눌러<br/>언어 별 단어 수를 확인할 수 있습니다.`}
          gap={10.5}
        />
      </Box>
      <Footer />
    </section>
  );
};

export default WordLanguagePage;

import styled from '@emotion/styled';

interface TPButtonResetProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  border?: boolean;
}

export default function TPButtonReset({
  children,
  ...props
}: TPButtonResetProps) {
  return (
    <STPButtonReset type="button" {...props}>
      초기화
    </STPButtonReset>
  );
}

const STPButtonReset = styled.button<TPButtonResetProps>`
  height: 32px;

  white-space: nowrap;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.25px;
  text-align: left;
  padding: 5px 14px;

  ${({ border }) => `border: ${border ? '1px solid #cbcbcb' : 'none'};`}

  color: #242424;
`;

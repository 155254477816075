const ModalType = {
  ALERT_MODAL: 'alert-modal',
  CONFRIM_MODAL: 'confrim-modal',
  TOAST_MODAL: 'toast-modal',
  TASKERSEARCH_PAGE: 'taskersearch-page',
  ASSIGNSEARCH_PAGE: 'assignsearch-page',
  STATESELECTOR_PAGE: 'stateselector-page',
  CUSTOM_PAGE: 'custom-page',
  JOBREGISTER_PAGE: 'register',
  JOBCOPY_PAGE: 'copy',
  JOBDETAIL_PAGE: 'detail',
  TASKDETAIL_PAGE: 'taskDetail',
  DETAIL_PAGE: 'detail',
  SUBTASKDETAIL_PAGE: 'mytaskDetail',
  JOBMODIFY_PAGE: 'modify',
  JOBASSIGN_PAGE: 'assign',
  PMASSIGN_PAGE: 'pmAssign',
  LLASSIGN_PAGE: 'llAssign'
};

export default ModalType;

import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';

/**
 * /task/status
 * @discription 프로젝트 상세 상태 변경
 * @returns {{ putStatus }}
 */
export const usePutStatus = () => {
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();

  const putData = async (payload: { taskId: string; taskStatus: string }) => {
    return await api.put('/task/status', { ...payload });
  };

  const { mutate: putStatus } = useMutation({
    mutationFn: putData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/projectDetail']);
      toastOpen({ text: '작업 상태가 변경되었습니다.', severity: 'success' });
    },
    onError: () => {
      toastOpen({ text: '작업 상태 변경에 실패했습니다.', severity: 'error' });
    }
  });

  return { putStatus };
};

interface IGlobalKeys {
  [key: string]: readonly string[];
}
export const globalKeys = {
  get authentication() {
    return ['authentication'];
  },
  get alert() {
    return ['alert'];
  },
  get toast() {
    return ['toast'];
  },
  get rangeCalendar() {
    return ['rangeCalendar'];
  },
  get singleCalendar() {
    return ['singleCalendar'];
  },
  get stateChange() {
    return ['stateChange'];
  },
  get taskerSearch() {
    return ['taskerSearch'];
  },
  get taskerSearchBookMark() {
    return ['taskerSearchBookMark'];
  },
  get managerSearch() {
    return ['managerSearch'];
  },
  get languageSelect() {
    return ['languageSelect'];
  },
  get sort() {
    return ['sort'];
  },
  get alarm() {
    return ['alarm'];
  }
};

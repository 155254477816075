import { LANGUAGE, getStatus, getTaskType } from '@common/config/Code';
import { cx } from '@emotion/css';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { useGetFilter } from '@hooks/searchFilter';
import { IconInfoBlack } from '@resources/icon';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { useGetTaskManagerList } from '@src/hooks/task/getManagerList';
import Footer from '@src/layouts/footer/Footer';
import { Wrap } from '@src/pages/dashboard/Dashboard.style';
import { useDashboardFilter } from '@src/pages/dashboard/hooks/useDashboardFilter';
import TPButtonReset from '@src/units/button/TPButton.Reset';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { useMemo, useState } from 'react';
import JobDetailPage from '../modal/JobDetail.page';
import JobRegisterPage from '../modal/JobRegister.page';
import { Row1CategoryList } from './units/Row1CategoryList';
import { Outlet } from 'react-router-dom';
import { useToast } from '@src/hooks/modal/useToast';

const LIST_TOOLTIP_MESSAGE =
  '화면에 노출되는 작업, 고객사, 프로젝트에 대해서 검색 또는<br/> 필터를 적용할 수 있습니다.';

function DashboardPage() {
  const { pmList, taskerList } = useGetTaskManagerList();
  const { filterData, Component: FilterButton } =
    useGetFilter('ALL_TASK_SEARCH');

  const [openedJobModifyPage, setOpenedJobModifyPage] = useState(false);
  const [openedJobRegisterPage, setOpenedJobRegisterPage] = useState(false);
  const [openedJobDetailPage, setOpenedJobDetailPage] = useState(false);

  const {
    tempKeyword,
    setTempKeyword,
    dashboardFilter,
    setDashboardFilter,
    dashboardFilterProxy,
    isFilter
  } = useDashboardFilter(filterData);

  const { Component: Calendar } = useRangeCalendar({
    dateType: 'searchDateRange'
  });

  const propsFilterData = useMemo(() => {
    const d = { ...dashboardFilter } as any;
    delete d.exposure;
    delete d.isFilter;
    for (let k in d) {
      let v = d[k];
      if (Array.isArray(v)) {
        v = v.filter(
          (e: any, index: number) =>
            e !== 'ALL' &&
            e !== '0' &&
            e !== '' &&
            e !== null &&
            e !== undefined
        );
        d[k] = v;
      }
    }
    return d;
  }, [dashboardFilter]);

  const { myInfo, isLoading } = useMyInfo();
  const notHavePermission = !myInfo?.roles?.some?.((e: string) =>
    ['PML', 'PM'].includes(e)
  );

  if (!isLoading && notHavePermission) throw new Error('권한이 없습니다.');

  return (
    <section css={Wrap}>
      <div className="filters">
        <div className="fullLookup--title">
          {/* <UnderlineTitle text="전체 작업 조회" align="notCenter" /> */}
          작업
        </div>
        <div className="fullLookup--list">
          <div className="name">
            <span>{`전체 작업 조회`}</span>
            <SimpleTooltip message={LIST_TOOLTIP_MESSAGE}>
              <IconInfoBlack width={18} height={18} />
            </SimpleTooltip>
          </div>
          <div className="filter--list">
            <div className="left">
              <TPSelectCheck
                label="상태"
                useOptionLabel
                optionList={getStatus([
                  'ALL',
                  'NEW',
                  'COMPLETE_ASSIGN',
                  'PROGRESS',
                  'COMPLETE',
                  'DELIVERY_COMPLETE',
                  'STOP'
                ])}
                selectOptions={dashboardFilter.taskStatus}
                changeSelect={dashboardFilterProxy.setTaskStatus}
              />
              <TPSelectCheck
                label="유형"
                optionList={getTaskType([
                  'ALL',
                  'TRANSLATION',
                  'POLISHING',
                  'LQA'
                ])}
                selectOptions={dashboardFilter.taskType}
                changeSelect={dashboardFilterProxy.setTaskType}
              />
              <TPSelectCheck
                label="출발어"
                optionList={LANGUAGE.filter((item: any) => item?.code !== '')}
                useOptionLabel
                selectOptions={dashboardFilter.startLanguageList}
                changeSelect={dashboardFilterProxy.setStartLanguageList}
              />
              <TPSelectCheck
                label="도착어"
                optionList={LANGUAGE.filter((item: any) => item?.code !== '')}
                useOptionLabel
                selectOptions={dashboardFilter.destinationLanguageList}
                changeSelect={dashboardFilterProxy.setDestinationLanguageList}
              />
              <TPSelectCheckManager
                label="담당 PM"
                PMList={pmList}
                showAvatar={true}
                selectOptions={dashboardFilter.projectManagerUserIdList}
                changeSelect={dashboardFilterProxy.setProjectManagerUserIdList}
              />
              <TPSelectCheckManager
                label="작업자"
                PMList={taskerList}
                showAvatar={true}
                selectOptions={dashboardFilter.workUserIdList}
                changeSelect={dashboardFilterProxy.setWorkUserIdList}
              />
              {isFilter && (
                <TPButtonReset
                  onClick={() => dashboardFilterProxy.clearFilter()}
                  border
                />
              )}
              <FilterButton
                getFilterData={{
                  dashboardFilter: (() => {
                    const { keyword, ...other } = dashboardFilter;
                    return other;
                  })()
                }}
              />
            </div>
            <div className="right">
              <TPInputSearchNew
                value={tempKeyword}
                placeholder="검색"
                onChange={dashboardFilterProxy.handleChangeKeyword}
                clickSearch={dashboardFilterProxy.setKeyword}
                width={240}
              />
            </div>
          </div>
        </div>
      </div>
      {/* List */}
      <div className="content-list">
        <Row1CategoryList
          {...{
            isFilter: isFilter,
            filterData: propsFilterData,
            setOpenedJobModifyPage,
            setOpenedJobRegisterPage,
            setOpenedJobDetailPage
          }}
        />
        <Footer />
      </div>

      {/*
        기존 여러 모달창이 라우트와 연동되어 있어 값 전달이 필요하다.
        수정하기는 taskId를 필요로 한다.
        복사하기는 
      */}
      <div className="modals">
        <Calendar />
        {/* <WorkPeriodCalendar /> */}
        <div
          data-title="복사하기"
          className={cx({
            mask: true,
            open: openedJobRegisterPage
          })}
        >
          {window.history.state.projectId && window.history.state.taskId && (
            <JobRegisterPage {...{ setOpenedJobRegisterPage }} />
          )}
        </div>
        <div
          data-title="수정하기"
          className={cx({
            mask: true,
            open: openedJobModifyPage
          })}
        >
          {window.history.state.taskId && (
            <JobRegisterPage {...{ setOpenedJobModifyPage }} />
          )}
        </div>
        <div
          data-title="디테일 조회하기"
          className={cx({
            mask: true,
            open: openedJobDetailPage
          })}
        >
          {window.history.state.projectId && window.history.state.taskId && (
            <JobDetailPage
              {...{ setOpenedJobDetailPage, setOpenedJobModifyPage }}
            />
          )}
        </div>
      </div>
      <Outlet />
    </section>
  );
}

export default DashboardPage;

import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { IManagerList } from '@src/common/config/ICode';
import { languageTaskKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';
import { LanguagePairType } from '@src/common/config/Code';
import { useEffect, useState } from 'react';
import { useMyInfo } from '../myInfo/getMyInfo';

/**
 * /languageTask/managerList
 * @description 담당 PM, 담당자 리스트 조회
 * @returns {{ taskerList }}
 */
export const useGetWorkerList = (myInfo: any) => {
  const { defaultUserList } = useHookFunc();
  const [languagePairList, setLanguagePairList] = useState(
    myInfo?.languagePairList ?? []
  );

  const getData = async (): Promise<IManagerList> => {
    return (await api.post(`/languageTask/managerList`, { languagePairList }))
      .data;
  };

  const {
    data = {
      pmList: [...defaultUserList],
      taskerList: [...defaultUserList]
    },
    refetch
  } = useQuery([...languageTaskKeys.workerList], getData, {
    select: (data) => ({
      pmList: [...defaultUserList, ...data.projectManagerUserDataList],
      taskerList: [...defaultUserList, ...data.workUserDataList]
    })
  });

  useEffect(() => {
    refetch();
  }, [languagePairList, myInfo]);

  const { pmList, taskerList } = data;

  return { pmList, taskerList, refetch };
};

import { ReactElement } from 'react';
import { TPH2, TPH3 } from './TPHeadline.Theme';

interface IHeadlineProps {
  display?: 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex';
  type?: 'h2' | 'h3' | undefined;
  decoration?: boolean;
  children: ReactElement | ReactElement[] | string;
  textAlign?: 'left' | 'center' | 'right';
}

export const TPHeadlineBasic = (props: IHeadlineProps) => {
  const {
    display = 'inline-block',
    type = 'h2',
    textAlign = 'center',
    children,
    decoration
  } = props;
  let headline =
    type === 'h3' ? (
      <TPH3
        className={decoration ? 'decoration' : ''}
        style={{ display: display, textAlign: textAlign }}
      >
        <span>{children}</span>
      </TPH3>
    ) : (
      <TPH2
        className={decoration ? 'decoration' : ''}
        style={{ display: display, textAlign: textAlign }}
      >
        <span>{children}</span>
      </TPH2>
    );
  return headline;
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { SortType } from '@src/common/utils/makeSortedList';
import {
  IconInfo,
  IconSortDown,
  IconSortUp,
  IconSortX
} from '@src/resources/icon';
import { HTMLAttributes, useEffect, useState } from 'react';
import { SortButton } from '@src/pages/components/SortButton';
import { extend } from 'lodash';

type ShowTooltipType =
  | 'AmountGross'
  | 'AmountNET'
  | 'AmountLanguageTotal'
  | 'AmountLanguageRatio';

interface IColumnHeadCompProps extends HTMLAttributes<HTMLDivElement> {
  title: any;
  field: string;
  isSort?: { code: string; isAsc: boolean };
  sortType: SortType;
  setIsSort?: any;
  showTooltip?: ShowTooltipType;
  count?: number;
  css?: any;
}

export default function ColumnHeadComp(props: IColumnHeadCompProps) {
  const {
    isSort,
    setIsSort,
    title,
    field,
    sortType,
    showTooltip,
    count,
    css,
    ...otherProps
  } = props;
  const [showSortButton, setShowSortButton] = useState(isSort?.code === field);
  const buttonActiveState = isSort?.code === field;
  const sortByAscending = isSort?.isAsc ?? false;

  useEffect(() => {}, [isSort]);
  const sortHandler = () => {
    setIsSort &&
      setIsSort((data: any) => ({
        code: field,
        isAsc: !data.isAsc
      }));
  };
  const sortResetHandler = () => {
    setIsSort &&
      setIsSort((data: any) => ({
        code: '',
        isAsc: false
      }));
  };
  const mouseOverHandler = () => setShowSortButton(true);
  const mouseLeaveHandler = () => setShowSortButton(false);

  let message = '';
  switch (showTooltip) {
    case 'AmountGross':
      message = '입력한 매치율 별 단어 수 총합이 자동 계산됩니다.';
      break;
    case 'AmountNET':
      message =
        '입력한 매치율 별 단어 수가 각 배율에 따라 자동</br> 계산됩니다. 소수점 첫째 자리에서 반올림됩니다.</br></br>[NET의 각 매치율 별 배율]</br>101% = 0</br>Rep  = 0.1</br>100% = 0.1</br>95%~99% = 0.7</br>85%~94% = 0.7</br>75%~84% = 0.7</br>50%~74% = 1.0</br>No match = 1.0';
      break;
    case 'AmountLanguageTotal':
      message =
        '각 언어페어 별 총합(Net) 수치를 확인할 수 있습니다.<br/>“중지(Net)”은 합산 대상이 아닙니다.';
      break;
    case 'AmountLanguageRatio':
      message =
        '각 언어페어 별 총합(Net) 비율을 확인할 수 있습니다.<br/>비율은 현재 조회 중인 데이터 기준으로 표시됩니다.';
      break;
    default:
      break;
  }

  if (sortType === 'unable') return <Wrap>{title}</Wrap>;
  return (
    <Wrap {...otherProps}>
      {/* <TitleStyle
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        <span
          className="title"
          {...(typeof title === 'string'
            ? {
                dangerouslySetInnerHTML: { __html: title }
              }
            : {
                children: title
              })}
        />
        {showSortButton || buttonActiveState ? (
          <SortButton
            buttonActiveState={buttonActiveState}
            sortHandler={sortHandler}
            sortResetHandler={sortResetHandler}
            sortByAscending={sortByAscending}
            thisColumn={field}
          />
        ) : null}
      </TitleStyle>
      {typeof count === 'number' && !isNaN(count) && (
        <span className="count">{count}</span>
      )}
      {showTooltip ? (
        <Tooltip title={<p dangerouslySetInnerHTML={{ __html: message }} />}>
          <IconInfo width={20} height={20} />
        </Tooltip>
      ) : null} */}
      <SortButton
        css={css}
        show={isSort?.code === field}
        onChange={(v) => {
          if (v) {
            setIsSort({ code: field, isAsc: v === 'up' });
          } else {
            setIsSort({ code: '', isAsc: false });
          }
        }}
      >
        <span
          className="title"
          {...(typeof title === 'string'
            ? {
                dangerouslySetInnerHTML: { __html: title }
              }
            : {
                children: title
              })}
        />
      </SortButton>
      {typeof count === 'number' && !isNaN(count) && (
        <span className="count">{count}</span>
      )}
      {showTooltip ? (
        <Tooltip title={<p dangerouslySetInnerHTML={{ __html: message }} />}>
          <IconInfo width={20} height={20} />
        </Tooltip>
      ) : null}
    </Wrap>
  );
}

const Wrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;

  /* 
  display: flex;
  align-items: center;
  justify-content: center;
   */
  user-select: none;
  margin: auto;
  white-space: nowrap;

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: center;
    color: #242424;
  }
  .count {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
    margin-left: 8px;
  }
`;

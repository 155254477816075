import { useQuery } from '@tanstack/react-query';
import api from '@api/Api';
import { IUser } from '@src/common/config/ICode';
import { projectKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';

/**
 * /project/projectManagerList
 * @discription 프로젝트 매니저 목록 조회
 * @returns {{ pmList }}
 */
export const useGetPmList = () => {
  const { defaultUserList } = useHookFunc();

  const getData = async (): Promise<IUser[]> => {
    return (await api.get('/project/projectManagerList')).data
      .projectManagerUserDataList;
  };

  const { data = defaultUserList } = useQuery([...projectKeys.pmList], getData);

  const pmList = [
    ...defaultUserList,
    ...data.filter((e: any) => e?.userId !== null && e?.status === 'ACTIVE')
  ];
  // ACTIVE 상태인 유저만 필터링

  return { pmList };
};

import { usePutAuthentication } from '@src/hooks/auth/putAuth';
import { PrivacyCss } from '@src/pages/policy/Privacy.style';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';

function PrivacyPage() {
  const { putAuth, signOut } = usePutAuthentication();

  const cancelHandler = () => {
    void signOut();
  };

  const okHandler = () => {
    void putAuth();
  };

  return (
    <>
      <div css={PrivacyCss}>
        {/* <IconBicClose
          className="closeButton"
          width={40}
          height={40}
          onClick={cancelHandler}
        /> */}
        <div className="title">
          <span>개인정보 처리방침 동의</span>
        </div>
        <div className="content">
          {/* <p>
            1. 개인정보의 수집항목 및 수집방법 BoraCAT에서 회원 가입과 관련하여
            아래와 같이 귀하의 개인정보를 수집·이용 하고자 합니다. 내용을 자세히
            읽어 보신 후 동의 여부를 결정해 주시기 바랍니다. 가. 개인정보 수집
            및 이용 동의
          </p>
          <table>
            <thead>
              <tr>
                <th>항목</th>
                <th>목적</th>
                <th>보유 및 이용기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>성명</p>
                  <p>이메일</p>
                  <p> 휴대전화번호</p>
                </td>
                <td>
                  <p>BoraCAT 서비스 이용</p>
                  <p>회원관리</p>
                  <p>부정 이용 방지</p>
                  <p>민원 신청 및 처리 등</p>
                </td>
                <td>회원 탈퇴시 까지</td>
              </tr>
            </tbody>
          </table>
          <p>
            개인정보의 수집·이용목적에 대한 동의를 거부할 수 있으며, 동의 거부시
            BoraCAT에 회원가입이 되지 않으며 BoraCAT에서 제공하는 서비스를
            이용할 수 없습니다.
          </p>
          <p>위 개인정보를 수집·이용 하는것에 동의하십니까?</p> */}
          {`
투바이트 주식회사 (이하 '회사')는 귀하의 개인정보를 중요시하며 『개인정보 보호법』 및 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 관련 법령상의 개인정보 보호 규정 및 가이드라인을 준수하고 있습니다. 개인정보처리방침을 통해 정보주체가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

1. 수집하는 개인정보의 항목
BoraCAT 홈페이지 이용을 위해 필요한 최소한의 범위로 개인정보를 수집합니다.
1)회원 가입
  - 이름, 휴대전화번호, 이메일

2. 개인정보의 수집 및 이용 목적
BoraCAT에서는 다음의 목적을 위하여 최소한의 개인정보를 수집 및 이용 하게 됩니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는「개인정보 보호법」제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1)회원 가입
  - 홈페이지 서비스 이용 및 회원관리, 불량회원의 부정 이용방지, 민원신청 및 처리 등

3. 개인정보의 제3자 제공
회사는 귀하의 개인정보를 2. 개인정보의 수집 및 이용 목적에서 고지한 범위 내에서 사용하며, 정보주체의 동의, 법률의 특별한 규정 등 관계 법령 상 허용된 경우 이외에는 개인정보를 제3자에게 제공하지 않습니다.
		
4. 개인정보의 보유 및 이용기간
회사는 개인정보를 수집 시에 동의받은 보유·이용 기간 내에서 개인정보를 처리 및 보유하며, 원칙적으로 개인정보의 처리 목적이 달성되면 지체없이 파기합니다. 정보주체가 명시적으로 파기 요청하는 경우, 지체 없이 해당 정보를 파기합니다. 다만, 관계 법령에 따라 특별히 보관해야 하는 개인정보가 수집될 경우, 해당 법령에서 규정한 기간 동안 개인정보를 보관합니다.

1)회원 가입
  - 회원 가입시 부터 회원 탈퇴시 까지
  
5. 개인정보의 파기
회사는 원칙적으로 개인정보 보유 기간의 경과 또는 처리 목적의 달성 등 개인정보가 불필요하게 되었을 때는 지체없이 해당 개인정보를 파기합니다.
정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
개인정보의 파기 절차 및 방법은 다음과 같습니다.
- 파기 절차 : 귀하의 개인정보는 목적이 달성된 후 지체없이 파기됩니다. 단, 관계 법령에 의해 보관되어야 하는 개인정보의 경우 별도로 분리되어 보관됩니다. 해당 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
- 파기 방법 : 종이 문서에 기록∙저장된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기하며, 전자적 파일 형태로 기록∙저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 이용하여 파기합니다.

6. 정보체와 법정대리인의 권리 및 행사방법
귀하는 언제든지 등록된 귀하의 개인정보의 열람, 정정, 삭제, 처리정지를 요청하실 수 있습니다. 이 경우, 개인정보보호 책임자 또는 개인정보 고충처리직군에 서면, 전화 또는 이메일로 연락하시면 확인 후 지체없이 조치하겠습니다.

7. 개인정보 자동수집장치의 설치, 운영 및 그 거부에 관한 사항
1) 웹로그 분석
회사는 외부 웹로그 분석 도구인 Google Analytics를 사용합니다.
[Google Analytics 차단 방법]
- https://tools.google.com/dlpage/gaoptout/

8. 개인정보의 안전성 확보 조치
회사는 개인정보의 안전성 확보를 위해 다음과 같은 관리적, 기술적 및 물리적 조치를 취하고 있습니다.
1) 관리적 대책
- 개인정보취급자의 지정을 최소화하고 있습니다.
- 개인정보보호 업무를 전담하는 직군에서 개인정보처리방침을 수립하여 관리하고 있습니다. 또한 내부 규정 준수 여부를 정기적으로 확인하여 문제가 발견될 경우, 지체없이 바로잡을 수 있도록 노력하고 있습니다.
2) 기술적 대책
- 개인정보는 암호화 통신을 이용하여 전송되며, 저장된 정보는 제한된 장소에서 제한된 사용자만이 강화된 인증을 통하여 접근할 수 있습니다.
- 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하고 있습니다.
- 회사는 개인정보를 처리할 수 있도록 체계적으로 구성된 데이터베이스 시스템에 필요한 안전성 확보 조치를 다하고 있습니다.
3) 물리적 대책
- 개인정보를 처리하는 데이터베이스 시스템에 대한 접근 권한 관리를 통해 개인정보에 대한 접근을 통제하고, 강력한 접근제한으로 무단 접근을 통제하고 있습니다.

9. 개인정보 보호책임자 및 권익침해 구제 방법
회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

개인정보와 관련한 문의사항 및 의견사항, 홈페이지 이용 중 개인정보의 유출 가능성 등 정보주체의 권익이 침해될 우려가 있는 사실을 발견하였을 경우에는 아래의 개인정보 보호책임자 또는 담당자에게 연락해주시면 즉시 조치하여 처리결과를 통보하겠습니다.

※ 개인정보 보호책임자
성명 : 함영철 (대표이사)
전화번호 : 031-423-0758
이메일주소 : 2bytescorp@2bytescorp.com

※ 개인정보 보호 담당 직군
직군 : 인프라기술팀
전화번호 : 031-423-0758
이메일주소 : security@2bytescorp.com

또한, 개인정보 침해로 인한 피해구제, 상담 등을 위해 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 문의하실 수 있습니다.
개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)
개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
경찰청 사이버안전지킴이 : (국번없이) 182 (cyberbureau.police.go.kr)

10. 개정 전 고지 의무
본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 사전 고지를 할 것입니다.
- 고지일자 : 2023년 00월 00일
- 시행일자 : 2023년 00월 00+7일
          `}
        </div>
        <div className="buttonContainer">
          <TPButtonBasic
            label="동의하지 않습니다."
            variant="outlined"
            fullWidth
            onClick={cancelHandler}
          />
          <TPButtonBasic
            // disabled={!(mainPm && subPm)}
            label="개인정보 처리방침에 동의하고 서비스를 계속 이용합니다."
            variant="contained"
            fullWidth
            onClick={okHandler}
          />
        </div>
      </div>
      {/* Modal */}

      {/* <TaskerSearch /> */}
    </>
  );
}

export default PrivacyPage;

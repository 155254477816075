import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import api from '@api/Api';
import { favoritesKey } from '@hooks/queryKeyFactory';
import { useState } from 'react';

/**
 * /favorites
 * @description 페이지 리스트 필터 정보 조회
 * @returns {{ favoritesList, onChangeFavorites }}
 */
export const useFavorites = () => {
  const getData = async (): Promise<number[]> =>
    (await api.get(`/favorites`)).data.favoritesUserIdList;

  const { data: favoritesList = [], refetch: refetchFavorites } = useQuery(
    [favoritesKey.default],
    getData
  );

  const queryClient = useQueryClient();

  const postData = async (userId: number) => {
    return await api.post(`/favorites`, { favoritesUserId: userId });
  };

  const deleteData = async (userId: number) => {
    return await api.delete(`/favorites?favoritesUserId=${userId}`);
  };

  const { mutate: postFavorites } = useMutation({
    mutationFn: postData,
    onSuccess: (variables) => {
      queryClient.invalidateQueries([favoritesKey.default]);
    }
  });

  const { mutate: deleteFavorites } = useMutation({
    mutationFn: deleteData,
    onSuccess: (variables) => {
      queryClient.invalidateQueries([favoritesKey.default]);
    }
  });

  const onChangeFavorites = (favoritesList: number[], userId: number) => {
    if (_.find(favoritesList, (id) => id === userId)) {
      deleteFavorites(userId);
    } else {
      postFavorites(userId);
    }
  };

  return { favoritesList, onChangeFavorites, refetchFavorites };
};

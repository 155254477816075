import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryFunctionContext } from '@tanstack/query-core';
import _ from 'lodash';
import api from '@api/Api';
import { ILanguageTask } from '@src/common/config/IProjectDetail';
import { wordAmountKeys } from '@hooks/queryKeyFactory';
import { IWordAmountLanguage } from '@common/config/IStatistics';
import { usePageFunc } from '@src/hooks/utils';
import download from '@src/hooks/utils/download';
import { sortByLanguage } from '@src/common/config/Code';

/**
 * /statistics/languagePerWordAmount
 * @description 통계 > 언어 별 단어 수
 * @param filterData
 */
export const usePostLanguage = (filterData: any) => {
  const { getDateTime } = usePageFunc();

  //#region state define
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [dateType, setDateType] = useState<string>('START_DATE');
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    startDateTime: undefined,
    endDateTime: undefined
  });
  const [fetchLanguagePairList, setFetchLanguagePairList] = useState<
    ILanguageTask[]
  >([]);
  const [fetchDateFilter, setFetchDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({ startDateTime: undefined, endDateTime: undefined });
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  useEffect(() => {
    if (!filterData) return;
    setLanguagePairList(filterData?.languagePairList ?? []);
    setDateType(filterData?.dateType ?? 'START_DATE');
    setDateFilter(
      filterData?.dateFilter ?? {
        startDateTime: undefined,
        endDateTime: undefined
      }
    );
  }, [filterData]);
  useEffect(() => {}, [dateFilter]);

  //#region setState
  const setState = {
    setDateType,
    setDateFilter,
    setLanguagePairList,
    setResetState: () => {
      setLanguagePairList([]);
      setFetchLanguagePairList([]);
      setDateType('START_DATE');
      setFetchDateFilter({ startDateTime: undefined, endDateTime: undefined });
      setDateFilter({ startDateTime: undefined, endDateTime: undefined });
      setIsFilter(false);
      remove();
    },
    setIsSort
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    languagePairList,
    dateType,
    dateFilter
  }: {
    languagePairList: ILanguageTask[];
    dateType: string;
    dateFilter: { [key: string]: any };
  }): object => {
    const { startDateTime, endDateTime } = dateFilter;
    const getLanguagePairList =
      !_.some(languagePairList, { startLanguage: 'ALL' }) &&
      languagePairList.length
        ? { languagePairList }
        : undefined;
    return {
      ...(getLanguagePairList && getLanguagePairList),
      ...(dateType !== 'ALL' &&
        startDateTime &&
        endDateTime && {
          dateType,
          startDateTime: getDateTime({
            date: new Date(startDateTime),
            filter: 'start'
          }),
          endDateTime: getDateTime({
            date: new Date(endDateTime),
            filter: 'end'
          })
        })
    };
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof wordAmountKeys)['languagelist']>
  >): Promise<IWordAmountLanguage> => {
    const [, filters] = queryKey;
    const getPayload = setPayload(filters);

    setIsFilter(Object.keys(getPayload).length !== 0);

    return await api
      .postPms('/statistics/languagePerWordAmount', { ...getPayload })
      .then((res) => res.data);
  };

  const fatchData = async () => {
    await setFetchLanguagePairList(languagePairList);
    await setFetchDateFilter(dateFilter);
    await refetch();
  };

  const downloadData = async () => {
    return await api
      .postPms(
        '/statistics/languagePerWordAmount/excel',
        {
          languagePairList: fetchLanguagePairList,
          dateType,
          dateFilter: {
            startDateTime: getDateTime({
              date: new Date(fetchDateFilter.startDateTime as any),
              filter: 'start'
            }),
            endDateTime: getDateTime({
              date: new Date(fetchDateFilter.startDateTime as any),
              filter: 'end'
            })
          }
        },
        { responseType: 'blob' }
      )
      .then((res) => {
        download(res, 'languagePerWordAmountStatistics.xls'); // POST
      });
  };
  //#endregion

  //#region useQuery define
  const {
    isLoading,
    isError,
    data: wordAmountLanguageList = {
      workerPerWordLastRowTotalAmount: {
        new: 0,
        completeAssign: 0,
        progress: 0,
        stop: 0,
        waitDelivery: 0,
        deliveryComplete: 0,
        totalAmount: 0,
        percentage: 0
      },
      workerPerWordAmountList: []
    },
    refetch,
    remove
  } = useQuery(
    [
      ...wordAmountKeys.languagelist({
        languagePairList: fetchLanguagePairList,
        dateType,
        dateFilter: fetchDateFilter
      })
    ],
    getData,
    {
      select: (data) => {
        return {
          workerPerWordLastRowTotalAmount: {
            ...data.workerPerWordLastRowTotalAmount
          },
          workerPerWordAmountList: isSort.code.length
            ? [
                ..._.orderBy(
                  data.workerPerWordAmountList,
                  [isSort.code],
                  [isSort.isAsc ? 'asc' : 'desc']
                )
              ]
            : data.workerPerWordAmountList.sort((a, b) => {
                let i = sortByLanguage(a.startLanguage, b.startLanguage);
                if (i === 0) {
                  i = sortByLanguage(a.endLanguage, b.endLanguage);
                }
                return i;
              }) // 디폹트로 언어순서 정렬
        };
      }
    }
  );
  //#endregion

  return {
    isLoading,
    isError,
    languagePairList,
    wordAmountLanguageList,
    fatchData,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState,
    downloadData
  };
};

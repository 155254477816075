import { useState, useRef, useEffect } from 'react';
import { IconCircleClose, IconNextArrow } from '@resources/icon';
import {
  LanguagePairTapStyle,
  LanguagePairSelectStyle
} from './LanguagePairSelect.Style';
import TPButtonCustom from '@src/units/button/TPButton.Custom';
import { useManagerSearch } from '@hooks/modal/useManagerSearch';
import { IUser } from '@common/config/ICode';
import _ from 'lodash';

interface IManagerSelectProps {
  workerUserList: IUser[];
  setWorkerUserList: any;
  description?: string;
  outSideBtn?: boolean;
  disabled?: boolean;
  isSelectAll?: boolean;
}

export const ManagerSelectComp = ({
  workerUserList,
  setWorkerUserList,
  description,
  outSideBtn,
  disabled = false,
  isSelectAll = false
}: IManagerSelectProps) => {
  const { openHandle, Component, open } = useManagerSearch({
    setWorkerUserList,
    description,
    isSelectAll
  });

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const outSideBtnRef = useRef<HTMLDivElement>(null);
  const [prev, setPrev] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);
  const [scrollX, setScrollX] = useState<number>(0);

  useEffect(() => {
    const parentWidth = parentRef.current?.clientWidth || 0;
    const childWidth = childRef.current?.scrollWidth || 0;
    const outSideBtnWidth = outSideBtnRef.current?.clientWidth || 0;

    if (parentWidth > 1750) {
      if (scrollX === 0) {
        setPrev(false);
        setNext(true);
      }
      if (scrollX > 0 && scrollX < childWidth - parentWidth) {
        setPrev(true);
        setNext(true);
      }
      const comparisonWidth = outSideBtn
        ? childWidth - parentWidth + outSideBtnWidth
        : childWidth - parentWidth;
      if (scrollX === comparisonWidth) {
        setPrev(true);
        setNext(false);
      }
    } else {
      setPrev(false);
      setNext(false);
    }
  }, [scrollX, workerUserList]);

  const scroll = (e: any) => {
    const { scrollLeft } = e.target;
    setScrollX(scrollLeft);
  };

  const prevHandle = () => {
    const parentWidth = parentRef.current?.clientWidth || 0;
    childRef.current?.scrollTo({
      left: scrollX - parentWidth,
      behavior: 'smooth'
    });
  };
  const nextHandle = () => {
    const parentWidth = parentRef.current?.clientWidth || 0;
    childRef.current?.scrollTo({
      left: scrollX + parentWidth,
      behavior: 'smooth'
    });
  };

  const removePair = (item: any) => {
    const removeList = _.filter(workerUserList, (i) => {
      return !(i.userId === item.userId);
    });
    setWorkerUserList(removeList);
  };

  return (
    <div
      css={LanguagePairSelectStyle}
      className={outSideBtn ? 'outSideContainer' : ''}
      ref={parentRef}
    >
      {outSideBtn ? (
        <div className="innerContainer outSideBtn_custom" ref={outSideBtnRef}>
          <TPButtonCustom
            onClick={() => openHandle()}
            label="담당자 선택"
            padding="11px 20px"
            fontSize="16px"
            height="48px"
            shadow="none"
            border="1px solid #EEEDF4"
            hoverType="black"
            disabled={disabled}
          />
        </div>
      ) : null}
      <div className="innerContainer" ref={childRef} onScroll={scroll}>
        {prev ? (
          <div className="arrow--shadow left">
            <div className="arrow" onClick={prevHandle}>
              <IconNextArrow fill="#646782" />
            </div>
            <div className="shadow"></div>
          </div>
        ) : null}

        {next ? (
          <div className="arrow--shadow right">
            <div className="arrow" onClick={nextHandle}>
              <IconNextArrow fill="#646782" />
            </div>
            <div className="shadow"></div>
          </div>
        ) : null}

        {outSideBtn ? null : (
          <div>
            <TPButtonCustom
              onClick={() => openHandle()}
              label="담당자 선택하기"
              padding="11px 20px"
              fontSize="16px"
              height="48px"
              shadow="none"
              border="1px solid #EEEDF4"
              hoverType="black"
            />
          </div>
        )}

        {!!workerUserList.length ? (
          _.map(workerUserList, (item: any, index: number) => {
            return (
              <div key={index}>
                <div css={LanguagePairTapStyle}>
                  <span className="label">{item.name}</span>
                  <IconCircleClose
                    className="remove"
                    onClick={() => {
                      removePair(item);
                    }}
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <TPButtonCustom
            label={isSelectAll ? '선택' : '전체'}
            shadow="none"
            hoverType="none"
          />
        )}
      </div>
      {open && <Component workerUserList={workerUserList} />}
    </div>
  );
};

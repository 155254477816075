import React from 'react';

export function Arrow() {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.00008L5.24264 4.75744M1 1.36011L5.24264 5.60275"
        stroke="#CBCBCB"
        strokeWidth="1.2"
      />
    </svg>
  );
}

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ArrowBubble, TooltipTailLeft } from '@src/resources/etc';
import React, { ReactElement, useState } from 'react';

type PositionType = 'top' | 'center' | 'bottom';
interface ISimpleTooltip {
  message: string;
  children: string | ReactElement;
  position?: PositionType;
  tooltipWidth?: string;
  defaultShow?: boolean;
}

export const SimpleTooltip = (props: ISimpleTooltip) => {
  const { message, children, position, tooltipWidth } = props;
  const [showTooltip, setShowTooltip] = useState(Boolean(props?.defaultShow));
  return (
    <Wrap
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      showTooltip={showTooltip}
      position={position}
    >
      <div className="childrenWrap">{children}</div>
      {showTooltip ? (
        <div className="tooltipWrap">
          <Tooltip tooltipWidth={tooltipWidth}>
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </Tooltip>
        </div>
      ) : null}
    </Wrap>
  );
};

interface IWrap {
  showTooltip: boolean;
  position?: PositionType;
}
const slowView = keyframes`
  0%{opacity:0}
  100%{opacity:1}
`;
const Wrap = styled.div<IWrap>`
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  .childrenWrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tooltipWrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    bottom: calc(100% - 3px);
    z-index: 1100;
    align-items: flex-start;
    white-space: pre-wrap !important;
    animation: ${slowView} 0.3s;
    ${({ position }) => {
      switch (position) {
        case 'top':
          return css`
            left: 0;
            bottom: 0;
            transform: translateY(-30px);
            padding-bottom: 30px;
            margin-bottom: -30px;
          `;
        case 'center':
          return css`
            transform: translateX(-50%);
            align-items: center;
          `;
        case 'bottom':
          return css`
            left: auto;
            right: 0;
            top: calc(100% + 3px);
            transform: translateX(50%);
          `;
        default:
          return css`
            left: 0;
            bottom: 0;
            transform: translateY(100%);
          `;
      }
    }}

    .tooltipTail {
      transform: translateY(-5px);
    }
  }
`;

interface ITooltip {
  tooltipWidth?: string;
}
const Tooltip = styled.span<ITooltip>`
  background-color: #fff;
  color: #000;
  box-shadow: 2px 2px 4px 0px #0001;
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 400;
  /* text-align: left; */
  text-align: ${({ tooltipWidth }) => (tooltipWidth ? 'justify' : 'left')};
  white-space: ${({ tooltipWidth }) => (tooltipWidth ? null : 'nowrap')};

  p {
    width: ${({ tooltipWidth }) => (tooltipWidth ? tooltipWidth : null)};
    line-height: 160%;
  }
`;

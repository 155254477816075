import { css } from '@emotion/react';

export const LanguagePairSelectStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const LanguagePairTapStyle = css`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  color: #727272;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: -0.25px;
  background-color: #38d9000f;
  padding: 5px 14px;
  .hidden {
    display: none !important;
  }
  .label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #009e73;
    color: #fff;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
  }
`;

import { Color } from '@src/common/styles/color';
import { injectGlobal } from '@emotion/css';

injectGlobal`
  html {
    font-family: 'IBM Plex Sans KR', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.5px;
    color: ${Color.black};
    height: 100%;
    * {
      box-sizing: border-box;
    }
    body,
    #root,
    main {
      height: 100%;
    }
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h2 {
    line-height: 160%;
  }

  p {
    margin: 0;
    line-height: 160%;
  }

  pre {
    font-family: monospace, monospace;
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${Color.black};
  }

  button {
    cursor: pointer;
    border: 0 none;
    background: transparent;
    padding: 0;
    line-height: 160%;
  }

  input:focus {
    outline: 0;
  }

  input::placeholder,
  textarea::placeholder {
    color: #9a9cb5 !important;
    opacity: 1 !important;
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1rem;
  }

  sub {
    bottom: -0.25rem;
  }

  sup {
    top: -0.5rem;
  }

  fieldset {
    padding: 0.35rem 0.75rem 0.625rem;
  }

  em {
    font-style: normal;
  }

  img {
    width: 100%;
    height: 100%;
  }

  ul,
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 160%;
  }

  dl {
    margin: 0;
    dd {
      margin: 0;
    }
  }

  address {
    line-height: 160%;
    letter-spacing: 0;
  }
`;

//lib
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
//etc
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TaskManagerWrap } from './TaskManager.style';

//hooks
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import DataTable from '../components/DataTable/DataTable';
import { taskManagerColumns } from './TaskManager.columns';
import { useGetTaskManagerList } from '@src/hooks/taskManager/getLanguageTaskList';
import { useGetTMProjectList } from '@src/hooks/taskManager/getProjectSimpleAllList';
import { useEffect } from 'react';
import Footer from '@src/layouts/footer/Footer';

function TaskManagerPage() {
  const { selectElementOptionListForProjectSimpleAllList } =
    useGetTMProjectList();

  const {
    searchResultForSelectedProjectSimpleAllListItem,
    keyword,
    setKeyword,
    setProjectId,
    setFilter,
    updateKeyword,
    projectId,
    updateProjectId,
    isSort,
    setIsSort
  } = useGetTaskManagerList();

  useEffect(() => {
    const _projectId = sessionStorage.getItem('projectId');
    const _keyword = sessionStorage.getItem('keyword');

    if (_projectId && _keyword) {
      setKeyword(_keyword);
      setProjectId(_projectId);
      setFilter({
        projectId: _projectId,
        keyword: _keyword
      });
      sessionStorage.removeItem('projectId');
      sessionStorage.removeItem('keyword');
    }
  }, [projectId, keyword]);

  const projectLabel = Array.isArray(
    selectElementOptionListForProjectSimpleAllList
  )
    ? selectElementOptionListForProjectSimpleAllList.find(
        (e) => e.code === projectId
      )?.label ?? '프로젝트 선택'
    : '프로젝트 선택';

  return (
    <section css={TaskManagerWrap}>
      <Box mb={'26px'} display={'flex'}>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} ml={2}>
          <dl className="project--dl--info">
            <TPHeadlineBasic type="h2" textAlign="left">
              작업 파일 목록
            </TPHeadlineBasic>
          </dl>
        </Box>
      </Box>

      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPSelectCheck
            label={<>{projectLabel ?? '프로젝트 선택'}</>}
            useOptionLabel
            optionList={selectElementOptionListForProjectSimpleAllList}
            selectOptions={[projectId]}
            changeSelect={updateProjectId}
            multiple={false}
          />
          <TPInputSearchNew
            value={keyword}
            placeholder="검색"
            onChange={(e) => setKeyword(e.target.value)}
            clickSearch={updateKeyword}
          />
        </Box>
      </Box>

      <Box mr={'40px'}>
        <DataTable
          columns={taskManagerColumns(isSort, setIsSort)}
          dataList={searchResultForSelectedProjectSimpleAllListItem}
          clickRowData={() => {}}
          noRowsMessage={'검색된 언어 작업이 없습니다.'}
        />
      </Box>
      <Footer />
      <Outlet />
    </section>
  );
}

export default TaskManagerPage;

import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authKeys } from '@hooks/queryKeyFactory';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';

type ErrorType<T> = {
  error: T | unknown;
};
/**
 * /auth/authentication
 * @description credential 로그인 인증
 * @returns {{ postAuth, errorInfo }}
 */
export const useAuthentication = () => {
  const queryClient = useQueryClient();
  const { refetchMyInfo: getMyInfo } = useMyInfo();

  const postData = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    return (
      await api.post(`/auth/authentication`, {
        email,
        password,
        loginSite: 'LPM'
      })
    ).data;
  };

  const { mutate: postAuth, error } = useMutation(
    [...authKeys.authentication],
    {
      mutationFn: postData,
      onError: (error, variables, context) => {
        queryClient.invalidateQueries(['/auth', '/authentication']);
      },
      onSuccess: (data, variables, context) => {
        // localStorage.setItem('accessToken', data.accessToken);
        // localStorage.setItem('refreshToken', data.refreshToken);
        // localStorage.setItem('accessTokenExpiresIn', data.accessTokenExpiresIn);
        // localStorage.setItem('privacyAgree', data.privacyAgree);
        for (let k in data) {
          let v = data[k];
          localStorage.setItem(k, v);
        }
        getMyInfo().then();
        queryClient.invalidateQueries(['/auth', '/email-validation']);
        queryClient.invalidateQueries(['/auth', '/authentication']);
      }
    }
  );

  const errorInfo = Object(error as ErrorType<Object>);

  return {
    postAuth,
    errorInfo
  };
};

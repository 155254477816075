import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { IManagerList } from '@src/common/config/ICode';
import { dashboardKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';

/**
 * /task/managerList
 * @description 전체 작업 조회
 * @returns {{ taskerList }}
 */
export const useGetTaskManagerList = () => {
  const { defaultUserList } = useHookFunc();
  const getData = async (): Promise<IManagerList> => {
    return (await api.get(`/task/managerList`)).data;
  };

  const {
    data = {
      pmList: [...defaultUserList],
      taskerList: [...defaultUserList]
    }
  } = useQuery([...dashboardKeys.pmList], getData, {
    select: (data) => ({
      pmList: [...defaultUserList, ...data.projectManagerUserDataList],
      taskerList: [...defaultUserList, ...data.workUserDataList]
    })
  });

  const { pmList, taskerList } = data;
  return { pmList, taskerList };
};

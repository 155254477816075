import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { addYears, format } from 'date-fns';
import { DayPicker, DateFormatter } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { CalendarWrap, CalendarStyle } from '@hooks/modal/Calendar.style';
import { IconModalClose, IconWatch, IconInputCheck } from '@resources/icon';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { globalKeys } from '../queryKeyFactory';
import _ from 'lodash';
import { ISingleCalendarState } from '@src/common/config/ICode';
import TPButtonClose from '@src/units/button/TPButton.Close';
import useTimeInput from '../datetime/useTimeInput';

/**
 * 달력 caption format (년. 월)
 * @type {(month: Date) => JSX.Element}
 * @param month
 */
const formatCaption: DateFormatter = (month) => (
  <>
    {`${month.getFullYear()}. ${month.getMonth() + 1 <= 9 ? 0 : ''}${
      month.getMonth() + 1
    }`}
  </>
);

/**
 * 달력 요일 format (Mo, Tu, We, Th, Fr, Sa, Su)
 * @type {(weekday: Date) => JSX.Element}
 * @param weekday
 */
const formatWeekdayName: DateFormatter = (weekday) => (
  <>{format(weekday, 'eeeeee')}</>
);

/**
 * datePicker hook
 * @type {(props?: ISingleCalendarState) => {resultDate: Date; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useSingleCalendar = (props?: ISingleCalendarState) => {
  //#region props
  const { fromLimit, toLimit } = props?.dateLimit ?? {
    fromLimit: addYears(new Date(), -1),
    toLimit: addYears(new Date(), 1)
  };
  //#endregion

  //#region global state
  const calenderData = useGlobalValue([...globalKeys.singleCalendar], {
    open: false,
    dateType: '',
    date: undefined,
    setDate: () => {}
  });

  const { date, setDate } = calenderData;

  const modalFetcher = useSetGlobalState([...globalKeys.singleCalendar]);
  //#endregion

  const openHandle = (
    dateType: string,
    date: Date | undefined,
    setDate: (el?: Date) => void
  ) => modalFetcher({ ...calenderData, open: true, date, dateType, setDate });

  const closeHandle = () => {
    modalFetcher({ calenderData, open: false });
  };

  //#region handler
  const clickConfirm = (date: Date | undefined, time: string) => {
    let dateStr = date ? format(new Date(date), 'yyyy-MM-dd') : '';
    setDate(new Date(`${dateStr} ${time}`));
    closeHandle();
  };
  //#endregion

  const Component = (): JSX.Element => {
    const nowDate = date ? new Date(date) : new Date();
    const [hour, minute] = _.map(
      [nowDate.getHours(), nowDate.getMinutes()],
      (time) => (time < 10 ? `0${time}` : time.toString())
    );
    const defaultTime = date ? `${hour}:${minute}` : '';
    const timeInputRef = useRef<HTMLInputElement>(null);
    const { value: time } = useTimeInput({
      defaultTime,
      timeInputRef
    });
    const [selectDate, setSelectDate] = useState<Date | undefined>(nowDate);

    return (
      calenderData.open && (
        <div css={CalendarWrap} className={`single`}>
          <div className="top">
            <TPButtonClose className="close-button" onClick={closeHandle} />
          </div>
          <div className="middle">
            <DayPicker
              formatters={{ formatCaption, formatWeekdayName }}
              css={CalendarStyle}
              mode={'single'}
              numberOfMonths={1}
              selected={selectDate}
              weekStartsOn={0}
              onSelect={setSelectDate}
              fromDate={fromLimit ? new Date(fromLimit) : undefined}
              toDate={toLimit ? new Date(toLimit) : undefined}
            />
          </div>
          <div className="bottom">
            <label className="select-Deadline-Time">
              <IconWatch className="IconWatch" />
              <input
                className="content"
                type="text"
                ref={timeInputRef}
                value={time}
              />
            </label>
          </div>
          <div className="buttonWrap">
            <TPButtonBasic
              fullWidth
              label="취소"
              variant="outlined"
              onClick={closeHandle}
            />
            <TPButtonBasic
              fullWidth
              disabled={!selectDate}
              label="확인"
              variant="contained"
              onClick={() => {
                clickConfirm(selectDate, time);
              }}
            />
          </div>
        </div>
      )
    );
  };

  return {
    closeHandle,
    openHandle,
    Component
  };
};

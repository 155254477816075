import styled from '@emotion/styled';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { CSSProperties, useState } from 'react';
import {
  renderDateNewTime,
  renderDateTime,
  renderNewLanguages,
  renderTreeButton
} from '../utils/render';
import Row4LanguageSubTaskList from './Row4LanguageSubTaskList';
import TaskStatus from './TaskStatus';
import RowStatusBadge from '@src/pages/components/RowStatusBadge';
import { useAlert } from '@src/hooks/modal/useAlert';
import { usePostLanguageSubTask } from '@src/hooks/languageTask/post';
import { TPSelectFile } from '@src/units/select/TPSelect.file';

interface Item {
  destinationLanguage: string;
  endDateTime: string;
  hopeSupplyDateTime: string;
  languageTaskId: number;
  languageTaskKey: string;
  languageTaskStatus: string;
  startDateTime: string;
  startLanguage: string;
  subDepthYn: string;
  workerList: string;
  taskType: string;
}

export default function Row3LanguageTaskItem({
  filterData,
  item,
  taskType,
  setOpenedJobModifyPage,
  filterExposure,
  languageTaskTitle,
  deleteTaskAlert,
  putFileStatusTask,
  dataLength,
  isFilter
}: any | { item: Item }) {
  const [open, setOpen] = useState(false);
  const [selectOptions, changeSelect] = useState(false);
  const { putLanguageTask } = usePostLanguageSubTask();
  const { openHandle: alertHandle } = useAlert();

  return (
    <>
      <Row3LanguageTaskItemWrap className="row-3-item">
        <td colSpan={15}>
          <div className="info">
            <div
              style={{
                paddingLeft: 20,
                width: '100%',
                display: 'flex',
                gridRow: 1,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 10
              }}
            >
              <RowStatusBadge
                statusCode={item.languageTaskStatus}
                {...(item?.subDepthYn
                  ? {
                      open,
                      setOpen
                    }
                  : {})}
              >
                {item?.languageTaskStatus === 'COMPLETE' && (
                  <div
                    className="item"
                    onClick={() => {
                      const payload = {
                        languageTaskId: item.languageTaskId,
                        languageTaskStatus: 'DELIVERY_COMPLETE'
                      };
                      alertHandle({
                        title: '상태 변경',
                        text: '상태를 변경하시겠습니까?',
                        cancelLabel: '취소',
                        ok: () => {
                          putLanguageTask(payload);
                        }
                      });
                    }}
                  >
                    납품완료
                  </div>
                )}
              </RowStatusBadge>
            </div>
            <div className="info-item" />
            <div className="info-item info-title">
              <div
                className="label"
                style={{
                  textAlign: 'left',
                  whiteSpace: 'normal'
                }}
              >
                {languageTaskTitle}
              </div>
              <div>-</div>
              <div>-</div>
              <div>-</div>
            </div>
            <div className="info-item div-center">
              {renderNewLanguages([item?.startLanguage])}
            </div>
            <div className="info-item div-center">
              {renderNewLanguages([item?.destinationLanguage])}
            </div>
            <div className="info-item div-center">
              <div className="div-avatar">
                {item.workerList.length > 0 ? (
                  <TPAvatarGroupNew userList={item.workerList} />
                ) : (
                  <TPButtonBasic
                    label="배정중"
                    size="small"
                    startIcon={null}
                    variant="outlined"
                    disabled={true}
                    sx={{
                      width: '64px !important'
                    }}
                  />
                )}
              </div>
            </div>
            {renderDateNewTime(item.startDateTime, false)}
            {renderDateNewTime(
              item.endDateTime,
              !['COMPLETE', 'DELIVERY_COMPLETE'].includes(
                item.languageTaskStatus
              )
            )}
            <div
              className="info-item div-center"
              onClick={() => console.log(item)}
            >
              {taskType === 'LQA'
                ? `${Math.floor(Number(item.grossAmount) / 250)}시간 ${
                    Number(item.grossAmount) % 250 > 0 ? '30' : '00'
                  }분`
                : `${item.grossAmount}`}
            </div>
            <div className="info-item div-center">
              {taskType === 'LQA'
                ? `${Math.floor(Number(item.netAmount) / 250)}시간 ${
                    Number(item.netAmount) % 250 > 0 ? '30' : '00'
                  }분`
                : `${item.netAmount}`}
            </div>
            {renderDateNewTime(
              item.hopeSupplyDateTime,
              !['COMPLETE', 'DELIVERY_COMPLETE'].includes(
                item.languageTaskStatus
              )
            )}
            <div className="info-item div-center">-</div>
            <div className="info-add">
              <TPSelectFile
                selectOptions={item.fileRegisterStatus}
                data={{
                  isBoracatUseOrNot: item?.isBoracatUseOrNot ?? false,
                  languageTaskStatus: item?.languageTaskStatus ?? 'NEW'
                }}
                changeSelect={(state: boolean) =>
                  putFileStatusTask({
                    languageTaskId: item.languageTaskId,
                    fileRegisterStatus: state
                  })
                }
              />
              {dataLength > 1 &&
              ['NEW', 'COMPLETE_ASSIGN'].includes(item?.languageTaskStatus) ? (
                <TPButtonClose
                  onClick={() => deleteTaskAlert({ id: item.languageTaskId })}
                />
              ) : (
                <div style={{ width: '20px' }} />
              )}
            </div>
            {/* 작업 수정 범위 todo */}
          </div>
        </td>
      </Row3LanguageTaskItemWrap>
      {open && (
        <Row4LanguageSubTaskList
          {...{
            filterData,
            item,
            taskType,
            setOpenedJobModifyPage,
            filterExposure,
            isFilter
          }}
        />
      )}
    </>
  );
}

/* 

destinationLanguage
endDateTime
hopeSupplyDateTime
languageTaskId
languageTaskKey
languageTaskStatus
startDateTime
startLanguage
subDepthYn
workerList

*/

const Row3LanguageTaskItemWrap = styled.tr`
  background-color: #f9f9f9;

  &:hover {
    td > div {
      background-color: #f8f5fc;
    }
  }

  & + [class*='Row4LanguageSubTaskItemWrap'] td {
    .deeps-control .line {
      &::before {
        content: '';
        width: 50%;
        height: calc(50% + 25px);
        border-left: 1px solid #d5d3e4;
        border-bottom: 1px solid #d5d3e4;
        border-radius: 0 0 0 6px;
        display: block;
        z-index: 0;
        position: absolute;
        left: 50%;
        top: calc(-50% - 7px);
      }
    }
  }

  & > td {
    text-align: center;
    vertical-align: middle;
  }

  td > div {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .row-3-item {
    float: right;
    display: inline-flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    margin-left: auto;
    margin-right: 1em;
  }

  .deeps-line {
    position: relative;
    width: 100%;
    height: 38px;
    &::before {
      content: '';
      width: calc(50% + 4px);
      height: calc(150% + 4px);
      border-left: 1px solid #d5d3e4;
      border-bottom: 1px solid #d5d3e4;
      border-radius: 0 0 0 6px;
      display: block;
      z-index: 0;
      position: absolute;
      left: 50%;
      top: calc(-100% + 0px);
    }
  }

  .deeps-controls {
    display: grid;
    grid-template-columns: 1fr 1fr 1em;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .key {
    height: 32px;
    width: 160px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #d5d3e4;
    padding: 4px 63pxem;
    border-radius: 6px;
    position: relative;
    background-color: white;
    z-index: 1;
    pointer-events: none;

    &:has(svg) {
      cursor: pointer;
      pointer-events: all;
    }

    & > span {
      line-height: 240%;
      &:first-of-type:has(> svg) {
        position: absolute;
        left: 6px;
        top: 2px;
      }
    }
  }

  .info {
    width: 100%;
    display: grid;
    grid-template-columns: 155px 10px 620px 110px 110px 120px 110px 110px 120px 120px 110px 80px 70px;
    justify-content: space-between;
    height: 100%;

    .info-item {
      grid-row: 1;
      width: 100%;
      display: grid;
      justify-content: space-between;
      grid-template-columns: 280px 135px 130px 45px;

      & > div {
        display: grid;
        align-items: center;
        text-align: center;
        gap: 1em;
        white-space: nowrap;

        & > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .info-add {
      grid-row: 1;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    .div-center {
      justify-content: center !important;
      justify-items: center !important;
      grid-template-columns: 120px !important;
    }
  }

  .label {
    color: #727272;
  }
`;

interface TPButtonCloseProps extends React.HTMLAttributes<HTMLDivElement> {}

function TPButtonClose({ ...props }: TPButtonCloseProps) {
  return (
    <TPButtonCloseWrap className="circle button close" {...props}>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.86987 0.3584L4.14751 3.08076L1.42515 0.3584L1.31909 0.252335L1.21302 0.358401L0.505914 1.06551L0.399848 1.17157L0.505913 1.27764L3.22827 4L0.602589 6.62569L0.496523 6.73175L0.602589 6.83782L1.3097 7.54492L1.41576 7.65099L1.52183 7.54493L4.14751 4.91924L6.86987 7.6416L6.97594 7.74767L7.08201 7.6416L7.78911 6.93449L7.89518 6.82843L7.78911 6.72236L5.06675 4L7.78911 1.27764L7.89518 1.17157L7.78911 1.06551L7.08201 0.3584L6.97594 0.252334L6.86987 0.3584Z"
          strokeWidth="0.3"
        />
      </svg>
    </TPButtonCloseWrap>
  );
}

const TPButtonCloseWrap = styled.div`
  --white: #ffffff;
  --black: #242424;

  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: var(--black);
    fill: var(--black);
  }
`;

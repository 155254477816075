import { css } from '@emotion/react';

export const Wrap = css`
  margin: 71px 40px 90px;
  width: max-content;
  .customer--title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    align-items: flex-start;
  }
  .customer--button--download,
  .customer--button--search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    width: auto;
    height: 48px;
    font-size: 14px;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #9b51e0;
    color: #9b51e0;
    border-radius: 40px;
    &:disabled {
      border-color: #d5d3e4;
      color: #d5d3e4;
      cursor: auto;
    }
  }
  .customer--sub--title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    align-items: center;
  }
  .customer--button--refresh {
    border: 1px solid #cbcbcb;
  }
`;

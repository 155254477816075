import styled from '@emotion/styled';

export const DividingLine = () => {
  return <Wrap></Wrap>;
};

const Wrap = styled.div`
  width: 100%;
  border-top: 1px solid #eeedf4;
`;

import { useEffect } from 'react';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { taskKeys } from '@hooks/queryKeyFactory';
import { ITaskDetailRes } from '@src/common/config/ITask';
import { AxiosError } from 'axios';

export const useGetTask = (id?: string) => {
  const getTaskStatusHistoryApi = async (id: number | string) =>
    (await api.get(`/task/statusHistory/${id}`)).data;

  const useQueryByTaskStatusHistory = (
    id: number | string,
    options?: UseQueryOptions<unknown, AxiosError, { id: number | string }>
  ) =>
    useQuery<unknown, AxiosError, { id: number | string }>(
      ['task', id, 'statusHistory'],
      getTaskStatusHistoryApi.bind(null, id),
      {
        enabled: false,
        refetchOnMount: true,
        ...options
      }
    );

  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof taskKeys)['get']>
  >): Promise<ITaskDetailRes> => {
    const [, , id] = queryKey;
    if (id === 'null' || id === 'undefined' || id === '')
      return {
        taskId: 0,
        taskTitle: '',
        taskKey: '',
        totalGrossAmount: 0,
        totalNetAmount: 0,
        languageTaskList: [],
        taskAmountList: [],
        description: '',
        linkList: []
      };
    return await api.get(`/task/${id}`).then((res) => res.data);
  };

  const {
    isLoading,
    data: taskDetail = {
      taskId: 0,
      taskTitle: '',
      taskKey: '',
      totalGrossAmount: 0,
      totalNetAmount: 0,
      languageTaskList: [],
      taskAmountList: [],
      description: '',
      linkList: []
    },
    refetch
  } = useQuery([...taskKeys.get(String(id))], getData, {
    enabled: !!id,
    refetchOnMount: true
  });

  return {
    isLoading,
    taskDetail,
    refetch,
    useQueryByTaskStatusHistory
  };
};

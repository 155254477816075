import { css } from '@emotion/react';
import { Color } from '@common/styles/color';

export const JobRegisterStyle = css`
  font-family: 'IBM Plex Sans', sans-serif;

  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  .lengthCheck {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.25px;
    text-align: right;
    color: #727272;

    position: relative;
    & > span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0.75rem;
      s {
        text-decoration: none;
      }
    }
    &.lengthError {
      span s {
        color: red;
      }
      textarea {
        border-color: red;
        &:focus-visible {
          outline: 1px solid red;
        }
      }
      fieldset {
        border-color: red;
      }
    }
  }
  .task-details {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .modal--div--register {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-right: 20px;
    margin-right: -26.7px;
  }
  .totalRate {
    .totalRateTitleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      display: flex;
      flex-direction: row;
      .left {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .title {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0px;
        }
        .grossAndNet {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 18px;
          background-color: #f5f5f5;
          padding: 2px 6px;
          white-space: nowrap;
          line-height: 19px;
          letter-spacing: -0.25px;

          .name {
            margin-right: 4px;
            font-family: 'IBM Plex Sans', sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #aaaaaa;
          }
          .value {
            font-family: 'IBM Plex Sans', sans-serif;
            font-weight: 500;
            font-size: 12px;
            color: #727272;
          }
        }
      }
      .controls {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        gap: 6px;
        color: #727272;
        line-height: 22.4px;
        letter-spacing: -0.25px;
      }
    }
    .time-calculation {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;
      .hour {
        width: 50px;
        height: 40px;
        border: 1px solid #ececec;
        text-align: center;
        outline: none;
        color: #727272;
        padding: 0 8px;
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .MuiFormControl-root .MuiInputBase-root {
        width: 60px !important;
      }
      .MuiInputBase-root {
        padding: 0 10px;
      }
      .MuiTextField-root {
        svg {
          left: 34px;
        }
      }
    }
    .amountInfo {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      > .item {
        font-size: 14px;
      }
      > .value {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .title {
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .color--red {
      color: ${Color.compulsory};
    }
  }
  .dates {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    & > div {
      width: 100%;
    }
    & > div:nth-child(1) {
    }
  }
  .dates-start {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .linkInputAndList {
    position: relative;
    h3.name {
      width: 30px;
      height: 26px;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      margin-bottom: 8px;
    }
    span.valid {
      position: absolute;
      color: #e6311a;
      font-size: 12px;
      top: 0;
      left: 40px;
    }
    div.input--wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 1px;
      gap: 9px;
      input {
        flex-grow: 1;
        padding: 5px 10px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #ececec;
        border-radius: 2px;
        color: #727272;
      }
      button.add--button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #ffffff;
        width: 100px;
        height: 32px;
        background: linear-gradient(270deg, #aa51e0 0%, #9b51e0 100%);
        border-radius: 2px;
        :disabled {
          background: #d5d3e4;
        }
      }
      div.link--count {
        color: #727272;
        position: absolute;
        top: 0;
        right: 0px;
        font-size: 0.75rem;
      }
    }
    ul.link--list {
      li {
        position: relative;
        border-bottom: 1px solid #eeedf4;
        display: flex;
        align-items: center;
        padding: 17px 4px;
        word-break: break-all;
        width: 100%;
        background: #ffffff;
        text-decoration: underline;
        color: #727272;
        span {
          text-align: left;
        }
        > .remove--button {
          position: absolute;
          right: 0;
          width: 18px;
          height: 18px;
          fill: #9a9cb5;
          background-color: #fff;
          border-radius: 2px;
          box-shadow: 0 0 5px 5px #fff;
          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .task-title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .workingLanguage {
    .MuiFormLabel-root {
      font-size: 1rem;
      font-weight: 500;
      color: #2c3558;
      &.Mui-focused {
        color: #2c3558;
      }
      & sup {
        margin-left: 4px;
        top: 0;
        font-size: 1rem;
      }
    }
  }
  .MuiBox-root {
    margin-top: 0;
  }
`;

export const cssTaskMaker = css`
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .add-button {
    width: 82px;
    height: 40px;
    background-color: #9b51e0;
    color: #ececec;
    padding: 5px 14px;
    border-radius: 2px;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #727272;
  }
`;

export const cssTaskMakerItem = css`
  position: relative;
  padding: 16px 16px 24px;
  background-color: #fafafa;
  margin-top: 12px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .button.close.circle {
    position: absolute;
    top: 18px;
    right: 16px;
  }

  .language-pair {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;

    color: #242424;
  }

  .add-button {
    width: 82px;
    height: 40px;
    background-color: #9b51e0;
    color: #ececec;
    padding: 5px 14px;
    border-radius: 2px;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #727272;
  }

  .language-sub-task-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-right: 22px;
  }
`;

import { Outlet } from 'react-router-dom';
import Footer from '@layouts/footer/Footer';
import HeaderRightMenu from '@src/layouts/header/HeaderRightMenu';
import { useModalContainer } from '@hooks/modal/useModalContainer';
import { css } from '@emotion/react';
import TopMenu from './topMenu/TopMenu';

const Layout = () => {
  const { ModalContainer } = useModalContainer();

  return (
    <>
      <div css={layoutWrap}>
        <TopMenu />
        <div className="layout--div--contents">
          <div className="layout--div--inner">
            <div className="layout--div--outlet">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <ModalContainer />
    </>
  );
};
export default Layout;

const layoutWrap = css`
  display: flex;
  height: 100%;
  .layout--div--contents {
    width: 100%;
    overflow-x: auto;
    .layout--div--inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* width: 1848px; */
      height: 100%;
      /* margin: 0 auto; */
    }
  }
  .layout--div--outlet {
    align-self: stretch;
    flex-grow: 1;
    padding: 0;
  }
`;

import { useState } from 'react';
import { format } from 'date-fns';
import { Wrap } from '@pages/dashboard/Customer.style';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { IconCalendar, IconInfo } from '@resources/icon';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import { SimpleTooltip } from '@units/tooltip/SimpleTooltip';
import BarChartComponent from '../components/BarChart.NoFooter';
import { Box, Tooltip } from '@mui/material';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { IconRefresh } from '@resources/icon';
import {
  getStatus,
  getDateType,
  getShowType,
  getContractType
} from '@src/common/config/Code';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { useGetWordGraph } from '@src/hooks/statistics/companyPerProject/getWordAmountGraph';
import { useGetTaskGraph } from '@src/hooks/statistics/companyPerProject/getTaskCountGraph';
import { useGetCompanyPerList } from '@src/hooks/statistics/companyPerProject/getList';
import { customerColumns } from './Customer.columns';
import DataTableCustomer from '../components/DataTable/DataTableCustomer';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@src/hooks/modal/useRangeCalendar';
import { useGetFilter } from '@src/hooks/searchFilter';
import { useGetStatisticsPMList } from '@src/hooks/statistics/companyPerProject/getPmLIst';
import Footer from '@src/layouts/footer/Footer';

const CustomerPage = () => {
  //#region hooks call
  const { myInfo } = useMyInfo();
  const { wordGraphData } = useGetWordGraph();
  const { taskGraphData } = useGetTaskGraph();
  const { pmList } = useGetStatisticsPMList();
  const { filterData, Component: FilterButton } = useGetFilter(
    'COMPANY_PER_PROJECT'
  );
  const {
    // keyword,
    projectStatusList,
    projectContractTypeList,
    pmUserList,
    dateType,
    dateFilter,
    companyPerList,
    isFilter,
    isSort,
    downloadData,
    setState,
    filterExposure,
    setFilterExposure
  } = useGetCompanyPerList(filterData);
  const { openHandle, Component: Calendar } = useRangeCalendar();
  //#endregion

  //#region set state
  const [searchValue, setSearchValue] = useState('');

  const {
    setProjectStatus,
    setProjectContractType,
    setPmUser,
    setDateType,
    setDateFilter,
    setKeyword,
    setResetState,
    setIsSort
  } = setState;
  //#endregion

  const resetState = () => {
    setResetState();
    setSearchValue('');
  };

  const defaultAccordionExposure = filterExposure === 'SPREAD';
  const titleDescription = `
  “작업” 또는 “언어 작업” 기준으로 고객사나 프로젝트 별 작업 수, 분량을 확인할 수 있습니다.<br/>
  “중지” 상태인 “작업” 또는 “언어 작업”은 집계 대상에서 제외됩니다.<br/>
  - 대상 칼럼: 작업수, 언어작업수, 총 분량(Gross), 총 분량(Net)`;

  const taskAmountDescription = `
  고객사 별 작업 수를 확인할 수 있습니다.<br/>
  막대 그래프에 마우스 커서를 올리면 <br/>  
  프로젝트 별 작업 수를 확인할 수 있습니다.`;

  const wordAmountDescription = `
  고객사 별 단어 수를 확인할 수 있습니다.<br/>
  막대 그래프에 마우스 커서를 올리면 <br/>  
  프로젝트 별 단어 수를 확인할 수 있습니다.`;

  return (
    <section css={Wrap}>
      <div className="customer--title">
        <UnderlineTitle text="고객사&프로젝트 별 통계" align="notCenter" />{' '}
        <Tooltip
          title={<p dangerouslySetInnerHTML={{ __html: titleDescription }} />}
          placement="right"
        >
          <IconInfo width={24} height={24} />
        </Tooltip>
      </div>
      <Box
        mb={3}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'8px'}
      >
        <Box display={'inline-flex'} gap={'8px'}>
          <TPInputSearchNew
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="검색"
            clickSearch={() => setKeyword(searchValue)}
            width={250}
          />
          <TPSelectCheck
            label="상태"
            useOptionLabel
            optionList={getStatus(['ALL', 'NEW', 'PROGRESS', 'END'])}
            selectOptions={projectStatusList}
            changeSelect={setProjectStatus}
          />
          <TPSelectCheck
            label="견적유형"
            useOptionLabel
            optionList={getContractType(['ALL', 'CASE', 'MONTHLY'])}
            selectOptions={projectContractTypeList}
            changeSelect={setProjectContractType}
          />
          <TPSelectCheckManager
            label="담당 PM"
            showAvatar={true}
            PMList={pmList}
            selectOptions={pmUserList}
            changeSelect={setPmUser}
          />
          <TPSelectOne
            label="노출"
            optionList={getShowType(['SPREAD', 'FOLD'])}
            selectOption={filterExposure}
            changeSelect={setFilterExposure}
          />
          <TPSelectOne
            label="날짜"
            optionList={getDateType([
              'ALL',
              'CONTRACT_START_DATE',
              'CONTRACT_END_DATE'
            ])}
            selectOption={dateType}
            changeSelect={setDateType}
          />
          {dateType !== 'ALL' && (
            <>
              <div
                onClick={() => {
                  openHandle('customer', dateFilter, setDateFilter);
                }}
              >
                <TPButtonActionCalendar
                  outline={true}
                  size={'medium'}
                  content={
                    dateFilter.startDateTime && dateFilter.endDateTime
                      ? `${format(
                          new Date(dateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )} - ${format(
                          new Date(dateFilter.endDateTime),
                          'yyyy.MM.dd'
                        )}`
                      : ''
                  }
                  icon={<IconCalendar />}
                />
              </div>
              <Calendar />
            </>
          )}
          {(isFilter || filterExposure === 'HIDE') && (
            <button className="customer--button--refresh" onClick={resetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          {/* [고객사&프로젝트 별 통계] 필터링 저장 */}
          <FilterButton
            getFilterData={{
              // keyword, // 고객사 또는 프로젝트 검색
              projectStatusList,
              projectContractTypeList,
              pmUserList,
              dateType,
              dateFilter,
              filterExposure
            }}
          />
        </Box>
        <button
          className={'customer--button--download'}
          onClick={downloadData}
          disabled={!companyPerList.length}
        >
          Excel로 내려받기
        </button>
      </Box>
      <Box
        m={'5rem 0 2rem 0'}
        style={{
          borderRadius: '16px',
          padding: '40px 20px 50px 20px',
          background: '#ffffff',
          boxShadow: '0 1px 32px 4px #f0edf6',
          textAlign: 'center'
        }}
      >
        <div className="customer--sub--title">
          <span>{`고객사 별 작업 수`}</span>
          <SimpleTooltip message={taskAmountDescription}>
            <IconInfo width={24} height={24} />
          </SimpleTooltip>
        </div>
        <BarChartComponent graphData={taskGraphData} type="project" />
      </Box>
      <Box
        m={'3rem 0 5rem 0'}
        style={{
          borderRadius: '16px',
          padding: '40px 20px 50px 20px',
          background: '#ffffff',
          boxShadow: '0 1px 32px 4px #f0edf6',
          textAlign: 'center'
        }}
      >
        <div className="customer--sub--title">
          <span>{`고객사 별 단어 수`}</span>
          <SimpleTooltip message={wordAmountDescription}>
            <IconInfo width={24} height={24} />
          </SimpleTooltip>
        </div>
        <BarChartComponent graphData={wordGraphData} type="word" />
      </Box>
      <Box m={'1.5rem 0'}>
        <DataTableCustomer
          myInfo={myInfo}
          columns={customerColumns(isSort, setIsSort)}
          dataList={[...companyPerList]}
          defaultAccordionExposure={defaultAccordionExposure}
          noRowsMessage={'검색된 고객사 또는 프로젝트가 없습니다.'}
          gap={17.8}
        />
      </Box>
      <Footer />
    </section>
  );
};

export default CustomerPage;

import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import {
  STATUS_COLOR,
  getStatus,
  getStatusLabel
} from '@src/common/config/Code';
import { IcoMenuChevronDown, IcoTriangle } from '@src/resources/icon';
import { MouseEvent, useEffect, useRef, useState } from 'react';

export const RowStatusBadgeWrap = styled.div<RowStatusBadgeProps>`
  /* position: relative; */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0 10px 0 0;
  width: min-content;
  min-width: 150px;

  user-select: none;
  /* 
  &:hover {
    :has(svg) {
      .collapse-btn {
        background: #242424;
        svg {
          fill: white;
        }
      }
    }
  } */

  .bar {
    width: 10px;
    height: 10px;
    border-radius: 1px;
    flex-shrink: 0;
  }
  .collapse-btn {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;

    &:has(svg) {
      border: 1px solid #ececec;

      &:hover {
        background: #242424;
        svg {
          fill: white;
        }
      }
    }

    & > svg {
      transform-origin: center;
      transform: rotate(-90deg);
      &.open {
        transform: rotate(0deg);
      }
    }
  }
  .label {
    /* flex-grow: 1; */
  }

  .children-btn {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      svg {
        fill: #000;
        stroke: #000;
      }
    }
  }

  .dropdown {
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    border: 1px solid #ececec;
    box-shadow: 2px 2px 4px 0px #00000014;
    animation: dropdown 0.3s ease-out;

    z-index: 1;

    & > * {
      padding: 8px 16px;
      &:hover {
        background-color: #9b51e00f;
      }
    }
  }

  @keyframes dropdown {
    0% {
      transform: translateY(calc(100% - 4px));
      opacity: 0.6;
    }
    100% {
      transform: translateY(100%);
      opacity: 1;
    }
  }
`;

interface RowStatusBadgeProps {
  open?: boolean;
  setOpen?: any;
  statusCode?: string;
  onClick?: any;
  children?: any;
  hide?: boolean;
}

function RowStatusBadge({
  open,
  setOpen,
  statusCode,
  children,
  hide,
  ...props
}: RowStatusBadgeProps) {
  // const label = getStatus(statusCode as any)?.[0]?.label ?? '';
  const label = getStatusLabel(statusCode as any);
  const color =
    STATUS_COLOR[statusCode as keyof typeof STATUS_COLOR] ?? 'transparent';

  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenDropdown(false);
  };

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (children !== undefined) setOpenDropdown((v) => !v);
  };

  return (
    <RowStatusBadgeWrap
      {...props}
      ref={anchorRef}
      onClick={(e: any) => {
        if (children) {
          onClick(e);
        }
      }}
    >
      {hide ? null : (
        <>
          <div
            className={cx({ bar: true, [statusCode ?? 'non']: true })}
            style={{ backgroundColor: color }}
          />
          <div className="label">{label}</div>

          {children && (
            <div className="children-btn">
              <IcoTriangle />
            </div>
          )}
          {openDropdown && children && (
            <Popper
              sx={{
                zIndex: 1
              }}
              open={openDropdown}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                    width: anchorRef.current?.parentElement?.clientWidth
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {children}
                        {/* {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))} */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </>
      )}
    </RowStatusBadgeWrap>
  );
}

export default RowStatusBadge;

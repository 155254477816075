import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import DoneDateFilter, {
  useDoneDateFilterProps
} from '@src/pages/dashboard/units/DoneDateFilter';
import FutureDateFilter, {
  useFutureDateFilterProps
} from '@src/pages/dashboard/units/FutureDateFilter';
import {
  DONE_TOOLTIP_MESSAGE,
  FUTURE_TOOLTIP_MESSAGE
} from '@src/pages/dashboard/units/Row2TaskList';
import {
  IcoMenuChevronDown,
  IconInfoBlack,
  IconLink
} from '@src/resources/icon';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyWorkTable } from '@src/pages/myTask/MyTaskTab.page';
import { useGetMyTaskListByDay } from '@src/hooks/myWork/getListByDay';

export type CategoryTableProps = {
  item: {
    label: string;
    items: number;
    order: number;
    autoCall: boolean;
    actionChain: string;
    url?: string;
  };
  type: 'mytask' | 'task';
  taskFilter: any;
};

export default function CategoryTableMyTask({
  item: { label, items, order, autoCall, actionChain, url },
  taskFilter,
  type
}: CategoryTableProps) {
  const to = useNavigate();
  const [open, setOpen] = useState(Boolean(autoCall));
  const futureDateFilter = useFutureDateFilterProps();
  const doneDateFilter = useDoneDateFilterProps();

  const {
    data: myWorkListByDayData,
    isLoading,
    isSuccess,
    setFilter: myWorkFilter
  } = useGetMyTaskListByDay();

  useEffect(() => {
    myWorkFilter({
      label,
      ...taskFilter,
      ...(label === 'FUTURE' ? futureDateFilter : {})
    });
  }, [
    futureDateFilter.dateType,
    futureDateFilter.startDateTime,
    futureDateFilter.endDateTime,
    items
  ]);

  useEffect(() => {
    if (
      myWorkListByDayData?.myWorkList?.length === 0 &&
      !['FUTURE', 'DONE'].includes(label)
    ) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [myWorkListByDayData]);

  if (isLoading && !isSuccess) return <div>Loading...</div>;

  return (
    <CategoryTableWrap className={cx({ open })}>
      <div className="toggle">
        <div
          className="category"
          onClick={() => {
            setOpen((v) => !v);
          }}
        >
          <IcoMenuChevronDown className={cx({ open })} />
          <div>{label}</div>
          <div className={`chip ${label}`}>
            {label === 'FUTURE'
              ? myWorkListByDayData?.myWorkList?.length
              : items}
          </div>
          {url && (
            <span
              {...{
                style: { cursor: 'pointer' },
                tile: url,
                onClick(e) {
                  e.preventDefault();
                  e.stopPropagation();
                  const v = url?.trim?.()?.toLowerCase?.();
                  if (v) to(v);
                }
              }}
            >
              <IconLink
                style={{
                  transform: 'translate(8px, 2px)'
                }}
              />
            </span>
          )}
        </div>
      </div>
      {open && (
        <>
          {label === 'FUTURE' && (
            <>
              <div className="filter-toolbar">
                <strong>조회기간</strong>
                <SimpleTooltip message={FUTURE_TOOLTIP_MESSAGE} position="top">
                  <IconInfoBlack width={16} height={16} />
                </SimpleTooltip>
                <FutureDateFilter
                  {...futureDateFilter}
                  disabledHopeSupplyDate
                />
              </div>
              <MyWorkTable
                label={label}
                data={myWorkListByDayData}
                taskFilter={{
                  ...taskFilter,
                  ...{
                    dateType: futureDateFilter.dateType,
                    startDateTime: futureDateFilter.startDateTime,
                    endDateTime: futureDateFilter.endDateTime
                  }
                }}
                setOpen={setOpen}
              />
            </>
          )}
          {label === 'DONE' && (
            <>
              <div className="filter-toolbar">
                <strong>조회기간</strong>
                <SimpleTooltip message={DONE_TOOLTIP_MESSAGE} position="top">
                  <IconInfoBlack width={16} height={16} />
                </SimpleTooltip>
                <DoneDateFilter {...doneDateFilter} disabledHopeSupplyDate />
              </div>
              <MyWorkTable
                label={label}
                data={myWorkListByDayData}
                taskFilter={{
                  ...taskFilter,
                  ...{
                    dateType: doneDateFilter.dateType,
                    startDateTime: doneDateFilter.startDateTime,
                    endDateTime: doneDateFilter.endDateTime
                  }
                }}
                setOpen={setOpen}
              />
            </>
          )}
          {label !== 'FUTURE' && label !== 'DONE' && (
            <MyWorkTable
              label={label}
              data={myWorkListByDayData}
              taskFilter={taskFilter}
              setOpen={setOpen}
            />
          )}
        </>
      )}
    </CategoryTableWrap>
  );
}

const CategoryTableWrap = styled.div`
  width: 1820px;

  .toggle {
    margin-top: 36px;
    min-width: 1820px;
    :where(:not(.open .toggle)) {
      background-color: #fff;
    }
  }

  .category {
    min-width: 1820px;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    height: 46px;

    font-family: 'Spoqa Han Sans Neo', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #242424;

    border-radius: 2px;
    padding: 5px 14px;
    cursor: pointer;
    user-select: none;

    > svg {
      transform: rotate(-90deg);
      &.open {
        transform: rotate(0deg);
      }
    }

    .chip {
      background-color: #242424;
      border-radius: 30px;
      color: #fff;
      text-align: center;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 23px;
      min-height: 15px;
      padding: 2px 4px;
    }
  }
  .filter-toolbar {
    z-index: 1052;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    white-space: nowrap;
    .FilterInput .label--selectOption + svg {
      margin-left: auto;
    }

    & > *:first-of-type {
      transform: translate(2px, -6px);
    }

    .main--button--refresh {
      margin-bottom: 16px;
      border: 1px solid #cbcbcb;
      padding: 5px 14px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #242424;
      background-color: #fff;

      &:hover {
        color: #9b51e0;
      }
    }
  }
`;

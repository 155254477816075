import api from '@api/Api';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { saturationKeys } from '@hooks/queryKeyFactory';
import { IUser } from '@src/common/config/ICode';
import { useEffect, useState } from 'react';

/**
 * /statistics/allAssignWorker
 * @returns {{ assignWorkerData, searchWorkerData, keyword, setKeyword }}
 */
export const useGetAllAssignWorker = () => {
  const [keyword, setKeyword] = useState('');
  const [searchWorkerData, setSearchWorkerData] = useState<IUser[]>([]);

  const getData = async (): Promise<IUser[]> => {
    return (await api.getPms(`/statistics/allAssignWorker`)).data
      .assignWorkUserList;
  };

  const { data = [] } = useQuery(
    [...saturationKeys.getAllAssignWorker],
    getData
  );

  const order = ['ACTIVE', 'INACTIVE', 'SLEEP', 'LEAVE'];

  const assignWorkerData = _.orderBy(data, ['name'], ['asc']).sort(
    (a, b) => order.indexOf(String(a.status)) - order.indexOf(String(b.status))
  );

  useEffect(() => {
    setSearchWorkerData(
      keyword.length
        ? [
            ..._.filter(assignWorkerData, (el) =>
              el.name.toUpperCase().includes(keyword.toUpperCase())
            )
          ]
        : assignWorkerData
    );
  }, [keyword, assignWorkerData]);

  return { assignWorkerData, searchWorkerData, keyword, setKeyword };
};

import { css } from '@emotion/react';

export const Wrap = css`
  margin: 71px 40px 90px;

  .worker--title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .worker--button--download,
  .worker--button--search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    width: auto;
    height: 48px;
    font-size: 14px;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #9b51e0;
    color: #9b51e0;
    border-radius: 40px;
    &:disabled {
      border-color: #d5d3e4;
      color: #d5d3e4;
      cursor: auto;
    }
  }

  .worker--button--refresh {
    margin-left: 24px;
    font-size: 0.875rem;
    margin-right: 20px;

    svg {
      width: 18px;
      height: 18px;
      vertical-align: top;
    }
    span {
      text-decoration: underline;
      margin-left: 4px;
    }
  }
  .worker--span--empty {
    color: #9a9cb5;
    font-size: 14px;
  }
`;

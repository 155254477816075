import styled from '@emotion/styled';
import {
  ILanguageTaskList,
  ITaskManager
} from '@src/common/config/ILanguageTask';
import { IMyTaskList } from '@src/common/config/IMyTask';
import { IProjectList } from '@src/common/config/IProject';
import { IWorkerPerWordAmountList } from '@src/common/config/IStatistics';
import { ITaskList } from '@src/common/config/ITask';
import { ReactNode, useMemo } from 'react';
import { IFullLookupDataList } from '../components/DataTable/DataTable';
import { cx } from '@emotion/css';
type dataTableCodeType = {
  code: string;
  value: any;
  width?: number;
  cssHeader?: any;
  cssBody?: any;
  renderHeader?: any;
  renderRowData?: any;
};

type DataListType =
  | Partial<IFullLookupDataList>
  | Partial<IProjectList>
  | Partial<ITaskList>
  | Partial<IMyTaskList>
  | Partial<ILanguageTaskList>
  | Partial<IWorkerPerWordAmountList>
  | Partial<ITaskManager>;

interface ProjectTableProps extends React.HTMLAttributes<HTMLTableElement> {
  columns: readonly dataTableCodeType[];
  dataList?: DataListType[];
  clickRowData?: (rowData: any) => void;
  clickColData?: { [k: string]: (rowData: any) => void };
  noRowsMessage?: ReactNode;
  gap?: number;
  counter?: any;
}

export default function ProjectTable(props: ProjectTableProps) {
  const { columns, gap, counter, dataList, noRowsMessage, clickColData } =
    props;

  const ths = useMemo(() => {
    return columns.map(
      (column) => {
        const el =
          typeof column.renderHeader === 'function'
            ? column.renderHeader(column, counter, props)
            : column.value ?? '';
        return (
          <td key={column.code} className={cx(column.cssHeader)}>
            {el}
          </td>
        );
      },
      [columns]
    );
  }, [columns]);

  const tds = useMemo(() => {
    return Array.isArray(dataList) && dataList.length > 0 ? (
      dataList?.map((data, index) => {
        return (
          <tr key={index}>
            {columns.map((column, index) => {
              const k = column.code;
              const v = data[column.code];
              const isClickable =
                clickColData && typeof clickColData[k] === 'function';
              const onClick = () => {
                if (isClickable) {
                  clickColData[k](data);
                }
              };
              return (
                <td
                  key={index}
                  className={cx(column.cssBody)}
                  onClick={onClick}
                  style={{
                    cursor: isClickable ? 'pointer' : 'default'
                  }}
                >
                  {typeof column.renderRowData === 'function'
                    ? column.renderRowData(v, data, props)
                    : v ?? ''}
                </td>
              );
            })}
          </tr>
        );
      })
    ) : (
      <tr>
        <td
          colSpan={columns.length}
          style={{
            height: 46,
            color: '#727272'
          }}
        >
          {noRowsMessage ?? '조회된 정보가 없습니다.'}
        </td>
      </tr>
    );
  }, [dataList, columns, counter]);

  return (
    <SProjectTable className="main-talbe">
      <colgroup>
        {columns.map((column, i) => (
          <col key={i} className={column.code} />
        ))}
      </colgroup>
      <thead>
        <tr>{ths}</tr>
      </thead>
      <tbody>{tds}</tbody>
    </SProjectTable>
  );
}

const SProjectTable = styled.table`
  --border-color: #ececec;
  width: 1860px;
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 4px;

  col {
    min-width: 125px;
    max-width: 150px;

    &.taskTitle {
      min-width: 172px;
      max-width: 360px;
    }
    &.option {
      min-width: initial;
      max-width: initial;
    }
  }

  td {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 7px 10px;
  }

  td:last-of-type {
    padding: 0;
    width: 50px;
  }

  thead td {
    height: 34px;
    text-align: center;
    vertical-align: middle;
  }

  tbody {
    tr {
      border-top: 1px solid #ececec;
      td {
        height: 36px;
        text-align: center;
        vertical-align: middle;
      }

      &:hover {
        background-color: #f8f5fc;
      }
    }
  }
`;

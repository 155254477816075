import { css, cx } from '@emotion/css';
import styled from '@emotion/styled';
import { Color } from '@src/common/styles/color';
import { IconSearch } from '@src/resources/icon';
import { HTMLAttributes } from 'react';

interface ITPInputSearchNew extends HTMLAttributes<HTMLDivElement> {
  value: string;
  placeholder?: string;
  onChange: (e: any) => void;
  clickSearch: () => void;
  type?: string;
  width?: number;
}

export const TPInputSearchNew = ({
  className,
  value,
  placeholder,
  onChange,
  clickSearch,
  width,
  ...props
}: ITPInputSearchNew) => {
  return (
    <div
      className={cx('input-search', cssTPInputSearchNew, className)}
      {...props}
    >
      <input
        type={props.type ?? 'text'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          width: width ? `${width}px` : '100%'
        }}
        width={width}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            clickSearch();
          }
        }}
      />
      <IconSearch
        className="button"
        width={24}
        height={24}
        stroke="#000"
        fill="#000"
        onClick={clickSearch}
      />
    </div>
  );
};

const cssTPInputSearchNew = css`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 2px;
  .button {
    position: absolute;
    left: 10px;
    transition: 0.1s;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
    :active {
      color: red;
      opacity: 0.95;
      fill: ${Color.main};
      stroke: ${Color.main};
    }
  }

  input {
    height: 100%;
    border-radius: 2px;
    border: 1px solid transparent;
    padding: 0 20px 0 40px;
    color: #2c3558;
    transition: 0.1s;
    :hover {
      border-color: ${Color.main};
    }
    :focus {
      border-color: ${Color.main};
    }
    ::placeholder {
      color: #9a9cb5;
      font-size: 14px;
    }
  }
`;

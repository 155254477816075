import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { globalKeys } from '../queryKeyFactory';
import { IconBulRight, IconModalClose } from '@resources/icon';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { TaskerSearchCss } from '@src/hooks/modal/TaskerSearchBookMark.style';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { TPInputSearch } from '@units/input/TPInput.search';
import { TPSeparation } from '@units/contour/TPSeparation';
import { alertWrap, ModalTheme } from '@hooks/modal/Modal.style';
import { Backdrop, ThemeProvider, Modal, Grid, Fade, Box } from '@mui/material';
import { useState } from 'react';
import { IUser } from '@src/common/config/ICode';
import { getLanguageLabelsByCode } from '@common/config/Code';
import { SelectLanguagePairStyle } from '@hooks/modal/LanguageSelect.style';
import { IconCircleClose } from '@src/resources/icon';
import _ from 'lodash';
import { useGetAllAssignWorker } from '@hooks/statistics/saturation/getAssignWorker';

/**
 * useManagerSearch hook
 * @description 담당자 조회 선택 모달
 */
export const useManagerSearch = ({
  setWorkerUserList,
  description,
  isSelectAll
}: {
  setWorkerUserList?: (select: IUser[]) => void;
  description?: string;
  isSelectAll?: boolean;
}) => {
  //#region global state
  const managerSearchData = useGlobalValue([...globalKeys.managerSearch], {
    open: false
  });
  const managerSearchFetcher = useSetGlobalState([...globalKeys.managerSearch]);
  const { open } = managerSearchData;
  //#endregion

  //#region handler
  const openHandle = () => {
    managerSearchFetcher({
      ...managerSearchData,
      open: true
    });
  };
  const closeHandle = () => {
    managerSearchFetcher({ ...managerSearchData, open: false });
  };
  const confirmHandle = (select: IUser[]) => {
    if (setWorkerUserList) setWorkerUserList(select);
    managerSearchFetcher({ ...managerSearchData, open: false });
  };
  //#endregion

  //#region component
  const Component = ({
    workerUserList
  }: {
    workerUserList?: IUser[];
  }): JSX.Element => {
    const [select, setSelect] = useState<IUser[]>(workerUserList || []);
    const { searchWorkerData, keyword, setKeyword } = useGetAllAssignWorker();

    const changeText = (e: any) => setKeyword(e?.target?.value ?? '');

    const clickSelect = (idx: IUser) => {
      if (_.includes(select, idx)) {
        setSelect(_.filter(select, (item) => item !== idx));
      } else {
        if (
          _.isEqual(
            _.sortBy([...select, idx], 'userId'),
            _.sortBy(searchWorkerData, 'userId')
          ) &&
          !isSelectAll
        ) {
          if (searchWorkerData.length === 0) {
            setSelect([]);
          } else {
            setSelect([...select, idx]);
          }
        } else {
          setSelect([...select, idx]);
        }
      }
    };

    return (
      <ThemeProvider theme={ModalTheme}>
        <Modal
          styledmodal-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition={true}
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={open}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div css={[TaskerSearchCss, alertWrap]}>
                <button className="modalCloseButton" onClick={closeHandle}>
                  <IconModalClose width={24} height={24} />
                </button>
                <UnderlineTitle text="담당자 찾기" />
                <p>{description}</p>
                <div className="searchContainer">
                  <TPInputSearch
                    autoFocus={true}
                    size={'medium'}
                    value={keyword}
                    placeholder={'담당자 검색'}
                    changeText={changeText}
                    resetButton={true}
                    clickSearch={() => console.debug('clickSearch')}
                  />
                </div>

                <div className={'select--manager--list'}>
                  {select.length > 0 ? (
                    _.map(select, (item, index) => {
                      return (
                        <div css={SelectLanguagePairStyle} key={index}>
                          <span className="selectLanguagePair">
                            {item.name}
                          </span>
                          <IconCircleClose
                            onClick={() => clickSelect(item)}
                            className="removeButton"
                            width={24}
                            height={24}
                            fill={'#fff'}
                          />
                        </div>
                      );
                    })
                  ) : !isSelectAll ? (
                    <div css={SelectLanguagePairStyle}>
                      <span className="selectLanguagePair">전체</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <TPSeparation color="#F3F3F4" margin="24px 0 12px" />
                <ul className="manager--list">
                  {!isSelectAll && (
                    <li
                      className={select.length > 0 ? '' : 'active'}
                      onClick={() => setSelect([])}
                    >
                      <div className="img--avatar--name">
                        <Box component={'div'} className="name" ml={1.5}>
                          전체
                        </Box>
                      </div>
                      <div className="language--pair--list"></div>
                    </li>
                  )}
                  {_.map(searchWorkerData, (user, index) => {
                    let userName = user.name;
                    let userGrayScale = '0%';
                    let userBrightness = 1;
                    if (user.status === 'INACTIVE') {
                      userName = userName + ' (비활성화)';
                      userGrayScale = '100%';
                      userBrightness = 0.7;
                    } else if (user.status === 'LEAVE') {
                      userName = '삭제된 사용자';
                      userGrayScale = '100%';
                      userBrightness = 0.7;
                    }
                    return (
                      <li
                        className={_.includes(select, user) ? 'active' : ''}
                        onClick={() => clickSelect(user)}
                        key={`taskerList${index}`}
                      >
                        <div className="img--avatar--name">
                          <img
                            src={user.avatarImgUrl}
                            alt={''}
                            style={{
                              filter: `grayscale(${userGrayScale}) brightness(${userBrightness})`
                            }}
                          />
                          <div className="name">{userName}</div>
                        </div>
                        <div className="language--pair--list">
                          {_.map(user.languagePairList, (lang, index) => {
                            return (
                              <div
                                className="language--pair"
                                key={`pairList${index}`}
                              >
                                <span>
                                  {getLanguageLabelsByCode([
                                    lang.startLanguage
                                  ])[0]
                                    ? getLanguageLabelsByCode([
                                        lang.startLanguage
                                      ])[0].label
                                    : lang.startLanguage}
                                </span>
                                <IconBulRight width={18} height={18} />
                                <span>
                                  {getLanguageLabelsByCode([
                                    lang.destinationLanguage
                                  ])[0]
                                    ? getLanguageLabelsByCode([
                                        lang.destinationLanguage
                                      ])[0].label
                                    : lang.destinationLanguage}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="button--container">
                  <TPButtonBasic
                    variant="outlined"
                    onClick={closeHandle}
                    label="취소하기"
                  />
                  <TPButtonBasic
                    variant="contained"
                    disabled={!isSelectAll ? false : !select}
                    onClick={() => confirmHandle(select)}
                    label="선택하기"
                  />
                </div>
              </div>
            </Grid>
          </Fade>
        </Modal>
      </ThemeProvider>
    );
  };

  return {
    closeHandle,
    openHandle,
    Component,
    open
  };
  //#endregion
};

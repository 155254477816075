import { useNavigate } from 'react-router-dom';
import { errorsWrap } from '@pages/errors/Errors.style';
import Error404 from '@images/error-404.svg';

function ErrorsPage({
  status,
  message,
  info,
  cacheClean,
  callback
}: {
  status?: string;
  message?: string;
  info?: string;
  cacheClean?: boolean;
  callback?: () => void;
}) {
  const navigate = useNavigate();

  const fallback = () => {
    if (callback) {
      callback();
    } else {
      if (cacheClean) {
        window.localStorage.clear();
      }
      navigate('/login', { replace: true });
    }
  };

  return (
    <section css={errorsWrap}>
      <div className="errorsInner">
        <em className="errorsStatusWrap">
          {status === '404' ? (
            <img src={Error404} alt={'404'} />
          ) : (
            <div className={'errorsStatus'}>페이지에 접근할 수 없습니다.</div>
          )}
        </em>

        {status === '404' ? (
          <strong className="errorsMessage">{message}</strong>
        ) : (
          <span className="authMessage">
            요청하신 페이지에 대한 접근 권한이 없습니다. <br />
            사용자 권한은 보라캣 관리자에게 부여받을 수 있습니다.
            <br />
            권한을 받고 싶다면 보라캣 관리자에게 요청하세요.
          </span>
        )}
        <p className={`errorsInfo ${status !== '404' ? 'error' : ''}`}>
          {info}
        </p>
        <button className="errorsHomeBtn" onClick={fallback}>
          {cacheClean ? '로그인 화면으로 가기' : '홈으로 가기'}
        </button>
      </div>
    </section>
  );
}

export default ErrorsPage;

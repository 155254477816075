import { useState } from 'react';
import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { globalKeys } from '../queryKeyFactory';
import { IconModalClose } from '@resources/icon';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { TaskerSearchCss } from '@src/hooks/modal/TaskerSearch.style';
import UnderlineTitle from '@pages/components/UnderlineTitle';
import { alertWrap, ModalTheme } from '@hooks/modal/Modal.style';
import { Backdrop, ThemeProvider, Modal, Grid, Fade, Box } from '@mui/material';
import { TPAvatarBasic } from '@src/units/avatar/TPAvatar.Basic';
import { useGetAllPmList } from '../project/getAllPmList';
import _ from 'lodash';

/**
 * dateRangePicker hook
 * @type {(props?: IRangeCalendarState) => {dateRange: {from: Date, to: Date }; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useTaskerSearch = () => {
  //#region global state
  const taskerSearchData = useGlobalValue([...globalKeys.taskerSearch], {
    open: false
  });
  const taskerSearchFetcher = useSetGlobalState([...globalKeys.taskerSearch]);
  const { open, setData, mainPm, subPm } = taskerSearchData;
  //#endregion

  //#region handler
  const openHandle = (
    setData: (data: number) => void,
    mainPm?: any,
    subPm?: any
  ) => {
    taskerSearchFetcher({
      ...taskerSearchData,
      open: true,
      setData,
      mainPm,
      subPm
    });
  };
  const closeHandle = () => {
    taskerSearchFetcher({ ...taskerSearchData, open: false });
  };
  const confirmHandle = (select: any) => {
    setData(select);
    taskerSearchFetcher({ ...taskerSearchData, open: false });
  };
  //#endregion

  //#region component
  const Component = (): JSX.Element => {
    const { allPmList } = useGetAllPmList();
    const [select, setSelect] = useState<any>({});
    const boxStyle = (checked: boolean) => {
      let defaultStyle = { cursor: 'pointer', fontWeight: 500, ml: 1 };
      return checked
        ? {
            ...defaultStyle,
            display: 'inline',
            color: '#9B51E0'
          }
        : defaultStyle;
    };

    return open ? (
      <ThemeProvider theme={ModalTheme}>
        <Modal
          styledmodal-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition={true}
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={open}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div css={[TaskerSearchCss, alertWrap]}>
                <div>
                  <button className="modalCloseButton" onClick={closeHandle}>
                    <IconModalClose width={24} height={24} />
                  </button>
                  <UnderlineTitle text="담당 PM 선택" />
                </div>
                <div className="modal--div--search">
                  <div className="assignCountry">
                    {_.map(
                      _.filter(
                        _.sortBy(allPmList, 'name'),
                        (el) =>
                          el.userId !== 0 &&
                          el.userId !== mainPm?.userId &&
                          el.userId !== subPm?.userId
                      ),
                      (item: any, index: number) => {
                        return (
                          <Box
                            display={'flex'}
                            py={1}
                            key={index}
                            component="button"
                            onClick={() => setSelect(item)}
                          >
                            <TPAvatarBasic
                              alt={item.name}
                              size="xsmall"
                              src={item.avatarImgUrl}
                            />
                            <Box sx={boxStyle(select?.userId === item.userId)}>
                              {item.name}
                            </Box>
                            <Box display={'inline'} color={'#9A9CB5'} ml={3}>
                              PM팀
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="button--container">
                  <TPButtonBasic
                    variant="outlined"
                    label="취소"
                    fullWidth
                    onClick={closeHandle}
                    size={'large'}
                  />
                  <TPButtonBasic
                    variant="contained"
                    label="확인"
                    fullWidth
                    onClick={() => confirmHandle(select)}
                    disabled={!select}
                    size={'large'}
                  />
                </div>
              </div>
            </Grid>
          </Fade>
        </Modal>
      </ThemeProvider>
    ) : (
      <></>
    );
  };

  return { closeHandle, openHandle, Component };
  //#endregion
};

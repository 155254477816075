import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { TASK_TYPE } from '@src/common/config/ICode';
import { saturationKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';
import { useEffect, useState } from 'react';
import { LanguageCodeType } from '@src/common/config/Code';
import _ from 'lodash';
import download, { downloadByURL } from '@src/hooks/utils/download';

interface IGetDegree {
  day: string;
  data: IGetDegreeData[];
  isSunday: boolean;
}
interface IGetDegreeData {
  taskType: TASK_TYPE;
  amount: number;
}
/**
 * /statistics/wordPerSaturationDegree
 * @description 언어 별 포화도
 * @returns {{
 *  degreeFilter, startLanguage, destinationLanguage,
 *  getDegreeData, setState, remove, refetch, downloadData
 * }}
 */
export const useGetSaturationDegree = (filterData: any) => {
  const { arraySet } = useHookFunc();

  //#region state define
  const [degreeFilter, setDegreeFilter] = useState<{
    taskTypeList: any[];
    taskStatusList: any[];
  }>({
    taskTypeList: ['ALL'],
    taskStatusList: ['ALL']
  });
  const [startLanguage, setStartLanguage] = useState<LanguageCodeType>('');
  const [destinationLanguage, setDestinationLanguage] =
    useState<LanguageCodeType>('');
  const [isFilter, setIsFilter] = useState<boolean>(false);
  //#endregion

  useEffect(() => {
    if (!filterData) return;
    setStartLanguage(filterData?.startLanguage ?? '');
    setDestinationLanguage(filterData?.destinationLanguage ?? '');
    setDegreeFilter(
      filterData?.degreeFilter ?? {
        taskTypeList: ['ALL'],
        taskStatusList: ['ALL']
      }
    );
  }, [filterData]);

  useEffect(() => {
    setIsFilter(
      startLanguage !== '' ||
        destinationLanguage !== '' ||
        !degreeFilter.taskTypeList.includes('ALL') ||
        !degreeFilter.taskStatusList.includes('ALL')
    );
  }, [startLanguage, destinationLanguage, degreeFilter]);

  //#region setState
  const setState = {
    setTaskTypeList: (e: any) => {
      const taskTypeList =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(degreeFilter.taskTypeList, e),
              (item) => item !== 'ALL'
            );
      setDegreeFilter({
        ...degreeFilter,
        taskTypeList: taskTypeList.length ? taskTypeList : ['ALL']
      });
    },
    setTaskStatusList: (e: any) => {
      const taskStatusList =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(degreeFilter.taskStatusList, e),
              (item) => item !== 'ALL'
            );
      setDegreeFilter({
        ...degreeFilter,
        taskStatusList: taskStatusList.length ? taskStatusList : ['ALL']
      });
    },
    setStartLanguage,
    setDestinationLanguage,
    setResetState: () => {
      setDestinationLanguage('');
      setStartLanguage('');
      setDegreeFilter({
        taskTypeList: ['ALL'],
        taskStatusList: ['ALL']
      });
    }
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    taskTypeList,
    taskStatusList,
    startLanguage,
    destinationLanguage
  }: {
    taskTypeList: string[];
    taskStatusList: string[];
    startLanguage: string;
    destinationLanguage: string;
  }) => {
    const params = new URLSearchParams();

    if (!taskTypeList.includes('ALL')) {
      params.set('taskTypeList', taskTypeList.join(','));
    }

    if (!taskStatusList.includes('ALL')) {
      params.set('taskStatusList', taskStatusList.join(','));
    }

    if (startLanguage) {
      params.append('startLanguage', startLanguage);
    }

    if (destinationLanguage) {
      params.append('destinationLanguage', destinationLanguage);
    }

    const nowDate = new Date();
    params.append('nowDate', nowDate.toISOString().split('T')[0]);

    return params;
  };
  //#endregion

  const getData = async (): Promise<IGetDegree[]> => {
    const getPayload = setPayload({
      ...degreeFilter,
      startLanguage,
      destinationLanguage
    });

    return (await api.getPms(`/statistics/wordPerSaturationDegree`, getPayload))
      .data;
  };

  const downloadData = async () => {
    const payload = setPayload({
      ...degreeFilter,
      startLanguage,
      destinationLanguage
    });

    const url = `${
      process.env.REACT_APP_API_BASE_URL
    }pms/statistics/wordPerSaturationDegree/excel${
      payload.size ? `?${payload}` : ''
    }`;

    downloadByURL(url); // GET
  };

  const {
    data: getDegreeData = [],
    refetch,
    remove
  } = useQuery([...saturationKeys.getDegree], getData, {
    enabled: false
  });

  return {
    degreeFilter,
    startLanguage,
    destinationLanguage,
    getDegreeData,
    setState,
    remove,
    refetch,
    downloadData,
    isFilter
  };
};

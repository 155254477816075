import { keyframes } from '@emotion/react';
import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';

const verticalUp = keyframes`
  0% { transform: translateY(50px) scale(0.8,0.8); opacity: 0; } 
  100% { transform: translateY(0) scale(1,1); opacity: 1; }  
`;

const modalStyle = css`
  background: #fff;
  overflow-y: scroll;
  margin-right: -20px;
  padding-right: 20px;
  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    margin: 50px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d5d3e4;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    margin-left: 10px;
  }
`;

export const ModalTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: 'rgba(0,0,0,0.3)'
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          height: '100%'
        }
      }
    }
  }
});

export const alertWrap = css`
  font-family: 'IBM Plex Sans', sans-serif;
  box-sizing: border-box;
  position: absolute;
  background: #fff;
  min-width: 758px;
  //max-width: 420px;
  max-height: calc(100% - 30px);
  border-radius: 2px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  padding: 40px 21px 38px;
  text-align: center;
  animation: ${verticalUp} 0.25s cubic-bezier(0.34, 1.56, 0.64, 1) 1;
  .hidden {
    display: none;
  }
  .alertClose {
    position: absolute;
    top: 5px;
    right: 14px;
    padding: 0;
  }
  .alertTitle {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.25px;
    text-align: center;
    margin-bottom: 64px;
    color: #242424;
  }
  h3.alertTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
    margin-bottom: 0px;
  }
  .alertBtn {
    display: block;
    width: 100%;
    margin-top: 40px;
    background: linear-gradient(270deg, #aa51e0 0%, #9b51e0 100%);
    border-radius: 2px;
    color: #fff;
    line-height: 56px;
  }
  .confirmBtnWrap {
    width: 340px;
    .confirmBtn {
      display: inline-block;
      width: 162px;
      margin-top: 40px;
      border-radius: 2px;
      line-height: 48px;
      &.ok {
        color: #fff;
        background: linear-gradient(270deg, #aa51e0 0%, #9b51e0 100%);
      }
      &.cancel {
        margin-right: 16px;
        border: 1px solid #b5b3c6;
      }
    }
  }
  .modal--div--search {
    max-height: 300px;
    ${modalStyle}
  }
  .modal--div--contents {
    max-height: 600px;
    ${modalStyle}
  }
  .modal--div--modify {
    max-height: 700px;
    ${modalStyle}
  }
  .modal--div--register {
    ${modalStyle}
    gap: 16px;
  }
`;

export const alertPasswordComplete = css`
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  position: absolute;
  background: #9b51e0;
  border-radius: 2px;
  width: 420px;
  line-height: 114px;
  text-align: center;
  animation: ${verticalUp} 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 1;
`;

import { css } from '@emotion/react';
import { Color } from '@common/styles/color';
import { createTheme } from '@mui/material/styles';
import styled from '@emotion/styled';

export const JobDetailStyle = css`
  width: 756px;
  max-width: 100%;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 20px 0 !important;

  .datail-div-type {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-align: left;
    padding: 0px 4px;
    color: #727272;
    background-color: #f3f3f3;
    margin: auto;
    border-radius: 2px;
    white-space: nowrap;
  }

  .datail-div-content {
    overflow: auto;
    padding: 0 18px;
    margin: 0 2px;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: #ececec;
      border-radius: 1px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    .content-div-infoBox {
      background-color: #fafafa;
      padding: 10px 16px;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      gap: 2px;

      .infoBox-row {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .content-div-info {
      padding: 20px 16px;
    }

    .dates {
      display: flex;
      gap: 60px;
      margin-top: 16px;
    }

    .content-div-language {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.25px;
      margin-top: 20px;
      color: #242424;
      white-space: break-spaces;
      word-break: break-all;
      &.bottom {
        margin-bottom: 12px;
      }
    }

    .info-div-text {
      margin-top: 16px;
      min-height: 2rem;

      &.description {
        white-space: pre-wrap;
        word-break: break-all;
      }
    }

    .content-div-line {
      height: 1px;
      width: 100%;
      margin-bottom: 20px;
      background-color: #ececec;
    }
  }

  .datail-span-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
    white-space: break-spaces;
    word-break: break-all;
    text-align: center;
    padding: 0 16px;

    margin-top: 6px;
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    flex-direction: row;
    gap: 28px;
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      svg {
        display: none;
      }
      &.active {
        color: #9b51e0;
        svg {
          display: block;
        }
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
  }

  .totalRate {
    .totalRateTitleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      display: flex;
      flex-direction: row;
      .left {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
      .title {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0px;
      }
    }
    .amountInfo {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      > .item {
        font-size: 14px;
      }
      > .value {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .title {
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .color--red {
      color: ${Color.compulsory};
    }
  }

  .grossAndNet,
  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    background-color: #f5f5f5;
    padding: 2px 6px;
    white-space: nowrap;
    line-height: 19px;
    letter-spacing: -0.25px;

    .value {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: #727272;
      &.off {
        color: #aaaaaa;
      }
      &.on {
        color: #9b51e0;
      }
    }
  }

  .name {
    margin-right: 4px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #aaaaaa;
    &.tm {
      color: #727272;
    }
    &.amount {
      font-size: 14px;
    }
  }
`;

export const AssignTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid  #EEEDF4',
          '&.Mui-expanded, &.Mui-expanded:first-of-type': {
            margin: 0
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          '&:before': { display: 'none' },
          '&.Mui-expanded, &.Mui-expanded:first-of-type': {
            margin: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 0
          },
          '&.disable:hover': {
            cursor: 'default'
          }
        },
        content: {
          '& > dl:first-of-type': { width: '26%' },
          '& > dl:nth-of-type(2)': { width: '24%' },
          '& > dl:nth-of-type(3)': { width: '24%' },
          '& > dl:last-of-type': { width: '26%' },
          '& dt': { color: '#9A9CB5' },
          '&.Mui-expanded': {
            margin: '12px 0'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          background: '#F9F8FC',
          padding: 0,
          '& > div': { borderTop: '1px solid #EEEDF4' },
          '& > div:first-of-type': { borderTop: 0 }
        }
      }
    }
  }
});

interface ISubTaskKeyBox {
  selectSubList: boolean;
}

export const SubTaskKeyBox = styled.div<ISubTaskKeyBox>`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  div.item {
    display: flex;
    gap: 2px;
    font-size: 14px;
    span.name {
      color: #9a9cb5;
    }
    span.value {
      padding: 0 6px;
      &.key {
        ${({ selectSubList }) => {
          if (selectSubList)
            return css`
              background-color: #ffb880;
            `;
        }}
      }
    }
  }
`;

interface ISubTaskBox {
  selectSubList: boolean;
}

export const SubTaskBox = styled.div<ISubTaskBox>`
  padding: 24px 40px 25px;
  background-color: #fafaff;
  .amountMismatch {
    color: ${Color.compulsory};
    font-size: 14px;
    margin-bottom: 4px;
  }
  .key--amount {
    display: flex;
    height: 22px;
    align-items: center;
    font-size: 14px;
    gap: 40px;
    .key,
    .amount {
      height: 100%;
      .item {
        height: 22px;
        color: #9a9cb5;
        margin-right: 8px;
      }
      .value {
        height: 22px;
        &.key {
          padding: 2px 4px;
          ${({ selectSubList }) => {
            if (selectSubList)
              return css`
                background-color: #ffb880;
              `;
          }}
        }
      }
    }
  }
  .work--box--container {
    margin-top: 22px;
    display: flex;
    align-items: center;
    gap: 40px;
  }
`;

export const cssTaskMakerItemDetail = css`
  position: relative;
  padding: 16px 16px 24px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .button.close.circle {
    position: absolute;
    top: 18px;
    right: 16px;
  }

  .language-pair {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;

    color: #242424;
  }

  .add-button {
    width: 82px;
    height: 40px;
    background-color: #9b51e0;
    color: #ececec;
    padding: 5px 14px;
    border-radius: 2px;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #727272;
  }

  .language-sub-task-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-right: 22px;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .amountMismatch {
      color: ${Color.compulsory};
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .time-calculation {
    display: flex;
    width: 77px;
    height: 22px;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.25px;
    margin-top: 8px;
  }
  .workers {
    /* display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr;
    align-items: center;
    column-gap: 28px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 50px 0 14px;

    .worker {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        padding: 2px 6px;
        min-width: 70px;
      }

      &.PROGRESS {
        background-color: #f0e44220;
      }
    }
  }
`;

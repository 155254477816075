import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@api/Api';
import { authKeys } from '@hooks/queryKeyFactory';
import { useAlert } from '@hooks/modal/useAlert';
import Constant from '@common/config/Constant';

/**
 * /auth/email-validation/{email}
 * @description 계정 이메일 유효성 검사
 * @returns {{ validEmail, mutate, variables }}
 */
export const useEmailValidation = () => {
  const queryClient = useQueryClient();
  const { openHandle, closeHandle } = useAlert();

  const getData = async (email?: string) => {
    if (!email) return '';
    return (await api.get(`/auth/email-validation/${email}`)).data;
  };

  const {
    data: validEmail,
    mutate,
    variables
  } = useMutation([...authKeys.emailValidation], {
    mutationFn: getData,
    onSuccess: (data, variables) => {
      switch (data) {
        case Constant.EMAIL_ACTIVE:
          queryClient.setQueryData([...authKeys.emailValidation], data);
          break;
        case Constant.EMAIL_INACTIVE:
          openHandle({
            text: `${variables}<br/>계정을 다시 활성화하고 싶으신 경우,<br/> 아래 고객센터로 문의해 주시기 바랍니다.`,
            title: '비활성화된 계정',
            okLabel: 'help@2bytes.io',
            ok: () => {
              closeHandle();
              window.location.href = 'mailto:help@2bytes.io';
            }
          });
          break;
        case Constant.EMAIL_SLEEP:
          openHandle({
            text: `${variables}<br/>회원탈퇴 신청중인 회원입니다. 탈퇴신청취소를 원하시는 경우,<br/> 아래 고객센터로 문의해 주시기 바랍니다.`,
            title: '회원탈퇴 신청중',
            okLabel: 'help@2bytes.io',
            ok: () => {
              closeHandle();
              window.location.href = 'mailto:help@2bytes.io';
            }
          });
          break;
        case Constant.EMAIL_LEAVE:
          openHandle({
            text: `${variables}<br/>동일한 계정으로 다시 가입을 원하시는 경우,<br/> 아래 고객센터로 문의해 주시기 바랍니다.`,
            title: '탈퇴 계정 안내',
            okLabel: 'help@2bytes.io',
            ok: () => {
              closeHandle();
              window.location.href = 'mailto:help@2bytes.io';
            }
          });
          break;
        case '':
          openHandle({
            text: `${variables}<br/>회원정보가 없습니다. 가입을 원하시는 경우,<br/> 아래 고객센터로 문의해 주시기 바랍니다.`,
            title: '회원정보가 없습니다.',
            okLabel: 'help@2bytes.io',
            ok: () => {
              closeHandle();
              window.location.href = 'mailto:help@2bytes.io';
            }
          });
          break;
        default:
          break;
      }
    }
  });

  return {
    validEmail,
    mutate,
    variables
  };
};

import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { languageTaskKeys } from '@hooks/queryKeyFactory';
import { ITaskData } from '@src/common/config/ITask';
import { useEffect } from 'react';

/**
 * /languageTask/{languageTaskId}/assignInfo
 * @param languageTaskId 언어 작업 아이디
 * @returns {{ assignInfoData }}
 */
export const useGetTaskAssignInfo = (languageTaskId: string) => {
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof languageTaskKeys)['assignInfo']>
  >): Promise<ITaskData> => {
    const [, , languageTaskId] = queryKey;
    return (await api.get(`/languageTask/assignInfo/${languageTaskId}`)).data;
  };

  const {
    data: assignInfoData = {
      languageTaskId: 0,
      languageTaskKey: '',
      taskKey: '',
      taskTitle: '',
      taskType: '',
      totalNetAmount: 0,
      totalGrossAmount: 0,
      languageSubTaskList: []
    },
    refetch
  } = useQuery([...languageTaskKeys.assignInfo(languageTaskId)], getData);

  useEffect(() => {
    refetch();
  }, [languageTaskId]);

  return { assignInfoData };
};

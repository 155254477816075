import {
  LanguageCodeType,
  fileUseYn,
  getLanguageLabelByCode
} from '@src/common/config/Code';
import { usePageFunc } from '@src/hooks/utils';
import { TPInputDate } from '@src/units/input/TPInput.date';
import { TPInputRadio } from '@src/units/input/TPInput.radio';
import { differenceInMinutes } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../components/AntSwitch';
import { TaskAmountInputs } from '../components/TaskAmountInputs.Comp';
import { cssTaskMakerItem } from './JobRegister.style';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPInputText } from '@src/units/input/TPInput.text';
import { cx } from '@emotion/css';
import {
  amountArrayToObject,
  renderLanguageSubTaskStatus,
  renderLanguageTaskStatus,
  renderRowStatus
} from '../dashboard/utils/render';
import { context } from '@src/Context';
import styled from '@emotion/styled';
import { useDeleteLanguageSubTask } from '@src/hooks/languageTask/deleteTask';
import { TPSelectBasic } from '@src/units/select/TPSelect.basic';

export interface LanguageTaskMakerItemProps {
  openCalendar: any; //
  startDateTime: Date | undefined; //
  _rid?: string; //
  onDelete: () => void; //
  onMakeDisabled: (isDisabled: boolean) => void; //
  languageTaskTitle: string;
  isBoracatUseOrNot: boolean;
  fileUse: string;
  tmMatch: boolean;
  taskAmount: { [key: string]: any };
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType;
  languageTaskAmountList: [any, any][];
  grossAmount: number;
  netAmount: number;
  endDateTime: Date | undefined;
  hopeSupplyDateTime: Date | undefined;
  pageMode?: string;
  taskListLength: number;
  languageTaskId: string;
  taskType: string;
  // 수정일 경우 존재
  languageTaskStatus?: string;
  languageTaskKey?: string;
}

export function LanguageTaskMakerItem({
  openCalendar,
  startDateTime,
  onDelete,
  onMakeDisabled,
  pageMode,
  languageTaskStatus,
  languageTaskKey,
  taskListLength,
  taskType,
  ...props
}: LanguageTaskMakerItemProps) {
  const { makerItemsRef } = useContext(context);
  const { deleteTaskAlert } = useDeleteLanguageSubTask();
  const [languageTaskTitle, setLanguageTaskTitle] = useState<string>(
    props.languageTaskTitle
  );
  const [fileUse, setFileUse] = useState<string>(props.fileUse); // 보라캣 사용여부
  const isBoracatUseOrNot = useMemo(() => fileUse === 'in', [fileUse]);
  const [tmMatch, setTmMatch] = useState<boolean>(Boolean(props.tmMatch));
  const [taskAmount, setTaskAmount] = useState<{ [key: string]: any }>({
    PERCENT_101: '',
    PERCENT_REP: '',
    PERCENT_100: '',
    PERCENT_9599: '',
    PERCENT_8594: '',
    PERCENT_7584: '',
    PERCENT_5074: '',
    NO_MATCH: '',
    ...amountArrayToObject(props.languageTaskAmountList)
  });
  const [totalAmount, setTotalAmount] = useState({
    totalGrossAmount: props.grossAmount ?? 0,
    totalNetAmount: props.netAmount ?? 0
  });
  const { taskAmountRate, taskAmountText, getDateTime } = usePageFunc();
  const [hour, setHour] = useState<number>(
    props.grossAmount > 0 ? Math.floor(props.grossAmount / 250) : 0
  );
  const [minute, setMinute] = useState<string>(
    props.grossAmount % 250 > 0 ? '0.5' : '0'
  );
  const [endDateTime, setEndDateTime] = useState<Date | undefined>(
    props.endDateTime
  );
  const [hopeSupplyDateTime, setHopeSupplyDateTime] = useState<
    Date | undefined
  >(props.hopeSupplyDateTime);

  const changeInput = (data: { [key: string]: any }) => {
    setTaskAmount(data);
    const totalNetAmount = Number(
      Object.values(taskAmountRate)
        .reduce((sum, val, index) => {
          return sum + Number(Object.values(data)[index]) * val;
        }, 0)
        .toFixed()
    );
    const totalGrossAmount = Number(
      Object.values(data).reduce((sum, val, index) => {
        return sum + Number(Object.values(data)[index]);
      }, 0)
    );
    setTotalAmount({ totalGrossAmount, totalNetAmount });
  };

  const changeTimeInput = (time: string, type: 'hour' | 'minute') => {
    const amount =
      type === 'hour'
        ? Number(Number(time) * 250 + Number(minute) * 250)
        : Number(hour * 250 + Number(time) * 250);
    setTotalAmount({ totalGrossAmount: amount, totalNetAmount: amount });
  };

  const endDateTimeMessage = () => {
    let msg = '';
    if (endDateTime && startDateTime) {
      if (differenceInMinutes(startDateTime, endDateTime) > 0) {
        msg = '마감일이 시작일보다 빠릅니다.';
      }
    }
    return msg;
  };

  const hopeSupplyDateTimeMessage = () => {
    let msg = '';

    if (hopeSupplyDateTime && endDateTime && startDateTime) {
      if (differenceInMinutes(startDateTime, hopeSupplyDateTime) > 0) {
        msg = '납품일이 시작일보다 빠릅니다.';
      } else if (differenceInMinutes(endDateTime, hopeSupplyDateTime) > 0) {
        msg = '납품일이 마감일보다 빠릅니다.';
      }
    }
    return msg;
  };

  const isOnMakeDisabled = useMemo(() => {
    const a =
      isBoracatUseOrNot ||
      totalAmount.totalGrossAmount > 0 ||
      totalAmount.totalNetAmount > 0;
    const b = Boolean(
      startDateTime &&
        endDateTime &&
        hopeSupplyDateTime &&
        endDateTimeMessage().length === 0 &&
        hopeSupplyDateTimeMessage().length === 0
    );
    const c = languageTaskTitle.length > 0 && languageTaskTitle.length <= 100;
    return !(a && b && c);
  }, [
    isBoracatUseOrNot,
    totalAmount.totalGrossAmount,
    totalAmount.totalNetAmount,
    startDateTime,
    endDateTime,
    hopeSupplyDateTime,
    endDateTimeMessage,
    hopeSupplyDateTimeMessage,
    languageTaskTitle
  ]);

  const isDisabled = useMemo(
    () =>
      pageMode === 'modify' &&
      !['NEW', 'PROGRESS', 'COMPLETE_ASSIGN', 'COMPLETE'].includes(
        languageTaskStatus as string
      ),
    [languageTaskStatus, pageMode]
  );

  useEffect(() => {
    onMakeDisabled(isOnMakeDisabled);
  }, [isOnMakeDisabled]);

  useEffect(() => {
    makerItemsRef.current[props._rid as string] = {
      languageTaskTitle,
      isBoracatUseOrNot,
      fileUse,
      tmMatch,
      totalAmount,
      endDateTime,
      hopeSupplyDateTime,
      taskAmount,
      startLanguage: props.startLanguage,
      destinationLanguage: props.destinationLanguage
    };
  });

  useEffect(() => {
    if (!tmMatch && taskType !== 'LQA') {
      const getData = {
        ...taskAmount,
        PERCENT_101: '0',
        PERCENT_100: '0',
        PERCENT_9599: '0',
        PERCENT_8594: '0',
        PERCENT_7584: '0',
        PERCENT_5074: '0'
      };
      setTaskAmount(getData);

      const totalNetAmount = Number(
        Object.values(taskAmountRate)
          .reduce((sum, val, index) => {
            return sum + Number(Object.values(getData)[index]) * val;
          }, 0)
          .toFixed()
      );
      const totalGrossAmount = Number(
        Object.values(getData).reduce((sum, val, index) => {
          return sum + Number(Object.values(getData)[index]);
        }, 0)
      );

      setTotalAmount({ totalGrossAmount, totalNetAmount });
    }
  }, [tmMatch]);

  return (
    <div css={cssTaskMakerItem}>
      <div className="language-pair">
        {pageMode !== 'modify' ? null : (
          <div className="language-sub-task-status">
            {renderRowStatus(languageTaskStatus)}
          </div>
        )}
        {getLanguageLabelByCode(props.startLanguage)}
        <svg width="6" height="10" viewBox="0 0 6 10">
          <path
            d="M1 8.99935L5.24264 4.75671M1 1.35938L5.24264 5.60202"
            stroke="currentColor"
            strokeWidth="1.2"
          />
        </svg>
        {getLanguageLabelByCode(props.destinationLanguage)}

        {taskListLength > 1 &&
          (pageMode === 'copy' ||
            ['NEW', 'COMPLETE_ASSIGN'].includes(languageTaskStatus ?? '')) && (
            <TPButtonDelete
              onClick={(e) => {
                e.stopPropagation();
                if (pageMode === 'modify') {
                  deleteTaskAlert({ id: props.languageTaskId });
                } else {
                  onDelete();
                }
              }}
            />
          )}
      </div>
      <div
        className={cx('lengthCheck', {
          lengthError: languageTaskTitle.length > 100
        })}
      >
        <span>
          <s>{languageTaskTitle.length}</s>/100
        </span>
        <TPInputText
          label={'작업명'}
          name={'title'}
          value={languageTaskTitle}
          changeText={(e: any) => setLanguageTaskTitle(e.target.value)}
          required={true}
          disabled={isDisabled}
          placeholder={'작업명을 100자 이내 입력해 주세요.'}
        />
      </div>

      <div>
        <TPInputRadio
          value={fileUse}
          label={'작업 환경'}
          required={true}
          name={'fileUse'}
          options={fileUseYn}
          disabled={isDisabled}
          changeRadio={(e: any) => setFileUse(e.target.value)}
        />
      </div>
      <div className="totalRate">
        <div className="totalRateTitleRow">
          <div className="left">
            <div className="title">
              <div>
                총 분량 <span className="color--red">*</span>
              </div>
            </div>

            {taskType !== 'LQA' && (
              <div className="grossAndNet">
                <div className="gross">
                  <span className="name">Gross</span>
                  <span className="value">
                    {totalAmount.totalGrossAmount.toLocaleString('en')}
                    {/* 입력한 매치율 별 단어 수 총합이 자동 계산됩니다. */}
                  </span>
                </div>
                <div className="title">
                  <span className="name">Net</span>
                  <span className="value">
                    {totalAmount.totalNetAmount.toLocaleString('en')}
                    {/* 입력한 매치율 별 단어 수가 각 배율에 따라 자동 계산됩니다. */}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="controls">
            {taskType === 'LQA' ? (
              <>(250단어 · 자 / 시간)</>
            ) : (
              <>
                TM매치
                <AntSwitch
                  checked={tmMatch}
                  onChange={() => setTmMatch((v) => !v)}
                  inputProps={{ 'aria-label': 'ant design' }}
                  disabled={isDisabled}
                />
              </>
            )}
          </div>
        </div>

        {taskType === 'LQA' ? (
          <div className="time-calculation">
            <input
              value={hour}
              type="number"
              className="hour"
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  return;
                }
                setHour(Number(e.target.value));
                changeTimeInput(e.target.value, 'hour');
              }}
            />
            시간
            <TPSelectBasic
              large={true}
              value={minute}
              options={[
                {
                  label: '0',
                  option: '0'
                },
                {
                  label: '30',
                  option: '0.5'
                }
              ]}
              autoWidth={false}
              changeSelect={(e) => {
                setMinute(e.target.value);
                changeTimeInput(e.target.value, 'minute');
              }}
            />
            분
          </div>
        ) : isBoracatUseOrNot ? (
          <div className="desc">
            작업 파일 등록 시, 분량이 자동 계산 됩니다.
          </div>
        ) : fileUse === 'out' ? (
          <TaskAmountInputs
            tmMatch={tmMatch}
            taskAmount={taskAmount}
            inputText={taskAmountText}
            changeInput={changeInput}
            isDisabled={isDisabled}
          />
        ) : null}
      </div>
      <div className="dates">
        <div>
          <TPInputDate
            name={'endDateTime'}
            label={'마감일'}
            date={endDateTime}
            helperText={endDateTimeMessage()}
            placeholder={'날짜를 입력해 주세요.'}
            openHandle={() => openCalendar('end', endDateTime, setEndDateTime)}
            required
            disabled={isDisabled}
          />
        </div>
        <div>
          <TPInputDate
            name="hopeSupplyDateTime"
            label="납품일"
            placeholder="날짜를 입력해 주세요."
            date={hopeSupplyDateTime}
            openHandle={() =>
              openCalendar('hope', hopeSupplyDateTime, setHopeSupplyDateTime)
            }
            helperText={hopeSupplyDateTimeMessage()}
            required
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
}

interface TPButtonCloseProps extends React.HTMLAttributes<HTMLDivElement> {}

function TPButtonDelete({ ...props }: TPButtonCloseProps) {
  return (
    <TPButtonCloseWrap className="circle button close" {...props}>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.86987 0.3584L4.14751 3.08076L1.42515 0.3584L1.31909 0.252335L1.21302 0.358401L0.505914 1.06551L0.399848 1.17157L0.505913 1.27764L3.22827 4L0.602589 6.62569L0.496523 6.73175L0.602589 6.83782L1.3097 7.54492L1.41576 7.65099L1.52183 7.54493L4.14751 4.91924L6.86987 7.6416L6.97594 7.74767L7.08201 7.6416L7.78911 6.93449L7.89518 6.82843L7.78911 6.72236L5.06675 4L7.78911 1.27764L7.89518 1.17157L7.78911 1.06551L7.08201 0.3584L6.97594 0.252334L6.86987 0.3584Z"
          strokeWidth="0.3"
        />
      </svg>
    </TPButtonCloseWrap>
  );
}

const TPButtonCloseWrap = styled.div`
  --white: #ffffff;
  --black: #242424;

  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: var(--black);
    fill: var(--black);
  }
`;

import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { languageTaskKeys } from '@hooks/queryKeyFactory';
import { ILanguageTaskData } from '@src/common/config/ILanguageTask';

/**
 * /languageTask/{languageTaskId}
 * @param languageTaskId 언어 작업 아이디
 * @returns {{ detailData, error }}
 */
export const useGetTaskDetail = (languageTaskId: string) => {
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof languageTaskKeys)['get']>
  >): Promise<ILanguageTaskData> => {
    const [, languageTaskId] = queryKey;
    return (await api.get(`/languageTask/${languageTaskId}`)).data;
  };

  const {
    error,
    data: detailData = {
      languageTaskId: 0,
      languageTaskKey: '',
      taskTitle: '',
      totalGrossAmount: 0,
      totalNetAmount: 0,
      description: '',
      taskAmountList: [],
      linkList: [],
      languageSubTaskList: []
    }
  } = useQuery([...languageTaskKeys.get(languageTaskId)], getData, {
    enabled: !!languageTaskId,
    refetchOnMount: true
  });

  return { detailData, error };
};

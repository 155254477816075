import { createTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const ProgressTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          height: '100%'
        }
      }
    }
  }
});

const spinnerInit = keyframes`
0% { opacity: 0; transform: scale(0.5, 0.5); }
100% { opacity: 1; transform: scale(1, 1); }
`;

const spinning = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) } 
`;

export const ProgressStyle = styled.div`
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  display: inline-block;
  width: 84px;
  height: 84px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  animation: ${spinnerInit} 0.2s ease-in-out 1;
  & > span {
    position: absolute;
    animation: ${spinning} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ffffff;
      margin: -3px 0 0 -3px;
    }
    &:nth-of-type(1) {
      animation-delay: -0.036s;
      &:after {
        top: 50px;
        left: 50px;
      }
    }
    &:nth-of-type(2) {
      animation-delay: -0.072s;
      &:after {
        top: 54px;
        left: 45px;
      }
    }
    &:nth-of-type(3) {
      animation-delay: -0.108s;
      &:after {
        top: 57px;
        left: 39px;
      }
    }
    &:nth-of-type(4) {
      animation-delay: -0.144s;
      &:after {
        top: 58px;
        left: 32px;
      }
    }
    &:nth-of-type(5) {
      animation-delay: -0.18s;
      &:after {
        top: 57px;
        left: 25px;
      }
    }
    &:nth-of-type(6) {
      animation-delay: -0.216s;
      &:after {
        top: 54px;
        left: 19px;
      }
    }
    &:nth-of-type(7) {
      animation-delay: -0.252s;
      &:after {
        top: 50px;
        left: 14px;
      }
    }
    &:nth-of-type(8) {
      animation-delay: -0.288s;
      &:after {
        top: 45px;
        left: 10px;
      }
    }
  }
`;

import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { calenderKeys } from '@hooks/queryKeyFactory';
import _ from 'lodash';

interface IHolidays {
  year: number;
  month: number;
  day: number;
  yearMonthDay: string;
}

/**
 * /calender/holidays
 * @description 공휴일 조회
 * @returns {{ holidays }}
 */
export const useGetHolidays = () => {
  //#region api call
  const getData = async (): Promise<IHolidays[]> => {
    const [year] = new Date().toISOString().split('T')[0].split('-');
    return (await api.getPms(`/calender/holidays?year=${year}`)).data;
  };
  //#endregion

  //#region useQuery define
  const { data = [] } = useQuery([...calenderKeys.holiday], getData);
  //#endregion

  const holidays: { [key: string]: any } = {};

  _.map(data, (el) => {
    const month = el.month < 10 ? `0${el.month}` : el.month;
    const day = el.day < 10 ? `0${el.day}` : el.day;
    holidays[`${el.year}-${month}`] = [
      ...(holidays[`${el.year}-${month}`] || []),
      day.toString()
    ];
  });

  return { holidays };
};

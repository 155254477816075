export class ErrorCustom extends Error {
  private data: string;
  private error: object;
  private status: number;
  private internal: boolean;
  private statusText: string;
  constructor(
    data = '',
    error = {},
    status = 0,
    internal = false,
    statusText = ''
  ) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorCustom);
    }

    this.data = data;
    this.error = error;
    this.status = status;
    this.internal = internal;
    this.statusText = statusText;
  }
}

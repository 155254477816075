import { useQuery } from '@tanstack/react-query';
import { myInfoKeys } from '@hooks/queryKeyFactory';
import api from '@api/Api';

export const useMyInfo = () => {
  const getMyInfoData = async () => {
    if (
      !localStorage.getItem('accessToken') &&
      localStorage.getItem('accessToken') === null
    ) {
      return null;
    }
    return (await api.get(`/myInfo`)).data;
  };

  const myInfoQuery = useQuery([...myInfoKeys.get], getMyInfoData);
  const {
    data: myInfo,
    refetch: refetchMyInfo,
    isLoading,
    isSuccess,
    error
  } = myInfoQuery;

  return {
    myInfo,
    refetchMyInfo,
    isSuccess,
    isLoading,
    error,
    myInfoQuery
  };
};

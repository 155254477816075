const Constant = {
  // 개인정보 동의
  PRIVACY_POLICY_AGREEMENT_YES: 'YES',

  // 로그인시 email 가입 유무 API 응답값
  EMAIL_ACTIVE: 'ACTIVE', // 정상
  EMAIL_INACTIVE: 'INACTIVE', // 비활성
  EMAIL_SLEEP: 'SLEEP', // 보관
  EMAIL_LEAVE: 'LEAVE', // 탈퇴
  EMAIL_NONE: 'NONE', // 없음

  // Project 작업 납품 정보
  DELIVERY_NON: 'NON', // 미납품
  DELIVERY_SUCCESS: 'SUCCESS', // 납품은 하였으나 재납품이 가능함
  DELIVERY_WAIT: 'WAIT' // 납품완료
} as const;

export default Constant;

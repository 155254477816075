import { css } from '@emotion/react';
import { cssResetButton } from '../dashboard/utils/css';

export const TaskManagerWrap = css`
  margin: 71px 40px 90px;
  width: max-content;

  h2 {
    max-width: 40%;
    word-break: break-all;
    word-wrap: break-word;
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }

  div[class*='-FilterInput'] {
    .label--checked {
      width: calc(100% - 30px);
      white-space: nowrap;
      text-overflow: ellipsis;
      .checked {
        display: none;
      }
    }
  }

  div[class*='-Wrap'] input {
    width: 16em;
  }

  div[class*='-SelectList'] {
    min-width: 21em;
  }

  .project--dl--info {
    display: flex;
    gap: 24px;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    > div {
      display: flex;
      align-items: center;
      dt {
        font-size: 0.75rem;
        margin-right: 8px;
        color: #9a9cb5;
      }
      dd {
        font-size: 0.875rem;
      }
      .dDay {
        margin-left: 8px;
      }
    }
  }
`;

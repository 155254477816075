import { useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { BARTYPE } from '@src/common/config/ICode';
import { BAR_COLOR, getTaskType } from '@src/common/config/Code';
import { IconInfo } from '@src/resources/icon';
import { usePageFunc } from '@src/hooks/utils';

const order = ['TRANSLATION', 'POLISHING', 'LQA'];

function BarChartFooterComponent({
  data,
  workerCount,
  startLanguage,
  destinationLanguage
}: {
  data: any;
  workerCount: number;
  startLanguage: string;
  destinationLanguage: string;
}) {
  const {
    lankColor,
    holidays,
    maxCount: getMaxCount,
    formatNumber
  } = usePageFunc();

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const dataTableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (!tableWrapperRef.current || !dataTableRef.current) {
      return;
    }

    const tableWrapper = tableWrapperRef.current;
    const dataTable = dataTableRef.current;

    const toggleScroll = () => {
      if (dataTable.scrollWidth > dataTable.clientWidth) {
        tableWrapper.classList.add('show-scroll');
      } else {
        tableWrapper.classList.remove('show-scroll');
      }
    };

    toggleScroll();

    window.addEventListener('resize', toggleScroll);

    return () => {
      window.removeEventListener('resize', toggleScroll);
    };
  }, []);

  const customHeaderData: { [key: string]: any } = {};
  _(data)
    .groupBy('day')
    .map((values, key) => {
      const day = key.split('-');
      const data = _.flatMap(values, 'data');
      const sum = _.sumBy(data, 'amount');
      const isSunday = values[0]?.isSunDay ?? false;
      customHeaderData[`${day[0]}-${day[1]}`] = [
        ...(customHeaderData[`${day[0]}-${day[1]}`] ?? []),
        {
          day: day[2],
          data,
          isSunday,
          sum
        }
      ];
      return values;
    })
    .value();

  const countArr: any[] = [];
  _.map(Object.keys(customHeaderData), (key, idx) => {
    countArr.push(...customHeaderData[key]);
  });

  //#region component set
  let tdCom1 = '';
  let tdCom2 = '';
  let tdCom3 = '';

  _.map(_.sortBy(Object.keys(customHeaderData)), (key, keyIdx) => {
    _.map(_.sortBy(customHeaderData[key], 'day'), (data: any, idx) => {
      const isHoliday = holidays(key, data.day);
      tdCom1 += `<td key=${idx} ${lankColor(data.sum, isHoliday)}>
        <Tooltip title=${data.sum}>
          <span>
          ${
            String(data.sum).length < 6
              ? data.sum
              : String(data.sum).slice(0, 6) + '...'
          }
          </span>
        </Tooltip>
      </td>`;

      const [year, month, day] = new Date()
        .toISOString()
        .split('T')[0]
        .split('-');
      const nowMonth = `${year}-${month}`;
      const nowDay = `${day}`;

      const dayColor = isHoliday
        ? `style="background-color:rgb(0 0 0 / 20%);"`
        : '';

      if (key === nowMonth && data.day === nowDay) {
        tdCom2 += `<td id="scrollTarget" key=${idx} style="background-color:yellow;">${Number(
          data.day
        )}일</td>`;
      } else {
        tdCom2 += `<td key=${idx} ${dayColor}>${Number(data.day)}일</td>`;
      }
    });

    const gapIndices = _.chain(customHeaderData[key])
      .map('isSunday')
      .map((used, index) => (used ? index + 1 : null))
      .compact()
      .value();

    const nowMonth = key.split('-')[0] + '년&nbsp;' + key.split('-')[1] + '월';

    _.map(gapIndices, (el, gapIdx) => {
      const gap = gapIdx > 0 ? el - gapIndices[gapIdx - 1] : el;

      tdCom3 += `<td colSpan="${gap}" key=${gapIdx} title=${nowMonth}>
            ${gap < 3 ? nowMonth.slice(0, 3) + '...' : nowMonth}
          </td>`;
      if (gapIdx === gapIndices.length - 1)
        tdCom3 +=
          customHeaderData[key].length - el
            ? `<td colSpan="${customHeaderData[key].length - el}" key=${
                gapIdx + '-1'
              } title=${nowMonth}>
              ${
                customHeaderData[key].length - el < 3
                  ? nowMonth.slice(0, 3) + '...'
                  : nowMonth
              }
          </td>`
            : '';
    });
  });
  //#endregion

  useEffect(() => {
    const targetElement = document.querySelector(
      '#scrollTarget'
    ) as HTMLElement;
    if (targetElement) {
      const containerElement = targetElement.parentElement?.parentElement
        ?.parentElement?.parentElement as HTMLElement;
      const scrollOffset = targetElement.offsetLeft - 420;

      containerElement.scrollTo({
        left: scrollOffset,
        behavior: 'smooth'
      });
    }
  });

  const maxCount = getMaxCount(true, countArr);

  return data.length ? (
    <>
      <TitleDiv>
        {startLanguage} &nbsp;-&nbsp; {destinationLanguage}
      </TitleDiv>
      <DescriptionDiv>
        {_.map(Object.keys(BAR_COLOR), (key, idx) => (
          <div key={idx} className="type-box">
            <div
              className="circle"
              style={{
                backgroundColor: `${BAR_COLOR[key as BARTYPE]}`
              }}
            />
            {getTaskType([key as BARTYPE])[0].label}
          </div>
        ))}
      </DescriptionDiv>
      <BarChartWrap>
        <div>
          <table className="table--column">
            <tbody>
              {_.times(5, (i) => (
                <tr key={i}>
                  <td>{formatNumber((maxCount / 5) * (5 - i))}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div
                    style={{
                      marginLeft: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    총합(Net)
                    <Tooltip
                      title={
                        <span>
                          언어작업 기준으로 분량 총합(Net)이 날짜 별로
                          표시됩니다.
                          <br />
                          작업자 1명 당 일일 권장 분량은 2,000 입니다.
                        </span>
                      }
                    >
                      <IconInfo width={18} height={18} />
                    </Tooltip>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="div--container">
          <Tooltip
            title={
              <div>
                <ul>
                  <li>권장 작업량(Net) : {2000 * workerCount}</li>
                  <li>*작업자 수 {workerCount}명 기준</li>
                </ul>
              </div>
            }
            followCursor
          >
            <CutLine
              count={workerCount}
              top={
                maxCount / 2000 < 1 || workerCount * 2000 > maxCount
                  ? 370
                  : (360 / maxCount) * workerCount * 2000 - 2
              }
            />
          </Tooltip>
          <div className="table--wrapper" ref={tableWrapperRef}>
            <table id="data-table" className="chart-table" ref={dataTableRef}>
              <tbody>
                <tr className="bar--row">
                  {_.map(Object.keys(customHeaderData), (key) =>
                    _.map(customHeaderData[key], (item, idx) => {
                      return (
                        <td key={idx}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column-reverse',
                              alignItems: 'center',
                              height: '100%'
                            }}
                          >
                            <Tooltip
                              title={
                                <div>
                                  <ul>
                                    <li>
                                      번역(Net) :{' '}
                                      {_.find(
                                        item.data,
                                        (el) => el.taskType === 'TRANSLATION'
                                      )?.amount ?? 0}
                                    </li>
                                    <li>
                                      폴리싱(Net) :{' '}
                                      {_.find(
                                        item.data,
                                        (el) => el.taskType === 'POLISHING'
                                      )?.amount ?? 0}
                                    </li>
                                    <li>
                                      LQA(Net) :{' '}
                                      {_.find(
                                        item.data,
                                        (el) => el.taskType === 'LQA'
                                      )?.amount ?? 0}
                                    </li>
                                  </ul>
                                </div>
                              }
                              followCursor
                            >
                              <div className="bar">
                                {_.map(
                                  _.sortBy(item.data, (v) =>
                                    order.indexOf(v.taskType)
                                  ),
                                  (el, idx) => (
                                    <div
                                      key={idx}
                                      style={{
                                        backgroundColor:
                                          BAR_COLOR[
                                            `${el.taskType as BARTYPE}`
                                          ],
                                        height: `${
                                          (360 / maxCount) *
                                          item.sum *
                                          (el.amount / item.sum)
                                        }px`,
                                        width: '27px'
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      );
                    })
                  )}
                </tr>
              </tbody>
              <tfoot>
                <tr dangerouslySetInnerHTML={{ __html: tdCom1 }} />
                <tr dangerouslySetInnerHTML={{ __html: tdCom2 }} />
                <tr dangerouslySetInnerHTML={{ __html: tdCom3 }} />
              </tfoot>
            </table>
          </div>
        </div>
      </BarChartWrap>
    </>
  ) : null;
}

export const CutLine = styled.div<{
  count: number;
  top: number;
}>`
  width: 100%;
  height: 2px;
  background-color: red;
  position: relative;
  top: ${({ top }) => `${360 - top}px`};
  visibility: ${({ count }) => (count ? 'visible' : 'hidden')};
  z-index: 2;
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 20px;
  padding-left: 10px;
  color: red;
  font-size: 20px;
`;

export const DescriptionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 20px;
  .type-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 10px 0 10px;
    .circle {
      width: 14px;
      height: 14px;
      margin: 0 5px 0 0;
      border-radius: 50%;
    }
  }
`;

export const BarChartWrap = styled.div`
  display: flex;
  font-size: 12px;
  .table--column {
    width: 80px;
    table-layout: fixed;
    text-align: center;
    border-spacing: 0;
    margin-top: 23px;
    tbody {
      width: 100px;
      height: 362px;
      tr {
        height: 60px;
        td {
          display: flex;
          position: relative;
          flex-direction: row-reverse;
          bottom: 10px;
          right: 10px;
          width: 100%;
        }
      }
    }
    tfoot {
      border: 1px solid;
      td:nth-of-type(1) {
        height: 30px;
        border-left: 1px solid #b6b6b6;
        border-top: 1px solid #b6b6b6;
        border-right: 1px solid #b6b6b6;
        text-align: left;
      }
      tr:nth-of-type(2) > td {
        height: 60px;
        border-bottom: 1px solid #b6b6b6;
      }
    }
  }
  .div--container {
    width: calc(100% - 80px);
    margin-top: 20px;
    .table--wrapper {
      width: 100%;
      overflow-x: auto;
    }
    /* 왼쪽 표 */
    .table--wrapper::-webkit-scrollbar {
      width: 20px; /*스크롤바의 너비*/
    }

    .table--wrapper::-webkit-scrollbar-thumb {
      background-color: rgb(101, 55, 173, 0.8); /*스크롤바의 색상*/
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: 50px;
    }

    .table--wrapper::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.03); /*스크롤바 트랙 색상*/
      border-radius: 50px;
    }

    .table--wrapper table {
      width: max-content;
      border-right: 1px dotted #b6b6b6;
      border-top: 1px dotted #b6b6b6;
      border-spacing: 0;
      border-left: none;
      table-layout: fixed;
      text-align: center;
      tbody > tr > td {
        height: 362px;
      }
      td:first-of-type {
        border-spacing: 0;
        border-left: none;
      }
      td {
        width: 60px;
        height: 30px;
      }
      tfoot > tr > td {
        border-top: 1px solid #b6b6b6;
      }
      tfoot > tr > td {
        border-left: 1px solid #b6b6b6;
      }
      tfoot > tr:nth-of-type(3) > td {
        border-bottom: 1px solid #b6b6b6;
      }
    }
  }

  .show-scroll {
    overflow-x: scroll;
  }

  .bar {
    position: relative;
    bottom: -1px;
    z-index: 1;
  }

  .bar:hover {
    border: 1px solid #ff0000;
    bottom: 0px;
    border-radius: 2px;
  }

  .bar--row {
    position: relative;
  }

  .bar--row > td:nth-of-type(1):before,
  .bar--row > td:nth-of-type(1):after,
  .bar--row > td:nth-of-type(2):before,
  .bar--row > td:nth-of-type(2):after,
  .bar--row .fifth-line:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 0;
    border-bottom: 1px dotted #b6b6b6;
  }

  .bar--row > td:nth-of-type(1):before {
    top: calc(20% - 1px);
    display: block;
  }

  .bar--row > td:nth-of-type(1):after {
    top: calc(40% - 1px);
  }

  .bar--row > td:nth-of-type(2):before {
    top: calc(60% - 1px);
  }

  .bar--row > td:nth-of-type(2):after {
    top: calc(80% - 1px);
  }

  .bar--row .fifth-line:before {
    top: calc(100% - 1px);
  }
`;

export default BarChartFooterComponent;

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryFunctionContext } from '@tanstack/query-core';
import _, { initial } from 'lodash';
import api from '@api/Api';
import { ILanguageTask } from '@src/common/config/IProjectDetail';
import {
  ILanguageTaskListRes,
  ITaskCountRes
} from '@src/common/config/ILanguageTask';
import { WidgetCode, getTaskStatusLabel } from '@src/common/config/Code';
import { languageTaskKeys } from '@hooks/queryKeyFactory';
import { useHookFunc, usePageFunc } from '@hooks/utils';
import { useMyInfo } from '../myInfo/getMyInfo';

const initialDataMap = {
  get taskFilter() {
    return {
      workUserIdList: ['0'],
      projectManagerUserIdList: ['0'],
      taskType: ['ALL'],
      languageTaskStatus: ['NEW', 'COMPLETE_ASSIGN', 'PROGRESS', 'COMPLETE']
    };
  }
};

export const usePostLanguageTaskTabList = (filterData?: any) => {
  const { myInfo } = useMyInfo();
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();

  //#region state define
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [taskFilter, setTaskFilter] = useState<{ [key: string]: any[] }>(
    initialDataMap.taskFilter
  );
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [sortInfo, setSortInfo] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  //#region setState
  const stateSetters = {
    setWorkerUser: (e: any) => {
      const workUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(taskFilter.workUserIdList, e),
              (item) => item !== '0'
            );
      setTaskFilter({
        ...taskFilter,
        workUserIdList: workUserIdList.length ? workUserIdList : ['0']
      });
    },
    setPmManager: (e: any) => {
      const projectManagerUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(taskFilter.projectManagerUserIdList, e),
              (item) => item !== '0'
            );
      setTaskFilter({
        ...taskFilter,
        projectManagerUserIdList: projectManagerUserIdList.length
          ? projectManagerUserIdList
          : ['0']
      });
    },
    setTaskType: (e: any) => {
      const taskType =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.taskType, e),
              (item) => item !== 'ALL'
            );
      setTaskFilter({
        ...taskFilter,
        taskType: taskType.length ? taskType : ['ALL']
      });
    },
    setTaskStatus: (e: any) => {
      const languageTaskStatus =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.languageTaskStatus, e),
              (item) => item !== 'ALL'
            );
      setTaskFilter({
        ...taskFilter,
        languageTaskStatus: languageTaskStatus.length
          ? languageTaskStatus
          : ['ALL']
      });
    },
    setKeyword,
    setLanguagePairList,
    setResetState: () => {
      setLanguagePairList([]);
      setKeyword('');
      setTaskFilter(initialDataMap.taskFilter);
    },
    setIsSort: setSortInfo
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    languagePairList,
    keyword,
    taskFilter
  }: {
    languagePairList: ILanguageTask[];
    keyword: string;
    taskFilter: { [key: string]: any[] };
  }): object => {
    const taskFilterArr: { [key: string]: any[] } = {};
    const getLanguagePairList =
      !_.some(languagePairList, { startLanguage: 'ALL' }) &&
      languagePairList.length
        ? { languagePairList }
        : undefined;

    Object.keys(taskFilter).forEach((key) => {
      _.map(
        taskFilter[key],
        (el) =>
          !['ALL', '0'].includes(el) && (taskFilterArr[key] = taskFilter[key])
      );
    });
    return {
      ...(getLanguagePairList && getLanguagePairList),
      ...(keyword && { keyword }),
      ...taskFilterArr
    };
  };

  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof languageTaskKeys)['tabCount']>
  >): Promise<ITaskCountRes> => {
    const [, , filters] = queryKey;
    const getPayload = setPayload(filters) as any;

    const is = !(
      initialDataMap.taskFilter.languageTaskStatus.sort().join() ===
        getPayload?.languageTaskStatus?.sort?.()?.join?.() &&
      (getPayload?.projectManagerUserIdList?.join() ?? '') === '' &&
      (getPayload?.workUserIdList?.join() ?? '') === '' &&
      (getPayload?.taskType?.join() ?? '') === '' &&
      (getPayload?.languagePairList?.join() ??
        getPayload?.languagePairList?.join() ===
          myInfo?.languagePairList?.join()) &&
      !getPayload?.keyword
    );

    setIsFilter(is);
    return await api
      .post('/languageTask/count', { ...getPayload })
      .then((res) => res.data?.section ?? []);
  };

  // POST /tms/languageTask/count
  const {
    isLoading,
    isError,
    data: languageTaskList,
    refetch: refetchLanguageTaskList
  } = useQuery(
    [
      ...languageTaskKeys.tabCount({
        languagePairList:
          languagePairList.length > 0
            ? languagePairList
            : myInfo?.languagePairList ?? [],
        // languagePairList,
        keyword,
        taskFilter
      })
    ],
    getData,
    {
      enabled: Array.isArray(myInfo?.languagePairList)
    }
  );
  //#endregion

  useEffect(() => {
    setLanguagePairList((v) => filterData?.languagePairList ?? v ?? []);
    setKeyword((v) => filterData?.keyword ?? v ?? '');
    setTaskFilter(
      (v) =>
        filterData?.taskFilter ??
        v ?? {
          workUserIdList: ['0'],
          projectManagerUserIdList: ['0'],
          taskType: ['ALL'],
          languageTaskStatus: ['ALL']
        }
    );
  }, [filterData]);

  return {
    isLoading,
    isError,
    languagePairList,
    languageTaskList,
    keyword,
    taskFilter,
    setTaskFilter,
    isFilter,
    sortInfo,
    stateSetters,
    refetchLanguageTaskList
  };
};

import { css } from '@emotion/react';
import { Color } from '@common/styles/color';

export const FileUploadStyle = css`
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  .lengthCheck {
    position: relative;
    & > span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0.75rem;
      s {
        text-decoration: none;
      }
    }
    &.lengthError {
      span s {
        color: red;
      }
      textarea {
        border-color: red;
        &:focus-visible {
          outline: 1px solid red;
        }
      }
      fieldset {
        border-color: red;
      }
    }
  }
  .modal--div--box {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 30px;
    padding-right: 20px;
    margin-right: -26.7px;
    overflow: auto;
    &.non-scroll {
      overflow: visible;
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 10px;
      margin: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(213, 211, 228);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgb(255, 255, 255);
      margin-left: 10px;
    }
    .title {
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .valid {
      color: ${Color.compulsory};
      width: 100%;
      text-align: left;
      font-size: 0.75rem;
      margin-left: 14px;
    }
    .color--red {
      color: ${Color.compulsory};
    }
    .input--text--wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: flex-end;
      > div,
      > p {
        flex-grow: 1;
      }
      button {
        flex-shrink: 0;
        height: 56px;
      }
    }
    .input--text--ci {
      padding: 60px 0 0;
    }
    .input--checkbox--wrap {
      display: flex;
      > div:hover {
        background: transparent;
      }
    }
    .input--checkbox--stockrefine {
      /* display: flex; */
      /* align-items: center; */
      padding: 5px 0;
      > div:hover {
        background: transparent;
      }
    }
  }
  .Mui-selected {
    color: #d463fc !important;
  }
  .MuiTabs-indicator {
    background-color: #d463fc;
  }
`;

import { LANGUAGE, STATUS, TASK_TYPE, WORKER_TYPE } from '../config/Code';

export type SortType =
  | 'status'
  | 'key'
  | 'string'
  | 'number'
  | 'date'
  | 'pm'
  | 'taskType'
  | 'workerType'
  | 'language'
  | 'languagePair'
  | 'file'
  | 'progress'
  | 'unable';

export const makeSortedList = (
  initialDataList: any,
  field: string,
  isAscending: boolean,
  sortType: SortType
) => {
  const sortTargetList = [...initialDataList];

  //
  const languageSubTaskSorter = (languageSubTask: any) => {
    if (!languageSubTask?.length) return [];
    let languageSubTaskField = field;

    field === 'taskKey' && (languageSubTaskField = 'languageSubTaskKey');
    field === 'totalNetAmount' && (languageSubTaskField = 'netAmount');

    const sortedList = makeSortedList(
      languageSubTask,
      languageSubTaskField,
      isAscending,
      sortType
    );
    return [...sortedList];
  };

  //배열길이가 1일경우에 그 하위 부작업 소팅 실행 로직
  if (sortTargetList.length === 1 && sortTargetList[0].languageSubTask) {
    const sortedSubTaskList = languageSubTaskSorter(
      sortTargetList[0].languageSubTask
    );
    sortTargetList[0].languageSubTask = sortedSubTaskList;
  }

  sortTargetList.sort((a: any, b: any) => {
    //subTask sorting
    if (a.languageSubTask?.length > 1) {
      a.languageSubTask = languageSubTaskSorter(a.languageSubTask);
    }

    let A = a[field];
    let B = b[field];
    const orderCompare = (
      a: string | number,
      b: string | number,
      isAscending: boolean
    ) =>
      isAscending
        ? a > b
          ? 1
          : a < b
          ? -1
          : 0
        : (a > b ? 1 : a < b ? -1 : 0) * -1;

    switch (sortType) {
      case 'string':
      case 'number':
        break;

      case 'key':
        A = A.split('-');
        B = B.split('-');
        if (A[0] === B[0] && A[1] && B[1]) {
          if (A[1] === B[1] && A[2] && B[2]) {
            if (A[2] === B[2] && A[3] && B[3]) {
              return orderCompare(Number(A[3]), Number(B[3]), isAscending);
            }
            return orderCompare(Number(A[2]), Number(B[2]), isAscending);
          }
          return orderCompare(Number(A[1]), Number(B[1]), isAscending);
        }
        return orderCompare(A[0], B[0], isAscending);

      case 'status':
        A = STATUS.findIndex((el) => el.code === A);
        B = STATUS.findIndex((el) => el.code === B);
        break;

      case 'date':
        A = new Date(a[field]).getTime();
        B = new Date(b[field]).getTime();
        break;

      case 'pm':
        A = a[field].name;
        B = b[field].name;
        break;

      case 'taskType':
        A = TASK_TYPE.findIndex((el) => el.code === A);
        B = TASK_TYPE.findIndex((el) => el.code === B);
        break;

      case 'workerType':
        A = WORKER_TYPE.findIndex((el) => el.code === A);
        B = WORKER_TYPE.findIndex((el) => el.code === B);
        break;

      case 'language':
        if (Array.isArray(A)) {
          A = A[0];
          B = B[0];
        }
        A = LANGUAGE.findIndex((el) => el.code === A);
        B = LANGUAGE.findIndex((el) => el.code === B);
        break;

      case 'languagePair':
        const startLanguageA = a['startLanguage'];
        const startLanguageB = b['startLanguage'];
        const destinationA = a['destinationLanguage'];
        const destinationB = b['destinationLanguage'];
        if (startLanguageA === startLanguageB) {
          return orderCompare(
            LANGUAGE.findIndex((el) => el.code === destinationA),
            LANGUAGE.findIndex((el) => el.code === destinationB),
            isAscending
          );
        }
        return orderCompare(
          LANGUAGE.findIndex((el) => el.code === startLanguageA),
          LANGUAGE.findIndex((el) => el.code === startLanguageB),
          isAscending
        );

      case 'file':
        //! TMS2 차때 진행예정

        break;
      case 'progress':
        ///오름차순 : 작업 시작 / 더보기 버튼 / 번역 중 / 1차검수 중 / 2차검수 중 / 완료
        const standard = [
          'TASK_START',
          'MORE',
          'TRANSLATOR',
          'FIRST_CHECKER',
          'SECOND_CHECKER',
          'COMPLETION'
        ] as const;
        type Value = (typeof standard)[number];
        const ordering = (value: Value) => {
          return standard.indexOf(value);
        };

        switch (a.languageSubTaskWorkStatus) {
          case 'NEW':
          case 'REJECT':
          case 'TRANSLATOR_REJECT':
          case 'FIRST_CHECKER_REJECT':
          case 'COMPLETE_TASK':
            if (a.languageSubTaskWorkProcess === 'COMPLETION') {
              A = ordering(a.languageSubTaskWorkProcess);
              break;
            }
            if (a.workerType !== a.languageSubTaskWorkProcess) {
              A = ordering(a.languageSubTaskWorkProcess);
              break;
            }
            A = ordering('TASK_START');
            break;

          case 'PROGRESS':
            A = ordering('MORE');
            break;
          default:
            break;
        }
        switch (b.languageSubTaskWorkStatus) {
          case 'NEW':
          case 'REJECT':
          case 'TRANSLATOR_REJECT':
          case 'FIRST_CHECKER_REJECT':
          case 'COMPLETE_TASK':
            if (b.languageSubTaskWorkProcess === 'COMPLETION') {
              B = ordering(b.languageSubTaskWorkProcess);
              break;
            }
            if (b.workerType !== b.languageSubTaskWorkProcess) {
              B = ordering(b.languageSubTaskWorkProcess);
              break;
            }
            B = ordering('TASK_START');
            break;

          case 'PROGRESS':
            B = ordering('MORE');
            break;
          default:
            break;
        }

        break;
      default:
        break;
    }

    return orderCompare(A, B, isAscending);
  });

  return sortTargetList;
};

import { css } from '@emotion/css';
import { context } from '@src/Context';
import ModalType from '@src/common/config/ModalType';

import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import _ from 'lodash';
import React, { ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface IMainLLAssignButton {
  id: number;
  data: any;
  rowData: any;
  userInfo: any;
}
export const MainLLAssignButton = (props: IMainLLAssignButton) => {
  // id:number - projectId
  const { id, data, rowData, userInfo } = props;
  const navigate = useNavigate();
  const { setTmp } = useContext(context);

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    const v = _.cloneDeep({ data, rowData });
    setTmp(v); // 2. 그래서 이렇게 state를 넘겨주는 방식으로 변경했다.
    navigate(`/project/${ModalType.LLASSIGN_PAGE}/${Number(id)}`, {
      state: v
    }); // 1. navigate를 통해 넘긴 state가 웬지 모르겠지만, 값이 있다가 없어지는 걸 확인했다.
  };

  let cell: ReactElement = (
    <TPAvatarGroupNew userList={data} onClick={clickHandler} />
  );

  return data[0]?.userId ? (
    cell
  ) : (
    <button className={cssButton} onClick={clickHandler}>
      배정하기
    </button>
  );
};

const cssButton = css`
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: transparent;
  color: #9b51e0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.25px;
  text-align: center;
  width: 100%;
  height: 100%;
`;

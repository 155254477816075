import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import React from 'react';

interface IDeadlineCheckDateViewer {
  data: Date | undefined;
  rowData: any;
  forceValue?: string;
  comma?: boolean;
}
export const DeadlineCheckDateViewer = (props: IDeadlineCheckDateViewer) => {
  const { data, rowData, forceValue, comma } = props;
  if (!data) return <div>-</div>;
  const status =
    rowData.taskStatus ??
    rowData.languageTaskStatus ??
    rowData.languageSubTaskWorkStatus;

  const updatedTime = new Date();
  const value = forceValue
    ? forceValue
    : comma
    ? format(new Date(data), 'yy.MM.dd, HH:mm').toLowerCase()
    : format(new Date(data), 'yy.MM.dd HH:mm').toLowerCase();

  let deadlineOver =
    format(new Date(data), 'yyMMdd') <= format(updatedTime, 'yyMMdd');

  switch (status) {
    case 'COMPLETE_TASK':
    case 'DELIVERY_COMPLETE':
    case 'STOP':
      deadlineOver = false;
      break;
    default:
      break;
  }

  return <Wrap deadlineOver={deadlineOver}>{value}</Wrap>;
};

interface IWrap {
  deadlineOver?: boolean;
}
const Wrap = styled.span<IWrap>`
  ${({ deadlineOver }) => {
    if (deadlineOver)
      return css`
        color: #e6311a;
      `;
  }}
`;

import { createTheme } from '@mui/material/styles/';
import bgImg from '@resources/icon/ico-unchecked.svg';
import bgImgChecked from '@resources/icon/ico-checked.svg';
export const TPSelectTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 14 },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          borderRadius: '2px',
          background: '#fff',
          marginRight: 8,
          '& svg': {
            width: 24,
            height: 24,
            top: 'calc(50% - 0.85em)',
            right: 20
          },
          '&:hover': {
            borderColor: 'transparent'
          },
          '&.large': {
            marginRight: 0,
            display: 'flex',
            flexDirection: 'column'
          },
          '&.large .MuiOutlinedInput-root': {
            marginRight: 0,
            display: 'flex',
            flexGrow: 1
          },

          '&.large .MuiSelect-select': {
            paddingTop: 9,
            paddingBottom: 9,
            flexGrow: 1,
            textAlign: 'left'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          top: 5,
          right: 'calc(50% - 1em)',
          left: '1em'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          border: '1px solid #D5D3E4',
          boxShadow: '0px 1px 6px 2px rgba(240, 237, 246, 0.8)',
          '& li': {
            color: '#2C3558',
            fontWeight: '400',
            fontSize: '0.875rem',
            padding: '9px 0 9px 12px',
            '&.Mui-selected': {
              background: 'rgba(155, 81, 224, 0.06)',
              color: '#9B51E0',
              '&:hover': {
                background: 'rgba(155, 81, 224, 0.06)',
                color: '#9B51E0'
              }
            },
            '&:hover': {
              background: 'rgba(155, 81, 224, 0.06)'
            }
            // '&:first-of-type': {
            //   display: 'none'
            // }
          },
          '&.multiple': {
            width: '300px',
            li: {
              display: 'inline-block',
              width: '50%',
              '&:first-of-type': {
                display: 'none'
              },
              '&::before': {
                content: '""',
                display: 'inline-block',
                width: '24px',
                height: '24px',
                background: `url(${bgImg}) no-repeat left top`,
                backgroundSize: '100%',
                verticalAlign: 'middle',
                marginRight: '8px'
              },
              '&.Mui-selected::before': {
                background: `url(${bgImgChecked}) no-repeat left top`
              }
            }
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: '0.75rem', color: '#E6311A' }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: 20,
          fontSize: 14,
          borderRadius: '2px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          },
          '& .span--label': {
            flexShrink: 0,
            color: '#727272'
          },
          '& svg': {
            width: 16
          }
        },
        notchedOutline: {
          border: '1px solid #ececec'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          width: 'auto',
          fontSize: 14,
          paddingLeft: 9,
          paddingTop: 0,
          paddingBottom: 0,
          // paddingTop: 11.5,
          // paddingBottom: 11.5,
          // paddingLeft: 8,
          border: '0',
          '& .select--value label': { marginRight: 8 }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    }
  }
});

import Api from '@src/api/Api';
import { useQuery } from '@tanstack/react-query';
import { DashboardKeys } from '../keys/DashboardKeys';

export function useApi3LanguageTaskList(id: any, filterData: any) {
  return useQuery(
    DashboardKeys.Deep3(id),
    (): Promise<any> => {
      // return Api.get(`/languageTask/list/${id}`).then((res) =>
      //   Array.isArray(res.data?.languageTaskList)
      //     ? res.data.languageTaskList
      //     : []
      // );

      // 슬랙: https://2bytes-workspace.slack.com/archives/C05BM9PHG9E/p1702971429380809?thread_ts=1702966730.079809&cid=C05BM9PHG9E
      return Api.post(`/languageTask/filterList`, {
        taskId: id,
        ...filterData
      }).then((res) =>
        Array.isArray(res.data?.languageTaskList)
          ? res.data.languageTaskList
          : []
      );
    },
    {
      enabled: true,
      // cacheTime: Infinity,
      // staleTime: Infinity,
      retry: 3,
      suspense: false
    }
  );
}

import { css } from '@emotion/react';
import { Color } from '@src/common/styles/color';

export const PMAssignCss = css`
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 30px 20px;
  position: relative;
  width: 756px;
  background: #ffffff;
  border-radius: 2px;

  .closeButton {
    top: 24px;
    right: 24px;
    position: absolute;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
    :active {
      opacity: 0.8;
    }
  }

  .selectPmButtonContainer {
    margin-top: 64px;
    margin-bottom: 20px;
    width: 100%;
    height: 60px;
    background-color: #fafafa;
    border-top: 1px solid #eeedf4;
    border-bottom: 1px solid #eeedf4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    gap: 10px;
  }

  .buttonContainer {
    display: flex;
    gap: 16px;
    width: 100%;
    height: 56px;
  }
`;

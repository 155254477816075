import styled from '@emotion/styled';
import React from 'react';

interface ITPSeparation {
  margin?: string;
  width?: string;
  color?: string;
}

/**
 * @param margin?: string; default '#D5D3E4'
 * @param width?: string; default '20px 0'
 * @param color?: string; default '1px solid #D5D3E4'
 *
 */

export const TPSeparation = (params: ITPSeparation) => {
  const { margin, width, color } = params;
  return <SeparationLine margin={margin} width={width} color={color} />;
};

const SeparationLine = styled.div<ITPSeparation>`
  margin: ${({ margin }) => (margin ? margin : '20px 0')};
  width: ${({ width }) => (width ? width : '100%')};
  border-bottom: ${({ color }) =>
    color ? `1px solid ${color}` : '1px solid #D5D3E4'};
`;

import { createTheme } from '@mui/material/styles/';
import styled from '@emotion/styled';

// input을 감싸는 div 엘리먼트 스타일
export const TPInputWrap = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: top;
  /* margin-right: 8px; */
`;

// textarea 감싸는 div 엘리먼트 스타일
export const TPTextAreaWrap = styled.div`
  textarea {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;

    width: 100%;
    color: #727272;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 18px 10px;
    resize: none;
    outline: none;

    :hover {
      border-color: #aa51e0;
    }
    :focus {
      border: 1px solid #aa51e0;
    }
  }
`;

// check box optoin style
export const TPInputCheckBoxWrap = styled.div`
  /* margin-right: 10px; */
  width: 100%;
  height: 32px;
  padding: 8px 24px 8px 0;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: #f9f5fd;
  }
  & > span:first-of-type {
    padding: 0 8px;
  }
`;

// select optoin style
export const TPInputSelectWrap = styled.div`
  /* margin-right: 10px; */
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  :hover {
    cursor: pointer;
    background-color: #f9f5fd;
  }
`;
// input date 테마
export const TPInputDateTheme = createTheme({
  typography: { fontFamily: '"IBM Plex Sans", sans-serif', fontSize: 16 },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: { display: 'flex' }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#242424',
          '&.Mui-focused': {
            color: '#242424'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          boxSizing: 'border-box',
          fontSize: '1rem',
          height: 40,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          }
        },
        input: {
          color: '#727272'
        },
        notchedOutline: {
          border: '1px solid #ececec',
          boxSizing: 'border-box',
          borderRadius: '2px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: '0.75rem', color: '#E6311A' }
      }
    }
  }
});

// input text 테마
export const TPInputTextTheme = createTheme({
  typography: { fontFamily: '"IBM Plex Sans", sans-serif', fontSize: 16 },
  components: {
    MuiFormControl: {
      variants: [
        {
          props: { size: 'small' },
          style: { height: 32 }
        },
        {
          props: { size: 'medium' },
          style: { height: 40 }
        }
      ],
      styleOverrides: {
        root: {
          '&.input--amount input': {
            width: '90%'
          },
          '&.input--title input': {
            fontSize: '2rem'
          },
          '&.input--title .MuiOutlinedInput-root': {
            height: '64px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#242424',
          '&.Mui-focused': {
            color: '#242424'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#fff',
          boxSizing: 'border-box',
          fontSize: '1rem',
          borderRadius: '2px',
          color: '#242424',
          height: 40,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          }
        },
        input: {
          fontSize: '14px',
          lineHeight: '22.4px',
          letterSpacing: '-0.25px',
          padding: '7px 10px'
        },
        notchedOutline: {
          border: '1px solid #ececec',
          boxSizing: 'border-box',
          borderRadius: '2px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: '0.75rem', color: '#E6311A' }
      }
    }
  }
});

// 라디오 박스 테마
export const TPInputRadioTheme = createTheme({
  typography: { fontFamily: '"IBM Plex Sans", sans-serif', fontSize: 16 },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#242424',
          '&.Mui-focused': {
            color: '#242424'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#727272',
          '&.Mui-checked ~ *': {
            color: '#242424'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#727272'
        }
      }
    }
  }
});

//체크 박스 테마
export const TPInputCheckBoxTheme = createTheme({
  typography: { fontFamily: '"IBM Plex Sans", sans-serif', fontSize: 16 },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#242424',
          '&.Mui-focused': {
            color: '#242424'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#242424'
        }
      }
    }
  }
});

// 검색창 테마
export const TPInputSearchTheme = createTheme({
  typography: { fontFamily: '"IBM Plex Sans", sans-serif', fontSize: 14 },
  components: {
    MuiOutlinedInput: {
      variants: [
        {
          props: { size: 'small' },
          style: { width: 160 }
        },
        {
          props: { size: 'medium' },
          style: { width: 320 }
        }
      ],
      styleOverrides: {
        root: {
          height: 48,
          background: '#fff',
          verticalAlign: 'top',
          borderRadius: '2px',
          fontSize: '0.875rem',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          }
        },
        input: { paddingRight: 32 },
        notchedOutline: {
          borderRadius: '2px',
          paddingRight: 45
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          position: 'absolute',
          display: 'inline-block',
          right: 3,
          top: 3,
          minWidth: 0,
          padding: '8px 9px 2px',
          borderRadius: '2px',
          '& svg': { width: 24, height: 24 }
        }
      }
    }
  }
});

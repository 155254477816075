import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';

interface TPButtonCloseProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function TPButtonClose({ ...props }: TPButtonCloseProps) {
  return (
    <TPButtonCloseWrap className="circle button close" {...props}>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.86987 0.3584L4.14751 3.08076L1.42515 0.3584L1.31909 0.252335L1.21302 0.358401L0.505914 1.06551L0.399848 1.17157L0.505913 1.27764L3.22827 4L0.602589 6.62569L0.496523 6.73175L0.602589 6.83782L1.3097 7.54492L1.41576 7.65099L1.52183 7.54493L4.14751 4.91924L6.86987 7.6416L6.97594 7.74767L7.08201 7.6416L7.78911 6.93449L7.89518 6.82843L7.78911 6.72236L5.06675 4L7.78911 1.27764L7.89518 1.17157L7.78911 1.06551L7.08201 0.3584L6.97594 0.252334L6.86987 0.3584Z"
          strokeWidth="0.3"
        />
      </svg>
    </TPButtonCloseWrap>
  );
}

const TPButtonCloseWrap = styled.div`
  --white: #ffffff;
  --black: #242424;

  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: var(--black);
    fill: var(--black);
  }

  &:hover {
    background-color: var(--black);
    svg {
      stroke: var(--white);
      fill: var(--white);
    }
  }
`;

export default function download(res: any, defaultFilename: string) {
  const blob = new Blob([res.data]);
  const fileObjectUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileObjectUrl;
  link.style.display = 'none';
  let disposition = res?.headers?.['content-disposition'];
  if (typeof disposition === 'string') {
    disposition = disposition.replace(/^filename/, '');
  } else {
    disposition = defaultFilename;
  }
  link.download = disposition;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(fileObjectUrl);
}

export function downloadByURL(url: string, defaultFilename?: string) {
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';
  // link.download = defaultFilename;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

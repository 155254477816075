import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useRef,
  useState
} from 'react';

export interface ContextProps {
  hasNewAlarm: boolean;
  setHasNewAlarm: Dispatch<SetStateAction<boolean>>;
  unReadMessage: boolean;
  setUnReadMessage: Dispatch<SetStateAction<boolean>>;
  showAlarmUI: boolean;
  setShowAlarmUI: Dispatch<SetStateAction<boolean>>;
  sseRef: MutableRefObject<EventSource | null>;
  timeRef: MutableRefObject<number>;
  tmp: any; // 페이지 이동시 임시 저장용
  setTmp: Dispatch<SetStateAction<any>>;
  makerItemsRef: MutableRefObject<any>;
}

export const context = createContext<ContextProps>(null!);

export default function ContextProvider({ children }: any) {
  // 알림 관련
  const [hasNewAlarm, setHasNewAlarm] = useState(false);
  const [unReadMessage, setUnReadMessage] = useState(false);
  const [showAlarmUI, setShowAlarmUI] = useState(false);
  const sseRef = useRef<EventSource | null>(null);
  const timeRef = useRef<number>(Date.now());
  const [tmp, setTmp] = useState<any>(null);
  const makerItemsRef = useRef<any>({});

  return (
    <context.Provider
      value={{
        hasNewAlarm,
        setHasNewAlarm,
        unReadMessage,
        setUnReadMessage,
        showAlarmUI,
        setShowAlarmUI,
        sseRef,
        timeRef,
        tmp,
        setTmp,
        makerItemsRef
      }}
    >
      {children}
    </context.Provider>
  );
}

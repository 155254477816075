import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { wordAmountKeys } from '@hooks/queryKeyFactory';
import { useEffect, useState } from 'react';
import { ILanguageTask } from '@common/config/IProjectDetail';
import _ from 'lodash';
import { QueryFunctionContext } from '@tanstack/query-core';
import { IWordAmountWorker } from '@common/config/IStatistics';
import { IUser } from '@common/config/ICode';
import { usePageFunc } from '@src/hooks/utils';
import download from '@src/hooks/utils/download';

/**
 * /statistics/workerPerWordAmount
 * @description 통계 > 작업자 별 단어 수
 * @param filterData
 */
export const useGetWorkerList = (filterData: any, isStats: boolean = false) => {
  const { getDateTime } = usePageFunc();

  //#region state define
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [workerUserList, setWorkerUserList] = useState<IUser[]>([]);
  const [dateType, setDateType] = useState<string>('START_DATE');
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    startDateTime: undefined,
    endDateTime: undefined
  });
  const [fetchLanguagePairList, setFetchLanguagePairList] = useState<
    ILanguageTask[]
  >([]);
  const [fetchDateFilter, setFetchDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({ startDateTime: undefined, endDateTime: undefined });
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  useEffect(() => {
    if (!filterData) return;
    setLanguagePairList(filterData?.languagePairList ?? []);
    setDateType(filterData?.dateType ?? 'START_DATE');
    setDateFilter(
      filterData?.dateFilter ?? {
        startDateTime: undefined,
        endDateTime: undefined
      }
    );
    setWorkerUserList(filterData?.workerUserList ?? []);
  }, [filterData]);

  //#region setState
  const setState = {
    setDateType,
    setDateFilter,
    setLanguagePairList,
    setWorkerUserList,
    setResetState: () => {
      remove();
      if (isStats) {
        setLanguagePairList([]);
        setWorkerUserList([]);
        setDateType('START_DATE');
        setDateFilter({});
        setFetchDateFilter({});
      } else {
        setLanguagePairList([]);
        setWorkerUserList([]);
        setDateType('START_DATE');
        setDateFilter({ startDateTime: undefined, endDateTime: undefined });
      }

      setIsFilter(false);
    },
    setIsSort
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    languagePairList,
    dateType,
    dateFilter
  }: {
    languagePairList: ILanguageTask[];
    dateType: string;
    dateFilter: { [key: string]: any };
  }): object => {
    const { startDateTime, endDateTime } = dateFilter;
    const getLanguagePairList =
      !_.some(languagePairList, { startLanguage: 'ALL' }) &&
      languagePairList.length
        ? { languagePairList }
        : undefined;
    const workUserList = _.map(workerUserList, 'userId');

    return {
      ...(workUserList.length && { workerUserList: workUserList }),
      ...(getLanguagePairList && getLanguagePairList),
      ...(dateType !== 'ALL' &&
        startDateTime &&
        endDateTime && {
          dateType,
          startDateTime: getDateTime({
            date: new Date(startDateTime),
            filter: 'start'
          }),
          endDateTime: getDateTime({
            date: new Date(endDateTime),
            filter: 'end'
          })
        })
    };
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof wordAmountKeys)['workerlist']>
  >): Promise<IWordAmountWorker> => {
    const [, filters] = queryKey;
    const getPayload = setPayload(filters);

    setIsFilter(Object.keys(getPayload).length !== 0);

    return await api
      .postPms('/statistics/workerPerWordAmount', { ...getPayload })
      .then((res) => res.data);
  };

  const fatchData = async () => {
    await setFetchLanguagePairList(languagePairList);
    await setFetchDateFilter(dateFilter);
    await refetch();
  };

  const downloadData = async () => {
    return await api
      .postPms(
        '/statistics/workerPerWordAmount/excel',
        {
          ...setPayload({
            languagePairList: fetchLanguagePairList,
            dateFilter: {
              dateType,
              startDateTime: getDateTime({
                date: new Date(fetchDateFilter.startDateTime as any),
                filter: 'start'
              }),
              endDateTime: getDateTime({
                date: new Date(fetchDateFilter.endDateTime as any),
                filter: 'end'
              })
            } // 다운로드하는 날짜 기간
          } as any)
        },
        { responseType: 'blob' }
      )
      .then((res) => {
        download(res, 'workerPerWordAmountStatistics.xls'); // POST
      });
  };
  //#endregion

  //#region useQuery define
  const {
    isLoading,
    isError,
    data: wordAmountWorkerList = {
      workerPerWordLastRowTotalAmount: {
        new: 0,
        progress: 0,
        stop: 0,
        reject: 0,
        complete: 0,
        totalAmount: 0,
        percentage: 0
      },
      workerPerWordAmountList: []
    },
    refetch,
    remove
  } = useQuery(
    [
      ...wordAmountKeys.workerlist({
        languagePairList: fetchLanguagePairList,
        dateType,
        dateFilter: fetchDateFilter
      })
    ],
    getData,
    {
      select: (data) => {
        return {
          workerPerWordLastRowTotalAmount: {
            ...data.workerPerWordLastRowTotalAmount
          },
          workerPerWordAmountList: isSort.code.length
            ? [
                ..._.orderBy(
                  data.workerPerWordAmountList,
                  [isSort.code],
                  [isSort.isAsc ? 'asc' : 'desc']
                )
              ]
            : _.orderBy(data.workerPerWordAmountList, ['name'], ['asc'])
        };
      }
    }
  );
  //#endregion

  return {
    isLoading,
    isError,
    languagePairList,
    workerUserList,
    wordAmountWorkerList,
    fatchData,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState,
    downloadData
  };
};

import { TPInputSelectWrap } from './TPInput.theme';

interface IInputSelectProps {
  option: string;
  optionKey: string;
  onClick: (state: string) => void;
}

export const TPInputSelect = (props: IInputSelectProps) => {
  const { option, optionKey, onClick = () => {} } = props;

  return (
    <TPInputSelectWrap
      className="option"
      onClick={() => {
        onClick(optionKey);
      }}
    >
      <span>{option}</span>
    </TPInputSelectWrap>
  );
};

import { getContractItem } from '@src/common/config/Code';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';

export const customerColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'projectStatus',
      value: '상태',
      width: 84,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      renderRowData: (data: any) => <StatusBadge statusCode={data} />
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 200,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트',
      width: 340,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'projectKey',
      value: '키',
      width: 80,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'projectContractType',
      value: '견적유형',
      width: 80,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        if (data) {
          return getContractItem(data)?.label;
        }
      }
    },
    {
      code: 'totalTaskAmount',
      value: '작업 수',
      width: 80,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'totalLanguageTaskAmount',
      value: '언어작업 수',
      width: 80,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'totalGrossAmount',
      value: '총 분량(Gross)',
      width: 100,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'totalNetAmount',
      value: '총 분량(Net)',
      width: 100,
      renderHeader: 'string',
      renderRowData: 'string'
    },
    {
      code: 'projectStartDateString',
      value: '계약 시작일',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        return format(new Date(data), 'yy.MM.dd, HH:mm').toLowerCase();
      }
    },
    {
      code: 'projectEndDateString',
      value: '계약 마감일',
      width: 120,
      renderHeader: 'string',
      renderRowData: (data: any) => {
        return format(new Date(data), 'yy.MM.dd, HH:mm').toLowerCase();
      }
    },
    {
      code: 'allPmUserList',
      value: '담당PM',
      width: 130,
      renderHeader: 'string',
      renderRowData: (data: any, rowData: any) => {
        return <TPAvatarGroupNew userList={data} />;
      }
    }
  ] as const;

import { css, keyframes } from '@emotion/react';
import { IconInputCheckUrl } from '@resources/icon';

const verticalUp = keyframes`
  0% { transform: translateY(50px); opacity: 0; } 
  100% { transform: translateY(0); opacity: 1; } 
`;

const verticalDown = keyframes`
  0% { transform: translateY(-50px); opacity: 0; } 
  100% { transform: translateY(0); opacity: 1; }
`;

const inputError = keyframes`
  0% { transform: translateY(-2px); } 
  20% { transform: translateY(2px); }
  40% { transform: translateY(-2px); }
  60% { transform: translateY(2px); }
  80% { transform: translateY(-2px); }
  100% { transform: translateY(0); } 
`;

export const loginWrap = css`
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1142px;
  image-rendering: -webkit-optimize-contrast;
  background: linear-gradient(180deg, #291251 0%, #6537ad 100%);

  .loginBox {
    position: absolute;
    width: 1137px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    .loginBoxLeft {
      position: relative;
      margin-right: 290px;
      text-align: center;
      color: rgba(255, 255, 255, 0.72);
      font-weight: 500;
      font-size: 1.25rem;
      width: 260px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      animation: ${verticalUp} 0.5s ease-in-Out 1;
      .loginLogo {
        margin-bottom: 40px;
      }
      p.content {
        width: 260px;
        height: 128px;
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        text-align: center;
        color: rgba(255, 255, 255, 0.72);
      }
    }
    .loginBoxRight {
      position: relative;
      box-sizing: border-box;
      padding: 56px;
      background: #ffffff;
      border-radius: 4px;
      width: 532px;
      animation: ${verticalDown} 0.5s ease-in-Out 1;
      .illust {
        position: absolute;
        top: 106px;
        left: -263px;
      }
      .loginTitle {
        font-weight: 700;
        font-size: 2.26rem;
        margin-bottom: 30px;
      }
      .formStepWrap {
        padding-top: 10px;
        overflow: hidden;
        .formStep {
          position: relative;
          left: 0;
          width: 200%;
          display: flex;
          transition: all 0.2s ease-out;
          &.step1 {
            left: 0;
          }
          &.step2 {
            left: -100%;
            min-height: 199px;
            max-height: 235px;
          }
        }
      }
      .loginPrivacyInfo {
        margin: 40px 0 0;
        font-size: 0.875rem;
        color: #646782;
        .loginPrivacyBtn {
          color: #aa51e0;
        }
      }
    }
  }
`;

export const formStepInner = css`
  width: 50%;
  &:first-of-type {
    margin-right: 20px;
  }
  .formLabel {
    position: relative;
    display: block;
    background: #fff;
    .formInput {
      width: 100%;
      height: 56px;
      vertical-align: top;
      background: #ffffff;
      border: 1px solid #d5d3e4;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 15px 20px;
      transition: border-color 0.3s ease-out;
      &:focus,
      &.active {
        border-color: #aa51e0;
      }
      &.error {
        border: 1px solid #b3261e;
        background: #fffcfc;
        animation: ${inputError} 0.3s ease-in-Out 1;
      }
      &.valid {
        background: #fff url(${IconInputCheckUrl}) no-repeat calc(100% - 20px)
          center;
      }
    }
    .formPlaceholder {
      position: absolute;
      display: inline-block;
      background: transparent;
      padding: 0 5px;
      left: 20px;
      top: 15px;
      color: #9a9cb5;
      pointer-events: none;
      transition: color, background, font-size, top 0.15s ease-out;
      &.active,
      &.focus {
        color: #aa51e0;
        background: #fff;
        font-size: 0.75rem;
        top: -11px;
      }
      &.error {
        color: #b3261e;
        background: #fffcfc;
        font-size: 0.75rem;
        top: -11px;
      }
    }
  }
  .formValidText {
    font-weight: 500;
    display: block;
    text-align: left;
    font-size: 0.75rem;
    color: #b3261e;
    padding: 8px 0 0 4px;
  }
  .loginEmailBtn {
    margin-top: 24px;
    /* width: 100%;
    height: 56px;
    margin-top: 24px;
    border: 0;
    border-radius: 30px;
    background: #aa51e0;
    transition: background 0.5s ease-out;
    color: #fff;
    &:disabled {
      background: #d5d3e4;
      cursor: auto;
    } */
  }
  .loginOR {
    position: relative;
    margin: 40px 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      border-top: 1px solid #eee;
    }
    span {
      position: relative;
      display: inline-block;
      background: #fff;
      padding: 0 16px;
    }
  }
  .loginPwReissuanceWrap {
    position: relative;
    margin: 40px 0;
    button {
      border: 0 none;
      color: #9b51e0;
      background: transparent;
    }
  }
`;

export const googleloginBtn = css`
  width: 100%;
  font-family: Spoqa Han Sans Neo, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  line-height: 54px;
  letter-spacing: -0.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #ffffff;
  border: 1px solid #d5d3e4;
  box-sizing: border-box;
  border-radius: 30px;
  svg {
    margin-right: 10px;
  }
`;

import { useStateChange as stateChange } from '@hooks/modal/useStateChange';
import {
  LANGUAGE,
  StatusCodeType,
  subTaskRole,
  TypeCategory
} from '@src/common/config/Code';
import { IMyTaskList } from '@src/common/config/IMyTask';
import { textSlice } from '@src/common/utils/Func';
import ColumnHeadComp from '@src/pages/components/ColumnHead.Comp';
import TaskNameViewerComp from '@src/pages/components/TaskNameViewer.Comp';
import { IconDetail } from '@src/resources/icon';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import { DeadlineCheckDateViewer } from '../components/DeadlineCheckDateViewer';
import { SubTaskProgress } from '../components/SubTask.Progress';

export const myTaskColumns = (
  myInfo: any,
  putStatus: (payload: any) => void,
  isSort: any,
  setIsSort: any,
  dataList?: any
) =>
  [
    {
      code: 'workerStatus',
      value: '상태',
      width: 70,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="status"
          />
        );
      },
      renderRowData: (data: StatusCodeType) => <StatusBadge statusCode={data} />
    },
    {
      code: 'workerType',
      value: '역할',
      width: 60,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="workerType"
          />
        );
      },
      renderRowData: (data: any) => {
        const role: string = (subTaskRole[data] as string) || '';
        return <span>{role}</span>;
      }
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 88,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'projectName',
      value: '프로젝트',
      width: 180,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: (data: any) => {
        if (data) {
          return <TaskNameViewerComp text={data} />;
        }
      }
    },
    {
      code: 'taskName',
      value: '작업',
      width: 280,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="string"
          />
        );
      },
      renderRowData: (data: any, rowData: IMyTaskList) => {
        if (data) {
          return <TaskNameViewerComp text={data} />; // D-7 표시 안함
          // let dDay = -1;
          // let shouldDisplayDDay = false;
          // if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(rowData.endDateTime)) {
          //   const current = new Date();
          //   const end = parseISO(rowData.endDateTime);
          //   dDay = differenceInCalendarDays(end, current);
          //   shouldDisplayDDay =
          //     (7 >= dDay && dDay >= 1) ||
          //     (dDay === 0 && current.getTime() <= end.getTime());
          // }
          // return (
          //   <>
          //     <TaskNameViewerComp text={data} />
          //     {shouldDisplayDDay && (
          //       <StatusBadge
          //         statusCode="END"
          //         forceText={`D-${dDay === 0 ? 'day' : dDay}`}
          //       />
          //     )}
          //   </>
          // );
        }
      }
    },
    {
      code: 'taskType',
      value: '유형',
      width: 66,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="taskType"
          />
        );
      },
      renderRowData: (data: any) => {
        const typeCategory = (TypeCategory[data] as string) || '';
        return <span>{typeCategory}</span>;
      }
    },
    {
      code: 'startLanguage',
      value: '출발어',
      width: 96,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 96,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="language"
          />
        );
      },
      renderRowData: (data: any) => {
        let startLanguage = LANGUAGE.filter((v) => data.includes(v.code)).map(
          (value) => value.label
        );

        switch (startLanguage.length) {
          case 0:
            return null;
          case 1:
            return startLanguage[0];
          default:
            return textSlice(startLanguage);
        }
      }
    },

    {
      code: 'taskAmount',
      value: '작업 분량(Net)',
      width: 110,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="number"
          />
        );
      },
      renderRowData: (data: any) => {
        const taskAmountSetForm = Number(data).toLocaleString('en');
        return <span>{taskAmountSetForm}</span>;
      }
    },
    {
      code: 'startDateTime',
      value: '시작일',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any) => {
        return format(new Date(data), 'yyyy.MM.dd HH:mm')
          .toLowerCase()
          .replace(',', '');
      }
    },
    {
      code: 'endDateTime',
      value: '마감일',
      width: 90,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="date"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return <DeadlineCheckDateViewer data={data} rowData={rowData} />;
      }
    },
    {
      code: 'mainProjectManager',
      value: '담당PM',
      width: 66,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="pm"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        const userList = [
          rowData.mainProjectManager,
          rowData.subProjectManager
        ];
        return <TPAvatarGroupNew userList={userList} />;
      }
    },
    {
      code: 'catButton',
      value: 'CAT',
      width: 116,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="key"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <TPButtonBasic
            className=""
            label={'CAT 이동'}
            onClick={(e) => {
              e.stopPropagation();
              const returUrl = `/edit/${rowData.languageTaskId}/${rowData.projectId}`;

              const host = process.env.REACT_APP_API_BASE_URL;

              window.open(
                `${host}/login/google/?returnUrl=${encodeURIComponent(
                  returUrl
                )}`.replace(/\/{2}/g, '/')
              );
            }}
            size="small"
            variant="contained"
          />
        );
      }
    },
    {
      code: 'workerType',
      value: '진행 단계',
      width: 132,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="unable"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <SubTaskProgress
            subTaskProgressInfo={rowData.workerList}
            rowData={rowData}
            myInfo={myInfo}
            onClick={putStatus}
          />
        );
      }
    },
    {
      code: 'option',
      value: '',
      width: 24,
      renderHeader() {
        return (
          <ColumnHeadComp
            title={this.value}
            field={this.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType="unable"
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        const { workerId, workerStatus, workerType } = rowData;
        const { openHandle: openStateChange } = stateChange();

        switch (workerStatus) {
          case 'PROGRESS':
            let setType: string = '';
            switch (workerType) {
              case 'TRANSLATOR':
                setType = '0';
                break;
              case 'FIRST_CHECKER':
                setType = '1';
                break;
              case 'SECOND_CHECKER':
                setType = '2';
                break;
              default:
                break;
            }
            return (
              <TPPopoverMenu
                popoverMenus={[
                  {
                    clickMenu: () => {
                      openStateChange({
                        type: 'myTask',
                        text: setType,
                        data: {
                          id: workerId,
                          status: workerStatus,
                          workerType
                        },
                        putStatus
                      });
                    },
                    label: '상태 변경하기'
                  }
                ]}
                clickPopoverButton={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <TPButtonAction icon={<IconDetail />} size="small" />
              </TPPopoverMenu>
            );
          default:
            return null;
        }
      }
    }
  ] as const;

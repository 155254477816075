import Api from '@src/api/Api';
import { useQuery } from '@tanstack/react-query';
import { DashboardKeys } from '../keys/DashboardKeys';
import { ApiTaskDashboardCountResponse } from '../types';

// 사용안함
export function useApi1CategoryList(filterData: any, isFilter: boolean) {
  return useQuery(
    DashboardKeys.Deep1(filterData),
    () => {
      return Api.post('/task/count', { ...filterData, isFilter }).then(
        (res) => {
          const v = res.data?.section as ApiTaskDashboardCountResponse;
          if (!Array.isArray(v)) return [];
          return v.sort((a, b) => a.order - b.order);
        }
      );
    },
    {
      enabled: true,
      // cacheTime: Infinity,
      // staleTime: Infinity,
      retry: 3,
      suspense: false
    }
  );
}

import { css, cx } from '@emotion/css';
import styled from '@emotion/styled';
import { useAuth } from '@hooks/auth/useAuth';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { IconAlarmUrl, IconOnBellUrl } from '@resources/icon';
import { context } from '@src/Context';
// import { useNotice } from '@src/hooks/notice/useNotice';
import Alarm from '@src/pages/Alarm';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { useContext, useEffect } from 'react';

function HeaderRightMenu() {
  const { signOut } = useAuth();
  const {
    myInfo
    // refetchMyInfo // 서버에서 로그인 유저의 roles 정보를 캐시한다. 따라서 로그인 상태에서는 어드민에서 업데이트헌 유저권한을 얻을 수 없다.
  } = useMyInfo();

  const { hasNewAlarm, showAlarmUI, setShowAlarmUI } = useContext(context);
  // const {} = useNotice();

  return (
    <SHeaderRightMenu>
      <button
        className={cx('header--button--alarm', {
          // 'header--button--alarm--on': isSEEConnected
        })}
        onClick={() => {
          setShowAlarmUI((v) => !v);
        }}
      >
        <img src={hasNewAlarm ? IconOnBellUrl : IconAlarmUrl} alt="" />
      </button>
      <div className="avatar">
        <TPAvatarBasic
          data-role={myInfo ? myInfo.roles : ''}
          alt={myInfo ? myInfo.name : ''}
          badge={false}
          size="small"
          src={myInfo ? myInfo.avatarImgUrl : ''}
          // onClick={() => {
          //   refetchMyInfo().then((query) => console.debug(query.data)); // 서버에서 로그인 유저의 roles 정보를 캐시한다. 따라서 로그인 상태에서는 어드민에서 업데이트헌 유저권한을 얻을 수 없다.
          // }}
        />
        <div className="menu">
          <div className="menu-gap" />
          <div className="menu-items">
            <button className="logout" onClick={signOut}>
              로그아웃
            </button>
          </div>
        </div>
      </div>
      {showAlarmUI && <Alarm />}
    </SHeaderRightMenu>
  );
}
export default HeaderRightMenu;

const SHeaderRightMenu = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  .header--button--alarm {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .alarm-ui {
    position: fixed;
    top: 44px;
    right: 84px;
  }

  .avatar {
    position: relative;

    .menu {
      display: none;
      position: absolute;
      white-space: nowrap;
      right: 0;
      padding: 0;
      margin: 0;

      .menu-gap {
        height: 8px;
      }

      .menu-items {
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 0;

        .logout {
          border-radius: 2px;
          padding: 5px 14px;
          color: #242424;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.25px;
          text-align: center;
          cursor: pointer;

          :hover {
            border-color: #000;
            background-color: #000;
            color: #fff;
          }
        }
      }
    }

    :hover {
      .menu {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

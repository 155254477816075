import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { myWorkKeys } from '@hooks/queryKeyFactory';
import { IMyWorkDetailData } from '@src/common/config/ITask';
import { apiKeys } from '@src/pages/myTask/MyTaskTab.page';
import { compareWorkerType } from '@src/common/config/Code';
import { useEffect, useState } from 'react';

export const useGetMyTaskCount = () => {
  const [filter, setFilter] = useState<any>({});
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof apiKeys)['count']>
  >): Promise<any> => {
    return (await api.post('/myWork/count', filter)).data;
  };

  const { data, isLoading, refetch } = useQuery(
    [...apiKeys.listByDay(filter)],
    getData,
    {
      initialData: { ids: [] },
      select: (data) => data?.section ?? [],
      enabled: false
    }
  );

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      refetch();
    }
  }, [filter]);

  return { data, isLoading, setFilter, refetch };
};

import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useState } from 'react';

/**
 * /project/assign
 * @discription 프로젝트 담당 매니저 수정
 * @returns {{ postPmList }}
 */
export const usePostPmAssign = () => {
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();
  const [pageType, setPageType] = useState('register');

  const postData = async (payload: any) => {
    return await api.post('/project/assign', payload);
  };

  const { mutate } = useMutation({
    mutationFn: postData,
    onSuccess: () => {
      toastOpen({
        text:
          pageType === 'register'
            ? '담당 PM이 배정되었습니다.'
            : '담당 PM이 변경되었습니다.'
      });
      queryClient.invalidateQueries(['/project']);
    },
    onError: (error: any) => {
      toastOpen({
        text:
          pageType === 'register'
            ? '담당 PM 배정에 실패했습니다.'
            : '담당 PM 변경에 실패했습니다.'
      });
    }
  });

  const postPmList = (payload: any) => {
    if (payload.pageType) setPageType(payload.pageType);
    mutate({
      projectId: payload.projectId,
      mainProjectManagerUserId: payload.mainProjectManagerUserId,
      subProjectManagerUserId: payload.subProjectManagerUserId
    });
  };

  return { postPmList };
};

import { useDeleteLanguageSubTask } from '@src/hooks/languageTask/deleteTask';
import { useApi3LanguageTaskList } from '../hooks/useApi3LanguageTaskList';
import Row3LanguageTaskItem from './Row3LanguageTaskItem';
import { usePostLanguageSubTask } from '@src/hooks/languageTask/post';

export default function Row3LanguageTaskList({
  filterData,
  item,
  taskType,
  setOpenedJobModifyPage,
  filterExposure,
  isFilter
}: any) {
  const { data, isSuccess, isError } = useApi3LanguageTaskList(
    item.taskId,
    filterData
  );
  const { deleteTaskAlert } = useDeleteLanguageSubTask();
  const { putFileStatusTask } = usePostLanguageSubTask();

  if (isError) return <></>;

  if (isSuccess) {
    return (
      <>
        {Array.isArray(data) &&
          data.length &&
          data.map((d: any, i) => {
            return (
              <Row3LanguageTaskItem
                key={d?.languageTaskId ?? i}
                {...{
                  filterData,
                  item: d,
                  taskType,
                  setOpenedJobModifyPage,
                  filterExposure,
                  languageTaskTitle: d.languageTaskTitle,
                  deleteTaskAlert,
                  putFileStatusTask,
                  dataLength: data.length,
                  isFilter
                }}
              />
            );
          })}
      </>
    );
  }
  return <></>;
}

import Api from '@src/api/Api';
import { useQuery } from '@tanstack/react-query';
import { DashboardKeys } from '../keys/DashboardKeys';

export function useApi4LanguageSubTaskList(id: any) {
  return useQuery(
    DashboardKeys.Deep4(id),
    (): Promise<any> => {
      return Api.get(`/languageSubTask/list/${id}`).then(
        (res) => res.data?.languageSubTaskList ?? []
      );
    },
    {
      enabled: true,
      // cacheTime: Infinity,
      // staleTime: Infinity,
      retry: 3,
      suspense: false
    }
  );
}

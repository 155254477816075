import { IWidgets, IStatus } from './ICode';

////////////////////////////////
////////////////////////////////
export const appEnv = process.env.REACT_APP_ENV;
export const isDev = appEnv === 'dev';
export const isQA = appEnv === 'qa';
export const isProd = appEnv === 'prod';
export const isPublished = isDev || isQA; // dev에서만 보이게

export interface ISideMenu {
  name: string;
  to: string;
  roles: roleType[];
  idx?: number;
  pattern?: RegExp;
}
export type roleType = 'PML' | 'PM' | 'LL' | 'TL' | 'ADMIN';
////////////////////////////////
////////////////////////////////

export const TaskStatus: IStatus = {
  NEW: '신규',
  COMPLETE_ASSIGN: '배정완료',
  // REQUEST_QUOTE: '견적 요청', // 삭제됨
  PROGRESS: '진행',
  COMPLETE: '작업완료',
  // WAIT_DELIVERY: '납품대기', // COMPLETE 로 변경
  COMPLETE_TASK: '완료',
  DELIVERY_COMPLETE: '납품완료',
  STOP: '중지'
} as const;

export const TaskStatusSortLevel = {
  NEW: 1, // 신규
  COMPLETE_ASSIGN: 2, // '배정완료',
  PROGRESS: 3, // '진행',
  WAIT_QUERY: 4,
  REJECT: 5,
  COMPLETE: 6, // '작업완료',
  COMPLETE_TASK: 7,
  DELIVERY_COMPLETE: 8, // '납품완료',
  STOP: 9, // '중지'
  END: 10 // '종료'
} as const;

export const workerTypeLevel = {
  TRANSLATOR: 1,
  FIRST_CHECKER: 2,
  SECOND_CHECKER: 3
} as const;
export type WorkerType = keyof typeof workerTypeLevel;
function getWorkerTypeLevel(type: any) {
  return (
    workerTypeLevel[
      (type && type.hasOwnProperty('workerType')
        ? type?.workerType
        : type) as WorkerType
    ] ?? NaN
  );
}
export function compareWorkerType(a: any, b: any) {
  const n = getWorkerTypeLevel(a) - getWorkerTypeLevel(b);
  return isNaN(n) ? 0 : n;
}

export const amountTypeLevel = {
  PERCENT_101: 1,
  PERCENT_REP: 2,
  PERCENT_100: 3,
  PERCENT_9599: 4,
  PERCENT_8594: 5,
  PERCENT_7584: 6,
  PERCENT_5074: 7,
  NO_MATCH: 8
} as const;
export type AmountType = keyof typeof amountTypeLevel;
export const compireAmountType = (a: any, b: any) => {
  const n = amountTypeLevel[a as AmountType] - amountTypeLevel[b as AmountType];
  return isNaN(n) ? 0 : n;
};

export const sortByTaskStatus = (a: any, b: any, asc: boolean = true) => {
  const al =
    TaskStatusSortLevel[a.taskStatus as keyof typeof TaskStatusSortLevel];
  const bl =
    TaskStatusSortLevel[b.taskStatus as keyof typeof TaskStatusSortLevel];

  if (isNaN(al) || isNaN(bl)) return 0;

  return asc ? Math.sign(al - bl) : Math.sign(bl - al);
};

export const sortByAnyStatus = (
  a: any,
  b: any,
  key: string = 'status',
  asc: boolean = true
) => {
  const al = TaskStatusSortLevel[a[key] as keyof typeof TaskStatusSortLevel];
  const bl = TaskStatusSortLevel[b[key] as keyof typeof TaskStatusSortLevel];

  if (isNaN(al) || isNaN(bl)) return 0;

  return asc ? Math.sign(al - bl) : Math.sign(bl - al);
};

//Categories 비교 후 수정처리
export const TypeCategory: IStatus = {
  TRANSLATION: '번역',
  POLISHING: '폴리싱',
  LQA: 'LQA'
};

export const WidgetCode: IWidgets = {
  // 메인화면 PML 위젯값
  widgetMain: [
    { badgeContent: 0, count: 0, icon: 'NEW', label: '신규' },
    { badgeContent: 0, count: 0, icon: 'PROGRESS', label: '진행' },
    { badgeContent: 0, count: 0, icon: 'END', label: '종료' }
  ],
  //todo: 아래 내용 명확하지 않아서 하나 더 만듬. 확인후 삭제
  // 메인화면 PML 위젯값
  widgetDetail: [
    { badgeContent: 0, count: 0, icon: 'NEW', label: '신규' },
    {
      badgeContent: 0,
      count: 0,
      icon: 'COMPLETE_ASSIGN',
      label: '배정완료'
    },
    { badgeContent: 0, count: 0, icon: 'PROGRESS', label: '진행' },
    {
      badgeContent: 0,
      count: 0,
      icon: 'STOP',
      label: '중지'
    },
    // { badgeContent: 0, count: 0, icon: 'WAIT_DELIVERY', label: '납품대기' },
    { badgeContent: 0, count: 0, icon: 'COMPLETE', label: '작업완료' },
    { badgeContent: 0, count: 0, icon: 'DELIVERY_COMPLETE', label: '납품완료' }
  ],
  widgetTask: [
    { badgeContent: 0, count: 0, icon: 'NEW', label: '신규' },
    {
      badgeContent: 0,
      count: 0,
      icon: 'COMPLETE_ASSIGN',
      label: '배정완료'
    },
    { badgeContent: 0, count: 0, icon: 'PROGRESS', label: '진행' },
    {
      badgeContent: 0,
      count: 0,
      icon: 'STOP',
      label: '중지'
    },
    // { badgeContent: 0, count: 0, icon: 'WAIT_DELIVERY', label: '납품대기' },
    { badgeContent: 0, count: 0, icon: 'COMPLETE', label: '작업완료' },
    { badgeContent: 0, count: 0, icon: 'DELIVERY_COMPLETE', label: '납품완료' }
  ],
  widgetMyWork: [
    { badgeContent: 0, count: 0, icon: 'NEW', label: '신규' },
    { badgeContent: 0, count: 0, icon: 'PROGRESS', label: '진행' },
    { badgeContent: 0, count: 0, icon: 'WAIT_QUERY', label: '쿼리대기' },
    {
      badgeContent: 0,
      count: 0,
      icon: 'STOP',
      label: '중지'
    },
    { badgeContent: 0, count: 0, icon: 'REJECT', label: '반려' },
    {
      badgeContent: 0,
      count: 0,
      icon: 'COMPLETE',
      label: '완료'
    }
  ]
};

// 작업등록 유형 코드
export const categories = [
  { label: '번역', value: 'TRANSLATION' },
  { label: '폴리싱', value: 'POLISHING' },
  { label: 'LQA', value: 'LQA' }
];

// 작업파일 등록 여부 코드
export const fileUseYn = isPublished
  ? [
      { label: '보라캣', value: 'in' },
      { label: '외부 CAT', value: 'out' }
    ]
  : [{ label: '외부 CAT', value: 'out' }];
export const isBoracatUseOrNotList = [
  { label: '보라캣', value: true },
  { label: '외부 CAT', value: false }
];

// 필터 코드
// 상태 state //
//페이지별로 상태코드 구분

export const subTaskRole: IStatus = {
  TRANSLATOR: '번역',
  FIRST_CHECKER: '1차 검수',
  SECOND_CHECKER: '2차 검수'
} as const;

export const getCategoryLabel = (code: string): string => {
  const item = categories.find((e) => e.value === code);
  return item ? item.label : code;
};

////////////////////////////////
////////////////////////////////
type IWorkerType = { [key: string]: { upper: string; lower: string } };
export const workerType: IWorkerType = {
  TRANSLATOR: { upper: 'TRANSLATOR', lower: 'translator' },
  FIRST_CHECKER: { upper: 'FIRST_CHECKER', lower: 'firstChecker' },
  SECOND_CHECKER: { upper: 'SECOND_CHECKER', lower: 'secondChecker' }
};

////////////////////////////////
////////////////////////////////
export const LANGUAGE = [
  { code: '', label: '' },
  { code: 'ALL', label: '전체' },
  { code: 'KO', label: '한국어' },
  { code: 'EN', label: '영어' },
  { code: 'ZH_CN', label: '중국어(간체)' },
  { code: 'ZH_TW', label: '중국어(번체)' },
  { code: 'JA', label: '일본어' },
  { code: 'DE', label: '독일어' },
  { code: 'FR', label: '프랑스어' },
  { code: 'ES', label: '스페인어(유럽)' },
  { code: 'ES_419', label: '스페인어(남미)' },
  { code: 'TR', label: '튀르키예어' },
  { code: 'ID', label: '인도네시아어' },
  { code: 'TH', label: '태국어' },
  { code: 'RU', label: '러시아어' },
  { code: 'PT', label: '포르투갈어(유럽)' },
  { code: 'PT_BR', label: '포르투갈어(브라질)' },
  { code: 'VI', label: '베트남어' },
  { code: 'PL', label: '폴란드어' },
  { code: 'IT', label: '이탈리아어' },
  { code: 'NO', label: '노르웨이어' },
  { code: 'AR', label: '아랍어' },
  { code: 'HI', label: '힌디어' },
  { code: 'MS', label: '말레이시아어' },
  { code: 'CS', label: '체코어' },
  { code: 'NL', label: '네덜란드어' },
  { code: 'TL', label: '필리핀어' },
  { code: 'SV', label: '스웨덴어' },
  { code: 'DA', label: '덴마크어' },
  { code: 'FI', label: '핀란드어' },
  { code: 'HU', label: '헝가리어' },
  { code: 'HR', label: '크로아티아어' },
  { code: 'SR', label: '세르비아어' },
  { code: 'ETC', label: '기타' }
] as const;
export const LANGUAGE_LEVEL = LANGUAGE.reduce(
  (r, e, i) => ({
    ...r,
    [e.code]: i
  }),
  {} as any
);
export function sortByLanguage(a: any, b: any, asc: boolean = true) {
  const al = LANGUAGE_LEVEL[a];
  const bl = LANGUAGE_LEVEL[b];

  if (isNaN(al) || isNaN(bl)) return 0;

  return asc ? Math.sign(al - bl) : Math.sign(bl - al);
}

export type LanguageCodeType = (typeof LANGUAGE)[number]['code'];
export type LanguagePairType = {
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType;
};
export const getLanguageLabelsByCode = (
  LanguageCodeType: LanguageCodeType[]
) => {
  return LANGUAGE.filter((el) => LanguageCodeType.includes(el.code));
};
export const getLanguageLabelByCode = (code: string) => {
  const item = LANGUAGE.find((e) => e.code === code);
  return item ? item.label : code;
};

////////////////////////////////
////////////////////////////////
// @PLAN: https://2bytes.atlassian.net/wiki/spaces/QEMRB/pages/180060161?focusedCommentId=201326745
export const PROJECT_STATUS_TYPE = [
  { code: 'NEW', label: '신규', text: '신규' },
  { code: 'PROGRESS', label: '진행', text: '진행' },
  { code: 'END', label: '종료', text: '종료' }
] as const;
export const TASK_STATUS_TYPE = [
  { code: 'NEW', label: '신규', text: '신규' },
  { code: 'COMPLETE_ASSIGN', label: '배정완료', text: '배정완료' },
  { code: 'PROGRESS', label: '진행', text: '작업진행' },
  { code: 'COMPLETE', label: '작업완료', text: '작업완료' },
  { code: 'DELIVERY_COMPLETE', label: '납품완료', text: '납품완료' },
  { code: 'STOP', label: '중지', text: '중지' }
] as const;
export const LANGUAGE_TASK_STATUS_TYPE = [
  { code: 'NEW', label: '신규', text: '신규' },
  { code: 'COMPLETE_ASSIGN', label: '배정완료', text: '배정완료' },
  { code: 'PROGRESS', label: '진행', text: '작업진행' },
  { code: 'COMPLETE', label: '작업완료', text: '작업완료' }, // 어느 것?
  { code: 'COMPLETE_TASK', label: '작업완료', text: '작업완료' }, // 어느 것?
  { code: 'DELIVERY_COMPLETE', label: '납품완료', text: '납품완료' },
  { code: 'STOP', label: '중지', text: '중지' }
] as const;
export const LANGUAGE_SUB_TASK_STATUS_TYPE = [
  { code: 'NEW', label: '신규', text: '신규' },
  { code: 'PROGRESS', label: '진행', text: '작업진행' },
  { code: 'WAIT_QUERY', label: '쿼리대기', text: '쿼리대기' },
  { code: 'REJECT', label: '반려', text: '작업반려' },
  { code: 'COMPLETE', label: '완료', text: '완료' },
  { code: 'STOP', label: '중지', text: '중지' }
] as const;
export const WORKER_TYPE = [
  { code: 'ALL', label: '전체', text: '전체' },
  { code: 'TRANSLATOR', label: '번역', text: '번역' },
  { code: 'FIRST_CHECKER', label: '1차 검수', text: '1차 검수' },
  { code: 'SECOND_CHECKER', label: '2차 검수', text: '2차 검수' }
] as const;
// export const STATUS_COLOR = [
//   { code: 'NEW', color: '#009E73' },
//   { code: 'COMPLETE_ASSIGN', color: '#00A3FF' },
//   { code: 'WAIT_QUERY', color: '#CC79A7' },
//   { code: 'PROGRESS', color: '#F0E442' },
//   { code: 'COMPLETE', color: '#0072B2' },
//   { code: 'DELIVERY_COMPLETE', color: '#0072B2' },
//   { code: 'REJECT', color: '#D55E00' },
//   { code: 'STOP', color: '#E69F00' }
// ] as const;

export const projectStatusTypes = PROJECT_STATUS_TYPE.map((e) => e.code);
export const taskStatusTypes = TASK_STATUS_TYPE.map((e) => e.code);
export const languageTaskStatusTypes = LANGUAGE_TASK_STATUS_TYPE.map(
  (e) => e.code
);
export const languageSubTaskStatusTypes = LANGUAGE_SUB_TASK_STATUS_TYPE.map(
  (e) => e.code
);
export const workerTypes = WORKER_TYPE.map((e) => e.code);

export type WorkerTypeCode = (typeof WORKER_TYPE)[number]['code'];
export const getWorkerType = (getWorkerType: WorkerTypeCode[]) => {
  return WORKER_TYPE.filter((el) => getWorkerType.includes(el.code));
};
export const getTaskStatusTypeLabel = (code: string): string => {
  const el = TASK_STATUS_TYPE.find((e) => e.code === code);
  return el ? el.label : code === 'ALL' ? '전체' : code;
};
export const getLanguageTaskStatusTypeLabel = (code: string): string => {
  const el = LANGUAGE_TASK_STATUS_TYPE.find((e) => e.code === code);
  return el ? el.label : code === 'ALL' ? '전체' : code;
};
export const getLanguageSubTaskStatusTypeLabel = (code: string): string => {
  const el = LANGUAGE_SUB_TASK_STATUS_TYPE.find((e) => e.code === code);
  return el ? el.label : code === 'ALL' ? '전체' : code;
};
export const getWorkerTypeLabel = (code: string): string => {
  const el = WORKER_TYPE.find((e) => e.code === code);
  return el ? el.label : code === 'ALL' ? '전체' : code;
};
////////////////////////////////
////////////////////////////////
// STATUS
export const STATUS = [
  { code: 'ALL', label: '전체', text: '전체' },
  { code: 'NEW', label: '신규', text: '신규' }, // 신규 작업
  { code: 'REQUEST_QUOTE', label: '견적요청', text: '견적요청' }, // 견적요청
  { code: 'QUOTE_DELIVERY', label: '견적발송', text: '견적발송' }, // 견적발송 - 삭제됨
  { code: 'COMPLETE_ASSIGN', label: '배정완료', text: '배정완료' }, // 작업배정
  { code: 'PROGRESS', label: '진행', text: '진행' },
  { code: 'COMPLETE', label: '작업완료', text: '완료' }, // 함정! 기존 코드에서 요 하나 때문에 useOptionLabel 로 분기처리하고 있음
  { code: 'REJECT', label: '반려', text: '반려' },
  { code: 'COMPLETE_TASK', label: '완료', text: '완료' },
  // { code: 'WAIT_DELIVERY', label: '납품대기', text: '납품대기' }, // 납품대기 - 삭제됨
  { code: 'WAIT_QUERY', label: '쿼리대기', text: '쿼리대기' }, // 쿼리대기 - 신규추가
  { code: 'DELIVERY_COMPLETE', label: '납품완료', text: '납품완료' },
  { code: 'END', label: '종료', text: '종료' },
  { code: 'STOP', label: '중지', text: '중지' }
] as const;
export type StatusCodeType = (typeof STATUS)[number]['code'];
export const getStatus = (getStatus: StatusCodeType[]) => {
  return STATUS.filter((el) => getStatus.includes(el.code));
};
export const statusCodes = STATUS.map((e) => e.code);
type StatusCode = (typeof statusCodes)[number];
export const DashboardFilterStatusTypes = statusCodes.reduce(
  (enumObject, status) => ({
    ...enumObject,
    [status]: status
  }),
  {} as { [key in StatusCode]: StatusCode }
);
Object.freeze(DashboardFilterStatusTypes);
export const STATUS_COLOR = {
  NEW: '#009E73', // 신규
  REQUEST_QUOTE: '#009E73', // 견적 요청
  COMPLETE_ASSIGN: '#00A3FF', // 배정
  PROGRESS: '#F0E442', // 진행
  STOP: '#E69F00', // 중지
  REJECT: '#D55E00', // 반려
  // WAIT_DELIVERY: '#E69F00', // 납품대기
  WAIT_QUERY: '#CC79A7', // 납품대기
  COMPLETE_TASK: '#0072B2', // 완료
  COMPLETE: '#0072B2', // 완료
  END: '#0072B2', // 종료
  DELIVERY_COMPLETE: '#000000' // 납품완료
} as const;

export const GANTT_COLOR = {
  NEW: STATUS_COLOR.NEW,
  PROGRESS: STATUS_COLOR.PROGRESS,
  STOP: STATUS_COLOR.STOP,
  REJECT: STATUS_COLOR.REJECT,
  COMPLETE: STATUS_COLOR.COMPLETE
};

export const BAR_COLOR = {
  TRANSLATION: STATUS_COLOR.NEW,
  POLISHING: '#9E9E9E',
  LQA: '#FFC107'
};
export const getTaskStatusLabel = (status: string): string => {
  return (TaskStatus[status] as string) ?? status;
};
export const getStatusLabel = (status: string): string => {
  const el = STATUS.find((e) => e.code === status);
  return el ? el.label : status;
};
export const getStatusText = (status: string): string => {
  const el = STATUS.find((e) => e.code === status);
  return el ? el.text : status;
};
export const getTaskStatusLabelColor = (status: string): string => {
  return STATUS_COLOR[status as keyof typeof STATUS_COLOR] ?? 'transparent';
};
////////////////////////////////
////////////////////////////////
export const TASK_TYPE = [
  { code: 'ALL', label: '전체', text: '전체' },
  { code: 'TRANSLATION', label: '번역', text: '번역' },
  { code: 'POLISHING', label: '폴리싱', text: '폴리싱' },
  { code: 'LQA', label: 'LQA', text: 'LQA' },
  { code: 'QA', label: 'QA', text: 'QA' },
  { code: 'PS', label: 'PS', text: 'PS' }
] as const;
type TaskTypeCode = (typeof TASK_TYPE)[number]['code'];
export const getTaskType = (getTaskType: TaskTypeCode[]) => {
  return TASK_TYPE.filter((el) => getTaskType.includes(el.code));
};
////////////////////////////////
////////////////////////////////
export const DATE_TYPE = [
  { code: 'ALL', label: '전체', text: '전체' },
  { code: 'DUE_DATE', label: '만료일', text: '만료일' },
  { code: 'HOPE_SUPPLY_DATE', label: '납품일', text: '납품일' },
  { code: 'START_DATE', label: '시작일', text: '시작일' },
  { code: 'END_DATE', label: '마감일', text: '마감일' },
  { code: 'ASSIGNMENT_DATE', label: '배정일', text: '배정일' },
  { code: 'CONTRACT_START_DATE', label: '계약일', text: '계약일' },
  { code: 'CONTRACT_END_DATE', label: '만료일', text: '만료일' }
] as const;
export type DateTypeCode = (typeof DATE_TYPE)[number]['code'];
export const getDateType = (getDateType: DateTypeCode[]) => {
  return DATE_TYPE.filter((el) => getDateType.includes(el.code));
};
////////////////////////////////
////////////////////////////////
export const SHOW_TYPE = [
  { code: 'SHOW', label: '노출', text: '노출' },
  { code: 'HIDE', label: '미노출', text: '미노출' },
  { code: 'SPREAD', label: '펼침', text: '펼침' },
  { code: 'FOLD', label: '접힘', text: '접힘' }
] as const;
export type ShowTypeCode = (typeof SHOW_TYPE)[number]['code'];
export const getShowType = (getShowType: ShowTypeCode[]) => {
  return SHOW_TYPE.filter((el) => getShowType.includes(el.code));
};
////////////////////////////////
////////////////////////////////
export const CONTRACT_TYPE = [
  { code: 'ALL', label: '전체', text: '전체' },
  { code: 'CASE', label: '건별 발송', text: '건별 발송' },
  { code: 'MONTHLY', label: '월별 발송', text: '월별 발송' }
] as const;
export type ContractTypeCode = (typeof CONTRACT_TYPE)[number]['code'];
export const getContractType = (getContractType: ContractTypeCode[]) => {
  return CONTRACT_TYPE.filter((el) => getContractType.includes(el.code));
};
export const getContractItem = (code: string) => {
  return CONTRACT_TYPE.find((el) => el.code === code);
};
////////////////////////////////

export const PERMISSIONS = {
  ASSIGN: ['NEW', 'PROCESSING', 'WAIT_QUERY', 'COMPLETE_ASSIGN']
} as const;

import { TWorker } from '@src/units/sobra/Worker';

const emptyWorker: TWorker = {
  userId: 0,
  slackId: '',
  workerId: 0,
  workerStatus: '',
  workerType: '',
  name: '',
  avatarImgUrl: '',
  status: ''
} as const;

export function formatWorkerList(list: TWorker[]) {
  const rs = [
    { ...emptyWorker, workerType: 'TRANSLATOR' },
    { ...emptyWorker, workerType: 'FIRST_CHECKER' },
    { ...emptyWorker, workerType: 'SECOND_CHECKER' }
  ];

  list.forEach((worker) => {
    if (worker.workerType === 'TRANSLATOR') {
      rs[0] = { ...rs[0], ...worker };
    } else if (worker.workerType === 'FIRST_CHECKER') {
      rs[1] = { ...rs[1], ...worker };
    } else if (worker.workerType === 'SECOND_CHECKER') {
      rs[2] = { ...rs[2], ...worker };
    }
  });

  return rs;
}

import styled from '@emotion/styled';

export const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24px;
  &.TPState {
    width: 30px;
    height: 30px;
  }
`;

interface IWrapSetPosition {
  itemLength: number;
}
export const WrapSetPosition = styled.div<IWrapSetPosition>`
  margin: ${({ itemLength }) => (itemLength - 1) * 1}px;
  width: ${({ itemLength }) => 24 * itemLength - (itemLength - 1) * 1}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IAvatarContainer {
  index: number;
  size?: number;
}
export const AvatarContainer = styled.div<IAvatarContainer>`
  position: relative;
  width: ${({ size }) => (size ? `${size}px` : '24px')};
  height: ${({ size }) => (size ? `${size}px` : '24px')};
  border-radius: 12px;
  transform: ${({ index }) => (index ? `translateX(${-2 * index}px)` : null)};
  /* z-index: ${({ index }) => (index ? `${10 + index * -1}` : 10)}; */
  .avatar--div--wrap {
    overflow: hidden;
    border-radius: 50%;
    width: ${({ size }) => (size ? `${size}px` : '24px')};
    height: ${({ size }) => (size ? `${size}px` : '24px')};
    line-height: 1;
    background-color: #ececec;
    img {
      max-width: 100%;
    }
  }

  div.tooltip {
    position: absolute;
    bottom: 40px;
    width: 60px;
    background-color: #2c3558;
    border-radius: 0 12px 0 12px;
    color: #ffffff;
    padding: 12px;
    ::after {
      content: '';
      position: absolute;
      bottom: -8px;
      right: 0;
      width: 8px;
      height: 8px;
      background-color: #2c3558;
    }
  }

  ::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    z-index: -5;
  }
`;

export const AdditionalUserNumber = styled.div`
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  border-radius: 50%;
  transform: translateX(-6px);
  text-align: center;
  color: #fff;
  background-color: #242424;
`;

export const AdditionalListStyle = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 18px;

  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  color: #000;
  max-width: 500px;
  /* bottom: 37px; */
  top: 30px;
  right: 20px;
  z-index: 10;
  padding: 4px 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 3px 0;
    font-size: 12px;
    .avatar--div--wrap {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .tooltipTailRight {
    display: none;
    position: absolute;
    bottom: -8px;
    right: 0;
  }

  animation: softView 0.4s;

  @keyframes softView {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

import styled from '@emotion/styled';
import {
  getStatusLabel,
  getTaskStatusLabelColor
} from '@src/common/config/Code';

const TaskStatusWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .color {
    width: 10px;
    height: 10px;
  }
  .counter {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
  }
`;

export default function TaskStatus({ children, item, counter, ...props }: any) {
  const { languageTaskStatus } = item;
  const languageTaskStatusLabel = getStatusLabel(languageTaskStatus);
  const backgroundColor = getTaskStatusLabelColor(languageTaskStatus);
  return (
    <TaskStatusWrap {...props}>
      <div className="color" style={{ backgroundColor }} />

      <div className="label" data-language-task-status={languageTaskStatus}>
        {languageTaskStatusLabel}
      </div>

      {counter && <div className="counter">{counter}</div>}
    </TaskStatusWrap>
  );
}

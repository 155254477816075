export default async function updateAccessToken() {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken === null || refreshToken === 'undefined') {
      return reject('refreshToken is null');
    }

    const host = process.env.REACT_APP_API_BASE_URL;
    const url = `${host}/tms/auth/refresh`.replace(/\/{2}/g, '/');

    const req = new XMLHttpRequest();

    req.open('POST', url, false);
    req.setRequestHeader('Content-Type', 'application/json');
    req.onload = function () {
      try {
        const data = JSON.parse(req.response);
        const keys = [
          'accessToken',
          'accessTokenExpiresIn',
          'refreshToken',
          'refreshTokenExpiresIn'
        ];
        for (let k of keys) {
          let v = data[k];
          localStorage.setItem(k, v);
        }
        sessionStorage.removeItem('isRefresh');
        if (req.status < 400) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error: any) {
        console.debug('!!!refresh-parse-server-data-error', error); // 서버 접속이 안될 경우
        // localStorage.removeItem('accessToken');
        sessionStorage.removeItem('isRefresh');
        reject(error);
      }
    };
    req.onabort = req.onerror = function (error: any) {
      // localStorage.removeItem('accessToken');
      sessionStorage.removeItem('isRefresh');
      console.error('!!!refresh-connect-error', error);
      reject(error);
    };
    req.send(
      JSON.stringify({
        refreshToken,
        loginSite: 'LPM'
      })
    );

    console.debug('!!!refresh-connect-start');
  });
}

import { ContractTypeCode } from '@src/common/config/Code';
import { IUser, PROJECT_STATUS, StringKeyType } from '@src/common/config/ICode';
import _ from 'lodash';
import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { companyPerKeys } from '@src/hooks/queryKeyFactory';
import { useEffect, useState } from 'react';
import { useHookFunc, usePageFunc } from '@src/hooks/utils';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import download, { downloadByURL } from '@src/hooks/utils/download';

export interface ICustomerData extends StringKeyType {
  companyName: string;
  totalTaskAmount: number;
  totalLanguageTaskAmount: number;
  totalGrossAmount: number;
  totalNetAmount: number;
  allPmUserList: IUser[];
  projectList: IProjectList[];
  allPmUserNames: string;
}
interface IProjectList {
  projectStatus: PROJECT_STATUS;
  projectTitle: string;
  projectKey: string;
  projectContractType: ContractTypeCode;
  taskAmount: number;
  languageTaskAmount: number;
  grossAmount: number;
  netAmount: number;
  startDateTime: Date;
  endDateTime: Date;
  pmUserList: IUser[];
  projectStartDateString: string;
  endDateTimeString: string;
  pmUserNames: string;
}

export const useGetCompanyPerList = (filterData: any) => {
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();

  //#region state define
  const [keyword, setKeyword] = useState<string>('');
  const [projectStatusList, setProjectStatusList] = useState<string[]>(['ALL']);
  const [projectContractTypeList, setProjectContractTypeList] = useState<
    string[]
  >(['ALL']);
  const [pmUserList, setPmUserList] = useState<string[]>(['0']);
  const [dateType, setDateType] = useState<string>('ALL');
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    projectStartDate: undefined,
    endDateTime: undefined
  });
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  const [filterExposure, setFilterExposure] = useState('SPREAD');
  //#endregion

  useEffect(() => {
    if (!filterData) return;
    setKeyword(filterData?.keyword ?? '');
    setProjectStatusList(filterData?.projectStatusList ?? ['ALL']);
    setProjectContractTypeList(filterData?.projectContractTypeList ?? ['ALL']);
    setPmUserList(filterData?.pmUserList ?? []);
    setDateType(filterData?.dateType ?? 'ALL');
    setDateFilter(
      filterData?.dateFilter ?? {
        startDateTime: undefined,
        endDateTime: undefined
      }
    );
    setFilterExposure(filterData?.filterExposure ?? 'SPREAD');
  }, [filterData]);
  useEffect(() => {}, [dateFilter]);

  //#region setState
  const setState = {
    setProjectStatus: (e: any) => {
      const getProjectStatus =
        e === 'ALL'
          ? ['ALL']
          : _.filter(arraySet(projectStatusList, e), (item) => item !== 'ALL');
      setProjectStatusList(
        getProjectStatus.length ? getProjectStatus : ['ALL']
      );
    },
    setProjectContractType: (e: any) => {
      const getProjectContractType =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(projectContractTypeList, e),
              (item) => item !== 'ALL'
            );
      setProjectContractTypeList(
        getProjectContractType.length ? getProjectContractType : ['ALL']
      );
    },
    setPmUser: (e: any) => {
      const getPmUser =
        e === '0'
          ? ['0']
          : _.filter(arraySet(pmUserList, e), (item) => item !== '0');
      setPmUserList(getPmUser.length ? getPmUser : ['0']);
    },
    setDateType,
    setDateFilter,
    setKeyword,
    setResetState: () => {
      setKeyword('');
      setProjectStatusList(['ALL']);
      setProjectContractTypeList(['ALL']);
      setPmUserList(['0']);
      setDateType('ALL');
      setDateFilter({ startDateTime: undefined, endDateTime: undefined });
    },
    setIsSort
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    keyword,
    projectStatusList,
    projectContractTypeList,
    pmUserList,
    dateType,
    dateFilter
  }: {
    keyword: string;
    projectStatusList: string[];
    projectContractTypeList: string[];
    pmUserList: string[];
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => {
    const params = new URLSearchParams();

    if (keyword) {
      params.append('keyword', keyword);
    }

    if (!projectStatusList.includes('ALL')) {
      params.set('projectStatusList', projectStatusList.join(','));
    }

    if (!projectContractTypeList.includes('ALL')) {
      params.set('projectContractTypeList', projectContractTypeList.join(','));
    }

    if (pmUserList.length && !pmUserList.includes('0')) {
      params.set('pmUserList', pmUserList.join(','));
    }

    if (
      dateType !== 'ALL' &&
      !!dateFilter.startDateTime &&
      !!dateFilter.endDateTime
    ) {
      params.set('dateType', dateType);
      params.set(
        'startDateTime',
        getDateTime({
          date: new Date(dateFilter.startDateTime),
          filter: 'start'
        })
          .toISOString()
          .replace(/\..+/, '')
      );
      params.set(
        'endDateTime',
        getDateTime({
          date: new Date(dateFilter.endDateTime),
          filter: 'end'
        })
          .toISOString()
          .replace(/\..+/, '')
      );
    }

    return params;
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof companyPerKeys)['list']>
  >): Promise<ICustomerData[]> => {
    const [, filters] = queryKey;
    const getPayload = setPayload(filters);
    setIsFilter([...new Set(getPayload.keys())].length !== 0);

    return (await api.getPms('/statistics/companyPerProjectList', getPayload))
      .data;
  };

  const downloadData = async () => {
    const payload = setPayload({
      keyword,
      projectStatusList,
      projectContractTypeList,
      pmUserList,
      dateType,
      dateFilter
    });

    const url = `${
      process.env.REACT_APP_API_BASE_URL
    }pms/statistics/companyPerProjectList/excel${
      payload.size ? `?${payload}` : ''
    }`;

    downloadByURL(url); // GET
  };
  //#endregion

  //#region useQuery define
  const { data: companyPerList = [] } = useQuery(
    [
      ...companyPerKeys.list({
        keyword,
        projectStatusList,
        projectContractTypeList,
        pmUserList,
        dateType,
        dateFilter
      })
    ],
    getData,
    {
      select: (data) =>
        isSort.code.length
          ? [..._.orderBy(data, [isSort.code], [isSort.isAsc ? 'asc' : 'desc'])]
          : data
    }
  );
  //#endregion

  return {
    keyword,
    projectStatusList,
    projectContractTypeList,
    pmUserList,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    downloadData,
    companyPerList,
    setState,
    filterExposure,
    setFilterExposure
  };
};

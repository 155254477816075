import styled from '@emotion/styled';

export const TaskAmountInputs = ({
  tmMatch,
  taskAmount,
  inputText,
  isDisabled = false,
  changeInput
}: {
  tmMatch: boolean;
  taskAmount: { [key: string]: any };
  inputText: { [key: string]: any };
  isDisabled?: boolean;
  changeInput: (taskAmount: { [key: string]: any }) => void;
}) => {
  const inputArr: any[] = [];
  Object.keys(taskAmount).forEach((key) => {
    // console.debug(key, taskAmount[key]);
    const disabled =
      (!tmMatch && !['PERCENT_REP', 'NO_MATCH'].includes(key)) || isDisabled;
    inputArr.push(
      <div className="inputTableCell" key={key}>
        <div>{inputText[key]}</div>
        <input
          disabled={disabled}
          value={taskAmount[key]?.toLocaleString?.('en-US') ?? ''}
          placeholder={'0'}
          onChange={(e) => {
            const value = Number(e.target.value.replace(/,/g, ''));
            !isNaN(value) && changeInput({ ...taskAmount, [`${key}`]: value });
          }}
        />
      </div>
    );
  });

  return <Wrap>{inputArr.map((El) => El)}</Wrap>;
};

const Wrap = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: space-between;

  .inputTableCell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-items: center;

    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;

    /* background-color: red; //disable => #f6f6fa; */
    font-size: 14px;
    border-radius: 2px;

    div {
      color: #727272;
      text-align: left;
    }
    input {
      width: 100%;
      height: 40px;
      border: 1px solid #ececec;
      text-align: center;
      outline: none;
      color: #727272;
      :disabled {
        background-color: #fafafa;
      }
    }
  }
`;

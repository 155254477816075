import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { cssResetButton } from '../dashboard/utils/css';

export const MyTaskWrap = css`
  padding: 71px 40px 90px;
  font-family: 'IBM Plex Sans', sans-serif !important;
  width: available;
  background-color: #f9f9f9;

  position: relative;

  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .myTaskStateWidget {
    & > div {
      width: calc(20% - 15px);
      text-align: center;
    }
  }
  .language--span--refresh {
    height: 2.8rem;
    top: 1rem;

    display: flex;
    align-items: flex-end;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  .project--dl--info {
    display: flex;
    flex-basis: calc(100% - 200px);
    margin-left: 40px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    dd {
      font-size: 0.875rem;
      > button {
        margin-right: 8px;
      }
    }
  }

  & > .filter-toolbar {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 1052;
    background-color: #fff;

    .title {
      padding-top: 16px;
    }
    .toolbar--left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
    }
    .info3 {
      margin-left: -20px;
    }
    [class*='DashboardWrap'] {
      background-color: #f9f9f9;
      margin-left: -40px;
      margin-right: -40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  & > .content {
    width: max-content;
    margin-left: -40px;
    padding-left: 40px;
    margin-right: -40px;
    padding-right: 40px;
    background-color: #f9f9f9;
    min-width: calc(1360px + 40px * 2);
    & > div {
      position: relative;
    }
  }
`;

//드롭다운

export const ShowMoreButtonWrap = styled.div`
  position: relative;
  line-height: 18px;
  font-size: 24px;
  :hover {
    opacity: 0.45;
  }
`;

export const DropdownWrap = styled.div`
  position: absolute;
  top: -25px;
  right: 5px;
  border: 1px solid #d5d3e4;
  border-radius: 12px;
  padding: 25px;
  background-color: #fff;
  z-index: 9;
  span {
    :hover {
      opacity: 0.45;
    }
  }
`;
export const DropdownBackground = styled.div`
  position: fixed;
  top: -50vw;
  left: -50vh;
  width: 200vw;
  height: 200vh;
  z-index: 2;
`;

export const StateSelectModal = styled.div`
  position: fixed;
  width: 300px;
  border-radius: 24px;
  padding: 40px;
  gap: 40px;
  background-color: #fff;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  height: 100px;
`;

export const PositionBase = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  .content {
    font-size: 14px;
    margin-left: 16px;
  }

  :hover {
    color: #9b51e0;
    cursor: pointer;
  }
`;

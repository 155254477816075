import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryFunctionContext } from '@tanstack/query-core';
import _ from 'lodash';
import api from '@api/Api';
import { ILanguageTask } from '@src/common/config/IProjectDetail';
import { ILanguageTaskListRes } from '@src/common/config/ILanguageTask';
import { WidgetCode } from '@src/common/config/Code';
import { languageTaskKeys } from '@hooks/queryKeyFactory';
import { useHookFunc, usePageFunc } from '@hooks/utils';

/**
 * /languageTask/list
 * @description 담당자 배정 페이지 목록 조회
 * @param filterData
 * @returns {{
 *  isLoading,isError,languagePairList,languageTaskList,taskTitle
 *  dashBoardItemList,taskFilter,dateType,dateFilter,setState
 * }}
 */
export const usePostLanguageTaskList = (filterData: any) => {
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();

  //#region state define
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [taskTitle, setTaskTitle] = useState<string>('');
  const [taskFilter, setTaskFilter] = useState<{ [key: string]: any[] }>({
    workUserIdList: ['0'],
    projectManagerUserIdList: ['0'],
    taskType: ['ALL'],
    languageTaskStatus: ['ALL']
  });
  const [dateType, setDateType] = useState<string>('ALL');
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    startDateTime: undefined,
    endDateTime: undefined
  });
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [sortInfo, setSortInfo] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  useEffect(() => {
    if (!filterData) return;
    setLanguagePairList(filterData?.languagePairList ?? []);
    setTaskTitle(filterData?.taskTitle ?? '');
    setTaskFilter(
      filterData?.taskFilter ?? {
        workUserIdList: ['0'],
        projectManagerUserIdList: ['0'],
        taskType: ['ALL'],
        languageTaskStatus: ['ALL']
      }
    );
    setDateType(filterData?.dateType ?? 'ALL');
    setDateFilter(
      filterData?.dateFilter ?? {
        startDateTime: undefined,
        endDateTime: undefined
      }
    );
  }, [filterData]);
  useEffect(() => {}, [dateFilter]);

  //#region setState
  const stateSetters = {
    setWorkerUser: (e: any) => {
      const workUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(taskFilter.workUserIdList, e),
              (item) => item !== '0'
            );
      setTaskFilter({
        ...taskFilter,
        workUserIdList: workUserIdList.length ? workUserIdList : ['0']
      });
    },
    setPmManager: (e: any) => {
      const projectManagerUserIdList =
        e === '0'
          ? ['0']
          : _.filter(
              arraySet(taskFilter.projectManagerUserIdList, e),
              (item) => item !== '0'
            );
      setTaskFilter({
        ...taskFilter,
        projectManagerUserIdList: projectManagerUserIdList.length
          ? projectManagerUserIdList
          : ['0']
      });
    },
    setTaskType: (e: any) => {
      const taskType =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.taskType, e),
              (item) => item !== 'ALL'
            );
      setTaskFilter({
        ...taskFilter,
        taskType: taskType.length ? taskType : ['ALL']
      });
    },
    setTaskStatus: (e: any) => {
      const languageTaskStatus =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.languageTaskStatus, e),
              (item) => item !== 'ALL'
            );
      setTaskFilter({
        ...taskFilter,
        languageTaskStatus: languageTaskStatus.length
          ? languageTaskStatus
          : ['ALL']
      });
    },
    setTaskTitle,
    setDateType,
    setDateFilter,
    setLanguagePairList,
    setResetState: () => {
      setLanguagePairList([]);
      setTaskTitle('');
      setTaskFilter({
        workUserIdList: ['0'],
        projectManagerUserIdList: ['0'],
        taskType: ['ALL'],
        languageTaskStatus: ['ALL']
      });
      setDateType('ALL');
      setDateFilter({ startDateTime: undefined, endDateTime: undefined });
    },
    setIsSort: setSortInfo
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    languagePairList,
    taskTitle,
    taskFilter,
    dateType,
    dateFilter
  }: {
    languagePairList: ILanguageTask[];
    taskTitle: string;
    taskFilter: { [key: string]: any[] };
    dateType: string;
    dateFilter: { [key: string]: any };
  }): object => {
    const { startDateTime, endDateTime } = dateFilter;
    const taskFilterArr: { [key: string]: any[] } = {};
    const getLanguagePairList =
      !_.some(languagePairList, { startLanguage: 'ALL' }) &&
      languagePairList.length
        ? { languagePairList }
        : undefined;

    Object.keys(taskFilter).forEach((key) => {
      _.map(
        taskFilter[key],
        (el) =>
          !['ALL', '0'].includes(el) && (taskFilterArr[key] = taskFilter[key])
      );
    });

    return {
      ...(getLanguagePairList && getLanguagePairList),
      ...(taskTitle && { taskTitle }),
      ...taskFilterArr,
      ...(dateType !== 'ALL' &&
        startDateTime &&
        endDateTime && {
          dateType,
          startDateTime: getDateTime({
            date: new Date(startDateTime),
            filter: 'start'
          }),
          endDateTime: getDateTime({
            date: new Date(endDateTime),
            filter: 'end'
          })
        })
    };
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof languageTaskKeys)['list']>
  >): Promise<ILanguageTaskListRes> => {
    const [, , filters] = queryKey;
    const getPayload = setPayload(filters);

    setIsFilter(Object.keys(getPayload).length !== 0);

    return await api
      .post('/languageTask/list', { ...getPayload })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const {
    isLoading,
    isError,
    data = {
      languageTaskList: []
    }
  } = useQuery(
    [
      ...languageTaskKeys.list({
        languagePairList,
        taskTitle,
        taskFilter,
        dateType,
        dateFilter
      })
    ],
    getData,
    {
      select: (data) => ({
        languageTaskList: sortInfo.code.length
          ? [
              ..._.orderBy(
                data.languageTaskList,
                [sortInfo.code],
                [sortInfo.isAsc ? 'asc' : 'desc']
              )
            ]
          : data.languageTaskList
      })
    }
  );

  const { languageTaskList } = data;
  //#endregion

  //#region 상단 count
  const dashBoardItemList = WidgetCode.widgetTask.map((code) => ({
    ...code,
    count: Number(
      _.size(
        _.filter(
          languageTaskList,
          (v: any) => v.languageTaskStatus === code.icon
        )
      )
    )
  }));
  //#endregion

  return {
    isLoading,
    isError,
    languagePairList,
    languageTaskList,
    dashBoardItemList,
    taskTitle,
    taskFilter,
    dateType,
    dateFilter,
    isFilter,
    sortInfo,
    stateSetters
  };
};

import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { TPAvatarGrayScaleTheme, TPAvatarTheme } from './TPAvatar.Theme';
import { Badge, Avatar, SxProps, Theme } from '@mui/material';
import AvatarBroken from '@images/avatar-broken.png';

interface IAvatarProps {
  variant?: 'square' | 'rounded' | 'circular' | undefined;
  src?: string;
  alt?: string;
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | undefined;
  badge?: boolean;
  type?: 'group' | 'text' | string | undefined;
  children?: ReactNode | undefined;
  onClick?: (() => void) | string | undefined;
  sx?: SxProps<Theme> | undefined;
  bgcolor?: string;
  grayscale?: boolean;
}

export const TPAvatarBasic = React.forwardRef(
  (
    props: IAvatarProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const {
      variant,
      size,
      badge,
      type,
      src,
      alt,
      children,
      onClick,
      bgcolor,
      grayscale,
      sx
    } = props;
    const propsOverride = {
      ...props,
      badge: null,
      type: null,
      bgcolor: null,
      grayscale: null,
      onClick: typeof onClick === 'string' ? undefined : onClick
    };
    const avatartSrc = src ? src : AvatarBroken;

    return (
      <ThemeProvider theme={grayscale ? TPAvatarGrayScaleTheme : TPAvatarTheme}>
        {type === 'group' ? (
          <Avatar
            variant={variant}
            {...propsOverride}
            className={`${size ? size : ''} ${onClick ? 'cursor-pointer' : ''}`}
            src={avatartSrc}
            alt={alt}
            children={children}
            ref={ref}
            sx={{ ...sx, ...{ bgcolor } }}
          />
        ) : type === 'text' ? (
          <Avatar
            variant={variant}
            {...propsOverride}
            className={`${size ? size : ''} ${onClick ? 'cursor-pointer' : ''}`}
            ref={ref}
            sx={{ ...sx, ...{ bgcolor } }}
          >
            <span className="inner--text">{alt}</span>
          </Avatar>
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant={badge ? 'dot' : undefined}
            className={size ? size : ''}
          >
            <Avatar
              variant={variant}
              {...propsOverride}
              className={`${size ? size : ''} ${
                onClick ? 'cursor-pointer' : ''
              }`}
              src={avatartSrc}
              alt={alt}
              ref={ref}
              sx={{ ...sx, ...{ bgcolor } }}
            />
          </Badge>
        )}
      </ThemeProvider>
    );
  }
);

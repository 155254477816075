import { Outlet } from 'react-router-dom';
import { ModalTheme } from '@hooks/modal/Modal.style';
import { Backdrop, Fade, Grid, Modal, ThemeProvider } from '@mui/material';

function ModalLayout() {
  return (
    <ThemeProvider theme={ModalTheme}>
      <Modal
        styledmodal-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        closeAfterTransition={true}
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Outlet />
          </Grid>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
}
export default ModalLayout;

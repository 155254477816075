//lib
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { format } from 'date-fns';
//etc
import { IconCalendar, IconRefresh } from '@resources/icon';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { MyTaskWrap } from './MyTask.style';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectOne } from '@src/units/select/TPSelect.one';
import {
  getStatus,
  getTaskType,
  getDateType,
  getWorkerType
} from '@src/common/config/Code';
import ModalType from '@src/common/config/ModalType';
import DataTable from '../components/DataTable/DataTable';
import { myTaskColumns } from './MyTask.columns';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { DashBoard } from '../components/DashBoard';
import { LanguagePairSelectComp } from '../components/LanguagePairSelect.Comp';
import UnderlineTitle from '../components/UnderlineTitle';
//hooks
import { usePostMyWorkList } from '@src/hooks/myWork/postList';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { useGetFilter } from '@src/hooks/searchFilter';
import { usePutMyWorkStatus } from '@src/hooks/myWork/putStatus';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import Footer from '@src/layouts/footer/Footer';

function MyTaskPage() {
  const navigate = useNavigate();

  //#region hooks call
  //todo 필터 타입 지정 필요
  const { myInfo } = useMyInfo();
  const { filterData, Component: FilterButton } = useGetFilter('MY_WORK');

  const {
    languagePairList,
    myWorkList,
    dashBoardItemList,
    myWorkFilter,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState
  } = usePostMyWorkList(filterData);
  const { putStatus } = usePutMyWorkStatus();
  const { openHandle, Component: Calendar } = useRangeCalendar();
  //#endregion

  //#region set state
  const [searchValue, setSearchValue] = useState('');
  const {
    setTaskType,
    setWorkerType,
    setWorkStatus,
    setTaskTitle,
    setDateType,
    setDateFilter,
    setLanguagePairList,
    setResetState,
    setIsSort
  } = setState;
  //#endregion

  const resetState = () => {
    setResetState();
    setSearchValue('');
  };

  const clickRowData = (rowData: any) => {
    const { languageSubTaskId } = rowData;
    navigate(`/mytask/${ModalType.SUBTASKDETAIL_PAGE}/${languageSubTaskId}`);
  };

  return (
    <section css={MyTaskWrap}>
      <Box display={'flex'} alignItems="flex-start" mb={'4px'} width={'1768px'}>
        {/* <UnderlineTitle text="내 작업" align="notCenter" /> */}
        <LanguagePairSelectComp
          countItemList={myWorkList}
          languagePairList={languagePairList}
          setLanguagePairList={setLanguagePairList}
          pageType={'mytask'}
        />
      </Box>
      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPInputSearchNew
            value={searchValue}
            placeholder="검색"
            onChange={(e) => setSearchValue(e.target.value)}
            clickSearch={() => setTaskTitle(searchValue)}
          />
          <TPSelectCheck
            label="상태"
            useOptionLabel
            optionList={getStatus([
              'ALL',
              'NEW',
              'PROGRESS',
              'STOP',
              'REJECT',
              'COMPLETE'
            ])}
            selectOptions={myWorkFilter.workerStatus}
            changeSelect={setWorkStatus}
          />
          <TPSelectCheck
            label="역할"
            optionList={getWorkerType([
              'ALL',
              'TRANSLATOR',
              'FIRST_CHECKER',
              'SECOND_CHECKER'
            ])}
            selectOptions={myWorkFilter.workerType}
            changeSelect={setWorkerType}
          />
          <TPSelectCheck
            label="유형"
            optionList={getTaskType(['ALL', 'TRANSLATION', 'POLISHING', 'LQA'])}
            selectOptions={myWorkFilter.taskType}
            changeSelect={setTaskType}
          />
          <TPSelectOne
            label="날짜"
            optionList={getDateType(['ALL', 'START_DATE', 'END_DATE'])}
            selectOption={dateType}
            changeSelect={setDateType}
          />
          {dateType !== 'ALL' && (
            <>
              <div
                onClick={() => {
                  openHandle('mytask', dateFilter, setDateFilter);
                }}
              >
                <TPButtonActionCalendar
                  outline={true}
                  size={'medium'}
                  content={
                    dateFilter.startDateTime && dateFilter.endDateTime
                      ? `${format(
                          new Date(dateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )} - ${format(
                          new Date(dateFilter.endDateTime),
                          'yyyy.MM.dd'
                        )}`
                      : ''
                  }
                  icon={<IconCalendar />}
                />
              </div>
              <Calendar />
            </>
          )}
          {isFilter && (
            <button className="main--button--refresh" onClick={resetState}>
              {/* <IconRefresh /> */}
              <span>초기화</span>
            </button>
          )}
          {/* [내 작업] 필터링 저장 */}
          <FilterButton
            getFilterData={{
              // taskTitle, // 작업명 제거
              myWorkFilter,
              dateType,
              dateFilter,
              languagePairList
            }}
          />
        </Box>
      </Box>
      <DashBoard list={dashBoardItemList} />

      <Box m={'1.5rem 0'}>
        <DataTable
          columns={myTaskColumns(
            myInfo,
            putStatus,
            isSort,
            setIsSort,
            myWorkList
          )}
          dataList={myWorkList}
          clickRowData={clickRowData}
          noRowsMessage={`${isFilter ? '검색된' : '배정된'} 작업이 없습니다.`}
        ></DataTable>
      </Box>
      <Footer />
      <Outlet />
    </section>
  );
}

export default MyTaskPage;

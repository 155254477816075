export default function TPButtonCheck(props: {
  status?: 'normal' | 'selected' | 'dimed';
  onClick?: () => void;
}) {
  const { status = 'normal', onClick } = props;

  if (status === 'normal') return <Normal onClick={onClick} />;
  if (status === 'selected') return <SelectedIcon onClick={onClick} />;
  if (status === 'dimed') return <DimedIcon onClick={onClick} />;
  return <></>;
}

function Normal(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <rect
        x="0.8"
        y="0.8"
        width="14.4"
        height="14.4"
        rx="7.2"
        stroke="#AAAAAA"
        strokeWidth="1.6"
      />
      <path
        d="M11.7639 6.538C12.1042 6.15922 12.0729 5.57633 11.6942 5.23608C11.3154 4.89583 10.7325 4.92707 10.3922 5.30585L7.15114 8.91395L5.55422 7.40894C5.18368 7.05973 4.60021 7.07702 4.251 7.44755C3.90179 7.81809 3.91908 8.40156 4.28962 8.75077L6.5737 10.9034C6.94423 11.2526 7.5277 11.2353 7.87691 10.8648C7.87815 10.8635 7.87937 10.8621 7.8806 10.8608"
        fill="#AAAAAA"
      />
      <path d="M11.7639 6.538L7.89469 10.8454L11.7639 6.538Z" fill="#AAAAAA" />
    </svg>
  );
}

function SelectedIcon(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <rect width="16" height="16" rx="8" fill="#9B51E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7463 6.66827C12.1154 6.25741 12.0815 5.62515 11.6706 5.25608C11.2598 4.88701 10.6275 4.92089 10.2584 5.33175L7.16752 8.77268L5.68586 7.37629C5.28394 6.9975 4.65105 7.01625 4.27226 7.41817C3.89348 7.82009 3.91223 8.45298 4.31415 8.83177L6.54083 10.9303L6.54117 10.9306C6.63766 11.0215 6.74747 11.0896 6.86387 11.1349C7.14037 11.2425 7.45411 11.222 7.71514 11.076C7.80449 11.026 7.88756 10.9614 7.96072 10.8823C7.96518 10.8775 7.96959 10.8727 7.97394 10.8678L11.7463 6.66827Z"
        fill="white"
      />
    </svg>
  );
}

function DimedIcon(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.8"
        y="0.8"
        width="14.4"
        height="14.4"
        rx="7.2"
        stroke="#CBCBCB"
        strokeWidth="1.6"
      />
      <path
        d="M11.7639 6.538C12.1042 6.15922 12.0729 5.57633 11.6942 5.23608C11.3154 4.89583 10.7325 4.92707 10.3922 5.30585L7.15114 8.91395L5.55422 7.40894C5.18368 7.05973 4.60021 7.07702 4.251 7.44755C3.90179 7.81809 3.91908 8.40156 4.28962 8.75077L6.5737 10.9034C6.94423 11.2526 7.5277 11.2353 7.87691 10.8648C7.87815 10.8635 7.87937 10.8621 7.8806 10.8608"
        fill="#CBCBCB"
      />
      <path d="M11.7639 6.538L7.89469 10.8454L11.7639 6.538Z" fill="#CBCBCB" />
    </svg>
  );
}

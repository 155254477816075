import api from '@api/Api';

import { useMemo } from 'react';
import { taskManagerKeys } from '../queryKeyFactory';
import { useQuery } from '@tanstack/react-query';

export function useGetTMProjectList() {
  // 프로젝트 리스트 GET /project/simpleAllList
  const { data: projectSimpleAllList = [] } = useQuery(
    taskManagerKeys.projectSimpleAllList,
    async function () {
      return await api
        .get('/project/projectListByUpload')
        .then((res) => res.data);
    },
    {
      suspense: false
    }
  );

  // 프로젝트 선택박스옵션 리스트
  const selectElementOptionListForProjectSimpleAllList = (() => {
    if (Array.isArray(projectSimpleAllList)) {
      return projectSimpleAllList.map((e: any) => ({
        code: String(e.projectId),
        label: e.projectTitle
      }));
    } else {
      return [];
    }
  })();

  return {
    selectElementOptionListForProjectSimpleAllList
  };
}

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

interface ITPInputNumberNew {
  value?: string;
  readOnly?: boolean;
  placeholder: string;
  autoFocus?: boolean;
  onChange: (e: any) => void;
}

/**
 *
 * @param value 인풋 값
 * @param readOnly 수정 가능여부, true:수정불가 false:수정가능
 * @param placeholder placeholder
 * @param onChange onChange 이벤트 함수
 *
 */

const TPInputNumberNew = (props: ITPInputNumberNew) => {
  const { value, readOnly, autoFocus, placeholder, onChange } = props;

  return (
    <TPInputNewStyle
      autoFocus={autoFocus}
      disabled={readOnly}
      readOnly={readOnly}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default TPInputNumberNew;

interface ITPInputNewStyle {
  readOnly?: boolean;
  /**
   * @params asdf
   */
}

export const TPInputNewStyle = styled.input<ITPInputNewStyle>`
  width: 120px;
  height: 48px;
  border: 1px #d5d3e4 solid;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: #2c3558;

  :focus {
    border: 1px solid #aa51e0;
  }

  ${({ readOnly }) => {
    if (readOnly) {
      return css`
        color: #9a9cb5;
        background-color: transparent;
      `;
    }
  }}
`;

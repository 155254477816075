import styled from '@emotion/styled';
import React from 'react';

interface ITaskNameViewerComp {
  text: string;
  width?: number;
}
export default function TaskNameViewerComp(props: ITaskNameViewerComp) {
  const { text, width } = props;

  const viewText = text.slice(0, 100);

  return <Wrap width={width}>{viewText}</Wrap>;
}

interface IWrap {
  width?: number;
}

const Wrap = styled.div<IWrap>`
  width: ${({ width }) => (width ? `${width}px` : `100%`)};
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  white-space: normal;
  word-break: break-all;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { IconStarFill, IconStarOutline } from '@src/resources/icon';

const style = {
  cursor: 'pointer'
};

export default function TPButtonStar(props: {
  status?: 'normal' | 'selected';
  onClick?: () => void;
}) {
  const { status = 'normal', onClick } = props;

  if (status === 'normal')
    return <IconStarOutline style={style} onClick={onClick} />;
  if (status === 'selected')
    return <IconStarFill style={style} onClick={onClick} />;
  return null;
}

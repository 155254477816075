import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { TPChipTheme } from './TPChip.Theme';
import { IconCircleClose } from '@resources/icon';

interface IChipProps {
  label?: string | [string, string];
  state?: string;
  size?: 'small' | 'medium';
  disabled?: false | true;
  variant?: 'filled' | 'outlined';
  borderround?: boolean;
  onDelete?: () => void | undefined;
}

export const TPChipBasic = React.forwardRef(
  (
    props: IChipProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { state, borderround } = props;
    const propsOverride = { ...props, state: null, borderround: undefined };
    return (
      <ThemeProvider theme={TPChipTheme}>
        <Chip
          {...propsOverride}
          className={`${state ? 'state--' + state : ''}${
            borderround ? ' chip--round' : ''
          }`}
          clickable={false}
          deleteIcon={<IconCircleClose fill={'#9A9CB5'} />}
          ref={ref}
        />
      </ThemeProvider>
    );
  }
);

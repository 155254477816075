import styled from '@emotion/styled';
import { ClickAwayListener } from '@mui/material';
import {
  IcoMenuChevronDown,
  IcoMenuChevronDownActive
} from '@src/resources/icon';
import { useEffect, useState } from 'react';

type AvatarProps = {
  user: any;
  onClick?: () => void;
};

export function Avatar({ user, onClick }: AvatarProps) {
  const [isImgError, setIsImgError] = useState<boolean>(
    Boolean(!user?.avatarImgUrl)
  );

  return user ? (
    <div className="AvatarWrap" onMouseDown={onClick}>
      {isImgError ? (
        <></>
      ) : (
        <img
          className="avatar"
          src={user?.avatarImgUrl}
          alt="no"
          onError={(e) => {}}
          onLoadedData={(e) => setIsImgError(false)}
        />
      )}
      <div className="name">{user?.name}</div>
    </div>
  ) : null;
}

export type TPSelectOnePMProps = {
  label: string;
  pmList: any[];
  value?: any;
  onChange?: (value: any) => void;
};

export default function TPSelectOnePM(props: TPSelectOnePMProps) {
  const { label = '', pmList = [], value, onChange } = props;
  const [user, setUser] = useState<any>(value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        setOpen(false);
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
  }, []);

  return (
    <TPSelectOnePMWrap>
      <div className="result" onClick={() => setOpen((v) => !v)}>
        <div className="label">{label}</div>
        <Avatar user={user} />
        <div className="division">{user ? 'PM팀' : '배정하기'}</div>
        <IcoMenuChevronDown className="icon" />
        <IcoMenuChevronDownActive className="icon-hover" />
      </div>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className="select" onClick={() => setOpen(false)}>
            {pmList
              .sort((a, b) => (a?.name < b?.name ? -1 : 0))
              .map((user, i) => {
                return (
                  <Avatar
                    user={user}
                    key={user.id ?? 10000000 + i}
                    onClick={() => {
                      setUser(user);
                      onChange?.(user);
                      setOpen(false);
                    }}
                  />
                );
              })}
          </div>
        </ClickAwayListener>
      )}
    </TPSelectOnePMWrap>
  );
}

export const TPSelectOnePMWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  position: relative;

  .AvatarWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fafafa;
  }

  .result {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .label {
      line-height: 20px;
      letter-spacing: -0.25px;
      text-align: left;
      color: #aaaaaa;
    }

    .division {
      line-height: 22px;
      letter-spacing: -0.25px;
      text-align: center;
      color: #727272;
    }

    .icon {
      &-hover {
        display: none;
      }
    }
    &:hover {
      .label,
      .name,
      .division {
        color: #9b51e0;
      }
      .avatar {
        filter: brightness(1.1);
      }
      .icon {
        display: none;
        &-hover {
          display: block;
        }
      }
    }
  }

  .select {
    position: absolute;
    top: 32px;
    left: 45.5px;

    height: calc(50vh - 50px);
    max-height: 400px;
    overflow: overlay;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 1;
    padding: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: 0px;
    column-gap: 4px;

    .AvatarWrap {
      width: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0 8px;
      &:hover {
        background-color: #f5f5f5;
        color: #9b51e0;
      }
      .avatar {
        --v: 20px;
        width: var(--v);
        height: var(--v);
      }
    }
  }
`;

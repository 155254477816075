/* 전역 설정 정보 */
interface IConfig {
  version: string;
  googleClientId: string; // 구글 클라이언트 아이디
  apiBaseUrl: string; // axios base url
  apiTimeOut: number; // axios API timeout 딜레이
  toastTimeOut: number; // 토스트 팝업 유지 시간
}

const Config: IConfig = {
  version: '0.0.1',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
  googleClientId:
    '24950519579-4ujlhuao00fl7gh91rtmcies9ac2malq.apps.googleusercontent.com',
  apiTimeOut: 360000,
  toastTimeOut: 1500
};

export default Config;

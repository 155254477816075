import Config from '@common/config/Config';
import axios from 'axios';
import Logger from './Logger';
import { format } from 'date-fns';

let lastAlertTime = 0;

const interceptor = axios.create({
  baseURL: '',
  timeout: Config.apiTimeOut,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
});
interceptor.defaults.headers.post['Content-Type'] = 'application/json';

interceptor.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken !== 'undefined') {
      let accessExp = localStorage.getItem('accessTokenExpiresIn') as any;
      if (!accessExp && accessExp !== null) {
        accessExp = Number(accessExp);
      }
      const nowDate = Date.now();

      if (Number(accessExp) < nowDate) {
        const refreshToken = localStorage.getItem('refreshToken') || '';
        const isRefresh = sessionStorage.getItem('isRefresh');
        if (!isRefresh) {
          sessionStorage.setItem('isRefresh', 'true');
          // @delete cookie;
          // document.cookie = `BORACAT_RT=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

          const req = new XMLHttpRequest();
          const host = process.env.REACT_APP_API_BASE_URL;
          const url = `${host}/tms/auth/refresh`.replace(/\/{2}/g, '/');

          req.open('POST', url, false);
          req.setRequestHeader('Content-Type', 'application/json');
          req.onload = function () {
            try {
              const data = JSON.parse(req.response);
              const keys = [
                'accessToken',
                'accessTokenExpiresIn',
                'refreshToken',
                'refreshTokenExpiresIn'
              ];
              for (let k of keys) {
                let v = data[k];
                localStorage.setItem(k, v);
              }
              sessionStorage.removeItem('isRefresh');
              console.debug(
                format(
                  new Date(data.accessTokenExpiresIn),
                  'yyyy-MM-dd HH:mm:ss'
                ),
                format(
                  new Date(data.refreshTokenExpiresIn),
                  'yyyy-MM-dd HH:mm:ss'
                )
              );
            } catch (e) {
              console.debug('!!!refresh-parse-server-data-error', e);
              sessionStorage.removeItem('isRefresh');
            }
          };
          req.onabort = req.onerror = function (error: any) {
            sessionStorage.removeItem('isRefresh');
            console.error('!!!refresh-connect-error', error);
          };
          req.send(
            JSON.stringify({
              refreshToken,
              loginSite: 'LPM'
            })
          );
        }
      }

      (config.headers || {}).Authorization =
        `bearer ${localStorage.getItem('accessToken') as string}` || '';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
interceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorResponse = error.response || {};
    let errorData = errorResponse.data || {};
    let status = errorResponse.status || 500;

    // 클라이언트 오류
    if (status >= 400 && status < 500) {
      if (status === 401) {
        // 인증 오류시 실행될 문장
      } else if (status === 403) {
        // 권한 오류시 실행될 문장
        // window.location.href = '/errorRoles';
      } else {
        // 그 외 오류
      }
    } else if (status >= 500) {
      // 서버 오류
      Logger.error(errorResponse);
    }

    if (lastAlertTime + 15000 < Date.now()) {
      lastAlertTime = Date.now();
      // alert(error.message);
    }

    return Promise.reject(errorData);
  }
);

export default interceptor;

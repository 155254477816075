import styled from '@emotion/styled';
import { Button } from '@mui/material';
import Api from '@src/api/Api';
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
  useParams
} from 'react-router-dom';
import { useToast } from './useToast';
import TPButtonClose from '@src/units/button/TPButton.Close';

type SaveOption = 'EMPTY' | 'SOURCE';

export default function TaskDownloadPage() {
  const { languageTaskId } = useParams();
  const { openHandle } = useToast();

  const handleDownload = (saveOption: SaveOption) => {
    window.history.back();
    Api.postBora('/export', {
      languageTaskId,
      saveOption
    })
      .then((res) => {
        const url = res.data.downloadUrl;
        if (url) {
          download(url);
          openHandle({
            text: `파일 다운로드가 성공되었습니다.`,
            severity: 'success'
          });
        } else {
          openHandle({
            text: `파일 다운로드에 실패하였습니다.`,
            severity: 'error'
          });
          console.debug(res.data);
        }
      })
      .catch((err) => {
        openHandle({
          text: `파일 다운로드에 실패하였습니다.`,
          severity: 'error'
        });
        console.debug(err);
      });
  };

  return (
    <TaskDownloadPageWrap>
      <TPButtonClose onClick={() => window.history.back()} />
      <h3>다운로드</h3>
      <div className="buttons">
        <Button variant="contained" onClick={() => handleDownload('EMPTY')}>
          빈값으로 채움
        </Button>
        <Button variant="contained" onClick={() => handleDownload('SOURCE')}>
          소스로 채움
        </Button>
      </div>
    </TaskDownloadPageWrap>
  );
}

const TaskDownloadPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 32px;
  border-radius: 2px;
  min-width: 320px;

  .button.close {
    position: absolute;
    right: 8px;
    top: 8px;
  }

  h3 {
    margin-bottom: 24px;
  }
  em {
    color: #eee;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
`;

async function download(url: string) {
  const anchorElement = document.createElement('a');
  document.body.appendChild(anchorElement);
  anchorElement.download = 'some file';
  anchorElement.href = url;
  anchorElement.click();
  document.body.removeChild(anchorElement);
}

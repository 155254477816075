import { useRef, useState, RefObject, useEffect, useMemo } from 'react';

// Utilis

const checkValid = (value: string) => {
  const reg = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
  return reg.test(value);
};

const checkInputedNumber = (key: string) => {
  const reg = /^[0-9]+$/;
  return reg.test(key);
};

const checkInputedArrow = (key: string) => {
  const reg = /^Arrow/;
  return reg.test(key);
};

const checkInputedBackspace = (key: string) => {
  const reg = /^Backspace$/;
  return reg.test(key);
};

const checkInputedDelete = (key: string) => {
  const reg = /^Delete$/;
  return reg.test(key);
};

interface Info {
  value: string;
  selection: string;
  selectionStart: number;
  selectionEnd: number;
  selectionDirection: string;
}
const applyReplace = (info: Info, key: string) => {
  let value: string = '';
  let index = Math.min(info.selectionStart, info.selectionEnd);
  let isChange = false;

  if (
    checkInputedNumber(key) ||
    checkInputedBackspace(key) ||
    checkInputedDelete(key)
  ) {
    if (checkInputedBackspace(key)) {
      // apply
      if (info.selection.length === 0) {
        index = Math.max(0, index - 1);
        if (index === 2) index -= 1;
        value = info.value.slice(0, index) + '0' + info.value.slice(index + 1);
        isChange = true;
      } else {
        const chunk = info.selection.replace(/\d/g, '0');
        value =
          info.value.slice(0, index) +
          chunk +
          info.value.slice(index + chunk.length);
        isChange = true;
      }
    } else if (checkInputedDelete(key)) {
      // apply
      if (info.selection.length === 0) {
        index = Math.max(0, index);
        if (index >= 2) index -= 1;

        let newValue: string[] = [];
        info.value.split(':').map((v) => {
          newValue = newValue.concat(v.split(''));
        });

        newValue[index] = '0';
        newValue.splice(2, 0, ':');
        value = newValue.join('');

        isChange = true;
      } else {
        const chunk = info.selection.replace(/\d/g, '0');
        value = info.value.slice(0, index + chunk.length) + chunk;
        isChange = true;
      }
    } else {
      // check index
      if (index === 2) {
        index = 3; // :앞에서 입력했을 때
      } else if (index === 5) {
        index = 4; // 마지막에 입력했을 때
      }
      let number = key.match(/\d/)?.[0];
      // check inputed number
      if (index === 0) {
        if (Number(number) > 2) {
          number = '2';
        }
      } else if (index === 1) {
        if (info.value[0] === '2' && Number(number) > 3) {
          number = '3';
        }
      } else if (index === 3) {
        if (Number(number) > 5) {
          number = '5';
        }
      }
      // apply
      const chunk = number + info.selection.slice(1);
      value =
        info.value.slice(0, index) +
        chunk +
        info.value.slice(index + chunk.length);
      index += 1;
      if (index === 2) index += 1;
      isChange = true;
    }
  }

  return { value, index, isChange };
};

const getInfo = (el: HTMLInputElement): Info => {
  const value = el.value ?? '';
  let v: string = '';
  if (el) {
    let selectionStart = el.selectionStart ?? 0;
    let selectionEnd = el.selectionEnd ?? 0;
    const selectionDirection = el.selectionDirection ?? '';
    v = el.value.slice?.(...[selectionStart, selectionEnd].sort());
    return {
      selectionStart,
      selectionEnd,
      selectionDirection,
      selection: v,
      value
    };
  } else {
    return {
      selectionStart: 0,
      selectionEnd: 0,
      selectionDirection: '',
      selection: v,
      value
    };
  }
};

const DEFAULT_TIME = '10:00';
const staticKeydownHandle = (e: KeyboardEvent) => {
  if (checkInputedArrow(e.key)) {
  } else {
    e.preventDefault();
  }
  const el = e.currentTarget as HTMLInputElement;
  const { value, index, isChange } = applyReplace(getInfo(el), e.key);

  if (isChange) {
    el.value = value;
    el.setSelectionRange(index, index);
  }
};

// Component

interface useTimeInputProps {
  defaultTime?: string;
  timeInputRef?: RefObject<HTMLInputElement>;
}

export default function useTimeInput({
  defaultTime = DEFAULT_TIME,
  timeInputRef
}: useTimeInputProps) {
  const [value, setValue] = useState<string>(
    checkValid(defaultTime) ? defaultTime : DEFAULT_TIME
  );
  const [info, setInfo] = useState<any>({});
  const changeInfo = (el: HTMLInputElement) =>
    setInfo({ ...info, ...getInfo(el) });

  const valid = useMemo(() => checkValid(value), [value]);
  const changeHandle = (e: KeyboardEvent) => {
    staticKeydownHandle(e);
    const el = e.currentTarget as HTMLInputElement;
    if (el) setValue(el.value);
  };

  useEffect(() => {
    if (!valid) {
      setValue(DEFAULT_TIME);
    }
    const el = timeInputRef?.current;
    if (el) {
      changeInfo(el);
    }
  }, [valid]);

  useEffect(() => {
    const el = timeInputRef?.current;
    if (el) {
      el.value = value;
      el.addEventListener('keydown', changeHandle);

      changeInfo(el);
    }

    return () => {
      if (el) {
        el.removeEventListener('keydown', changeHandle);
      }
    };
  }, []);

  return {
    value,
    info
  };
}

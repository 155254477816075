import { css } from '@emotion/react';

export const errorsWrap = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(#430aa1, #762988);
  background-size: contain;
  .errorsInner {
    text-align: center;
    .errorsStatusWrap {
      display: block;

      .four {
      }
      .zero {
      }
      .errorsStatus {
        font-weight: 700;
        line-height: 1;
        background-clip: text;
        color: #fff;
        font-size: 64px;
        margin: auto -0.5rem;
      }
    }
    .errorsMessage {
      display: block;
      color: #fff;
      line-height: 1;
      font-size: 6.25rem;
      font-weight: 700;
      margin-top: 24px;
    }
    .authMessage {
      display: block;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1;
      font-size: 36px;
      font-weight: 500;
      margin-top: 24px;
    }
    .errorsInfo {
      margin-top: 16px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 2.25rem;
      font-weight: 700;
      &.error {
        font-size: 1rem;
      }
    }
    .errorsHomeBtn {
      display: inline-block;
      margin-top: 40px;
      font-weight: 500;
      background: #fff;
      border: 1px solid #b5b3c6;
      box-sizing: border-box;
      box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.12);
      border-radius: 30px;
      min-width: 240px;
      line-height: 56px;
    }
  }
`;

import { useState } from 'react';
import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { globalKeys } from '../queryKeyFactory';
import { ThemeProvider, Backdrop, Modal, Grid, Box, Fade } from '@mui/material';
import { StateSelectorStyle } from '@hooks/modal/StateSelector.style';
import { alertWrap, ModalTheme } from '@hooks/modal/Modal.style';
import { IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPChipBasic } from '@units/chip/TPChip.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import {
  getStatus,
  getStatusLabel,
  getTaskStatusLabelColor
} from '@src/common/config/Code';
import _ from 'lodash';
import { ITaskerSearch } from '@src/common/config/ICode';
import TPButtonClose from '@src/units/button/TPButton.Close';

/**
 * dateRangePicker hook
 * @type {(props?: IRangeCalendarState) => {dateRange: {from: Date, to: Date }; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useStateChange = () => {
  //#region global state
  const stateChangeData = useGlobalValue([...globalKeys.stateChange], {
    open: false,
    type: '',
    text: '',
    data: '',
    status: ''
  });
  const stateChangeFetcher = useSetGlobalState([...globalKeys.stateChange]);
  const { open, type, data, status, putStatus } = stateChangeData;
  //#endregion

  const setStatus = ({ type, data }: { type: string; data: any }) => {
    if (type === 'project') {
      if (data.status !== 'STOP' && data.status !== 'DELIVERY_COMPLETE') {
        if (data.status === 'COMPLETE') {
          return ['DELIVERY_COMPLETE', 'STOP'];
        } else {
          return ['STOP'];
        }
      }
      return [];
    } else if (type === 'myTask') {
      return data.workerType === 'TRANSLATOR'
        ? ['PROGRESS', 'COMPLETE']
        : ['PROGRESS', 'REJECT', 'COMPLETE'];
    } else {
      return data.status === 'COMPLETE' ? ['DELIVERY_COMPLETE'] : ['STOP'];
    }
  };

  //#region handler
  const openHandle = (arg: ITaskerSearch) => {
    stateChangeFetcher({
      ...stateChangeData,
      ...arg,
      open: true,
      status: setStatus(arg)
    });
  };
  const closeHandle = () => {
    stateChangeFetcher({ ...stateChangeData, open: false });
  };
  const confirmHandle = (status: string) => {
    putStatus(
      type === 'myTask'
        ? { workerId: data.id, workerStatus: status }
        : { taskId: data.taskId, taskStatus: status }
    );
    stateChangeFetcher({ ...stateChangeData, open: false });
  };
  //#endregion

  //#region component
  const Component = (): JSX.Element => {
    // checked box css style
    const [select, setSelect] = useState(type === 'myTask' ? data.status : '');
    return open ? (
      <ThemeProvider theme={ModalTheme}>
        <Modal
          styledmodal-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition={true}
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={open}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div css={[StateSelectorStyle, alertWrap]}>
                <TPButtonClose className="alertClose" onClick={closeHandle} />
                <Box mb={5}>
                  <TPHeadlineBasic decoration type="h2">
                    상태 변경
                  </TPHeadlineBasic>
                </Box>

                <div className="modal--div--contents">
                  {_.map(status, (el, index) => (
                    <div className={'state--list'} key={`state${index}`}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        component="div"
                        onClick={() => setSelect(el)}
                        pb={4}
                      >
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            bgcolor: getTaskStatusLabelColor(el)
                          }}
                        />
                        <Box
                          data-state={getStatus(el)?.[0]?.code}
                          className={`state--list--text ${
                            select === el ? 'active' : ''
                          }`}
                        >
                          {getStatusLabel(el)}
                        </Box>
                      </Box>
                    </div>
                  ))}
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={2}
                    mt={1}
                  >
                    <Box flexGrow={1}>
                      <TPButtonBasic
                        variant="outlined"
                        label="취소"
                        fullWidth
                        onClick={closeHandle}
                        size={'large'}
                      />
                    </Box>
                    <Box flexGrow={1}>
                      <TPButtonBasic
                        variant="contained"
                        label="확인"
                        fullWidth
                        disabled={select === ''}
                        onClick={() => confirmHandle(select)}
                        size={'large'}
                      />
                    </Box>
                  </Box>
                </div>
              </div>
            </Grid>
          </Fade>
        </Modal>
      </ThemeProvider>
    ) : (
      <></>
    );
  };

  return {
    closeHandle,
    openHandle,
    Component
  };
  //#endregion
};

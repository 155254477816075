import { RouterProvider } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Config from '@src/common/config/Config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouteObject } from '@src/hooks/router/useRouterObject';
import ContextProvider from './Context';

if (process.env.NODE_ENV === 'production') {
  console.log = console.warn = console.error = () => {};
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false
      }
    }
  });
  const { routes } = useRouteObject();

  return (
    <ContextProvider>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={Config.googleClientId}>
          <main>
            <RouterProvider router={routes} />
          </main>
        </GoogleOAuthProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </ContextProvider>
  );
}

export default App;

import api from '@api/Api';
import { useToast } from '@hooks/modal/useToast';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { taskManagerKeys } from '../queryKeyFactory';

interface IFilters {
  projectId: string;
  keyword: string;
}

export function useGetTaskManagerList() {
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  // 검색 시 사용 GET /languageTask/list - { projectId, keyword }
  const [keyword, setKeyword] = useState<string>('');
  const [projectId, setProjectId] = useState<string>('');
  const [changeKeyword, setChangeKeyword] = useState<string>('');
  const [filter, setFilter] = useState<IFilters>(() => ({
    projectId: '',
    keyword: ''
  }));

  const { openHandle } = useToast();

  // 검색
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof taskManagerKeys)['list']>
  >): Promise<any> => {
    const [, , filter] = queryKey;
    let payload: IFilters = { projectId: '', keyword: '' };
    if (filter.projectId === '') return [];
    if (filter.projectId) payload.projectId = filter.projectId;
    if (filter.keyword) payload.keyword = filter.keyword;
    return await api
      .get('/project/languageTaskListByUpload', payload)
      .then((res) => res.data);
  };

  // 언어작업목록 조건검색 결과
  const { data: searchResultForSelectedProjectSimpleAllListItem = [] } =
    useQuery(taskManagerKeys.list(filter), getData, {
      suspense: false,
      select: (data) =>
        isSort.code.length
          ? [..._.orderBy(data, [isSort.code], [isSort.isAsc ? 'asc' : 'desc'])]
          : data
    });

  const updateKeyword = () => {
    if (projectId) {
      setFilter({
        projectId,
        keyword
      });
    } else {
      openHandle({ text: '프로젝트를 선택해주세요.', severity: 'error' });
    }
  };

  const updateProjectId = (id: string) => {
    setProjectId(id);
    setFilter({
      projectId: id,
      keyword
    });
  };

  return {
    searchResultForSelectedProjectSimpleAllListItem,
    projectId,
    setProjectId,
    updateProjectId,
    changeKeyword,
    setChangeKeyword,
    updateKeyword,
    keyword,
    setKeyword,
    searchType: projectId,
    setSearchType: setProjectId,
    filter,
    setFilter,
    isSort,
    setIsSort
  };
}

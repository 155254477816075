import { ReactElement, useState, cloneElement, MouseEvent } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { TPPopoverTheme } from './TPPopover.Theme';
import { Link } from 'react-router-dom';
import { Box, Menu, MenuItem } from '@mui/material';

interface IPopoverProps {
  children: ReactElement;
  popoverMenus: {
    clickMenu: () => void;
    label: string;
    to?: string;
    state?: {};
  }[];
  clickPopoverButton?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const TPPopoverMenu = (props: IPopoverProps) => {
  const { children, popoverMenus, clickPopoverButton } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    if (clickPopoverButton) clickPopoverButton(event);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ThemeProvider theme={TPPopoverTheme}>
      {cloneElement(children, { ...children.props, onClick: openPopover })}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closePopover}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '2px',
              m: 0,
              p: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              a: {
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                px: 2,
                py: 0,
                cursor: 'pointer'
              },
              'div:not(:has(a))': {
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                px: 2,
                py: 0,
                cursor: 'pointer'
              }
              // '.MuiList-root': {
              //   p: 0
              // }
            }
          }}
        >
          {popoverMenus.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  item.clickMenu();
                  closePopover();
                }}
              >
                {item.to && item.state ? (
                  <Link to={item?.to} state={{ ...item?.state }}>
                    {item.label}
                  </Link>
                ) : (
                  item.label
                )}
              </Box>
            );
          })}
        </Menu>
      </div>
    </ThemeProvider>
  );
};

import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { IconModalClose } from '@resources/icon';
import { globalKeys } from '../queryKeyFactory';
import { alertWrap, ModalTheme } from '@hooks/modal/Modal.style';
import { Backdrop, Box, Fade, Grid, Modal, ThemeProvider } from '@mui/material';
import styled from '@emotion/styled';
import { IAlertOpen } from '@src/common/config/ICode';
import TPButtonClose from '@src/units/button/TPButton.Close';

const WarningText = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #e6311a;
`;

/**
 * dateRangePicker hook
 * @type {(props?: IRangeCalendarState) => {dateRange: {from: Date, to: Date }; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useAlert = () => {
  //#region global state
  const alertData = useGlobalValue([...globalKeys.alert], {
    open: false,
    title: '',
    text: '',
    okLabel: '확인',
    cancelLabel: '',
    validAmount: false,
    isLQA: false,
    ok: () => {},
    cancel: () => {}
  });
  const alertFetcher = useSetGlobalState([...globalKeys.alert]);
  const {
    open,
    title,
    text,
    okLabel,
    cancelLabel,
    validAmount,
    ok,
    isLQA,
    cancel
  } = alertData;
  //#endregion

  //#region handler
  const openHandle = (data?: IAlertOpen) => {
    alertFetcher({
      ...alertData,
      open: true,
      ...data,
      okLabel: data?.okLabel ?? '확인'
    });
  };
  const closeHandle = () => {
    cancel && cancel();
    alertFetcher({ ...alertData, open: false });
  };
  const clickConfirm = () => {
    ok && ok();
    alertFetcher({ ...alertData, open: false });
  };
  //#endregion

  //#region component
  const Component = (): JSX.Element =>
    open && (
      <ThemeProvider theme={ModalTheme}>
        <Modal
          styledmodal-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition={true}
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={alertData.open}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div css={alertWrap}>
                <TPButtonClose className="alertClose" onClick={closeHandle} />
                <h3 className="alertTitle">{title}</h3>
                {typeof text !== 'string' ? (
                  text
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                )}
                {validAmount ? (
                  <WarningText>
                    * 총 분량과 배분된 부작업의 총합이 불일치합니다.
                  </WarningText>
                ) : null}
                {cancelLabel ? (
                  <Box className="confirmBtnWrap">
                    <button className="confirmBtn cancel" onClick={closeHandle}>
                      {cancelLabel}
                    </button>
                    <button className="confirmBtn ok" onClick={clickConfirm}>
                      {okLabel}
                    </button>
                  </Box>
                ) : (
                  <button className="alertBtn" onClick={clickConfirm}>
                    {okLabel}
                  </button>
                )}
              </div>
            </Grid>
          </Fade>
        </Modal>
      </ThemeProvider>
    );

  return {
    closeHandle,
    openHandle,
    Component
  };
  //#endregion
};

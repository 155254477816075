import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { IcoMenuChevronDown, IconLink } from '@src/resources/icon';
import { HTMLAttributes, useState } from 'react';

interface TPGroupProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  label: string;
}

export function TPGroup({ children, ...props }: TPGroupProps) {
  const [open, setOpen] = useState<boolean>(Boolean(props.open));

  return (
    <TPGroupWrap className={cx({ open })}>
      <div className="button">
        <span
          className="collapse"
          onClick={(e) => {
            e.preventDefault();
            setOpen((v) => !v);
          }}
        >
          <span className="label" style={{ marginLeft: 10 }}>
            {props.label}
          </span>
          <IcoMenuChevronDown className="icon" />
        </span>
      </div>
      {open && children}
    </TPGroupWrap>
  );
}

const TPGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-family: 'IBM Plex Sans', sans-serif;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.25px;
  text-align: left;

  --color: #727272;
  .label {
    color: var(--color);
  }
  .icon {
    stroke-width: 0.2;
    stroke: var(--color);
    fill: var(--color);
    font-size: 16px;
    margin-left: 5px;
  }

  .button {
    width: 100%;
    display: flex;
    color: #242424;
    height: 46px;
    align-items: center;
    user-select: 'none';
    cursor: pointer;

    & .icon {
      transform: rotate(-90deg) translateX(2px);
    }

    .collapse {
      user-select: none;
      .label {
        margin: 0 !important;
      }
    }
  }
  .nodata {
    color: #aaa;
  }
  &.open {
    border-color: transparent;
    background-color: transparent;

    .icon {
      transform: rotate(0deg) translateY(-2px);
    }
  }
`;

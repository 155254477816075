import { Box, TextareaAutosize } from '@mui/material';
import { categories } from '@src/common/config/Code';
import { PROJECT_DETAIL_TYPE, TASK_TYPE } from '@src/common/config/ICode';
import { usePageFunc } from '@src/hooks/utils';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import { TPHeadlineBasic } from '@src/units/character/TPHeadline.Basic';
import { TPChipBasic } from '@src/units/chip/TPChip.Basic';
import _ from 'lodash';

//분량 input box
//todo ui작업
export const TaskAmountReadOnly = ({
  totalGrossAmount,
  totalNetAmount,
  taskAmountList
}: {
  totalGrossAmount: number;
  totalNetAmount: number;
  taskAmountList: any;
}) => {
  const { taskAmountText } = usePageFunc();
  const taskAmountTextArr: any[] = [];

  Object.keys(taskAmountText).forEach((key, idx) =>
    taskAmountTextArr.push(
      <div className="inputTableCell disabled" key={idx}>
        <div>{taskAmountText[key]}</div>
        <input
          value={
            _.find(taskAmountList, (el) => el.matchingRate === key)?.amount ?? 0
          }
          placeholder={'숫자 입력'}
          disabled={true}
        />
      </div>
    )
  );

  return (
    <div className="totalRate">
      <div className="amountInfo">
        <span className="item">총 분량(Gross)</span>
        <span className="value">{totalGrossAmount}</span>
        <span>/</span>
        <span className="item">총 분량(Net) </span>
        <span className="value">{totalNetAmount}</span>
      </div>
      <div className="inputTable">{taskAmountTextArr}</div>
    </div>
  );
};

//모달 하단 공통 부분
export const DetailModalFooter = ({
  hopeSupplyDateTime,
  startDateTime,
  endDateTime,
  description,
  linkList
}: {
  hopeSupplyDateTime?: Date;
  startDateTime?: Date;
  endDateTime?: Date;
  description: string;
  linkList: string[];
}) => {
  const { formattedString } = usePageFunc();
  return (
    <>
      {/* 납품일 */}
      <Box component={'dl'} mt={5}>
        <Box component={'dt'} fontWeight={500} color={' #9A9CB5'}>
          납품일
        </Box>
        <Box component={'dd'} mt={1}>
          {formattedString(hopeSupplyDateTime)}
        </Box>
      </Box>
      {/* 시작일 & 마감일 */}
      <Box display={'flex'} mt={5}>
        <Box component={'dl'} flexGrow={1}>
          <Box component={'dt'} fontWeight={500} color={' #9A9CB5'}>
            시작일
          </Box>
          <Box component={'dd'} mt={1}>
            {formattedString(startDateTime)}
          </Box>
        </Box>
        <Box component={'dl'} flexGrow={1}>
          <Box component={'dt'} fontWeight={500} color={' #9A9CB5'}>
            마감일
          </Box>
          <Box component={'dd'} mt={1}>
            {formattedString(endDateTime)}
          </Box>
        </Box>
      </Box>
      {/* 설명 */}
      <Box component={'dl'} mt={5}>
        <Box component={'dt'} fontWeight={500} color={' #9A9CB5'}>
          설명
        </Box>
        <Box component={'dd'} mt={1}>
          <div className={'descriptionArea'}>
            <TextareaAutosize value={description} readOnly />
          </div>
        </Box>
      </Box>
      {/* 링크 */}
      <Box component={'dl'} mt={5}>
        <Box component={'dt'} fontWeight={500} color={' #9A9CB5'}>
          링크
        </Box>
        <ul className="link--list">
          {_.map(linkList, (el, idx) => (
            <li className="link" key={idx}>
              <a href={el ?? ''} target="_blank" rel="noopener noreferrer">
                {el}
              </a>
            </li>
          ))}
        </ul>
      </Box>
    </>
  );
};

//모달 상단 공통 부분
export const DetailModalHeader = ({
  taskStatus,
  taskKey,
  taskTitle,
  taskType,
  style
}: {
  taskStatus?: PROJECT_DETAIL_TYPE;
  taskKey: string;
  taskTitle: string;
  taskType?: TASK_TYPE;
  style?: any;
}) => {
  return (
    <>
      {/* 스테이터스 & 키 영역 */}
      <Box display={'flex'} gap={1.5} alignItems={'center'} style={style}>
        <StatusBadge statusCode={taskStatus ?? 'NEW'} />
        <p>{taskKey}</p>
      </Box>
      {/* 타이틀 영역 */}
      <Box mt={1}>
        <TPHeadlineBasic display={'block'} textAlign="left" type="h2">
          {taskTitle}
        </TPHeadlineBasic>
      </Box>
      {/* 프로젝트 카테고리 영역 */}
      <Box mt={2} mb={5}>
        <TPChipBasic
          label={_.find(categories, (el) => el.value === taskType)?.label}
          borderround={true}
          variant={'outlined'}
        />
      </Box>
    </>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

interface ITPButtonCustom
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  fontSize?: string;
  border?: string;
  width?: string;
  height?: string;
  padding?: string;
  shadow?: string;
  disabled?: boolean;
  hoverType?: 'black' | 'none';
  onClick?: (item?: any) => void;
}

/**
 *@props label: string;
 *@props fontSize?: string;
 *@props border?: string;
 *@props width?: string;
 *@props height?: string;
 *@props disabled?: boolean;
 *@props onClick?:()=>void;
 */

export const TPButtonCustom = (props: ITPButtonCustom) => {
  const {
    label,
    fontSize,
    border,
    width,
    height,
    disabled,
    padding,
    shadow,
    hoverType,
    onClick
  } = props;

  return (
    <TPButtonCustomStyle
      fontSize={fontSize}
      border={border}
      width={width}
      height={height}
      padding={padding}
      disabled={disabled}
      shadow={shadow}
      hoverType={hoverType}
      onClick={onClick}
    >
      {label}
    </TPButtonCustomStyle>
  );
};

export default TPButtonCustom;

const TPButtonCustomStyle = styled.button<Partial<ITPButtonCustom>>`
  color: #2c3558;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  border: ${({ border }) => (border ? border : '')};
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : '48px')};
  padding: ${({ padding }) => (padding ? padding : '11px 24px')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  border-radius: 40px;
  box-shadow: ${({ shadow }) => (shadow ? shadow : '')};
  white-space: nowrap;
  transition: 0.2s;

  ${({ hoverType }) => {
    if (hoverType) {
      switch (hoverType) {
        case 'none':
          return css`
            :hover {
              cursor: default;
            }
          `;
        case 'black':
          return css`
            :hover {
              border-color: #2c3558;
              background: #2c3558;
              box-shadow: 0 4px 8px rgba(44, 53, 88, 0.24);
              color: #fff;
            }
            :active {
              opacity: 0.6;
            }
          `;

        default:
          return css`
            :hover {
              border-color: #9b51e0;
              color: #9b51e0;
            }
            :active {
              border-color: #9b51e0;
              color: #9b51e0;
              opacity: 0.6;
            }
          `;
      }
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      color: #9a9cb5;
      border-color: #9a9cb5;
      :hover {
        color: #9a9cb5;
        background-color: #fff;
        border-color: #9a9cb5;
        box-shadow: none;
        cursor: default;
      }
    `}
`;

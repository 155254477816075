import styled from '@emotion/styled';
import { IconBtnRocket, IconNextArrow } from '@src/resources/icon';
import {
  AvatarProgressType,
  NewAvatarProgressType,
  TPAvatarWorkerType
} from '@src/units/avatar/TPAvatar.WorkerType';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import _ from 'lodash';
import { ILanguageSubTask } from '@common/config/ILanguageTask';
import { LanguageCodeType, WorkerTypeCode } from '@common/config/Code';
import { IUser, TASK_TYPE } from '@common/config/ICode';

interface ISubTaskProgress {
  subTaskProgressInfo: NewAvatarProgressType[];
  rowData: ISubTask;
  myInfo?: any;
  onClick?: (e: any) => void;
  readonly?: boolean;
}

type pmManagerType =
  | [
      { projectManagerType: 'MAIN'; projectManager: IUser },
      { projectManagerType: 'SUB'; projectManager: IUser }
    ]
  | [];

type StringKeyType = { [key in string]: any };

const order = ['TRANSLATOR', 'FIRST_CHECKER', 'SECOND_CHECKER'];

export interface ISubTask extends ILanguageSubTask, StringKeyType {
  languageSubTaskId: number;
  languageSubTaskKey: string;
  languageSubTaskProcess: WorkerTypeCode;
  languageSubTaskWorker: AvatarProgressType[];
  destinationLanguage: LanguageCodeType;
  endDateTime: Date | string;
  hopeSupplyDate: Date | string;
  netAmount: number;
  pmManager: pmManagerType;
  startDate: Date | string;
  startLanguage: LanguageCodeType;
  taskName: string;
  taskType: TASK_TYPE;
}

export const SubTaskProgress = ({
  subTaskProgressInfo,
  rowData,
  myInfo,
  onClick
}: ISubTaskProgress) => {
  const { workerId, workerType, isStart } = rowData;

  const myWorkProcess = _.find(subTaskProgressInfo, {
    userId: myInfo?.id ?? 0,
    workerType
  });
  const progressList = _.filter(subTaskProgressInfo, (el) => el !== undefined);

  const clickHandler = (e: any, workerStatus: string) => {
    e.stopPropagation();
    onClick && onClick({ workerId, workerStatus });
  };

  if (isStart) {
    if (workerType === myWorkProcess?.workerType) {
      return (
        <TPButtonBasic
          className=""
          label={'작업 시작'}
          onClick={(e) => clickHandler(e, 'PROGRESS')}
          size="small"
          startIcon={<IconBtnRocket />}
          variant="contained"
          disabled={!isStart}
        />
      );
    }
  }

  return (
    <Wrap>
      {_.map(
        [..._.sortBy(progressList, (o) => order.indexOf(o.workerType))],
        (el, index) => {
          return el && myWorkProcess ? (
            <div className="flexCenter" key={index}>
              {index ? (
                <IconNextArrow className="nextArrow" width={6} height={8} />
              ) : null}
              <SimpleTooltip message={el.name} position="center">
                <TPAvatarWorkerType info={el} />
              </SimpleTooltip>
            </div>
          ) : null;
        }
      )}
    </Wrap>
  );
};

export const DashboardSubTaskProgress = ({
  subTaskProgressInfo
}: ISubTaskProgress) => {
  const progressList = _.filter(subTaskProgressInfo, (el) => el !== undefined);

  return (
    <Wrap>
      {_.map(
        [..._.sortBy(progressList, (o) => order.indexOf(o.workerType))],
        (el, index) => {
          return el ? (
            <div className="flexCenter" key={index}>
              {index ? (
                <IconNextArrow className="nextArrow" width={6} height={8} />
              ) : null}
              <SimpleTooltip message={el.name} position="center">
                <TPAvatarWorkerType info={el} />
              </SimpleTooltip>
            </div>
          ) : null;
        }
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.65px;
  width: 108px;
  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nextArrow {
    margin-right: 4.65px;
  }
`;

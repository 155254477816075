import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { useProgress } from '@hooks/modal/useProgress';
import { useStateChange } from '@hooks/modal/useStateChange';

export const useModalContainer = () => {
  const { Component: Toast } = useToast();
  const { Component: Alert } = useAlert();
  const { Component: Progress } = useProgress();
  const { Component: StateChange } = useStateChange();

  const ModalContainer = () => (
    <>
      <StateChange />
      <Toast />
      <Alert />
      {/* <Progress /> */}
    </>
  );

  return { ModalContainer };
};

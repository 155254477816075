import React, { useState, useRef, useEffect } from 'react';
import {
  IcoMenuChevronDown,
  IconCircleClose,
  IconNextArrow
} from '@resources/icon';
import {
  LanguagePairTapStyle,
  LanguagePairSelectStyle
} from './LanguagePairSelect.Style';
import { NotificationBadge } from '@src/units/badge/NotificationBadge';
import TPButtonCustom from '@src/units/button/TPButton.Custom';
import { useLanguageSelect } from '@hooks/modal/useLanguageSelect';
import { useLanguageByCount } from '@hooks/myWork/getLanguageByCount';
import { getLanguageLabelsByCode } from '@common/config/Code';
import _ from 'lodash';
import { cx } from '@emotion/css';
import TPButtonClose from '@src/units/button/TPButton.Close';

interface ILanguagePairSelectProps {
  countItemList: any;
  languagePairList: any;
  setLanguagePairList: any;
  description?: string;
  outSideBtn?: boolean;
  isSelectAll?: boolean;
  type?: string;
  pageType?: string;
}

export const LanguagePairSelectComp = ({
  countItemList,
  languagePairList,
  setLanguagePairList,
  description,
  outSideBtn,
  isSelectAll = false,
  type = 'multi',
  pageType = 'work'
}: ILanguagePairSelectProps) => {
  const { openHandle, Component } = useLanguageSelect(
    setLanguagePairList,
    description,
    isSelectAll,
    type
  );
  const { count, languagePairOptions } = useLanguageByCount({
    countItemList,
    pageType
  });

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const outSideBtnRef = useRef<HTMLDivElement>(null);
  const [prev, setPrev] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);
  const [scrollX, setScrollX] = useState<number>(0);

  useEffect(() => {
    const parentWidth = parentRef.current?.clientWidth || 0;
    const childWidth = childRef.current?.scrollWidth || 0;
    const outSideBtnWidth = outSideBtnRef.current?.clientWidth || 0;
    if (childWidth > parentWidth) {
      if (scrollX === 0) {
        setPrev(false);
        setNext(true);
      }
      if (scrollX > 0 && scrollX < childWidth - parentWidth) {
        setPrev(true);
        setNext(true);
      }
      const comparisonWidth = outSideBtn
        ? childWidth - parentWidth + outSideBtnWidth
        : childWidth - parentWidth;
      if (scrollX === comparisonWidth) {
        setPrev(true);
        setNext(false);
      }
    } else {
      setPrev(false);
      setNext(false);
    }
  }, [scrollX, languagePairList]);

  const scroll = (e: any) => {
    const { scrollLeft } = e.target;
    setScrollX(scrollLeft);
  };

  const prevHandle = () => {
    const parentWidth = parentRef.current?.clientWidth || 0;
    childRef.current?.scrollTo({
      left: scrollX - parentWidth,
      behavior: 'smooth'
    });
  };
  const nextHandle = () => {
    const parentWidth = parentRef.current?.clientWidth || 0;
    childRef.current?.scrollTo({
      left: scrollX + parentWidth,
      behavior: 'smooth'
    });
  };

  const removePair = (item: any) => {
    const removeList = _.filter(languagePairList, (i) => {
      return !(
        i.startLanguage === item.startLanguage &&
        i.destinationLanguage === item.destinationLanguage
      );
    });
    setLanguagePairList(removeList);
  };

  return (
    <div
      css={LanguagePairSelectStyle}
      className={cx([
        'LanguagePairSelectStyle',
        {
          outSideContainer: outSideBtn
        }
      ])}
      ref={parentRef}
    >
      {outSideBtn ? (
        <div className="innerContainer outSideBtn_custom" ref={outSideBtnRef}>
          <TPButtonCustom
            onClick={openHandle}
            label="언어페어 선택"
            padding="11px 20px"
            fontSize="16px"
            height="48px"
            shadow="none"
            border="1px solid #EEEDF4"
            hoverType="black"
          />
        </div>
      ) : null}
      <div className="innerContainer" ref={childRef} onScroll={scroll}>
        {prev ? (
          <div className="arrow--shadow left">
            <div className="arrow" onClick={prevHandle}>
              <IconNextArrow fill="#646782" />
            </div>
            <div className="shadow"></div>
          </div>
        ) : null}

        {next ? (
          <div className="arrow--shadow right">
            <div className="arrow" onClick={nextHandle}>
              <IconNextArrow fill="#646782" />
            </div>
            <div className="shadow"></div>
          </div>
        ) : null}

        {!!languagePairList.length
          ? _.map(JSON.parse(JSON.stringify(languagePairList)), (o: any) => {
              let filterData: any = {
                startLanguage: o.startLanguage,
                destinationLanguage: o.destinationLanguage
              };
              if (pageType === 'work') {
                filterData = {
                  ...filterData,
                  languageTaskStatus: 'NEW'
                };
              } else {
                filterData = {
                  ...filterData,
                  workerStatus: 'NEW'
                };
              }

              const count = _.filter(countItemList, filterData).length;
              return { ...o, count };
            }).map((item: any, index: number) => {
              const { startLanguage, destinationLanguage, count } = item;
              return (
                <div key={`languagePair${index}`}>
                  <div css={LanguagePairTapStyle}>
                    <span className="label">
                      {getLanguageLabelsByCode([startLanguage])[0].label}
                      <IcoMenuChevronDown
                        fill="#727272"
                        style={{ rotate: '-90deg' }}
                      />
                      {getLanguageLabelsByCode([destinationLanguage])[0].label}
                    </span>
                    <div
                      className={cx([
                        'badge',
                        {
                          hidden: count === 0
                        }
                      ])}
                    >
                      {count}
                    </div>
                    <TPButtonClose
                      onClick={() => {
                        removePair(item);
                      }}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <Component
        languagePairList={languagePairList}
        languagePairOptions={languagePairOptions}
      />
    </div>
  );
};
